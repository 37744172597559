import React, {
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';

import Table from 'components/Table';
import { PaymentForm } from 'components/forms';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import { ConfirmModal } from 'components/modals';
import { useScreen } from 'hooks';
import { PAYMENTS } from 'consts/columns';
import {
  usePaymentsApi,
  useCompaniesApi,
  useAgreementsApi,
} from 'hooks/api';

export default ({ screenRef }) => {
  // hooks
  const {
    payments,
    paymentsMeta,
    getPayments,
    deletePayment,
    putPayment,
    postPayment,
    isPendingDeletePayment,
    isPendingPutPayment,
    isPendingPostPayment,
    isPendingGetPayments,
    lastUpdatedGetPayments,
  } = usePaymentsApi();
  const { getCompanies, companiesAll } = useCompaniesApi();
  const { agreementsAll, getAgreements } = useAgreementsApi();
  const onRowClickCallback = (data) => {
    const payload = {
      ...data,
      date: new Date(data.date),
    };

    if (data.expiryDate) {
      payload.expiryDate = new Date(data.expiryDate);
    }
    if (data.exchangeRate) {
      payload.exchangeRate = `${(data.exchangeRate || 0)?.toFixed?.(2)}`;
    }
    if (data.exchangeRateDate) {
      payload.exchangeRateDate = new Date(data.exchangeRateDate);
    }

    return payload;
  };
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getPayments,
    deleteFunc: deletePayment,
    onRowClickCallback,
  });
  // memed vars
  const partnersOptions = useMemo(() => companiesAll
    .map(item => ({
      value: item.id,
      label: item.name,
    })), [companiesAll]);
  // functions
  const onSubmit = ({ accountCurrency, ...values }) => {
    const body = {
      ...values,
      date: values.date ? moment(values.date).format('YYYY-MM-DDTHH:mm:ssZ') : null,
      agreementId: values.agreementId,
      direction: values.direction,
      accountCurrencyAmount: +values.accountCurrencyAmount,
      paymentCurrencyAmount: +values.paymentCurrencyAmount,
      exchangeRate: +values.exchangeRate,
      isConfirmed: values.isConfirmed ?? false,
    };

    if (values.expiryDate) {
      body.expiryDate = moment(values.expiryDate).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    if (values.exchangeRateDate) {
      body.exchangeRateDate = moment(values.exchangeRateDate).format('YYYY-MM-DDTHH:mm:ssZ');
    }

    return (editableRecord ? putPayment : postPayment)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  // effects
  useEffect(() => {
    // todo времено, лучше сделать отдельный запрос на получение всех записей
    getCompanies({
      storeKey: 'companiesAll',
      limit: 1000,
    });

    // todo времено, лучше сделать отдельный запрос на получение всех записей
    getAgreements({ limit: 1000, storeKey: 'agreementsAll' });
  }, []);

  return (
    <div className="payments-layout">
      <Table
        limit={limit}
        total={paymentsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.PAYMENTS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.PAYMENTS.ADD"
        columnDefs={PAYMENTS}
        rowData={payments}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetPayments || !lastUpdatedGetPayments}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={PAYMENTS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.PAYMENTS.PAYMENT"
            editableRecord={editableRecord}
          >
            <PaymentForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              agreementsAll={agreementsAll}
              partnersOptions={partnersOptions}
              initialValues={editableRecord}
              isPending={isPendingDeletePayment || isPendingPostPayment || isPendingPutPayment}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};
