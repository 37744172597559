import endpoints from 'consts/endpoints';

export default ({
  taskId,
  body,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getTreeStructureOrderUrl(taskId),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: updatedTreeStructure => ({
    rateImportInfo: updatedTreeStructure,
  }),
  update: {
    rateImportInfo: (oldValue = {}, newTreeStructure) => ({
      ...oldValue,
      treeStructureElements: newTreeStructure,
    }),
  },
});
