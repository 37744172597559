import endpoints from 'consts/endpoints';

export default ({
  taskId,
  attributeId,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getTreeStructureUrl(taskId, attributeId),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: () => ({
    rateImportInfo: attributeId,
  }),
  update: {
    rateImportInfo: (oldValue = {}, attributeIdToDelete) => ({
      ...oldValue,
      treeStructureElements: oldValue.treeStructureElements
        .filter(item => item.attributeId !== attributeIdToDelete),
    }),
  },
});
