import endpoints from 'consts/endpoints';

export default ({ serviceType, successCallback }) => ({
  url: endpoints.getServicesWithScopesUrl(serviceType),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
});
