import React from 'react';
import { Icon } from '@blueprintjs/core';

import DrawerSource from 'components/Drawer';
import Button from '@setproduct-ui/core/Button';

import styles from './Drawer.module.scss';

import { ReactComponent as UndoIcon } from '../../icons/arrow-rotate-left.svg';
import { ReactComponent as FloppyDiskIcon } from '../../icons/floppy-disk.svg';

const Drawer = ({
  closeDrawer,
  title,
  children,
  onSave,
  isPending,
}) => (
  <DrawerSource
    onClose={closeDrawer}
    title={(
      <div className={styles.drawerTitle}>
        {title}
        <Icon
          icon="cross"
          size={16}
          onClick={closeDrawer}
          color="var(--grey50)"
        />
      </div>
    )}
    size={540}
    style={{ padding: 24 }}
    isOpen
  >
    <div className={styles.drawerBody}>
      {children}
      <div className={styles.footer}>
        <Button
          text="CONTROLS.CANCEL"
          view="outlined"
          onClick={closeDrawer}
          icon={<UndoIcon width={12} fill="var(--grey50)" />}
          disabled={isPending}
          dense
        />
        <Button
          text="CONTROLS.SAVE_CHANGES"
          color="primary"
          onClick={onSave}
          icon={<FloppyDiskIcon width={12} fill="var(--white)" />}
          loading={isPending}
          dense
        />
      </div>
    </div>
  </DrawerSource>
);

export default Drawer;
