import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@setproduct-ui/core/Button/Button';

import SavedTabs from './SavedTabs';
import RightTools from './RightTools';

import './style.scss';

const Header = ({ screenRef }) => {
  const { activeTab } = useSelector(state => state.states.routes);

  const onPinClick = () => {
    screenRef?.current?.saveState();
  };

  return (
    <header className="header">
      <SavedTabs screenRef={screenRef} />
      <Button
        dense
        view="smooth"
        icon="unpin"
        type="icon"
        color="primary"
        iconSize={18}
        onClick={onPinClick}
        className="header__pin-button"
        disabled={!!activeTab}
      />
      <RightTools />
    </header>
  );
};

export default Header;
