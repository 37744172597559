import React, { useMemo, useRef } from 'react';
import cx from 'classnames';
import { Field, FieldArray } from 'formik';
import { differenceBy } from 'lodash';

import { Typography } from 'components/Typography';
import TextField from 'components/fields/TextField';
import { TextAreaField } from 'components/fields';
import { copyInClipboard } from 'helpers';
import { inputPatterns } from 'consts';
import exampleImage from 'assets/images/bot-example.png';

import ContactArrayField from '../ContactArrayField';
import ImageField from '../ImageField';
import FieldsBlockWrapper from '../../FieldsBlockWrapper';

const VisualSettings = ({
  values,
  errors,
  style,
  readOnly,
  showTabs,
  initialValues,
  highlightChanged,
  senderValues,
  styles,
}) => {
  const fieldStyles = (marginTop, width = '100%') => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
  });
  const textareaFieldStyles = {
    container: {
      width: '100%',
    },
  };
  const arrayFieldStyle = {
    marginBottom: 24,
  };
  const imageFieldStyles = ({ width, marginTop }) => ({
    container: { marginTop },
    image: { width },
  });
  const chipsStyles = ({ marginTop, isCurrent } = {}) => ({
    backgroundColor: `var(--${isCurrent ? 'grey' : 'blue'}30)`,
    marginTop,
  });

  const contentRef = useRef(null);

  const dividerBottomValue = useMemo(() =>
    (contentRef.current?.clientHeight || 0) - (contentRef?.current?.scrollHeight || 0), [contentRef.current]);

  const isDiff = (key) => {
    if (!senderValues) {
      return false;
    }

    switch (key) {
      case 'phone':
      case 'website':
      case 'email': {
        const diffValueRes = differenceBy(senderValues[key], initialValues[key], 'value');
        const diffLabelRes = differenceBy(senderValues[key], initialValues[key], 'label');

        return !!diffValueRes.length || !!diffLabelRes.length;
      }
      default: {
        if (!senderValues[key] && !initialValues[key]) {
          return !!senderValues[key] !== !!initialValues[key];
        }

        return senderValues[key] !== initialValues[key];
      }
    }
  };

  const isContactsBlockDiff = isDiff('phone') || isDiff('website') || isDiff('email');
  const isLegalBlockDiff = isDiff('ppPage') || isDiff('tcPage');
  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  };

  return (
    <div className={styles.content} ref={contentRef} style={style}>
      <div className={styles.column}>
        {
          (!readOnly || showTabs) && (
            <>
              <Typography
                className={cx(styles.subtitle, { [styles.subtitle_required]: !readOnly })}
              >
                INSTANCES.SENDER_ID
              </Typography>
              <Field
                name="senderId"
                component={TextField}
                styles={fieldStyles(14)}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={readOnly}
                full
              />
            </>
          )
        }
        <FieldsBlockWrapper
          title="INSTANCES.SENDER_IDS.DISPLAY_NAME"
          isRequired={!readOnly}
          isDiff={isDiff('displayName')}
          highlightChanged={highlightChanged}
          style={{ marginTop: readOnly && !showTabs ? 0 : 24 }}
          formComponent={(
            <Field
              name="displayName"
              component={TextField}
              placeholder="Alaris Labs"
              color={isDiff('displayName') && readOnly ? 'primary' : 'default'}
              maxLength={40}
              disabled={readOnly}
              withCopyButton={readOnly}
              full
            />
          )}
          hintComponent={(
            <TextField
              value={senderValues?.displayName}
              onChange={Function.prototype}
              withCopyButton={readOnly}
              disabled
              full
            />
          )}
        />
        <FieldsBlockWrapper
          title="INSTANCES.DESCRIPTION"
          isDiff={isDiff('description')}
          highlightChanged={highlightChanged}
          formComponent={(
            <Field
              name="description"
              component={TextAreaField}
              styles={textareaFieldStyles}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              minRows={2}
              maxLength={100}
              withCopyButton={readOnly}
              disabled={readOnly}
              color={isDiff('description') && readOnly ? 'primary' : 'default'}
              showCharCount
            />
          )}
          hintComponent={(
            <TextAreaField
              value={senderValues?.description}
              onChange={Function.prototype}
              styles={textareaFieldStyles}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              minRows={2}
              maxLength={100}
              withCopyButton
              disabled
            />
          )}
        />
        <Typography
          className={cx(styles.subtitle, {
            [styles.subtitle_changed]: highlightChanged
              && (isDiff('logoUrl') || isDiff('bannerImageUrl') || isDiff('color')),
          })}
          style={{ marginTop: 24 }}
        >
          SCREENS.SENDER_IDS.IMAGES
        </Typography>
        <Field
          name="logoUrl"
          label="INSTANCES.SENDER_IDS.LOGO"
          component={ImageField}
          text="CONTROLS.SENDER_IDS.ADD_LOGO"
          isDiff={isDiff('logoUrl') && readOnly}
          currentValue={senderValues?.logoUrl}
          disabled={readOnly}
          withDownloadButton
          highlightChanged={highlightChanged}
          styles={imageFieldStyles({
            marginTop: 14,
            width: 164,
          })}
          fileWidth={224}
          fileHeight={224}
          maxWeight={51200}
        />
        <Typography className={styles.hint}>
          {{
            key: 'SCREENS.SENDER_IDS.AVAILABLE_FORMATS',
            values: { formats: 'jpg, png', size: '224x224', weight: 50 },
          }}
        </Typography>
        <Field
          name="bannerImageUrl"
          label="INSTANCES.SENDER_IDS.BACKGROUND"
          component={ImageField}
          text="CONTROLS.SENDER_IDS.ADD_BACKGROUND"
          isDiff={isDiff('bannerImageUrl') && readOnly}
          chipsStyles={chipsStyles}
          currentValue={senderValues?.bannerImageUrl}
          disabled={readOnly}
          withDownloadButton
          highlightChanged={highlightChanged}
          styles={imageFieldStyles({
            marginTop: 24,
            width: '100%',
          })}
          fileWidth={1440}
          fileHeight={448}
          maxWeight={204800}
        />
        <Typography className={styles.hint}>
          {{
            key: 'SCREENS.SENDER_IDS.AVAILABLE_FORMATS',
            values: { formats: 'jpg, png', size: '1440x448', weight: 200 },
          }}
        </Typography>
        <FieldsBlockWrapper
          isDiff={isDiff('color')}
          highlightChanged={highlightChanged}
          formComponent={(
            <Field
              name="color"
              label="INSTANCES.SENDER_IDS.COLOR"
              component={TextField}
              color={isDiff('color') && readOnly ? 'primary' : 'default'}
              disabled={readOnly}
              clearButton={false}
              placeholder="#000000"
              regex="^[#]?[A-Fa-f0-9]{0,6}$"
              fieldTouched
              fieldError={errors.color}
              isRequired
              rightElement={(
                <div
                  style={{ backgroundColor: isColor(values.color) ? values.color : 'transparent' }}
                  className={styles.colorIndicator}
                  role="presentation"
                  onClick={() => copyInClipboard(values.color)}
                />
              )}
              full
            />
          )}
          hintComponent={(
            <TextField
              label="INSTANCES.SENDER_IDS.COLOR"
              onChange={Function.prototype}
              value={senderValues?.color}
              rightElement={(
                <div
                  style={{ backgroundColor: isColor(senderValues?.color) ? senderValues?.color : 'transparent' }}
                  className={styles.colorIndicator}
                  role="presentation"
                  onClick={() => copyInClipboard(senderValues?.color)}
                />
              )}
              full
              disabled
            />
          )}
        />
      </div>
      <div className={styles.divider} style={{ bottom: dividerBottomValue }} />
      <div className={styles.column}>
        <FieldsBlockWrapper
          title="SCREENS.SENDER_IDS.CONTACT_INFORMATION"
          isDiff={isContactsBlockDiff}
          highlightChanged={highlightChanged}
          style={{ marginTop: 0 }}
          formComponent={(
            <>
              <FieldArray
                name="phone"
                render={props => (
                  <ContactArrayField
                    values={values}
                    firstLabel="INSTANCES.PHONE_NUMBER"
                    secondLabel="INSTANCES.NAME"
                    firstPlaceholder="PLACEHOLDERS.PHONE_NUMBER"
                    secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                    instance="INSTANCES.SENDER_IDS.PHONE"
                    maxLength={25}
                    style={arrayFieldStyle}
                    color={isContactsBlockDiff && readOnly ? 'primary' : 'default'}
                    readOnly={readOnly}
                    withCopyButton={readOnly}
                    leftRegex={inputPatterns.numbers({ withPlus: true })}
                    {...props}
                  />
                )}
              />
              <FieldArray
                name="website"
                render={props => (
                  <ContactArrayField
                    values={values}
                    firstLabel="INSTANCES.WEBSITE"
                    firstPlaceholder="PLACEHOLDERS.WEBSITE"
                    secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                    instance="INSTANCES.WEBSITE"
                    maxLength={25}
                    style={arrayFieldStyle}
                    color={isContactsBlockDiff && readOnly ? 'primary' : 'default'}
                    readOnly={readOnly}
                    withCopyButton={readOnly}
                    {...props}
                  />
                )}
              />
              <FieldArray
                name="email"
                render={props => (
                  <ContactArrayField
                    values={values}
                    firstLabel="INSTANCES.EMAIL"
                    firstPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                    secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                    instance="INSTANCES.EMAIL"
                    maxLength={25}
                    style={arrayFieldStyle}
                    color={isContactsBlockDiff && readOnly ? 'primary' : 'default'}
                    readOnly={readOnly}
                    withCopyButton={readOnly}
                    {...props}
                  />
                )}
              />
            </>
          )}
          hintComponent={(
            <>
              <ContactArrayField
                values={senderValues}
                firstLabel="INSTANCES.PHONE_NUMBER"
                secondLabel="INSTANCES.NAME"
                firstPlaceholder="PLACEHOLDERS.PHONE_NUMBER"
                secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                instance="INSTANCES.SENDER_IDS.PHONE"
                style={arrayFieldStyle}
                name="phone"
                readOnly
                withCopyButton
              />
              <ContactArrayField
                values={senderValues}
                firstLabel="INSTANCES.WEBSITE"
                firstPlaceholder="PLACEHOLDERS.WEBSITE"
                secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                instance="INSTANCES.WEBSITE"
                style={arrayFieldStyle}
                name="website"
                readOnly
                withCopyButton
              />
              <ContactArrayField
                values={senderValues}
                firstLabel="INSTANCES.EMAIL"
                firstPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                secondPlaceholder="PLACEHOLDERS.ENTER_VALUE"
                instance="INSTANCES.EMAIL"
                style={arrayFieldStyle}
                name="email"
                readOnly
                withCopyButton
              />
            </>
          )}
        />
        <FieldsBlockWrapper
          title="SCREENS.SENDER_IDS.LEGAL_INFORMATION"
          isDiff={isLegalBlockDiff}
          highlightChanged={highlightChanged}
          formComponent={(
            <>
              <Field
                name="ppPage"
                label="INSTANCES.SENDER_IDS.PRIVACY_POLICY"
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                component={TextField}
                color={isLegalBlockDiff && readOnly ? 'primary' : 'default'}
                disabled={readOnly}
                withCopyButton={readOnly}
                full
              />
              <Field
                name="tcPage"
                label="INSTANCES.SENDER_IDS.TOC_URL"
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                component={TextField}
                styles={fieldStyles(24)}
                color={isLegalBlockDiff && readOnly ? 'primary' : 'default'}
                disabled={readOnly}
                withCopyButton={readOnly}
                full
              />
            </>
          )}
          hintComponent={(
            <>
              <TextField
                label="INSTANCES.SENDER_IDS.PRIVACY_POLICY"
                onChange={Function.prototype}
                value={senderValues?.ppPage}
                full
                disabled
                withCopyButton
              />
              <TextField
                label="INSTANCES.SENDER_IDS.TOC_URL"
                styles={fieldStyles(24)}
                onChange={Function.prototype}
                value={senderValues?.tcPage}
                full
                disabled
                withCopyButton
              />
            </>
          )}
        />
        <Typography className={cx(styles.subtitle, styles.subtitle_border)}>
          SCREENS.SENDER_IDS.EXAMPLE
        </Typography>
        <img
          src={exampleImage}
          className={styles.exampleImage}
          alt=""
        />
      </div>
    </div>
  );
};

export default VisualSettings;
