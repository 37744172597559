import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getDictionariesWithLinksUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => ({
    dictionariesWithLinks: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    dictionariesWithLinks: (oldValue, newValue) => newValue,
  },
});
