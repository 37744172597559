import React, { useRef, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  SwitchField,
  DropdownField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';
import { useDictionariesApi } from 'hooks/api';
import {
  PASSWORD_WITHOUT_RE_SCHEMA,
  CLIENT_SMPP_CONNECTION_SCHEMA,
} from 'consts/validationSchemas';

import styles from './ClientSmppConnectionForm.module.scss';

const DC_TYPES = [
  {
    value: 0,
    label: 'GSM 7 Bit',
  },
  {
    value: 1,
    label: 'IA5 (CCITT T.50)/ASCII (ANSI X3.4)',
  },
  {
    value: 3,
    label: 'Latin 1 (ISO-8859-1)',
  },
  {
    value: 6,
    label: 'Cyrillic (ISO-8859-5)',
  },
  {
    value: 7,
    label: 'Latin/Hebrew (ISO-8859-8)',
  },
  {
    value: 8,
    label: 'UCS2 (ISO/IEC-10646)',
  },
];
const SSL_TYPES = [
  {
    value: 0,
    label: 'Non secure',
  },
  {
    value: 1,
    label: 'SSLv23 / TLSv1.3',
  },
  {
    value: 2,
    label: 'TLSv1',
  },
];

const SmppConnectionsForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {
    // по факту тру, там перевернуто, чтоб пользователю было понятнее
    enabled: false,
  },
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = ({
    width, marginTop, marginBottom, ...inputStyles
  } = {}) => ({
    container: {
      width,
      marginTop,
      marginBottom,
    },
    input: {
      ...inputStyles,
    },
  });
  const dropdownFieldStyles = {
    container: {
      width: '100%',
    },
  };

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const { companiesOptions } = useDictionariesApi();

  const validationSchema = useMemo(() => (mode === 'add'
    ? CLIENT_SMPP_CONNECTION_SCHEMA.concat(PASSWORD_WITHOUT_RE_SCHEMA)
    : CLIENT_SMPP_CONNECTION_SCHEMA), [mode]);
  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    enabled, port, sslType, ...values
  }) => {
    onSubmit({
      ...values,
      enabled: !enabled,
      sslType: +sslType || undefined,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.form}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <Field
              name="name"
              component={TextField}
              label="INSTANCES.NAME"
              disabled={isView}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              fill
            />
            <Field
              name="companyId"
              label="INSTANCES.COMPANY"
              component={DropdownField}
              options={companiesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              styles={dropdownFieldStyles}
              isRequired={!isView}
            />
            <Field
              name="ip"
              component={TextField}
              label="INSTANCES.NETWORK"
              disabled={isView}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <div className={styles.row}>
              <Field
                name="systemId"
                component={TextField}
                label="INSTANCES.LOGIN"
                disabled={isView}
                isRequired={!isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                full
              />
              <Field
                name="password"
                component={TextField}
                label="INSTANCES.PASSWORD"
                disabled={isView}
                isRequired={!isView && mode === 'add'}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                full
              />
            </div>
            <div className={styles.row}>
              <Field
                name="systemType"
                component={TextField}
                label="INSTANCES.SYSTEM_TYPE"
                disabled={isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                full
              />
              <Field
                name="sslType"
                component={DropdownField}
                label="INSTANCES.VENDORS_SMPP.SSL_TYPE"
                styles={dropdownFieldStyles}
                options={SSL_TYPES}
                disabled={isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                isRequired
                full
                clearButton
              />
            </div>
            <Field
              name="defaultDc"
              component={DropdownField}
              label="INSTANCES.CLIENT_SMPP.DEFAULT_DC"
              styles={dropdownFieldStyles}
              options={DC_TYPES}
              disabled={isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              isRequired
              full
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
          >
            <Field
              name="enabled"
              label={`CONTROLS.DISABLE${values?.enabled ? 'D' : ''}`}
              styles={fieldStyle({ marginBottom: 24 })}
              component={SwitchField}
              disabled={isView}
            />
          </FormButtonsGroup>
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default SmppConnectionsForm;
