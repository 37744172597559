import endpoints from 'consts/endpoints';

export default ({
  props,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getAdditionalFeePriceUrl(),
  body: props,
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    hideToast: true,
    successCallback,
    errorCallback,
  },
});
