import React, { useMemo } from 'react';
import { Field } from 'formik';
import { Icon } from '@blueprintjs/core';

import { TextAreaField } from 'components/fields/TextAreaField';
import DropdownField from 'components/fields/DropdownField';
import Button from '@setproduct-ui/core/Button';

import styles from './LocalizationFieldArray.module.scss';

const LocalizationFieldArray = ({
  push,
  remove,
  name,
  form: {
    values,
  },
  disabled,
  languagesOptions,
}) => {
  const dropdownStyle = {
    content: {
      width: 178,
    },
  };

  const languagesOptionsModify = useMemo(() => languagesOptions.map(option => ({
    ...option,
    disabled: values[name]?.find(item => item.language === option.value),
  })), [values[name]]);

  return (
    <div className={styles.fieldArray}>
      {values[name] && values[name].map((field, index) => (
        <div
          className={styles.row}
          // eslint-disable-next-line react/no-array-index-key
          key={`${name}.${index}.row`}
        >
          <Field
            name={`${name}.${index}.language`}
            component={DropdownField}
            options={languagesOptionsModify}
            placeholder="PLACEHOLDERS.SELECT"
            styles={dropdownStyle}
            disabled={disabled}
          />
          <Field
            name={`${name}.${index}.text`}
            component={TextAreaField}
            maxLength={80}
            placeholder="Text with a brief statement of the service or name"
            disabled={disabled}
          />
          {
            index !== 0 && (
              <Icon
                icon="trash"
                className={styles.iconButton}
                size={20}
                onClick={() => remove(index)}
                disabled={disabled}
                data-testid={`${window.location.pathname}/localization-field-array/delete-button/${name}/${index}`}
              />
            )
          }
        </div>
      ))}
      {(!values[name]?.length || values[name]?.length < 3) && (
        <Button
          icon="plus"
          color="primary"
          text="CONTROLS.PORTALS.ADD_TRANSLATION"
          onClick={() => push({ language: null, text: null })}
          disabled={disabled}
          data-testid={`${window.location.pathname}/localization-field-array/add-button`}
          dense
        />
      )}
    </div>
  );
};

export default LocalizationFieldArray;
