import endpoints from 'consts/endpoints';

export default ({
  taskId,
  body,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getRateImportSettingsUrl(taskId),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ settings }) => ({
    rateImportInfo: settings,
  }),
  update: {
    rateImportInfo: (oldValue = {}, newValue) => ({
      filePreview: oldValue.filePreview,
      ...newValue,
    }),
  },
});
