import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getServicePropertiesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: data => ({
    serviceProperties: data.map(({ id, fieldName }) => ({
      value: id,
      label: fieldName.replaceAll('_', ' '),
    })),
  }),
  update: {
    serviceProperties: (oldValue, newValue) => newValue,
  },
});
