import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { useToggle } from 'react-use';
import { Icon, Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { useInvoicesApi } from 'hooks/api';

import './style.scss';

const targetStyle = {
  marginLeft: 'auto',
  cursor: 'pointer',
  marginRight: 3,
  height: 16,
};

const NoteMenu = ({
  invoiceId,
  noteId,
  setNotesList,
  onEditClick,
}) => {
  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  const {
    deleteInvoiceNote,
    isPendingDeleteInvoiceNote,
  } = useInvoicesApi();

  const onEdit = () => {
    onEditClick();
    setIsOpenMenu(false);
  };
  const onDelete = () => {
    deleteInvoiceNote({
      invoiceId,
      noteId,
    }).then(() => {
      setIsOpenMenu(false);
      setNotesList(prev => prev.filter(N => N.id !== noteId));
    });
  };

  return (
    <Popover2
      placement="bottom-end"
      transitionDuration={0}
      isOpen={isOpenMenu}
      interactionKind="click"
      onClose={() => setIsOpenMenu(false)}
      minimal
      usePortal
      renderTarget={({ ref, ...targetProps }) => (
        <div
          ref={ref}
          style={targetStyle}
          {...targetProps}
        >
          <Icon
            size={16}
            icon="more"
            color={`var(--${isOpenMenu ? 'blue' : 'grey'}60)`}
            onClick={setIsOpenMenu}
          />
        </div>
      )}
      content={(
        <div className="note-menu__overlay">
          <div
            role="presentation"
            onClick={onEdit}
            className="note-menu__item"
          >
            <Icon
              icon="edit"
              size={16}
              color="var(--blue60)"
              style={{ marginRight: 6 }}
            />
            {t('CONTROLS.EDIT')}
          </div>
          <div
            role="presentation"
            onClick={onDelete}
            className="note-menu__item"
          >
            {isPendingDeleteInvoiceNote
              ? <Spinner size={16} className="note-menu__item-spinner" />
              : (
                <Icon
                  icon="delete"
                  size={16}
                  color="var(--red60)"
                  style={{ marginRight: 6 }}
                />
              )}
            {t('CONTROLS.DELETE')}
          </div>
        </div>
      )}
    />
  );
};

export default NoteMenu;
