import React from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { round } from 'helpers';
import { decimal } from 'consts/inputPatterns';

import ScaleConfig from './ScaleConfig';
import Multiselect from './Multiselect';
import styles from './PriceCell.module.scss';

import Dropdown from '../Dropdown';
import { ReactComponent as TierIcon } from '../../icons/arrow-up-from-line.svg';
import { ReactComponent as BackToFirstIcon } from '../../icons/arrow-down-left.svg';

const SCALE_TYPES_OPTIONS = [
  {
    value: 1,
    label: 'Tier',
    icon: TierIcon,
    style: {
      color: 'var(--teal60)',
    },
  },
  {
    value: 2,
    label: 'Back-to-first',
    icon: BackToFirstIcon,
    style: {
      color: 'var(--indigo60)',
    },
  },
];

const PriceCell = ({
  priceTypeCellValue,
  disabled,
  isEdit,
  errors,
  touched,
  cellRef,
  counterGroupDisabled,
  counterGroupOptions,
  serviceType,
}) => {
  const dropdownStyle = {
    width: 210,
  };
  const wrapperStyle = {
    cursor: isEdit ? undefined : 'default',
  };

  const { t } = useTranslation();

  if (serviceType !== 1) {
    return null;
  }

  if (priceTypeCellValue === '1/price') {
    const onChange = (e, updateFunc, regex) => {
      const regExp = new RegExp(regex, 'i');

      if (regExp.test(e.target.value)) {
        updateFunc(e);
      }
    };

    return (
      <div className={styles.priceCell} ref={cellRef}>
        <Field name="price">
          {({ field }) => (
            <input
              type="text"
              placeholder={t('PLACEHOLDERS.ENTER_VALUE')}
              className={cx(styles.input, {
                [styles.input_error]: touched.price && errors.price,
              })}
              disabled={disabled}
              {...field}
              value={disabled
                ? round({ num: field.value, decimalsCount: 5 })
                : field.value || ''}
              onChange={e => onChange(e, field.onChange, decimal({ decimalLimit: 5 }))}
              onBlur={Function.prototype}
            />
          )}
        </Field>
        <ErrorMessage name="price">
          {msg => typeof msg === 'string' && <div className={styles.error}>{t(msg)}</div>}
        </ErrorMessage>
      </div>
    );
  }
  if (priceTypeCellValue === '2/price') {
    return (
      <div className={styles.priceCell} ref={cellRef}>
        <Field
          name="scaleType"
          component={Dropdown}
          options={SCALE_TYPES_OPTIONS}
          placeholder="PLACEHOLDERS.SERVICES.SELECT_SCALE_TYPE"
          disabled={disabled}
          textInputStyles={dropdownStyle}
          wrapperStyle={wrapperStyle}
        />
        <FieldArray
          name="price"
          render={props => (
            <ScaleConfig
              {...props}
              errors={errors}
              touched={touched}
              disabled={disabled}
              isEdit={isEdit}
            />
          )}
        />
        <Field
          name="scaleCounterGroupBy"
          component={Multiselect}
          options={counterGroupOptions}
          placeholder="PLACEHOLDERS.SELECT"
          disabled={disabled || counterGroupDisabled}
          wrapperStyle={wrapperStyle}
        />
      </div>
    );
  }

  return <div />;
};

export default PriceCell;
