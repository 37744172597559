import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table from 'components/Table';
import { Modal } from 'components';
import { ConfirmModal } from 'components/modals';
import { MccMncForm } from 'components/forms';
import { useClientsApi, useDictionariesApi } from 'hooks/api';
import { MCC_MNC_COLUMNS } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import { useFilteredColumns, useModalLogic, useTable } from 'hooks';

import './style.scss';
import Prefixes from './Prefixes';

const MccMncScreen = ({ screenRef }) => {
  // hooks
  const tableRef = useRef(null);
  const formValuesRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeTab } = useSelector(state => state.states.routes);
  const [editableRecord, setEditableRecord] = useState();
  const [editableRecordID, setEditableRecordID] = useState();
  const [selectedRowData, setSelectedRowData] = useState();
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const [
    isOpenConfirm,
    {
      openModal: openConfirm,
      closeModal: closeConfirm,
    },
  ] = useModalLogic('');
  const isFormPristine = useRef(false);
  const { countries } = useDictionariesApi();
  const { t } = useTranslation();
  const {
    mccMnc,
    mccMncMeta,
    getMccMnc,
    deleteMccMnc,
    isPendingDeleteMccMnc,
    postMccMnc,
    isPendingPostMccMnc,
    putMccMnc,
    isPendingPutMccMnc,
    isPendingGetMccMnc,
    lastUpdatedGetMccMnc,
  } = useClientsApi();
  const {
    sendQuery,
    setAndSendLimit,
    setAndSendOffset,
    setLimit,
    setOffset,
    limit,
    offset,
    onSortChanged,
    onFilterChanged,
    filters,
    setFilters,
    sort,
    sortRef,
  } = useTable({
    sendQuery: getMccMnc,
    tableRef,
    withInitialQuery: !history.location.state,
  });
  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns();
  // functions
  const onCloseModal = ({ isSave }) => {
    if (isFormPristine.current || isSave) {
      setEditableRecord();
      closeModal();
    } else {
      openConfirm('confirm');
    }
  };
  const onConfirmClose = () => {
    setEditableRecord();
    closeConfirm();
    closeModal();
    if (activeTab) {
      dispatch({ type: 'saveRoute', formValues: undefined });
    }
  };
  const onRowClicked = ({ data }) => {
    setSelectedRowData(data);
  };
  const onEditClicked = ({ data }) => {
    setEditableRecord({
      ...data,
      startDate: data.startDate === null ? null : new Date(data.startDate),
      endDate: data.endDate === null ? null : new Date(data.endDate),
    });
    openModal('view');
  };
  const onDeleteClick = (id) => {
    openConfirm('delete');
    setEditableRecordID(id);
  };

  const onDeleteConfirm = () => {
    closeConfirm();
    deleteMccMnc({
      id: editableRecord?.id || editableRecordID,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  const onSubmit = values => (editableRecord ? putMccMnc : postMccMnc)({
    body: values,
    successCallback: () => {
      sendQuery();
      onCloseModal({ isSave: true });
    },
    errorCallback: () => onCloseModal({ isSave: true }),
  });
  // memed vars
  const countryOptions = useMemo(() => countries
    .map(item => ({
      value: item.label,
      label: item.label,
    })), [countries]);
  const mccMncColumns = useMemo(() => ([
    ...MCC_MNC_COLUMNS,
    {
      headerName: t('INSTANCES.ACTIONS'),
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererActions',
      width: 80,
      minWidth: 80,
      resizable: false,
      sortable: false,
      cellRendererParams: {
        buttons: [
          {
            id: 'delete',
            icon: 'trash',
            color: '#F6655A',
            onClick: ({ id }) => onDeleteClick(id),
          },
          {
            id: 'edit',
            icon: 'edit',
            color: '#2264D1',
            onClick: data => onEditClicked({ data }),
          },
        ],
      },
      lockVisible: true,
      pinned: 'right',
    },
  ]), []);

  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        mccModalMode: isOpenModal,
        selectedRow: selectedRowData,
      });
    }
  }, [isOpenModal, selectedRowData]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        mccTableFilters: filters,
      });
    }
  }, [filters]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        mccTableSort: sort,
      });
    }
  }, [sort]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        mccLimit: limit,
      });
    }
  }, [limit]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        mccOffset: offset,
      });
    }
  }, [offset]);
  useEffect(() => {
    if (history.location.state) {
      const {
        mccTableFilters,
        mccFormValues,
        mccModalMode,
        selectedRow,
        mccLimit = 100,
        mccOffset = 1,
        mccTableSort,
      } = history.location.state;

      if (mccTableSort) {
        sortRef.current = mccTableSort;
        tableRef.current.setSort(mccTableSort.name, mccTableSort.type);
      } else if (sort) {
        sortRef.current = null;
        tableRef.current.setSort();
      }
      if (mccTableFilters) {
        setFilters(mccTableFilters);
        tableRef.current.setFilters(mccTableFilters);
      } else if (filters) {
        setFilters(null);
        tableRef.current.setFilters({});
      }
      if (mccFormValues) {
        setEditableRecord(mccFormValues);
      } else if (editableRecord) {
        setEditableRecord();
      }
      setLimit(mccLimit);
      setOffset(mccOffset);
      setTimeout(() => sendQuery({ stateOffset: mccOffset, stateLimit: mccLimit }));
      openModal(mccModalMode);
      setSelectedRowData(selectedRow);
    } else {
      setFilters(null);
      sortRef.current = null;
      tableRef.current.setSort();
      formValuesRef.current = null;
      tableRef.current.setFilters(null);
      onFilterChanged(null);
      setEditableRecord();
      setSelectedRowData();
      closeConfirm();
      closeModal();
    }
  }, [history.length, history.location.state]);

  return (
    <div className="mcc-mnc">
      <Table
        limit={limit}
        total={mccMncMeta.size}
        page={offset}
        onChangePage={setAndSendOffset}
        onChangeLimit={setAndSendLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.MCCMNC"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.MCCMNC.ADD"
        columnDefs={mccMncColumns}
        rowData={mccMnc}
        onRowClicked={onRowClicked}
        classNames={{
          layout: 'mcc-mnc__layout',
          container: 'mcc-mnc__wrapper',
          pagination: 'mcc-mnc__pagination',
          screenHeader: 'mcc-mnc__header',
        }}
        sendQuery={sendQuery}
        isPending={isPendingGetMccMnc || !lastUpdatedGetMccMnc}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={MCC_MNC_COLUMNS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      <div className="mcc-mnc__prefixes">
        <Prefixes
          mccMnc={selectedRowData?.mccMnc}
          screenRef={screenRef}
          mccFilters={filters}
          mccModalMode={isOpenModal}
          mccFormValues={formValuesRef}
          selectedRowData={selectedRowData}
          mccLimit={limit}
          mccOffset={offset}
          mccSort={sort}
        />
      </div>
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="INSTANCES.MCCMNC"
            editableRecord={editableRecord}
          >
            <MccMncForm
              mode={isOpenModal}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              countriesOptions={countryOptions}
              changeMode={openModal}
              onDelete={onDeleteClick}
              onSubmit={onSubmit}
              isFormPristine={isFormPristine}
              isPending={isPendingDeleteMccMnc || isPendingPostMccMnc || isPendingPutMccMnc}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default MccMncScreen;
