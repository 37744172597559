import endpoints from 'consts/endpoints';

// for reset password from email letter
export default ({
  body,
  successCallback,
} = {}) => ({
  url: endpoints.getResetPasswordUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    successCallback,
  },
});
