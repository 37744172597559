/* eslint-disable */
import moment from 'moment';

export default (filters = {}) => {
	const expressions = Object.keys(filters).reduce((acc, filterId) => {
		const values = filters[filterId].values;
		const filterConfig = values.filterConfig;

		if (filterConfig === 'empty' || filterConfig === 'notempty') {
			acc.push({
				field: filterId,
				operator: `${filterConfig === 'notempty' ? '-' : ''}isnull`,
				value: null,
			});

			return acc;
		}

		switch (filters[filterId].type) {
			case 'mask':
				if (values.value.search(/[*_%]/g) < 0) {
					acc.push({
						field: filterId,
						operator: 'ilike',
						value: `%${values.value}%`,
					});
				} else {
					acc.push({
						field: filterId,
						operator: 'iregex',
						value: `^${values.value.replace(/\*/g, '.*').replace(/_/g, '.')}$`
					});
				}
				break;
			case 'list': {
				const selectedValues = Object.keys(values).reduce(
					(accJ, key) => {
						if (key !== 'filterConfig') {
							if (values[key]) {
								accJ.push(key);
							}
						}
						return accJ;
					}, []);

				acc.push({
					// NOTE: костыль для кейса, когда в одной колонке нужно делать фильтарцию по одному полю, а сортировку - другому
					// в конфиг колонки передается доп поле filterKey, которое позже прокидывается в компоненте AgColumnHeader,
					// а затем попадает сюда
					field: filters[filterId]?.field || filterId,
					operator: 'in',
					value: selectedValues,
				});
				break;
			}
			case 'number':
				if (values.range) {
					if (values.fromValue && values.toValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}range`,
							value: [values.fromValue, values.toValue],
						});
					} else if (values.fromValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}gte`,
							value: values.fromValue,
						});
					} else if (values.toValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}lt`,
							value: values.toValue,
						});
					}
				} else {
					acc.push({
						field: filterId,
						operator: `${values.exclude ? '-' : ''}eq`,
						value: values.fromValue,
					});
				}
				break;
			case 'date': {
				const dateFrom = `${moment(values.fromValue).format('YYYY-MM-DDTHH:mm:ss')}Z`;
				const dateTo = `${moment(values.toValue).format('YYYY-MM-DDTHH:mm:ss')}Z`;

				if (values.range) {
					if (values.fromValue && values.toValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}range`,
							value: [dateFrom, dateTo],
						});
					} else if (values.fromValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}gte`,
							value: dateFrom,
						});
					} else if (values.toValue) {
						acc.push({
							field: filterId,
							operator: `${values.exclude ? '-' : ''}lt`,
							value: dateTo,
						});
					}
				} else {
					acc.push({
						field: filterId,
						operator: `${values.exclude ? '-' : ''}range`,
						value: [
							`${moment(values.fromValue).format('YYYY-MM-DD')}T00:00:00Z`,
							`${moment(values.fromValue).add(1, 'day').format('YYYY-MM-DD')}T00:00:00Z`,
						],
					});
				}
				break;
			}
			default: break;
		}
		return acc;
	}, [])

	return {
		condition: 'AND',
		expressions,
	};
};
