import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getPayments as getPaymentsApi,
  deletePayment as deletePaymentApi,
  putPayment as putPaymentApi,
  postPayment as postPaymentApi,
} from 'queries/payments';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetPayments,
      lastUpdated: lastUpdatedGetPayments,
    },
    getPayments,
  ] = useMutation(getPaymentsApi);
  const [
    {
      isPending: isPendingDeletePayment,
    },
    deletePayment,
  ] = useMutation(deletePaymentApi);
  const [{ isPending: isPendingPutPayment }, putPayment] = useMutation(putPaymentApi);
  const [{ isPending: isPendingPostPayment }, postPayment] = useMutation(postPaymentApi);

  const selector = useCallback(state => ({
    payments: getArray(state, 'payments'),
    paymentsMeta: getObject(state, 'paymentsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getPayments,
    deletePayment,
    putPayment,
    postPayment,
    isPendingGetPayments,
    isPendingDeletePayment,
    isPendingPutPayment,
    isPendingPostPayment,
    lastUpdatedGetPayments,
    ...data,
  };
};
