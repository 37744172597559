import endpoints from 'consts/endpoints';

export default ({
  taskId,
  colId,
  haveToFilterTreeStructure = true,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getColumnSettingsUrl(taskId, colId),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: () => ({
    rateImportInfo: colId,
  }),
  update: {
    rateImportInfo: (oldValue = {}, idToDelete) => ({
      ...oldValue,
      columns: oldValue.columns.filter(col => String(col.idx) !== String(idToDelete)),
      treeStructureElements: haveToFilterTreeStructure
        ? oldValue.treeStructureElements
          ?.filter(item => item.attributeId !== oldValue.columns.find(col => String(col.idx) === String(idToDelete))?.entityId)
        : oldValue.treeStructureElements,
    }),
  },
});
