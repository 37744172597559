import endpoints from 'consts/endpoints';

export default ({ group = 'backoffice' } = {}) => ({
  url: endpoints.getRolesUrl(),
  queryKey: endpoints.getRolesUrl(),
  body: {
    isPrime: false,
    group,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    [`${group}Roles`]: response,
  }),
  update: {
    [`${group}Roles`]: (oldValue, newValue) => newValue,
  },
});
