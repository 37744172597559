import React, { useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './style.scss';

export default ({
  I,
  routes,
}) => {
  const tabItemClass = key => classNames('saved-tabs__tab-item', {
    'saved-tabs__tab-item_active': routes.activeTab.includes(key),
  });
  const tabListItemClass = key => classNames('saved-tabs__tab-list-item', {
    'saved-tabs__tab-list-item_active': routes.activeTab === key,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const onItemClick = (e, item, directory) => {
    e.stopPropagation();
    dispatch({ type: 'setActiveTab', activeTab: item.key });
    history.push({
      pathname: `/${directory}`,
      // Needed to bypass the restrictions on the number of characters for state
      state: directory === 'reports' ? {
        data: JSON.stringify(item),
      } : {
        ...item,
      },
    });
  };
  const onCrossItemClick = (e, key) => {
    e.stopPropagation();
    dispatch({ type: 'deleteRoute', key });
  };
  const onDirectoryCrossClick = (e, key) => {
    e.stopPropagation();
    dispatch({ type: 'deleteDirectory', key });
  };

  return (
    <div
      role="presentation"
      className={tabItemClass(I)}
      onClick={e => onItemClick(e, routes.openTabs[I][0], I)}
      key={I}
      ref={ref}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {routes.openTabs[I].length > 1 && (
      <div className="saved-tabs__counter">
        {routes.openTabs[I].length}
      </div>
      )}
      {t(I)}
      <Icon
        icon="cross"
        color={`var(--blue${routes.activeTab.includes(I) ? '70' : '30'})`}
        iconSize={12}
        onClick={e => onDirectoryCrossClick(e, I)}
      />
      {routes.openTabs[I].length > 1 && isHovered && (
      <div className="saved-tabs__tabs-list" style={{ left: ref.current?.getBoundingClientRect?.()?.left }}>
        {routes.openTabs[I].map(tab => (
          <div
            role="presentation"
            className={tabListItemClass(tab.key)}
            key={tab.key}
            onClick={e => onItemClick(e, tab, I)}
          >
            {tab.key}
            <Icon
              icon="cross"
              color={`var(--blue${routes.activeTab === tab.key ? '70' : '30'})`}
              iconSize={12}
              onClick={e => onCrossItemClick(e, tab.key)}
            />
          </div>
        ))}
      </div>
      )}
    </div>
  );
};
