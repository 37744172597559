import MENU_ITEMS from './menu';

const flattenMenu = menu => menu.reduce((acc, item) => {
  if (item.href) {
    acc.push({ ...item });
  }

  if (item.subLinks) {
    acc.push(...flattenMenu(item.subLinks));
  }

  return acc;
}, []);

export default flattenMenu(MENU_ITEMS);
