import React from 'react';

import Table from 'components/Table';
import { NOTIFICATIONS_CHANNELS_SETTINGS } from 'consts/columns';
import { useScreen } from 'hooks';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import { useNotificationsApi } from 'hooks/api';
import ConfirmModal from 'components/modals/ConfirmModal';

import NotificationsChannelsForm from './NotificationsChannelsForm';

const NotificationsChannelsScreens = ({ screenRef }) => {
  const {
    isPendingGetNotificationsChannels,
    lastUpdatedGetNotificationsChannels,
    isPendingPutNotificationsChannel,
    getNotificationsChannels,
    putNotificationsChannel,
    notificationsChannels,
    notificationsChannelsMeta,
  } = useNotificationsApi();

  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    onDeleteConfirm,
    onConfirmClose,
    closeConfirm,
    isOpenModal,
    onRowClicked,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getNotificationsChannels,
    onRowClickCallback: data => data,
  });

  const onSubmit = (data) => {
    let body = {
      id: data.id,
      name: data.name,
      channelType: Number(data.channelType),
    };
    switch (body.channelType) {
      case 1:
        body = {
          ...body,
          uri: data.uri,
        };
        break;
      case 5:
        body = {
          ...body,
          token: data.token,
        };
        break;
      case 6:
        body = {
          ...body,
          host: data.host,
          port: Number(data.port),
          useTls: Boolean(data.useTls),
          emailSender: data.emailSender,
          emailLogin: data.emailLogin,
          emailPassword: data.emailPassword,
          sslContextProtocol: data.sslContextProtocol,
        };
        break;
      default:
        break;
    }
    putNotificationsChannel({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  const getRowHeight = ({ data }) => (data.channelType === 6 ? 169 : 50);

  return (
    <div>
      <Table
        tableHeader="TABS.NOTIFICATIONS_CHANNELS_SETTINGS"
        columnDefs={NOTIFICATIONS_CHANNELS_SETTINGS}
        rowData={notificationsChannels}
        onRowClicked={onRowClicked}
        showFilters={false}
        getRowHeight={getRowHeight}
        openCustomColumns={openCustomViewDrawer}
        limit={limit}
        total={notificationsChannelsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetNotificationsChannels || !lastUpdatedGetNotificationsChannels}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={NOTIFICATIONS_CHANNELS_SETTINGS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.NOTIFICATIONS_CHANNELS_SETTINGS.NOTIFICATION_CHANNEL"
            editableRecord={editableRecord}
            withInfo={false}
          >
            <NotificationsChannelsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              isFormPristine={isFormPristine}
              isPending={isPendingPutNotificationsChannel}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default NotificationsChannelsScreens;
