import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Chips from '@setproduct-ui/core/Chips/Chips';

import './styles.scss';

export default ({
  value,
  valueFormatted,
}) => {
  const classes = classNames('direction-cell-renderer', {
    'direction-cell-renderer_outbound': value,
    'direction-cell-renderer_inbound': !value,
  });

  const { t } = useTranslation();

  return (
    <Chips
      type="dense"
      view="smooth"
      round={false}
      className={classes}
      tag={t(valueFormatted)}
    />
  );
};
