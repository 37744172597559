import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table from 'components/Table';
import { Modal } from 'components';
import { ConfirmModal } from 'components/modals';
import { DIAL_CODES } from 'consts/columns';
import { PrefixesForm } from 'components/forms';
import { useClientsApi } from 'hooks/api';
import { useModalLogic, useTable } from 'hooks';

import './style.scss';

const Prefixes = ({
  mccMnc,
  screenRef,
  mccFilters,
  mccModalMode,
  mccFormValues,
  selectedRowData,
  mccLimit,
  mccOffset,
  mccSort,
}) => {
  // hooks
  const tableRef = useRef(null);
  const formValuesRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeTab } = useSelector(state => state.states.routes);
  const [editableRecord, setEditableRecord] = useState();
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const [
    isOpenConfirm,
    {
      openModal: openConfirm,
      closeModal: closeConfirm,
    },
  ] = useModalLogic('');
  const isFormPristine = useRef(false);
  const {
    dialCodes,
    dialCodesMeta,
    getDialCodes,
    deleteDialCode,
    isPendingDeleteDialCode,
    postDialCode,
    isPendingPostDialCode,
    putDialCode,
    isPendingPutDialCode,
    isPendingGetDialCodes,
    lastUpdatedGetDialCodes,
  } = useClientsApi();
  const {
    sendQuery,
    setAndSendLimit,
    setAndSendOffset,
    setLimit,
    setOffset,
    limit,
    offset,
    onSortChanged,
    onFilterChanged,
    filters,
    setFilters,
    sort,
    sortRef,
  } = useTable({
    sendQuery: getDialCodes,
    tableRef,
    withInitialQuery: !history.location.state,
  });
  // functions
  useImperativeHandle(screenRef, () => ({
    saveState() {
      const { dialCode } = (formValuesRef.current || {});
      const { mccMnc: mccMncValue } = (mccFormValues.current || {});

      dispatch({
        type: 'saveRoute',
        mccTableFilters: mccFilters,
        mccModalMode,
        mccFormValues: mccFormValues.current,
        selectedRow: selectedRowData,
        mccLimit,
        mccOffset,
        prefixLimit: limit,
        prefixOffset: offset,
        mccTableSort: mccSort,
        prefixTableSort: sort,
        tabKey: (dialCode ? `Prefix: ${dialCode}` : undefined)
          || (mccMncValue ? `MCCMNC: ${mccMncValue}` : undefined),
      });
    },
  }));
  const onCloseModal = ({ isSave }) => {
    if (isFormPristine.current || isSave) {
      setEditableRecord();
      closeModal();
    } else {
      openConfirm('confirm');
    }
  };
  const onConfirmClose = () => {
    setEditableRecord();
    closeConfirm();
    closeModal();
    if (activeTab) {
      dispatch({ type: 'saveRoute', formValues: undefined });
    }
  };
  const onRowClicked = ({ data }) => {
    setEditableRecord({
      ...data,
      startDate: data.startDate === null ? null : new Date(data.startDate),
      endDate: data.endDate === null ? null : new Date(data.endDate),
    });
    openModal('view');
  };
  const onDeleteClick = () => {
    openConfirm('delete');
  };
  const onDeleteConfirm = () => {
    closeConfirm();
    deleteDialCode({
      id: editableRecord.id,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };

  const onSubmit = values => (editableRecord ? putDialCode : postDialCode)({
    body: values,
    successCallback: () => {
      sendQuery();
      onCloseModal({ isSave: true });
    },
    errorCallback: () => onCloseModal({ isSave: true }),
  });
  // effects
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        prefixModalMode: isOpenModal,
      });
    }
  }, [isOpenModal]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        prefixTableFilters: filters,
      });
    }
  }, [filters]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        prefixTableSort: sort,
      });
    }
  }, [sort]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        prefixLimit: limit,
      });
    }
  }, [limit]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        prefixOffset: offset,
      });
    }
  }, [offset]);
  useEffect(() => {
    if (history.location.state) {
      const {
        prefixTableFilters,
        prefixFormValues,
        prefixModalMode,
        prefixLimit = 100,
        prefixOffset = 1,
        prefixTableSort,
      } = history.location.state;

      if (prefixTableSort) {
        sortRef.current = prefixTableSort;
        tableRef.current.setSort(prefixTableSort.name, prefixTableSort.type);
      } else if (sort) {
        sortRef.current = null;
        tableRef.current.setSort();
      }
      if (prefixTableFilters) {
        setFilters(prefixTableFilters);
        tableRef.current.setFilters(prefixTableFilters);
      } else if (filters) {
        setFilters(null);
        tableRef.current.setFilters({});
      }
      if (prefixFormValues) {
        setEditableRecord(prefixFormValues);
      } else if (editableRecord) {
        setEditableRecord();
      }
      setLimit(prefixLimit);
      setOffset(prefixOffset);
      setTimeout(() => sendQuery({ stateOffset: prefixOffset, stateLimit: prefixLimit }));
      openModal(prefixModalMode);
    } else {
      setFilters(null);
      formValuesRef.current = null;
      sortRef.current = null;
      tableRef.current.setSort();
      tableRef.current.setFilters(null);
      onFilterChanged(null);
      setEditableRecord();
      closeConfirm();
      closeModal();
    }
  }, [history.length, history.location.state]);
  useEffect(() => {
    getDialCodes({
      limit: 100,
      'mccMnc[of]': mccMnc,
    });
  }, [mccMnc]);

  return (
    <div className="prefix">
      <Table
        limit={limit}
        total={dialCodesMeta.size}
        page={offset}
        onChangePage={setAndSendOffset}
        onChangeLimit={setAndSendLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={DIAL_CODES}
        tableHeader="TABS.PREFIXES"
        openForm={() => openModal('add')}
        openCustomColumns={() => {}}
        buttonText="CONTROLS.PREFIXES.ADD"
        columnDefs={DIAL_CODES}
        rowData={dialCodes}
        onRowClicked={onRowClicked}
        iconColor="var(--blue70)"
        classNames={{
          layout: 'prefix__layout',
        }}
        showFilters={false}
        showColumns={false}
        buttonView="outlined"
        sendQuery={sendQuery}
        isPending={isPendingGetDialCodes || !lastUpdatedGetDialCodes}
        tableRef={tableRef}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="INSTANCES.PREFIXES.PREFIX"
            editableRecord={editableRecord}
          >
            <PrefixesForm
              mode={isOpenModal}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              changeMode={openModal}
              onDelete={onDeleteClick}
              onSubmit={onSubmit}
              isFormPristine={isFormPristine}
              isPending={isPendingDeleteDialCode || isPendingPostDialCode || isPendingPutDialCode}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default Prefixes;
