import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Popover2 } from '@blueprintjs/popover2';

import Button from '@setproduct-ui/core/Button';
import { DropdownField } from 'components/fields';
import { useIntegrationsApi } from 'hooks/api';
import Spinner from '@setproduct-ui/core/Spinner';
import { CHART_TYPE } from 'consts';
import fetchFile from 'helpers/fetchFile';
import endpoints from 'consts/endpoints';
import getFiltersForQueryNew from 'helpers/getFiltersForQueryNew';
import toastRef from 'helpers/toast';
import { Typography } from 'components';

import { CountryChart, DeliveryChart, OperatorChart } from './charts';
import DateRangeField from './DateRangeField';
import styles from './StatisticBlock.module.scss';

const downloadButtons = [
  {
    format: 'csv',
    text: { key: 'CONTROLS.DOWNLOAD_FORMAT', values: { format: 'CSV' } },
  },
  {
    format: 'xlsx',
    text: { key: 'CONTROLS.DOWNLOAD_FORMAT', values: { format: 'XLSX' } },
  },
];

const StatisticBlock = ({
  id,
  name,
  companyId,
  setStatisticRecord,
}) => {
  const { t } = useTranslation();
  const chartContainerRef = useRef();

  const [popoverShow, setPopoverShow] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [chartType, setChartType] = useState('timeline');
  const [fractionalType, setFractionalType] = useState('1 week');
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(3, 'days').seconds(0).toDate(),
    to: moment().seconds(0).toDate(),
  });

  const {
    getIntegrationStatistics,
    timelineIntegrationsStatistic,
    byCountryIntegrationsStatistic,
    byNetworkIntegrationsStatistic,
    isPendingGetIntegrationStatistics,
  } = useIntegrationsApi();

  const statisticTypes = {
    byNetwork: 'by_network',
    byCountry: 'by_country',
    timeline: 'timeline',
  };

  const isNoData = useMemo(
    () => (!timelineIntegrationsStatistic.length
        && chartType === 'timeline'
        && !isPendingGetIntegrationStatistics
    ) || (!byCountryIntegrationsStatistic.length
        && chartType === 'byCountry'
        && !isPendingGetIntegrationStatistics
    ) || (!byNetworkIntegrationsStatistic.length
      && chartType === 'byNetwork'
      && !isPendingGetIntegrationStatistics
    ),
    [
      byCountryIntegrationsStatistic,
      timelineIntegrationsStatistic,
      byNetworkIntegrationsStatistic,
      isPendingGetIntegrationStatistics,
      chartType,
    ],
  );

  const currentChart = useMemo(() => {
    if (isPendingGetIntegrationStatistics) {
      return <Spinner size={30} />;
    }
    switch (chartType) {
      case 'timeline':
        return (
          <DeliveryChart
            chartContainerRef={chartContainerRef}
            data={timelineIntegrationsStatistic}
            fractionalType={fractionalType}
          />
        );
      case 'byNetwork':
        return (
          <OperatorChart
            data={byNetworkIntegrationsStatistic}
          />
        );
      case 'byCountry':
        return (
          <CountryChart
            data={byCountryIntegrationsStatistic}
          />
        );
      default:
        return <div />;
    }
  }, [
    chartType,
    dateRange,
    timelineIntegrationsStatistic,
    byNetworkIntegrationsStatistic,
    byCountryIntegrationsStatistic,
    chartContainerRef,
    isPendingGetIntegrationStatistics,
    fractionalType,
  ]);

  const onClickDownloadEdrs = (format) => {
    const date = {
      from: new Date(dateRange.from),
      to: new Date(dateRange.to),
    };

    const isDateOnlyPeriod = fractionalType === '1 day'
        || fractionalType === '1 week'
        || fractionalType === '1 month'
        || fractionalType === '1 quarter'
        || fractionalType === '1 year';

    if (isDateOnlyPeriod) {
      date.to.setTime(date.to.getTime() + 1000);
    }

    const filters = {
      time: {
        values: {
          filterConfig: 'byValue',
          range: true,
          fromValue: date?.from?.toISOString(),
          toValue: date?.to?.toISOString(),
        },
        type: 'date',
        isWithoutEmpty: false,
      },
      integrationId: {
        values: {
          filterConfig: 'byValue',
          range: false,
          fromValue: id,
        },
        type: 'number',
        isWithoutEmpty: false,
      },
    };
    setPopoverShow(false);

    const differenceInMonths = moment(date.to).diff(moment(date.from), 'months', true);
    if (differenceInMonths.toFixed(6) <= 6) {
      setIsDownloading(true);
      fetchFile({
        url: endpoints.getEdrDownloadUrl(
          filters
            ? encodeURIComponent(JSON.stringify(getFiltersForQueryNew(filters)))
            : null,
          format,
          companyId,
        ),
        fileName: `edrs.${format}`,
        callback: () => setIsDownloading(false),
      });
    } else {
      toastRef?.current?.showMessage({
        message: 'TOASTS.EDR_DOWNLOAD_LIMIT',
        intent: 'danger',
      });
    }
  };

  const sendQuery = () => {
    const isDateOnlyPeriod = fractionalType === '1 day'
      || fractionalType === '1 week'
      || fractionalType === '1 month'
      || fractionalType === '1 quarter'
      || fractionalType === '1 year';

    const paramPeriodEnd = isDateOnlyPeriod
      ? `${moment(dateRange.to)
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.sss')}Z`
      : dateRange.to.toISOString();

    getIntegrationStatistics({
      type: statisticTypes[chartType],
      entityName: chartType,
      paramIntegrationId: id,
      paramPeriodStart: dateRange.from.toISOString(),
      paramPeriodEnd,
      paramTz: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
      paramGranularity: fractionalType,
    });
  };

  const onClose = () => {
    setStatisticRecord(undefined);
  };

  useEffect(() => {
    if (dateRange?.from && dateRange?.to) {
      sendQuery();
    }
  }, [
    id,
    dateRange,
    chartType,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Button
          type="default"
          view="outlined"
          color="default"
          icon="chevron-left"
          onClick={onClose}
        />
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>{t('SCREENS.TWO_FACTOR.INTEGRATION_STATISTIC')}</div>
          <div className={styles.headerName}>{name}</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.filter}>
          <DateRangeField
            externalValue={dateRange}
            onChange={setDateRange}
            fractionalType={fractionalType}
            onChangeFractional={setFractionalType}
          />
          <div className={styles.typeControl}>
            <div>{t('SCREENS.TWO_FACTOR.INTEGRATION_STATISTIC')}</div>
            <DropdownField
              options={CHART_TYPE}
              value={chartType}
              onChange={setChartType}
            />
          </div>
        </div>
        <div ref={chartContainerRef} className={styles.body}>
          {isNoData ? (
            <div className={styles.noDataMessage}>
              <Icon icon="issue" size={64} color="var(--grey10)" />
              <span className={styles.noDataMessageText}>No data</span>
            </div>
          ) : currentChart}
        </div>
        <div className={styles.footer}>
          <Popover2
            isOpen={popoverShow}
            onInteraction={setPopoverShow}
            placement="top-end"
            transitionDuration={0}
            minimal
            content={(
              <div className={styles.dropdownOverlay}>
                {downloadButtons.map((I, index) => (
                  <div
                    role="presentation"
                    className={styles.downloadButton}
                    onClick={() => onClickDownloadEdrs(I.format)}
                    data-testid={`${window.location.pathname}/download-button/${index}`}
                  >
                    <Typography>
                      {t(I.text.key, { ...I.text.values })}
                    </Typography>
                  </div>
                ))}
              </div>
              )}
          >
            <Button
              view="outlined"
              type="default"
              color="default"
              icon="import"
              text="CONTROLS.TWO_FACTOR.DOWNLOAD_EDR"
              loading={isDownloading}
              disabled={!dateRange?.from || !dateRange?.to || isNoData}
            />
          </Popover2>
          <Button
            type="default"
            view="outlined"
            color="primary"
            icon="refresh"
            onClick={sendQuery}
            loading={isPendingGetIntegrationStatistics}
            disabled={isNoData}
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
