import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Button from '@setproduct-ui/core/Button';
import TextField from 'components/fields/TextField';
import { useInvoicesApi } from 'hooks/api';
import { useUserPermissions } from 'hooks';

import NoteMenu from './NoteMenu';

import './style.scss';

const NotesBlock = ({
  invoiceId,
}) => {
  const { t } = useTranslation();
  const {
    getInvoiceNotes,
    patchInvoiceNote,
    postInvoiceNote,
    isPendingPostInvoiceNote,
    isPendingPatchInvoiceNote,
  } = useInvoicesApi();
  const { editAllowed } = useUserPermissions();
  const [value, setValue] = useState('');
  const [editableNoteId, setEditableNoteId] = useState();
  const [notesList, setNotesList] = useState([]);
  const inputRef = useRef();

  const onEditClick = (note) => {
    setEditableNoteId(note.id);
    setValue(note.note);
    inputRef.current.focus();
  };
  const onCancelEditing = () => {
    setValue('');
    setEditableNoteId(undefined);
  };
  const onSubmit = () => {
    (editableNoteId ? patchInvoiceNote : postInvoiceNote)({
      invoiceId,
      noteId: editableNoteId,
      body: { note: value },
      successCallback: ({ data }) => {
        if (editableNoteId) {
          setNotesList(prev => prev.map((N) => {
            if (N.id === editableNoteId) {
              return data;
            }
            return N;
          }));
          setEditableNoteId(undefined);
        } else {
          setNotesList(prev => [data, ...prev]);
        }
        setValue('');
      },
    });
  };

  useEffect(() => {
    getInvoiceNotes({
      invoiceId,
      successCallback: ({ data }) => setNotesList(data),
    });
  }, []);

  return (
    <div className="notes-block">
      <div className="notes-block__header">
        {t('SCREENS.INVOICES.NOTES')}
      </div>
      <div className="notes-block__list">
        {notesList.map(N => (
          <div
            className="notes-block__list-item"
            key={N.id}
          >
            <div className="notes-block__list-item-column">
              {N.note}
              <div className="notes-block__list-item-row">
                <span className="notes-block__label">
                  by
                </span>
                <span style={{ color: 'var(--blue70)' }}>
                  {`${N.createdByFirstName} ${N.createdByLastName}`}
                </span>
                <span className="notes-block__label" style={{ marginLeft: 'auto' }}>
                  at
                </span>
                <span style={{ color: 'var(--grey50)' }}>
                  {moment(N.createdAt).format('DD.MM.YYYY HH:mm')}
                </span>
              </div>
            </div>
            {editAllowed && (
              <NoteMenu
                invoiceId={invoiceId}
                noteId={N.id}
                setNotesList={setNotesList}
                onEditClick={() => onEditClick(N)}
              />
            )}
          </div>
        ))}
      </div>
      <TextField
        value={value}
        onChange={setValue}
        placeholder="Add note"
        inputRef={inputRef}
        data-testid={`${window.location.pathname}/note-text-input/${invoiceId}`}
        disabled={!editAllowed}
        full
      />
      <div className="notes-block__buttons">
        {editableNoteId && (
          <Button
            onClick={onCancelEditing}
            color="primary"
            view="flat"
            text="CONTROLS.CANCEL"
            dense
          />
        )}
        <Button
          onClick={onSubmit}
          color="primary"
          text="CONTROLS.SAVE"
          icon={editableNoteId ? 'tick' : 'send-message'}
          iconStyle={editableNoteId ? {} : { transform: 'rotate(-45deg) translateX(3px)' }}
          loading={isPendingPostInvoiceNote || isPendingPatchInvoiceNote}
          data-testid={`${window.location.pathname}/save-note-button/${invoiceId}`}
          disabled={!value?.trim() || !editAllowed}
          title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
          dense
        />
      </div>
    </div>
  );
};

export default NotesBlock;
