import React from 'react';
import Highlighter from 'react-highlight-words';

import './style.scss';

export default ({
  value,
}) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(value, 'text/html');
  return (
    <Highlighter
      className="text-cell-renderer"
      highlightClassName="text-cell-renderer__variable"
      highlightTag="span"
      searchWords={[/{{.([^]+?)}}/gm]}
      textToHighlight={html?.documentElement?.textContent}
      autoEscape={false}
    />
  );
};
