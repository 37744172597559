import React from 'react';
import { Icon } from '@blueprintjs/core';

export default ({
  main,
  title,
  removable,
  onRemove,
}) => (
  <div className="run-report-block__tab-content">
    {main && (
      <Icon
        icon="properties"
        color="var(--blue70)"
        iconSize={12}
        style={{
          marginRight: 8,
        }}
      />
    )}
    {title}
    {removable && (
      <Icon
        icon="cross"
        style={{
          marginLeft: 10,
        }}
        color="var(--grey70)"
        iconSize={12}
        onClick={onRemove}
      />
    )}
  </div>
);
