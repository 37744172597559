import { isEmpty } from 'lodash';
import {
  Formik,
  Form,
  Field, FieldArray,
} from 'formik';
import React, { useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'components';
import {
  SwitchField,
  TextAreaField,
  TextField,
  CodeField,
  MultiselectField,
} from 'components/fields';
import Button from '@setproduct-ui/core/Button';
import { REPORTS_CATEGORIES } from 'consts';
import { REPORT_SCHEMA } from 'consts/validationSchemas';

import SettingsBlock from './SettingsBlock';

import IsFormChanged from '../../forms/IsFormChanged';

import './style.scss';

export default ({
  editableReport,
  setIsForm,
  onSubmit,
  reportFormValuesRef,
  reportInitialValues,
  parameters,
  setParameters,
  onDeleteReport,
}) => {
  const fieldStyle = ({
    width,
    marginTop,
    marginRight,
    marginLeft,
    marginBottom,
    ...inputStyles
  } = {}) => ({
    container: {
      width,
      marginTop,
      marginBottom,
      marginRight,
      marginLeft,
    },
    input: {
      ...inputStyles,
    },
  });
  const switchStyles = {
    container: {
      marginTop: 32,
      marginBottom: 8,
    },
    label: {
      fontSize: 14,
    },
  };

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const formRef = useRef();
  const { t } = useTranslation();

  const formInitialValues = useMemo(() => {
    const initial = reportInitialValues || editableReport;
    return {
      ...initial,
      parameters: [],
    };
  }, []);

  const isEdit = useMemo(() => !isEmpty(editableReport), [editableReport]);

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      reportFormValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          rfv: values,
        });
      }
    }
  };
  const onCancelClick = () => {
    setIsForm(false);
    formRef.current.setFieldValue('parameters', []);
    setParameters([]);
  };
  const handleSubmit = () => {
    formRef.current.handleSubmit();
    if (!isEmpty(formRef.current.errors)) {
      Object.keys(formRef.current.errors).map(i => formRef.current.setFieldTouched(i, true));
    }
  };
  const handleSubmitAndClose = () => {
    formRef.current.handleSubmit();
    if (!isEmpty(formRef.current.errors)) {
      Object.keys(formRef.current.errors).map(i => formRef.current.setFieldTouched(i, true));
    } else {
      setIsForm(false);
    }
    formRef.current.setFieldValue('parameters', []);
    setParameters([]);
  };

  useEffect(() => {
    if (editableReport.parameters && !parameters.length) {
      const sortedParameters = editableReport
        .parameters.sort((a, b) => a.parameterOrder - b.parameterOrder);
      formRef.current.setFieldValue('parameters', [
        ...sortedParameters,
      ]);
      setParameters([
        ...sortedParameters,
      ]);
    }
  }, [editableReport.parameters]);

  return (
    <div className="create-report-block">
      <Typography className="create-report-block__header" type="h5">
        SCREENS.REPORTS.REPORT
      </Typography>
      <div className="create-report-block__body">
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={formInitialValues}
          validationSchema={REPORT_SCHEMA}
          onSubmit={(values) => {
            onSubmit({ ...values });
          }}
          validateOnMount
        >
          <Form style={{ display: 'flex', flex: 1 }}>
            <div className="create-report-block__form-container">
              <Typography tagName="span" className="create-report-block__title">
                {isEdit
                  ? t('CONTROLS.REPORTS.EDIT_REPORT_WHAT', { what: editableReport.name })
                  : 'CONTROLS.REPORTS.CREATE_REPORT'}
              </Typography>
              <div className="create-report-block__content">
                <div className="create-report-block__form">
                  <div className="create-report-block__row">
                    <Field
                      name="name"
                      label="SCREENS.REPORTS.REPORT_NAME"
                      component={TextField}
                      isRequired
                      clearButton={false}
                      classNames={{
                        container: 'create-report-block__name-field',
                      }}
                      styles={fieldStyle({ width: 363 })}
                    />
                    <Field
                      name="categories"
                      label="SCREENS.REPORTS.CATEGORIES"
                      component={MultiselectField}
                      styles={fieldStyle({ width: 363, marginLeft: 16 })}
                      options={REPORTS_CATEGORIES}
                      allowCreate={false}
                      isRequired
                    />
                  </div>
                  <Field
                    name="description"
                    label="INSTANCES.DESCRIPTION"
                    component={TextAreaField}
                    styles={fieldStyle({ width: 363, marginTop: 24 })}
                    minRows={2}
                  />
                  <Field
                    name="isPublic"
                    color="primary"
                    label="SCREENS.REPORTS.AVAILABLE_TO_CLIENTS"
                    component={SwitchField}
                    styles={switchStyles}
                    isRequired
                  />
                  <Field
                    name="sqlQuery"
                    component={CodeField}
                    label="SCREENS.REPORTS.SQL_QUERY"
                    showControls={false}
                    resizable
                    isRequired
                    theme="tomorrow"
                    mode="mysql"
                    highlightActiveLine={false}
                    showPrintMargin={false}
                    classNames={{
                      container: 'create-report-block__code-field',
                      input: 'create-report-block__code-field-input',
                    }}
                  />
                  <IsFormChanged checkFunction={checkFunction} />
                </div>
              </div>
            </div>
            <FieldArray name="parameters">
              {props => (
                <SettingsBlock
                  {...props}
                />
              )}
            </FieldArray>
          </Form>
        </Formik>
      </div>
      <div className="create-report-block__footer">
        <Button
          type="default"
          view="outlined"
          color="primary"
          className="create-report-block__button_cancel"
          onClick={onCancelClick}
          style={{
            marginRight: 24,
          }}
        >
          <Typography
            tagName="span"
            className="create-report-block__button-text
            create-report-block__button-text_outlined"
          >
            CONTROLS.CANCEL
          </Typography>
        </Button>
        {isEdit && (
          <Button
            type="default"
            view="outlined"
            color="danger"
            className="create-report-block__button_delete"
            onClick={onDeleteReport}
            style={{
              marginRight: 24,
            }}
          >
            <Typography
              tagName="span"
              className="create-report-block__button-text
              create-report-block__button-text_delete"
            >
              CONTROLS.DELETE
            </Typography>
          </Button>
        )}
        {isEdit && (
          <Button
            type="default"
            view="filled"
            color="primary"
            onClick={handleSubmit}
            style={{
              marginRight: 16,
            }}
          >
            <Typography
              tagName="span"
              className="create-report-block__button-text"
            >
              CONTROLS.SAVE
            </Typography>
          </Button>
        )}
        <Button
          type="default"
          view="filled"
          color="primary"
          onClick={handleSubmitAndClose}
        >
          <Typography
            tagName="span"
            className="create-report-block__button-text"
          >
            {isEdit ? 'CONTROLS.REPORTS.SAVE_AND_CLOSE' : 'CONTROLS.SAVE'}
          </Typography>
        </Button>
      </div>
    </div>
  );
};
