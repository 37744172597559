import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { MediaPreview, Typography } from 'components';
import {
  CheckboxField,
  DropdownField, SwitchField, TextAreaField, TextField,
} from 'components/fields';

import styles from './TemplateContent.module.scss';

const BUTTONS_TYPE = [
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.REPLY',
    value: 'replyAction',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.COPY_CODE',
    value: 'copyAction',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.URL',
    value: 'urlAction',
  },
];

export default ({
  selectedType,
  header,
  body,
  footer,
  suggestionsInside,
  limitedTimeOffer,
  hasExpiration,
  securityDisclaimer,
  expirationWarning,
  expirationTimer,
  cards,
}) => {
  // styles
  const fieldStyle = ({
    marginTop,
  } = {}) => ({
    container: {
      color: 'var(--grey50)',
    },
    label: {
      marginTop,
    },
  });
  const switchFieldStyle = ({
    marginTop,
    marginBottom,
  } = {}) => ({
    label: {
      fontWeight: 600,
    },
    input: {
      marginTop,
      marginLeft: 0,
      marginBottom,
    },
  });

  const [selectedCard, setSelectedCard] = useState(0);

  const itemClass = index => classNames(styles.carouselItem, {
    [styles.selectedItem]: index === selectedCard,
  });
  const renderHeader = (headerData) => {
    const headerFormat = headerData && headerData?.format ? `INSTANCES.WHATS_APP_TEMPLATES.${headerData.format.toUpperCase()}` : headerData;
    const formatsByType = headerData?.format
      ? {
        IMAGE: { imageUrl: headerData?.image?.link },
        VIDEO: { video: { link: headerData?.video?.link } },
        DOCUMENT: { document: { link: headerData?.document?.link } },
      }[headerData.format] || undefined
      : null;

    return (
      <>
        <SwitchField
          value={!!headerData}
          color="primary"
          type="def"
          iStyle
          leftPosition
          label="INSTANCES.WHATS_APP_TEMPLATES.HEADER"
          styles={switchFieldStyle()}
          disabled
        />
        {headerFormat && (
        <div className={styles.headerType}>
          <Typography>
            INSTANCES.WHATS_APP_TEMPLATES.HEADER_TYPE
          </Typography>
          <Typography>
            {headerFormat}
          </Typography>
        </div>
        )}
        {formatsByType && (
        <div style={{ marginTop: 5 }}>
          <MediaPreview file={formatsByType} />
        </div>
        )}
        {(headerData?.format === 'TEXT') && (
        <TextField
          value={headerData.text}
          label="INSTANCES.WHATS_APP_TEMPLATES.HEADER_TEXT"
          styles={fieldStyle()}
          maxLength={60}
          disabled
          full
        />
        )}
        {headerData?.variables && (
        <div>
          {headerData?.variables.map(I => (
            <TextField
              key={I.key}
              value={I.value}
              styles={fieldStyle()}
              label={{
                key: 'INSTANCES.WHATS_APP_TEMPLATES.VARIABLE',
                values: { number: `{{${I.key}}}` },
              }}
              disabled
            />
          ))}
        </div>
        )}
      </>
    );
  };
  const renderBody = bodyData => (
    <>
      {bodyData?.text && (
        <>
          <Typography style={{ marginTop: 20, fontWeight: 600 }}>
            INSTANCES.WHATS_APP_TEMPLATES.BODY
          </Typography>
          <TextAreaField
            value={bodyData.text}
            label="INSTANCES.WHATS_APP_TEMPLATES.BODY_TEXT"
            styles={{
              input: {
                color: 'var(--grey50)',
              },
              label: {
                marginTop: 5,
              },
            }}
            disabled
          />
        </>
      )}
      {selectedType === 'whatsAppLimitedTimeOfferCard' && (
        <CheckboxField
          value={hasExpiration}
          text="INSTANCES.WHATS_APP_TEMPLATES.LIMITED_DURATION"
          styles={{ label: { marginTop: 5 } }}
          disabled
        />
      )}
      {bodyData?.variables && (
        <div>
          {bodyData.variables.map(I => (
            <TextField
              key={I.key}
              value={I.value}
              label={{
                key: 'INSTANCES.WHATS_APP_TEMPLATES.VARIABLE',
                values: { number: `{{${I.key}}}` },
              }}
              styles={fieldStyle({ marginTop: 2 })}
              disabled
              full
            />
          ))}
        </div>
      )}
    </>
  );
  const renderButtons = buttonsData => (
    <>
      <SwitchField
        value={!!buttonsData?.length}
        leftPosition
        label="INSTANCES.WHATS_APP_TEMPLATES.BUTTONS"
        styles={switchFieldStyle({ marginTop: 20, marginBottom: 10 })}
        color="primary"
        type="def"
        iStyle
        disabled
      />
      {buttonsData && buttonsData.map((item, index) => (
        <>
          <Typography style={{ marginTop: 10 }}>
            {{ key: 'INSTANCES.WHATS_APP_TEMPLATES.BUTTON_VARIABLES', values: { number: index + 1 } } }
          </Typography>
          <div className={styles.buttonBlock}>
            <DropdownField
              options={BUTTONS_TYPE}
              value={item.category}
              label="INSTANCES.WHATS_APP_TEMPLATES.BUTTON_TYPE"
              styles={{
                input: {
                  color: 'var(--grey50)',
                },
              }}
              disabled
              fill
            />
            <TextField
              value={item?.code ? item.code : item.text}
              label="INSTANCES.WHATS_APP_TEMPLATES.BUTTON_TEXT"
              styles={fieldStyle()}
              disabled
              fill
            />
          </div>
          {item.category === 'urlAction' && (
          <TextField
            value={item.url}
            label="Url"
            styles={fieldStyle({ marginTop: 5 })}
            disabled
            fill
          />
          )}
        </>
      ))}
    </>
  );

  switch (selectedType) {
    case 'whatsAppRichCard':
    case 'whatsAppCouponCodeCard':
      return (
        <div className={styles.content}>
          <Typography large type="body_bold" style={{ marginBottom: 24 }}>
            {`INSTANCES.WHATS_APP_TEMPLATES.${selectedType === 'whatsAppRichCard' ? 'CARD' : 'COUPON_CODE'}`}
          </Typography>
          {renderHeader(header)}
          {renderBody(body)}
          {selectedType === 'whatsAppRichCard' && (
            <SwitchField
              value={!!footer}
              leftPosition
              label="INSTANCES.WHATS_APP_TEMPLATES.FOOTER"
              styles={switchFieldStyle({ marginTop: 20 })}
              color="primary"
              type="def"
              iStyle
              disabled
            />
          )}
          {footer?.text && (
          <TextField
            value={footer.text}
            label="INSTANCES.WHATS_APP_TEMPLATES.FOOTER_TEXT"
            styles={fieldStyle({ marginTop: 5 })}
            maxLength={60}
            disabled
            full
          />
          )}
          {renderButtons(suggestionsInside)}
        </div>
      );
    case 'whatsAppCarouselCard':
      return (
        <div className={styles.content}>
          <Typography large type="body_bold" style={{ marginBottom: 24 }}>
            INSTANCES.WHATS_APP_TEMPLATES.CAROUSEL
          </Typography>
          <TextAreaField
            value={body.text}
            label="INSTANCES.WHATS_APP_TEMPLATES.BUBBLE_MESSAGE"
            styles={{
              input: {
                color: 'var(--grey50)',
              },
            }}
            disabled
          />
          <div className={styles.carouselItems}>
            {cards.map((I, index) => (
              <div
                role="presentation"
                className={itemClass(index)}
                onClick={() => setSelectedCard(index)}
              >
                <Icon icon="application" size={28} color="var(--grey10)" />
              </div>
            ))}
          </div>
          {renderHeader(cards[selectedCard].header)}
          {renderBody(cards[selectedCard].body)}
          {renderButtons(cards[selectedCard].suggestionsInside)}
        </div>
      );
    case 'whatsAppLimitedTimeOfferCard':
      return (
        <div className={styles.content}>
          <Typography large type="body_bold" style={{ marginBottom: 24 }}>
            INSTANCES.WHATS_APP_TEMPLATES.LIMITED_TIME
          </Typography>
          {renderHeader(header)}
          {limitedTimeOffer?.text && (
          <>
            <Typography style={{ marginTop: 10 }}>
              INSTANCES.WHATS_APP_TEMPLATES.OFFER_DETAILS
            </Typography>
            <div className={styles.offerText}>
              <div>
                <Icon
                  icon="shop"
                  size={30}
                  color="var(--grey50)"
                />
              </div>
              <TextField
                value={limitedTimeOffer.text}
                styles={fieldStyle({ marginTop: 2 })}
                disabled
                full
              />
            </div>
          </>
          )}
          {renderBody(body)}
          {renderButtons(suggestionsInside)}
        </div>
      );
    case 'whatsAppAuthenticationCard':
      return (
        <div className={styles.content}>
          <Typography large type="body_bold" style={{ marginBottom: 24 }}>
            INSTANCES.WHATS_APP_TEMPLATES.AUTHENTICATION
          </Typography>
          <Typography>
            {`INSTANCES.WHATS_APP_TEMPLATES.${securityDisclaimer ? 'AUTHENTICATION_SECURITY_TEXT' : 'AUTHENTICATION_TEXT'}`}
          </Typography>
          <SwitchField
            value={securityDisclaimer}
            color="primary"
            type="def"
            iStyle
            leftPosition
            label="INSTANCES.WHATS_APP_TEMPLATES.SECURITY_DISCLAIMER"
            styles={switchFieldStyle()}
            disabled
          />
          <SwitchField
            value={expirationWarning}
            color="primary"
            type="def"
            iStyle
            leftPosition
            label="INSTANCES.WHATS_APP_TEMPLATES.EXPIRATION_WARNING"
            styles={switchFieldStyle()}
            disabled
          />
          {expirationTimer && (
            <Typography>
              {{
                key: 'INSTANCES.WHATS_APP_TEMPLATES.EXPIRES_TEXT',
                values: { timer: `${expirationTimer} ${expirationTimer === 1 ? 'minute' : 'minutes'}` },
              }}
            </Typography>
          )}
          {renderButtons(suggestionsInside)}
        </div>
      );
    default:
      return (
        <div className={styles.content}>
          ERRORS.WHATS_APP_TEMPLATES
        </div>
      );
  }
};
