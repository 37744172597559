import endpoints from 'consts/endpoints';

export default ({
  successCallback = () => {},
  errorCallback = () => {},
} = {}) => ({
  url: endpoints.getSendersUrl(),
  body: {
    limit: 1000,
    offset: 0,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ resourceList }) => ({
    sendersOptions: resourceList.map(({ id, properties, senderId }) => ({
      value: id,
      label: properties?.displayName || senderId,
    })),
    sendersDictionary: resourceList.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    sendersOptions: (oldValue, newValue) => newValue,
    sendersDictionary: (oldValue, newValue) => newValue,
  },
});
