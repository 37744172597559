import React from 'react';

import './style.scss';

const Avatar = ({
  src,
  name,
}) => (src ? (
  <img
    src={src}
    alt={name}
    className="avatar"
  />
) : (
  <div className="avatar avatar_no-image">
    {name[0]}
  </div>
));

export default Avatar;
