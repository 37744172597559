import { useMutation } from 'redux-query-react';

import {
  postResetPassword as postResetPasswordApi,
  postConfirmCode as postConfirmCodeApi,
  postActivatePassword as postActivatePasswordApi,
  putResetPassword as putResetPasswordApi,
} from 'queries/account';

export default () => {
  const [
    {
      isPending: isPendingPostResetPassword,
    },
    postResetPassword,
  ] = useMutation(postResetPasswordApi);
  const [
    {
      isPending: isPendingPostConfirmCode,
    },
    postConfirmCode,
  ] = useMutation(postConfirmCodeApi);
  const [
    {
      isPending: isPendingPostActivatePassword,
    },
    postActivatePassword,
  ] = useMutation(postActivatePasswordApi);
  const [
    {
      isPending: isPendingPutResetPassword,
    },
    putResetPassword,
  ] = useMutation(putResetPasswordApi);

  return {
    postResetPassword,
    postConfirmCode,
    postActivatePassword,
    putResetPassword,
    isPendingPostResetPassword,
    isPendingPostConfirmCode,
    isPendingPostActivatePassword,
    isPendingPutResetPassword,
  };
};
