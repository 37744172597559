import round from './round';

/**
 * Функция, которая форматирует число
 *
 * @param num {Number} Число которое нужно отформатировать
 * @param minToK {Number} Число, начиная с которого будут приобразовываться тысячные
 * @param digits {Number} Количество цифр после десятичной запятой
 * @returns {string|number} Возвращает отформатированное значение
 */

export default (num, minToK = 1000, digits = 2) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(0).replace(/\.0$/, '')}g`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(0).replace(/\.0$/, '')}m`;
  }
  if (num >= minToK) {
    return `${(num / 1000).toFixed(0).replace(/\.0$/, '')}k`;
  }
  return round({ num, decimalsCount: digits, cutDecimalZeroes: true });
};
