import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '@setproduct-ui/core/Spinner';
import Button from '@setproduct-ui/core/Button';
import { useAccountsApi } from 'hooks/api';
import endpoints from 'consts/endpoints';
import fetchFile from 'helpers/fetchFile';
import copyInClipboard from 'helpers/copyInClipboard';

import styles from './RowDetails.module.scss';

const HEADER_COLS = [
  'TABLE',
  'OPERATION',
  'OBJECT_ID',
  'OLD_VALUE',
  'NEW_VALUE',
  'COMPANY_ID',
];
const RowDetails = ({ data }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState([]);
  const [detailsCount, setDetailsCount] = useState('?');
  const [isDownloadPending, setIsDownloadPending] = useState(false);
  const {
    getAuditDetails,
    isPendingGetAuditDetails,
  } = useAccountsApi();

  const downloadDetails = () => {
    setIsDownloadPending(true);

    fetchFile({
      url: endpoints.getLoadAuditDetailsUrl(data.execId),
      fileName: `details_${data.execId}.csv`,
      callback: () => setIsDownloadPending(false),
    });
  };

  useEffect(() => {
    if (data.execId) {
      getAuditDetails({
        execId: data.execId,
        successCallback: (res) => {
          setDetailsCount(res.size);
          setDetails(res.details);
        },
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      {data.execId === 0 ? (
        <span className={styles.title}>
          {t('SCREENS.AUDIT.NO_DETAILS')}
        </span>
      ) : (
        <>
          <div className={styles.title}>
            {t('SCREENS.AUDIT.DETAILS_COUNT', { count: detailsCount })}
            {isPendingGetAuditDetails && (
              <Spinner size={24} />
            )}
            {!!details.length && (
              <Button
                icon="export"
                view="outlined"
                text="CONTROLS.AUDIT.DOWNLOAD_CSV"
                loading={isDownloadPending}
                onClick={downloadDetails}
                dense
              />
            )}
          </div>
          {!!details?.length && (
            <div className={styles.grid}>
              <div className={styles.header}>
                {HEADER_COLS.map(headerCol => (
                  <div key={headerCol}>
                    {t(`SCREENS.AUDIT.${headerCol}`)}
                  </div>
                ))}
              </div>
              {details.map((detail, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.row} key={`${detail.oldValue}${index}`}>
                  <div>{detail.tableName}</div>
                  <div>{t(`SCREENS.AUDIT.${detail.operation}`)}</div>
                  <div>{detail.objectId}</div>
                  <div className={styles.valueCell}>
                    {detail.oldValue && (
                      <>
                        <span title={detail.oldValue}>{detail.oldValue}</span>
                        <Button
                          icon="duplicate"
                          view="outlined"
                          onClick={() => copyInClipboard(detail.oldValue)}
                          dense
                        />
                      </>
                    )}
                  </div>
                  <div className={styles.valueCell}>
                    {detail.newValue && (
                      <>
                        <span title={detail.newValue}>{detail.newValue}</span>
                        <Button
                          icon="duplicate"
                          view="outlined"
                          onClick={() => copyInClipboard(detail.newValue)}
                          dense
                        />
                      </>
                    )}
                  </div>
                  <div>{detail.companyId}</div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RowDetails;
