import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '@setproduct-ui/core/ButtonGroup';

import './style.scss';

export const ButtonGroupField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: { setFieldValue = externalSetFieldValue } = {},
  options = [],
  classNames = {},
  fill = true,
  fieldType = 'dense',
  fieldName = '',
  ...props
}) => {
  // hooks
  const { t } = useTranslation();
  const [activeState, setActiveState] = useState(options[0]?.value);
  // functions
  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    } else {
      onChange(val);
    }
    setActiveState(val);
  };
  // memed vars
  const data = useMemo(() => options.map(I => ({
    id: I.value,
    text: t(I.label),
    isActive: activeState === I.value,
    dataTestid: `${window.location.pathname}/filter/filer-config/${fieldName}/${I.value}`,
    onClick: () => handleChange(I.value),
  })), [options, activeState]);
  // effects
  useEffect(() => {
    if (value) {
      setActiveState(value);
    } else {
      setActiveState(options[0]?.value);
    }
  }, [value]);

  return (
    <div className={classNames.container}>
      <ButtonGroup
        type={fieldType}
        view="flat"
        color="primary"
        data={data}
        fill={fill}
        {...props}
      />
    </div>
  );
};
