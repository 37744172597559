import endpoints from 'consts/endpoints';

export default ({
  taskId,
  colId,
  body,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getColumnMappingsUrl(taskId, colId),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: updatedColumnSettings => ({
    rateImportInfo: updatedColumnSettings,
  }),
  update: {
    rateImportInfo: (oldValue = {}, newColumnSettings) => ({
      ...oldValue,
      columns: oldValue.columns.map((col) => {
        if (String(col.idx) === colId) {
          return {
            ...newColumnSettings,
          };
        }

        return col;
      }),
    }),
  },
});
