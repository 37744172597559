import endpoints from 'consts/endpoints';

export default ({
  invoiceId,
  successCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getInvoiceNotesUrl(invoiceId),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
});
