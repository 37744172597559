import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getCompanies as getCompaniesApi,
  postCompany as postCompanyApi,
  putCompany as putCompanyApi,
  deleteCompany as deleteCompanyApi,
  getOwnCompany as getOwnCompanyApi,
} from 'queries/companies';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetCompanies,
      lastUpdated: lastUpdatedGetCompanies,
    },
    getCompanies,
  ] = useMutation(getCompaniesApi);

  const [{ isPending: isPendingPostCompany }, postCompany] = useMutation(postCompanyApi);

  const [{ isPending: isPendingPutCompany }, putCompany] = useMutation(putCompanyApi);

  const [{ isPending: isPendingDeleteCompany }, deleteCompany] = useMutation(deleteCompanyApi);

  const [{ isPending: isPendingGetOwnCompany }, getOwnCompany] = useMutation(getOwnCompanyApi);

  const selector = useCallback(state => ({
    companies: getArray(state, 'companies'),
    companiesAll: getArray(state, 'companiesAll'),
    companiesMeta: getObject(state, 'companiesMeta'),
    ownCompany: getObject(state, 'ownCompany'),
    companiesDictionary: getObject(state, 'companiesDictionary'),
  }), []);
  const data = useISESelector(selector);

  return {
    getCompanies,
    postCompany,
    putCompany,
    deleteCompany,
    getOwnCompany,
    isPendingGetCompanies,
    isPendingPostCompany,
    isPendingPutCompany,
    isPendingDeleteCompany,
    isPendingGetOwnCompany,
    lastUpdatedGetCompanies,
    ...data,
  };
};
