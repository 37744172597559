import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getPrintableForms as getPrintableFormsApi,
  getPrintableFormTypes as getPrintableFormTypesApi,
  postPrintableForm as postPrintableFormApi,
  putPrintableForm as putPrintableFormApi,
  deletePrintableForm as deletePrintableFormApi,
} from 'queries/printableForms';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetPrintableForms,
      lastUpdated: lastUpdatedGetPrintableForms,
    },
    getPrintableForms,
  ] = useMutation(getPrintableFormsApi);
  const [, getPrintableFormTypes] = useMutation(getPrintableFormTypesApi);
  const [
    {
      isPending: isPendingPostPrintableForm,
    },
    postPrintableForm,
  ] = useMutation(postPrintableFormApi);
  const [
    {
      isPending: isPendingPutPrintableForm,
    },
    putPrintableForm,
  ] = useMutation(putPrintableFormApi);
  const [
    {
      isPending: isPendingDeletePrintableForm,
    },
    deletePrintableForm,
  ] = useMutation(deletePrintableFormApi);

  const selector = useCallback(state => ({
    printableForms: getArray(state, 'printableForms'),
    printableFormsMeta: getObject(state, 'printableFormsMeta'),
    printableFormTypes: getArray(state, 'printableFormTypes'),
  }), []);
  const data = useISESelector(selector);

  return {
    getPrintableForms,
    getPrintableFormTypes,
    postPrintableForm,
    putPrintableForm,
    deletePrintableForm,
    isPendingGetPrintableForms,
    isPendingPostPrintableForm,
    isPendingPutPrintableForm,
    isPendingDeletePrintableForm,
    lastUpdatedGetPrintableForms,
    ...data,
  };
};
