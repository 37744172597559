import useISESelector from './useISESelector';

export {
  useISESelector,
};
//
export const defaultArray = [];
export const defaultObject = {};
export const defaultOString = '';
export const defaultNumber = 0;

export const getArray = (state, key) => state.entities[key] || defaultArray;
export const getObject = (state, key) => state.entities[key] || defaultObject;
export const getString = (state, key) => state.entities[key] || defaultOString;
export const getNumber = (state, key) => state.entities[key] || defaultNumber;
