import React, {
  useMemo, useRef, useState,
} from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import {
  DatePickerField,
  DropdownField,
  CheckboxField,
} from 'components/fields';
import { getDiffFromNow } from 'helpers';
import { FormButtonsGroup } from 'components/blocks';
import { SERVICE_ENABLED_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';

import './style.scss';

const ServiceEnabledForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  agreementsOptions,
  servicesOptions,
  timezonesOptions,
}) => {
  const fieldStyle = ({
    width,
    marginTop,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });
  const servicesDropdownStyles = {
    overlay: {
      maxHeight: 170,
    },
  };
  const agreementsDropdownStyles = {
    container: {
      marginTop: 24,
    },
  };
  const timezoneDropdownFieldStyles = {
    container: {
      width: 268,
    },
    overlay: {
      bottom: '100%',
      right: 0,
      top: 'initial',
      left: 'initial',
    },
  };

  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);

  const [selectedService, setSelectedService] = useState({});

  const isView = useMemo(() => mode === 'view', [mode]);
  const isAdd = useMemo(() => mode === 'add', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);

  const isDisabledEffective = (target) => {
    if (isView) {
      return true;
    }
    if (isAdd) {
      return false;
    }
    if (isNull(target)) {
      return false;
    }

    return moment(target).isBefore(moment());
  };

  const agreementsByCurrency = useMemo(() => {
    if (selectedService?.currency) {
      return agreementsOptions.filter(
        ({ currency }) => currency === selectedService.currency,
      );
    }
    return agreementsOptions;
  }, [selectedService, servicesOptions, agreementsOptions]);

  const onChangeAgreementId = ({ value: selectedAgreementId, values }) => {
    if (selectedAgreementId) {
      const { fromTz, tillTz } = agreementsOptions.find(I => I.value === selectedAgreementId);
      if (values?.effectiveFrom) {
        formRef.current?.setFieldValue('effectiveFromTz', fromTz);
      }
      if (values?.effectiveTill || values?.effectiveTill === null) {
        formRef.current?.setFieldValue('effectiveTillTz', tillTz);
      }
    }
  };

  const onChangeServiceId = ({ value: selectedServiceId, form }) => {
    const target = servicesOptions.find(
      ({ value }) => value === selectedServiceId,
    );
    if (form.dirty) {
      formRef.current?.setFieldValue('agreementId', undefined);
    }
    setSelectedService(target);
  };

  const onSubmitModify = ({
    effectiveFrom,
    effectiveTill,
    effectiveFromTz,
    effectiveTillTz,
    ...values
  }) => {
    const {
      id,
      agreementId,
      serviceId,
      hideFromClient,
    } = values;
    const effectiveFromWithTz = isDisabledEffective(effectiveFrom) || getDiffFromNow(effectiveFrom) >= 0
      ? moment(effectiveFrom).tz(effectiveFromTz, true).utc().format('YYYY-MM-DDTHH:mm:ss')
      : 'now';
    let effectiveTillWithTz;
    if (effectiveTill) {
      effectiveTillWithTz = moment(effectiveTill)
        .tz(effectiveTillTz, true)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    onSubmit({
      id,
      effectiveFrom: effectiveFromWithTz === 'now' ? effectiveFromWithTz : `${effectiveFromWithTz}Z`,
      effectiveTill: effectiveTillWithTz ? `${effectiveTillWithTz}Z` : null,
      agreementId,
      serviceId,
      hideFromClient,
      serviceType: servicesOptions.find(item => item.value === serviceId)?.type,
    });
  };

  const changeToEdit = () => changeMode('edit');

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        effectiveFrom: moment().startOf('day').toDate(),
        effectiveTill: null,
        ...initialValues,
      }}
      onSubmit={onSubmitModify}
      validationSchema={SERVICE_ENABLED_SCHEMA}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="service-enabled-form">
          <div className="service-enabled-form__content">
            <Field
              name="serviceId"
              component={DropdownField}
              label="INSTANCES.SERVICES_ENABLED.SERVICE"
              options={servicesOptions}
              styles={servicesDropdownStyles}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="agreementId"
              component={DropdownField}
              label="INSTANCES.AGREEMENT"
              options={agreementsByCurrency}
              styles={agreementsDropdownStyles}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <div className="service-enabled-form__row">
              <Field
                name="effectiveFrom"
                label="INSTANCES.EFFECTIVE_FROM"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                maxDate={values?.effectiveTill}
                minDate={moment().toDate()}
                disabled={isDisabledEffective(values.effectiveFrom)}
                isRequired
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_FROM')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
                isNow={!isView}
              />
              <Field
                name="effectiveFromTz"
                label="INSTANCES.SERVICES_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled
                translateOptions={false}
              />
            </div>
            <div className="service-enabled-form__row">
              <Field
                name="effectiveTill"
                label="INSTANCES.EFFECTIVE_TILL"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                minDate={moment(moment(values?.effectiveFrom) > moment()
                  ? values?.effectiveFrom
                  : moment())
                  .add(5, 'minutes')
                  .toDate()}
                disabled={isDisabledEffective(values.effectiveTill)}
                withIndefinitely
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_TILL')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
              />
              <Field
                name="effectiveTillTz"
                label="INSTANCES.SERVICES_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled
                translateOptions={false}
              />
            </div>
            <Field
              name="hideFromClient"
              component={CheckboxField}
              text="INSTANCES.SERVICES_ENABLED.DO_NOT_SHOW"
              styles={fieldStyle({ marginTop: 20 })}
              disabled={isView}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            showDelete={!isDisabledEffective(values.effectiveFrom)}
            isPending={isPending}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          <OnChangeComponent field="agreementId" onChange={onChangeAgreementId} />
          <OnChangeComponent field="serviceId" onChange={onChangeServiceId} />
        </Form>
      )}
    </Formik>
  );
};

export default ServiceEnabledForm;
