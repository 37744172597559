import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getAdditionalFees as getAdditionalFeesApi,
  getAdditionalFeePrice as getAdditionalFeePriceApi,
  postAdditionalFee as postAdditionalFeeApi,
} from 'queries/additionalFees';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [{
    isPending: isPendingGetAdditionalFees,
    lastUpdated: lastUpdatedGetAdditionalFees,
  }, getAdditionalFees] = useMutation(getAdditionalFeesApi);
  const [{ isPending: isPendingGetAdditionalFeePrice }, getAdditionalFeePrice] = useMutation(getAdditionalFeePriceApi);
  const [{ isPending: isPendingPostAdditionalFee }, postAdditionalFee] = useMutation(postAdditionalFeeApi);

  const selector = useCallback(state => ({
    additionalFees: getArray(state, 'additionalFees'),
    additionalFeesMeta: getObject(state, 'additionalFeesMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getAdditionalFees,
    getAdditionalFeePrice,
    postAdditionalFee,
    isPendingGetAdditionalFees,
    isPendingGetAdditionalFeePrice,
    isPendingPostAdditionalFee,
    lastUpdatedGetAdditionalFees,
    ...data,
  };
};
