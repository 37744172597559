import React from 'react';
import { Link as LinkSource } from 'react-router-dom';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './Link.module.scss';

const Link = ({
  isActive,
  href,
  onClick,
  icon,
  label,
  activePinnedRoute,
  isHovered,
  hasSubLinks,
  isCollapsed,
  innerRef,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <LinkSource
      {...rest}
      className={cx(styles.container, {
        [styles.container_active]: isActive,
        [styles.container_isHovered]: isHovered,
      })}
      to={href}
      onClick={onClick}
      ref={innerRef}
    >
      {typeof icon === 'string' ? <Icon icon={icon} size={20} /> : icon}
      {!isCollapsed && (
        <>
          {t(label)}
          {hasSubLinks && (
            <Icon
              icon="chevron-right"
              size={20}
              className={styles.rightIcon}
            />
          )}
          {activePinnedRoute === href && (
            <Icon
              icon="add"
              size={20}
              className={styles.rightIcon}
              htmlTitle={t('TOOLTIPS.MENU_OPEN_TAB')}
            />
          )}
        </>
      )}
    </LinkSource>
  );
};

export default Link;
