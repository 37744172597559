import { useMutation } from 'redux-query-react';

import {
  getCharges as getChargesApi,
  getChargeDetails as getChargeDetailsApi,
  patchCharge as patchChargeApi,
} from 'queries/charges';

export default () => {
  const [
    {
      isPending: isPendingGetCharges,
      lastUpdated: lastUpdatedGetCharges,
    },
    getCharges,
  ] = useMutation(getChargesApi);

  const [
    {
      isPending: isPendingGetChargeDetails,
    },
    getChargeDetails,
  ] = useMutation(getChargeDetailsApi);

  const [
    {
      isPending: isPendingPatchCharge,
    },
    patchCharge,
  ] = useMutation(patchChargeApi);

  return {
    getCharges,
    getChargeDetails,
    patchCharge,
    isPendingGetCharges,
    isPendingGetChargeDetails,
    isPendingPatchCharge,
    lastUpdatedGetCharges,
  };
};
