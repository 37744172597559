import { Icon } from '@blueprintjs/core';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';
import { isEmpty } from 'lodash';

import {
  CheckboxField,
  SwitchField,
  ButtonGroupField,
  InnerField,
} from 'components/fields';
import { Typography } from 'components/Typography';
import Button from '@setproduct-ui/core/Button/Button';
import Card from '@setproduct-ui/core/Card';
import { FILTER_BUTTONS, inputPatterns } from 'consts';
import { NUMBER_FILTER_SCHEMA } from 'consts/validationSchemas';

import './style.scss';

export default forwardRef(
  (
    {
      onSubmit: onExternalSubmit,
      colDef: {
        field,
        fieldId,
        headerComponentParams: {
          // не работает т к было задуманно
          withoutEmpty = false,
          withoutExclude = false,
        } = {},
      } = {},
      filterChangedCallback,
      api,
    },
    ref,
  ) => {
    const isApply = useRef();
    const formRef = useRef();
    const { t } = useTranslation();

    useKey('Enter', () => {
      formRef.current.handleSubmit();
    });

    const switchStyles = {
      container: {
        marginTop: 16,
        marginBottom: 8,
      },
      label: {
        fontSize: 14,
      },
    };

    const checkBoxStyles = {
      container: {
        marginBottom: 16,
      },
    };
    const rightFieldStyles = {
      container: {
        borderRadius: ' 0 4px 4px 0',
        boxShadow: 'none',
        margin: 0,
      },
    };
    const leftFieldStyles = {
      container: {
        textAlign: 'center',
        width: 36,
        background: 'var(--grey0)',
        color: 'var(--grey50)',
        borderRadius: ' 4px 0 0 4px',
        margin: 0,
      },
    };

    useImperativeHandle(ref, () => ({
      getModel() {
        // eslint-disable-next-line react/no-this-in-sfc
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          values: formRef.current.values,
          type: 'number',
          isWithoutEmpty: withoutEmpty,
        };
      },
      setModel(data) {
        isApply.current = true;
        if (data) {
          const { values } = data;

          if (formRef?.current?.setValues) {
            formRef.current.setValues(values);
          }
          setTimeout(() => {
            if (formRef?.current?.setValues) {
              formRef.current.setValues(values);
            }
          });
        } else {
          formRef.current.resetForm({
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: '',
            },
          });
        }
      },
      isFilterActive() {
        const { values } = formRef.current;

        if (!values || !isApply.current) {
          return false;
        }

        return Object.values(values).some(item => item);
      },
      doesFilterPass() {
        return true;
      },
    }));

    return (
      <Formik
        innerRef={formRef}
        keepDirtyOnReinitialize
        initialValues={{}}
        validationSchema={NUMBER_FILTER_SCHEMA}
        onSubmit={(values, actions) => {
          if (values.filterConfig === 'empty' || values.filterConfig === 'notempty') {
            formRef.current.resetForm();
            formRef.current.setFieldValue('filterConfig', values.filterConfig);
          }

          // todo delete after the introduction of new tables
          if (onExternalSubmit) {
            onExternalSubmit(values, actions);
            api.menuFactory.hidePopup();
          } else {
            isApply.current = true;
            filterChangedCallback({ values, field, fieldId });
            api.menuFactory.hidePopup();
          }

          api.redrawRows();
          setTimeout(() => api.sendQuery());
        }}
      >
        {({
          handleSubmit, values, errors,
        }) => (
          <Form
            className="number-filter"
            data-testid={`${window.location.pathname}/filter/number/${field}`}
          >
            <Field
              name="filterConfig"
              className="number-filter__button-group"
              component={ButtonGroupField}
              options={FILTER_BUTTONS}
              fieldName={field}
              data-testid={`${window.location.pathname}/filter/number/${field}/filter-config`}
            />
            {values.filterConfig === 'notempty' || values.filterConfig === 'empty' ? (
              <Card
                type="def"
                view="smooth"
                color="warning_alt"
                interactive={false}
                className="number-filter__card"
              >
                <div className="mask-filter__card__content">
                  <Icon color="#FDC68B" icon="info-sign" iconSize={16} />
                  <Typography
                    tagName="span"
                    className="number-filter__card__content__text"
                  >
                    {t('CONTROLS.FILTER.DISPLAYED_CELLS', {
                      what: t(`CONTROLS.FILTER.${values.filterConfig === 'empty' ? 'EMPTY_CELLS' : 'NOT_EMPTY_CELLS'}`),
                    })}
                  </Typography>
                </div>
              </Card>
            ) : (
              <>
                {!withoutExclude && (
                  <Field
                    name="exclude"
                    type="dense"
                    component={CheckboxField}
                    className="number-filter__exclude"
                    styles={checkBoxStyles}
                    text={t('CONTROLS.FILTER.EXCLUDE')}
                    data-testid={`${window.location.pathname}/filter/number/${field}/exclude`}
                  />
                )}
                <InnerField
                  name="fromValue"
                  chipValue={values.range ? '>=' : '='}
                  leftFieldStyles={leftFieldStyles}
                  rightFieldStyles={rightFieldStyles}
                  leftProps={{
                    type: 'zero-padding',
                  }}
                  rightProps={{
                    fill: true,
                    regex: inputPatterns.numbers(),
                    dataTestid: `${window.location.pathname}/filter/number/${field}/from-value`,
                  }}
                  rtl
                />
                <Field
                  name="range"
                  color="primary"
                  label={t('CONTROLS.FILTER.BY_RANGE')}
                  className="number-filter__range-switch"
                  component={SwitchField}
                  styles={switchStyles}
                  data-testid={`${window.location.pathname}/filter/number/${field}/range`}
                />
                {values.range && (
                  <InnerField
                    name="toValue"
                    chipValue="<"
                    leftFieldStyles={leftFieldStyles}
                    rightFieldStyles={rightFieldStyles}
                    leftProps={{
                      type: 'zero-padding',
                    }}
                    rightProps={{
                      fill: true,
                      regex: inputPatterns.numbers(),
                      dataTestid: `${window.location.pathname}/filter/number/${field}/to-value`,
                    }}
                    rtl
                  />
                )}
              </>
            )}
            <div className="number-filter__filter-controls">
              <Button
                dense
                view="filled"
                color="primary"
                onClick={handleSubmit}
                className="number-filter__filter-controls__button"
                data-testid={`${window.location.pathname}/filter/number/${field}/apply`}
                disabled={!isEmpty(errors)}
              >
                <Typography
                  tagName="span"
                  className="number-filter__filter-controls__button__text"
                >
                  {t('CONTROLS.FILTER.APPLY_FILTER')}
                </Typography>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);
