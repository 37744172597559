import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getAgreements as getAgreementsApi,
  deleteAgreement as deleteAgreementApi,
  postAgreement as postAgreementApi,
  getAgreementVersions as getAgreementVersionsApi,
  putAgreementVersion as putAgreementVersionApi,
  postAgreementVersion as postAgreementVersionApi,
  putAgreementCreditLimit as putAgreementCreditLimitApi,
  deleteAgreementVersion as deleteAgreementVersionApi,
} from 'queries/agreements';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetAgreements,
      lastUpdated: lastUpdatedGetAgreements,
    },
    getAgreements,
  ] = useMutation(getAgreementsApi);
  const [
    {
      isPending: isPendingGetAgreementVersions,
      lastUpdated: lastUpdatedGetAgreementVersions,
    },
    getAgreementVersions,
  ] = useMutation(getAgreementVersionsApi);
  const [
    {
      isPending: isPendingDeleteAgreement,
    },
    deleteAgreement,
  ] = useMutation(deleteAgreementApi);
  const [
    {
      isPending: isPendingDeleteAgreementVersion,
    },
    deleteAgreementVersion,
  ] = useMutation(deleteAgreementVersionApi);
  const [
    {
      isPending: isPutAgreementVersion,
    },
    putAgreementVersion,
  ] = useMutation(putAgreementVersionApi);
  const [
    {
      isPending: isPendingPostAgreement,
    },
    postAgreement,
  ] = useMutation(postAgreementApi);
  const [
    {
      isPending: isPendingPostAgreementVersion,
    },
    postAgreementVersion,
  ] = useMutation(postAgreementVersionApi);
  const [
    {
      isPending: isPendingPutAgreementCreditLimit,
    },
    putAgreementCreditLimit,
  ] = useMutation(putAgreementCreditLimitApi);

  const selector = useCallback(state => ({
    agreements: getArray(state, 'agreements'),
    agreementsAll: getArray(state, 'agreementsAll'),
    agreementsMeta: getObject(state, 'agreementsMeta'),
    agreementsVersions: getObject(state, 'agreementsVersions'),
    agreementsVersionsMeta: getObject(state, 'agreementsVersionsMeta'),
    agreementVersionsAll: getArray(state, 'agreementVersionsAll'),
    agreementVersionsById: getArray(state, 'agreementVersionsById'),
  }), []);
  const data = useISESelector(selector);

  return {
    getAgreements,
    getAgreementVersions,
    deleteAgreement,
    deleteAgreementVersion,
    postAgreement,
    putAgreementVersion,
    postAgreementVersion,
    putAgreementCreditLimit,
    isPendingGetAgreements,
    isPendingDeleteAgreement,
    isPendingPostAgreement,
    isPutAgreementVersion,
    isPendingPostAgreementVersion,
    isPendingGetAgreementVersions,
    isPendingDeleteAgreementVersion,
    isPendingPutAgreementCreditLimit,
    lastUpdatedGetAgreementVersions,
    lastUpdatedGetAgreements,
    ...data,
  };
};
