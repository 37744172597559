import React, { useMemo } from 'react';

import {
  ViberChannelIcon,
  SmsChannelIcon,
  WhatsappChannelIcon,
  RCSIcon,
  EmailChannelIcon,
  WeChatChannelIcon,
} from 'assets/icons';

import './style.scss';

export default ({
  value,
}) => {
  const channels = useMemo(() => value.map((I) => {
    switch (I) {
      case 1: return <SmsChannelIcon className="channel-icon" />;
      case 2: return <RCSIcon className="channel-icon" />;
      case 3: return <ViberChannelIcon className="channel-icon" />;
      case 4: return <WhatsappChannelIcon className="channel-icon" />;
      case 5: return null;
      case 6: return <WeChatChannelIcon className="channel-icon" />;
      case 7: return <EmailChannelIcon className="channel-icon" />;
      default: return null;
    }
  }), [value]);

  return (
    <div className="channels-container-icon">
      {channels}
    </div>
  );
};
