import React from 'react';

import { round } from 'helpers';

import './style.scss';

export default ({
  value,
  data,
  decimalsCount = 2,
  cutDecimalZeroes,
  onClick,
}) => {
  const roundedValue = round({ num: value, decimalsCount, cutDecimalZeroes });

  const onCellClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  return (
    <div
      className="number-cell-renderer"
      role="presentation"
      onClick={onCellClick}
    >
      {(roundedValue === '0' || !roundedValue) ? null : roundedValue}
    </div>
  );
};
