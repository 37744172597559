import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getRatePlans as getRatePlansApi,
  deleteRatePlan as deleteRatePlanApi,
  putRatePlan as putRatePlanApi,
  postRatePlan as postRatePlanApi,
} from 'queries/ratePlans';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetRatePlans,
      lastUpdated: lastUpdatedRatePlans,
    },
    getRatePlans,
  ] = useMutation(getRatePlansApi);
  const [
    {
      isPending: isPendingDeleteRatePlan,
    },
    deleteRatePlan,
  ] = useMutation(deleteRatePlanApi);
  const [
    {
      isPending: isPendingPutRatePlan,
    },
    putRatePlan,
  ] = useMutation(putRatePlanApi);
  const [
    {
      isPending: isPendingPostRatePlan,
    },
    postRatePlan,
  ] = useMutation(postRatePlanApi);

  const selector = useCallback(state => ({
    ratePlans: getArray(state, 'ratePlans'),
    ratePlansMeta: getObject(state, 'ratePlansMeta'),
    ratePlansAll: getArray(state, 'ratePlansAll'),
  }), []);
  const data = useISESelector(selector);

  return {
    getRatePlans,
    deleteRatePlan,
    putRatePlan,
    postRatePlan,
    isPendingGetRatePlans,
    isPendingDeleteRatePlan,
    isPendingPutRatePlan,
    isPendingPostRatePlan,
    lastUpdatedRatePlans,
    ...data,
  };
};
