import React from 'react';

import styles from './ChannelConfigCellRenderer.module.scss';

const ChannelConfigCellRenderer = ({ valueFormatted }) => (
  <div className={styles.container}>
    {valueFormatted.map(I => (
      <div className={styles.option}>
        <div className={styles.label}>
          {I.label}
          :
        </div>
        <div>{I.value}</div>
      </div>
    ))}
  </div>
);

export default ChannelConfigCellRenderer;
