import endpoints from 'consts/endpoints';

export default ({
  id,
  limit = 100,
  offset = 0,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
  ...queries
} = {}) => ({
  url: endpoints.getChargeDetailsUrl(id),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  // transform: response => ({
  //   chargeDetails: response.data,
  //   chargeDetailsMeta: response.meta,
  // }),
  // update: {
  //   chargeDetails: (oldValue, newValue) => newValue,
  //   chargeDetailsMeta: (oldValue, newValue) => newValue,
  // },
});
