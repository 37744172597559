/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './style.scss';

export default ({ node, data }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
	  node.gridOptionsWrapper.gridOptions.isPropagationStopped = true;
	  setTimeout(() => {
		  node.gridOptionsWrapper.gridOptions.isPropagationStopped = false;
	  });
	  history.push({
		  pathname: '/sender-ids',
		  state: {
		  	ratePlanId: data.id,
		  },
	  });
  };

  return (
    <div
      className="senders-cell-renderer"
      role="presentation"
      onClick={onClick}
    >
      {t('CONTROLS.RATE_PLANS.DETAILS')}
    </div>
  );
};
