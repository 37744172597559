/* eslint-disable */
import { Icon } from '@blueprintjs/core';
import Tooltip from '@setproduct-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import React from 'react';

import './style.scss';

export default ({ value }) => {
  const { t } = useTranslation();

  return (
    <div className="sources-cell-renderer">
      {
        value.map(I => (
          <Tooltip
            content={t(`STATES.CHARGES.${I ? 'MANUAL' : 'AUTOMATIC'}`)}
            position="auto"
            key={I}
          >
            <div
              className="sources-cell-renderer__item"
              style={{ backgroundColor: I ? 'var(--orange5)' : 'var(--teal5)' }}
            >
              <Icon
                icon={I ? 'person' : 'cog'}
                iconSize={8}
                color={I ? 'var(--orange70)' : 'var(--teal70)'}
              />
            </div>
          </Tooltip>
        ))
      }
    </div>
  );
};
