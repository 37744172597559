import React, { useMemo } from 'react';
import cx from 'classnames';

import Chips from '@setproduct-ui/core/Chips/Chips';
import { useDictionariesApi, useUsersApi } from 'hooks/api';

import styles from './CompanyWithChipCellRenderer.module.scss';

const CompanyWithChipCellRenderer = ({
  value,
}) => {
  const { currentUser } = useUsersApi();
  const { companiesDictionary } = useDictionariesApi();

  const isOwnCompany = useMemo(
    () =>
      value === currentUser?.companyId,
    [value, currentUser],
  );

  return (
    <div className={cx(styles.container, { [styles.container_isOwnCompany]: isOwnCompany })}>
      {companiesDictionary?.[value]?.name}
      {isOwnCompany && (
        <Chips
          type="dense"
          view="smooth"
          round={false}
          color="primary"
          tag="Own"
          style={{ marginLeft: 5 }}
        />
      )}
    </div>
  );
};

export default CompanyWithChipCellRenderer;
