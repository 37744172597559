import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Typography } from 'components';

import convertTemplateToConstructorFormat from './converterTemplateToConstructorFormat';
import TemplateContent from './TemplateContent/index';
import styles from './WhatsAppTemplateForm.module.scss';

export default ({
  initialValues = {},
}) => {
  const history = useHistory();

  const goToCompany = () => {
    history.push({
      pathname: '/companies',
      state: {
        tableFilters: {
          id: {
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: initialValues.companyId,
            },
            type: 'number',
            isWithoutEmpty: false,
          },
        },
      },
    });
  };

  const templateInfoBlocks = [[
    {
      label: 'INSTANCES.COMPANY',
      value: initialValues?.companyName,
      click: goToCompany,
    },
    {
      label: 'INSTANCES.NAME',
      value: initialValues?.name,
    },
    {
      label: 'INSTANCES.WHATS_APP_TEMPLATES.SENDER',
      value: initialValues?.senderDisplayName,
    },
  ],
  [
    {
      label: 'INSTANCES.WHATS_APP_TEMPLATES.CATEGORY',
      value: initialValues?.senderDisplayName,
    },
    {
      label: 'INSTANCES.WHATS_APP_TEMPLATES.LANGUAGE',
      value: initialValues?.category,
    },
    {
      label: 'INSTANCES.CREATED',
      value: moment(initialValues?.createdAt).format('DD.MM.YYYY'),
    },
  ]];

  const content = convertTemplateToConstructorFormat({
    components: initialValues?.components,
    category: initialValues?.category,
  });

  return (
    <div className={styles.content}>
      <div className={styles.templateInfo}>
        {templateInfoBlocks.map(row => (
          <div className={styles.row}>
            {row.map(item => (
              <div className={styles.templateTopBlock} key={item.label}>
                <Typography small colorStep={40}>
                  {item.label}
                </Typography>
                {item.click ? (
                  <Typography color="primary" colorStep={60} className={styles.link} onClick={item.click}>
                    {item.value}
                  </Typography>
                ) : (
                  <Typography>
                    {item.value}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <TemplateContent
        {...content}
      />
    </div>
  );
};
