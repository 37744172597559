import React from 'react';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';

import './styles.scss';

export default ({
  value,
}) => {
  const classes = classNames('config-cell-renderer', {
    'config-cell-renderer_confirmed': value,
    'config-cell-renderer_not-confirmed': !value,
  });

  return (
    <div className={classes}>
      {value ? <Icon icon="tick" size={10} color="#43A047" /> : <Icon icon="circle" size={10} color="#9696A0" />}
    </div>
  );
};
