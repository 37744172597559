import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  deleteIntegration as deleteIntegrationApi,
  getIntegrationById as getIntegrationByIdApi,
  getIntegrations as getIntegrationsApi,
  getIntegrationStatistics as getIntegrationStatisticsApi,
  postIntegration as postIntegrationApi,
  postGenerateApiKey as postGenerateApiKeyApi,
  putIntegration as putIntegrationApi,
  getIntegrationsUrls as getIntegrationsUrlsApi,
} from 'queries/integrations';

import { getArray, getObject, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetIntegrations,
      lastUpdated: lastUpdatedGetIntegrations,
    },
    getIntegrations,
  ] = useMutation(getIntegrationsApi);
  const [
    {
      isPending: isPendingDeleteIntegration,
    },
    deleteIntegration,
  ] = useMutation(deleteIntegrationApi);
  const [
    {
      isPending: isPendingPostIntegration,
    },
    postIntegration,
  ] = useMutation(postIntegrationApi);
  const [
    {
      isPending: isPendingPutIntegration,
    },
    putIntegration,
  ] = useMutation(putIntegrationApi);
  const [
    {
      isPending: isPendingGetIntegrationById,
    },
    getIntegrationById,
  ] = useMutation(getIntegrationByIdApi);
  const [
    {
      isPending: isPendingPostGenerateApiKey,
    },
    postGenerateApiKey,
  ] = useMutation(postGenerateApiKeyApi);
  const [
    {
      isPending: isPendingGetIntegrationsUrls,
    },
    getIntegrationsUrls,
  ] = useMutation(getIntegrationsUrlsApi);
  const [
    {
      isPending: isPendingGetIntegrationStatistics,
      lastUpdated: lastUpdatedGetIntegrationStatistics,
    },
    getIntegrationStatistics,
  ] = useMutation(getIntegrationStatisticsApi);

  const selector = useCallback(state => ({
    integrations: getArray(state, 'integrations'),
    integrationsMeta: getObject(state, 'integrationsMeta'),
    integrationsUrls: getObject(state, 'integrationsUrls'),
    timelineIntegrationsStatistic: getArray(state, 'timelineIntegrationsStatistic'),
    timelineIntegrationsStatisticMeta: getArray(state, 'timelineIntegrationsStatisticMeta'),
    byCountryIntegrationsStatistic: getArray(state, 'byCountryIntegrationsStatistic'),
    byCountryIntegrationsStatisticMeta: getArray(state, 'byCountryIntegrationsStatisticMeta'),
    byNetworkIntegrationsStatistic: getArray(state, 'byNetworkIntegrationsStatistic'),
    byNetworkIntegrationsStatisticMeta: getArray(state, 'byNetworkIntegrationsStatisticMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getIntegrations,
    deleteIntegration,
    getIntegrationStatistics,
    postIntegration,
    putIntegration,
    getIntegrationById,
    getIntegrationsUrls,
    postGenerateApiKey,
    isPendingGetIntegrations,
    isPendingDeleteIntegration,
    isPendingPostIntegration,
    isPendingPutIntegration,
    isPendingGetIntegrationById,
    isPendingGetIntegrationsUrls,
    isPendingPostGenerateApiKey,
    lastUpdatedGetIntegrations,
    lastUpdatedGetIntegrationStatistics,
    isPendingGetIntegrationStatistics,
    ...data,
  };
};
