import React from 'react';
import moment from 'moment';

import './styles.scss';

export default ({
  value,
}) => (
  <div className="d-flex flex-column align-items-start buttons-cell-renderer">
    <div className="d-flex">
      {moment(value).format('MMM D/YY')}
    </div>
    <div className="d-flex text-secondary">
      {moment(value).format('h:m a')}
    </div>
  </div>
);
