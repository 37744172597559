import { Popover2 } from '@blueprintjs/popover2';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import { GROUPING_LIST } from 'consts';

import DateRangeFieldModal from './DateRangeFieldModal';
import styles from './DateRangeField.module.scss';

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

const DateRangePicker = ({
  externalValue = {},
  onChange,
  onChangeFractional,
  fractionalType,
  withGrouping = true,
  roundEndDate = true,
}) => {
  const { t } = useTranslation();
  const [popoverShow, setPopoverShow] = useToggle(false);
  const [rangeValue, setRangeValue] = useState(externalValue);

  const fieldClass = cx(styles.fieldsContainer, {
    [styles.isOpen]: popoverShow,
  });

  const selectedMode = useMemo(
    () =>
      GROUPING_LIST.find(I => I.value === fractionalType)?.label,
    [fractionalType],
  );

  const displayStartDate = useMemo(() => {
    if (!rangeValue.from) {
      return '';
    }

    return moment(rangeValue?.from).format(DATETIME_FORMAT);
  }, [rangeValue]);
  const displayEndDate = useMemo(() => {
    if (!rangeValue.to) {
      return '';
    }

    const isDateOnlyPeriod = fractionalType === '1 day'
      || fractionalType === '1 week'
      || fractionalType === '1 month'
      || fractionalType === '1 quarter'
      || fractionalType === '1 year';

    if (roundEndDate) {
      return isDateOnlyPeriod
        ? moment(rangeValue?.to).add(1, 'day').startOf('day').format(DATETIME_FORMAT)
        : moment(rangeValue?.to).format(DATETIME_FORMAT);
    }

    return moment(rangeValue?.to).format(DATETIME_FORMAT);
  }, [rangeValue, roundEndDate]);

  const handleFractionalChange = (value) => {
    const { from, to } = rangeValue;
    const newFrom = moment(from);
    const newTo = moment(to);

    switch (value) {
      case '1 minute':
      case '5 minute':
      case '15 minute':
      case '1 hour':
        newFrom.hours(0);
        newFrom.seconds(0);
        newFrom.minutes(0);
        newTo.hours(0);
        newTo.minutes(0);
        newTo.seconds(0);
        break;
      case '1 day':
        newFrom.startOf('day');
        newTo.endOf('day');
        break;
      case '1 week':
        newFrom.startOf('isoWeek');
        newTo.endOf('isoWeek');
        break;
      case '1 month':
        newFrom.startOf('month');
        newTo.endOf('month');
        break;
      case '1 quarter':
        newFrom.startOf('quarter');
        newTo.endOf('quarter');
        break;
      case '1 year':
        newFrom.startOf('year');
        newTo.endOf('year');
        break;
      default:
        break;
    }
    const result = {
      from: moment(newFrom).toDate(),
      to: moment(newTo).toDate(),
    };
    setRangeValue(result);
    onChangeFractional(value);
  };

  const handleOnApply = () => {
    onChange(rangeValue);
  };

  const handleInteraction = (nextOpenState, e) => {
    if (e?.target?.className !== 'rc-picker-time-panel-cell-inner'
      && e?.target?.className !== 'rc-picker-now-btn'
      && e?.target?.parentElement?.className !== 'rc-picker-ok') {
      setPopoverShow(nextOpenState);
    }
  };

  return (
    <Popover2
      isOpen={popoverShow}
      onInteraction={handleInteraction}
      interactionKind="click"
      placement="bottom-start"
      minimal
      usePortal
      transitionDuration={0}
      content={(
        <DateRangeFieldModal
          onClose={setPopoverShow}
          mode={fractionalType}
          onChange={setRangeValue}
          onApply={handleOnApply}
          rangeValue={rangeValue}
          onChangeFractional={handleFractionalChange}
          withGrouping={withGrouping}
        />
      )}
      renderTarget={({
        ref,
        className,
        isOpen,
        ...targetProps
      }) => (
        <div
          role="presentation"
          className={styles.container}
          ref={ref}
          {...targetProps}
        >
          <div className={fieldClass}>
            <div className={styles.field}>
              {displayStartDate}
            </div>
            <Icon
              icon="arrow-right"
              size={20}
              color="var(--grey30)"
            />
            <div className={styles.field}>
              {displayEndDate}
            </div>
            <Icon
              icon="calendar"
              size={20}
              color="var(--grey30)"
              htmlTitle={t('TOOLTIPS.STATISTICS.PERIOD')}
            />
          </div>
          {withGrouping && (
            <div className={styles.mode} title={t('PLACEHOLDERS.GROUPING')}>
              {t(selectedMode)}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default DateRangePicker;
