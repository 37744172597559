import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import moment from 'moment';

import { useFeeRulesApi } from 'hooks/api';
import { useModalLogic } from 'hooks';
import DropdownField from 'components/fields/DropdownField';
import RadioButtonField from 'components/fields/RadioButtonField';
import FormButtonsGroup from 'components/blocks/FormButtonsGroup';
import OnFormChange from 'components/forms/OnFormChange';
import ChargePrice from 'components/blocks/ChargePrice';
import { SENDER_ID_TYPES } from 'consts';
import { SETUP_FEE_SCHEMA } from 'consts/validationSchemas';
import toastRef from 'helpers/toast';
import { round } from 'helpers';

import ConfirmWriteOffModal from '../ConfirmWriteOffModal';

import './style.scss';

const SetupFee = ({
  id,
  channel,
  onCancel,
}) => {
  const fieldStyle = ({ marginTop, ...styles }) => ({
    container: {
      ...styles,
    },
    overlay: {
      maxHeight: 176,
    },
  });

  const { t } = useTranslation();
  const {
    mnos,
    getMnos,
    postSetupFee,
    getFeePrice,
    isPendingPostSetupFee,
  } = useFeeRulesApi();
  const [isOpenModal, { openModal, closeModal }] = useModalLogic(false);
  const [price, setPrice] = useState();

  const mnosOptions = useMemo(() => mnos.map(mno => ({
    value: mno.id,
    label: mno.name,
  })), [mnos]);

  const onChangeFormValues = (values) => {
    getFeePrice({
      feeType: 'setup-fee',
      params: {
        senderId: id,
        senderIdType: values.senderIdType,
        mno: values.mno,
      },
      successCallback: (res) => {
        setPrice({
          price: `${round({ num: res.value })} ${res.agreementCurrency}`,
          service: res.serviceName,
          date: `Effective: ${moment(res.effectiveFrom).format('DD.MM.YYYY HH:mm:ss')}${res.effectiveTill
            ? `- ${moment(res.effectiveTill).format('DD.MM.YYYY HH:mm:ss')}` : ''}`,
        });
      },
      errorCallback: ({ data } = {}) => {
        setPrice({ errorMessage: data?.message });
      },
    });
  };
  const onSubmit = (values) => {
    postSetupFee({
      body: {
        senderId: id,
        force: isOpenModal,
        ...values,
      },
      successCallback: () => {
        toastRef?.current?.show({ message: 'Write-off was successful!', intent: 'success' });
        onCancel();
      },
      errorCallback: ({ data, key }) => {
        if (key === 'balance_updater_service.conflict.duplicate') {
          openModal(true);
        } else {
          toastRef?.current?.show({ message: key || data?.message, intent: 'danger' });
          if (openModal) {
            closeModal();
          }
        }
      },
    });
  };

  useEffect(() => {
    if (channel === 1) {
      getMnos();
    }
  }, [channel]);

  return (
    <Formik
      initialValues={{
        senderIdType: 1,
      }}
      onSubmit={onSubmit}
      validationSchema={SETUP_FEE_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
      }) => (
        <Form className="setup-fee">
          <span className="setup-fee__subtitle">
            {t('SCREENS.SENDER_IDS.SETUP_FEE_PROPERTIES')}
          </span>
          <div className="setup-fee__row">
            <Field
              name="senderIdType"
              label="INSTANCES.SENDER_IDS.SENDER_ID_TYPE"
              component={RadioButtonField}
              options={SENDER_ID_TYPES}
              styles={fieldStyle({ width: '100%' })}
              isRequired
            />
            <Field
              name="mno"
              label="INSTANCES.SENDER_IDS.MNO"
              component={DropdownField}
              styles={fieldStyle({ width: '100%' })}
              options={mnosOptions}
              isRequired
            />
          </div>
          <ChargePrice {...(price && price)} />
          <OnFormChange callback={onChangeFormValues} />
          <FormButtonsGroup
            onCancel={onCancel}
            onApply={handleSubmit}
            errors={errors}
            setFieldTouched={setFieldTouched}
            showDelete={false}
            isPending={isPendingPostSetupFee}
          />
          {
            isOpenModal && (
              <ConfirmWriteOffModal
                closeModal={closeModal}
                onConfirm={handleSubmit}
                isRewrite
              />
            )
          }
        </Form>
      )}
    </Formik>
  );
};

export default SetupFee;
