import React from 'react';
import { Spinner } from '@blueprintjs/core';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { useFilteredColumns } from 'hooks';

import DetailCell from '../DetailCell';

import './style.scss';

const DetailsBlock = ({
  headerCells,
  footerCells = [],
  detailsBody,
  tableProps,
  rowStyle,
  tableTitle,
  isPendingTable,
  footerElement,
  rightContentElement = null,
}) => {
  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns();

  return (
    <div className="details-block">
      <div className="details-block__instance-info">
        <div className="details-block__column">
          <div className="details-block__separated-row">
            {headerCells.map(cell => (
              <DetailCell
                key={cell.title}
                {...cell}
              />
            ))}
          </div>
          <div className="details-block__row" style={rowStyle}>
            {detailsBody}
          </div>
          <div className="details-block__separated-row">
            {footerCells.map(cell => (
              <DetailCell
                key={cell.title}
                {...cell}
              />
            ))}
          </div>
          {footerElement && (
            <div className="details-block__footer">
              {footerElement}
            </div>
          )}
        </div>
        {rightContentElement}
      </div>
      {isPendingTable
        ? <Spinner className="details-block__spinner" />
        : (
          <Table
            tableHeader={tableTitle}
            headerDense
            showFilters={false}
            rowHeight={28}
            isPending={false}
            classNames={{
              screenHeader: 'details-block__table-header',
              layout: 'details-block__table',
            }}
            openCustomColumns={openCustomViewDrawer}
            filteredColumns={filteredColumns}
            {...tableProps}
          />
        )}
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={tableProps.columnDefs}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};

export default DetailsBlock;
