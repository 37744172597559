import React from 'react';
import { Icon } from '@blueprintjs/core';

import DrawerSource from '@setproduct-ui/core/Drawer/Drawer';
import Button from '@setproduct-ui/core/Button';
import { Typography } from 'components/Typography';

import './style.scss';

const Drawer = ({
  isOpen,
  onClose,
  title,
  size = 320,
  position = 'right',
  children,
  style,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <DrawerSource
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      position={position}
      className="drawer"
      style={style}
    >
      {typeof title === 'string' ? (
        <div className="drawer__header">
          <Typography tagName="span" className="drawer__header__title">
            {title}
          </Typography>
          <Button className="drawer__close-button" onClick={onClose}>
            <Icon icon="cross" size={12} color="#2264D1" />
          </Button>
        </div>
      ) : title}
      {children}
    </DrawerSource>
  );
};

export default Drawer;
