import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/Typography';
import Chips from '@setproduct-ui/core/Chips';

import './style.scss';

const FieldsBlockWrapper = ({
  title,
  hintComponent,
  formComponent,
  isDiff,
  highlightChanged,
  isRequired,
  style,
}) => {
  const chipsStyles = color => ({
    backgroundColor: `var(--${color}30)`,
  });

  const subtitleClass = cx('fields-block-wrapper__subtitle', {
    'fields-block-wrapper__subtitle_required': isRequired,
    'fields-block-wrapper__subtitle_changed': highlightChanged && isDiff,
  });

  const { t } = useTranslation();

  return (
    <div className="fields-block-wrapper" style={style}>
      {
        isDiff && !highlightChanged && (
          <>
            <div className="fields-block-wrapper__row">
              {
                title && (
                  <Typography className="fields-block-wrapper__subtitle">
                    {title}
                  </Typography>
                )
              }
              <Chips
                tag={t('SCREENS.APPLICATIONS.CURRENT')}
                type="dense"
                className="fields-block-wrapper__chips"
                style={chipsStyles('grey')}
              />
            </div>
            {hintComponent}
          </>
        )
      }
      <div className="fields-block-wrapper__row" style={isDiff && !highlightChanged ? { marginTop: 24 } : {}}>
        {
          title && (
            <Typography className={subtitleClass}>
              {title}
            </Typography>
          )
        }
        {
          isDiff && !highlightChanged && (
            <Chips
              tag={t('SCREENS.APPLICATIONS.NEW')}
              type="dense"
              className="fields-block-wrapper__chips"
              style={chipsStyles('blue')}
            />
          )
        }
      </div>
      {formComponent}
    </div>
  );
};

export default FieldsBlockWrapper;
