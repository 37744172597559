import { Icon } from '@blueprintjs/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { SmsChannelIcon } from 'assets/icons';

import styles from '../preview.module.scss';

const SmsPreview = ({
  message,
  showChannelIcon,
}) => (
  <div className={styles.messageContainer}>
    <div className={styles.messageTile}>
      {showChannelIcon && <SmsChannelIcon />}
      <Icon icon="font" color="var(--grey30)" size={16} />
      Text
    </div>
    <Highlighter
      className={styles.bodyContainer}
      highlightClassName={styles.highlight}
      highlightTag="span"
      searchWords={[/{{.([^]+?)}}/gm]}
      textToHighlight={message.body}
      autoEscape={false}
    />
  </div>
);

export default SmsPreview;
