import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import { Typography } from 'components/Typography';
import TextField from 'components/fields/TextField';

import './style.scss';

export const InnerField = ({
  label,
  name,
  chipValue,
  styles = {},
  disabled,
  placeholder,
  leftFieldComponent = TextField,
  rightFieldComponent = TextField,
  rtl,
  rightFieldStyles,
  leftFieldStyles,
  leftProps = {},
  rightProps = {},
  bothFields,
  noFields,
  isRequired,
  dense,
  className = '',
  isHaveError,
}) => {
  const labelClass = classNames('inner-field__label', {
    'inner-field__label_required': isRequired,
    'inner-field__label_dense': dense,
  });
  const rowClass = classNames('inner-field__row', {
    'inner-field__row_disabled': disabled,
    'inner-field__row_rtl': rtl,
    'inner-field__row_error': isHaveError,
  });

  const innerFieldClass = classNames('inner-field', className);

  const leftFieldProps = rtl ? rightProps : leftProps;
  const rightFieldProps = rtl ? leftProps : rightProps;

  return (
    <div className={innerFieldClass} style={styles.wrapper}>
      {
        label && (
          <Typography className={labelClass}>
            {label}
          </Typography>
        )
      }
      <div className={rowClass} style={styles.row}>
        {
          noFields
            ? (rtl ? rightFieldComponent : leftFieldComponent)({
              styles: rtl ? rightFieldStyles : leftFieldStyles,
              disabled,
              placeholder,
              dense,
              ...leftFieldProps,
            })
            : (
              <Field
                name={name}
                component={rtl ? rightFieldComponent : leftFieldComponent}
                styles={rtl ? rightFieldStyles : leftFieldStyles}
                disabled={disabled}
                placeholder={placeholder}
                dense={dense}
                {...leftFieldProps}
              />
            )
        }
        {
          bothFields
            ? (
              <Field
                name={name}
                component={rtl ? leftFieldComponent : rightFieldComponent}
                styles={rtl ? leftFieldStyles : rightFieldStyles}
                dense={dense}
                {...rightFieldProps}
              />
            )
            : (
              <TextField
                value={chipValue}
                styles={rtl ? leftFieldStyles : rightFieldStyles}
                dense={dense}
                disabled
                {...rightFieldProps}
              />
            )
        }
      </div>
    </div>
  );
};
