import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  postEdrRerateTask as postEdrRerateTaskApi,
  getEdrRerateTasks as getEdrRerateTasksApi,
  getEstimateEdrRerateTask as getEstimateEdrRerateTaskApi,
  putEdrRerateTask as putEdrRerateTaskApi,
} from 'queries/rerate';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [{ isPending: isPendingPostEdrRerateTask }, postEdrRerateTask] = useMutation(postEdrRerateTaskApi);
  const [
    {
      isPending: isPendingGetEdrRerateTask,
      lastUpdated: lastUpdatedGetEdrRerateTask,
    },
    getEdrRerateTasks,
  ] = useMutation(getEdrRerateTasksApi);
  const [
    {
      isPending: isPendingGetEstimateEdrRerateTask,
    },
    getEstimateEdrRerateTask,
  ] = useMutation(getEstimateEdrRerateTaskApi);
  const [{ isPending: isPendingPutEdrRerateTask }, putEdrRerateTask] = useMutation(putEdrRerateTaskApi);

  const selector = useCallback(state => ({
    edrRerateTasks: getArray(state, 'edrRerateTasks'),
    edrRerateTasksMeta: getObject(state, 'edrRerateTasksMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    postEdrRerateTask,
    getEdrRerateTasks,
    getEstimateEdrRerateTask,
    putEdrRerateTask,
    isPendingPostEdrRerateTask,
    isPendingGetEdrRerateTask,
    isPendingGetEstimateEdrRerateTask,
    isPendingPutEdrRerateTask,
    lastUpdatedGetEdrRerateTask,
    ...data,
  };
};
