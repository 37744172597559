import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';
import TabItem from './TabItem';

export default () => {
  const { t } = useTranslation();
  const routes = useSelector(state => state.states.routes);
  const savedTabsRef = useRef(null);

  // useEffect(() => {
  //   const adaptiveScroll = (e) => {
  //     if (e.deltaY > 0 && e.target.className && e.target.className.includes('saved-tabs')) {
  //       savedTabsRef.current.scrollLeft += 10;
  //     } else {
  //       savedTabsRef.current.scrollLeft -= 10;
  //     }
  //   };
  //   window.addEventListener('wheel', adaptiveScroll);
  //
  //   return () => window.removeEventListener('wheel', adaptiveScroll);
  // }, []);

  return (
    <div className="tabs-wrapper">
      <div className="saved-tabs" ref={savedTabsRef}>
        {Object.keys(routes.openTabs).map(I => (
          <TabItem
            I={I}
            routes={routes}
            t={t}
            key={I}
          />
        ))}
      </div>
    </div>
  );
};
