import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getCountriesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    countries: response.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  }),
  update: {
    countries: (oldValue, newValue) => newValue,
  },
});
