import React, { useState, useMemo, useEffect } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { orderBy, cloneDeep } from 'lodash';
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import Button from '@setproduct-ui/core/Button';
import DropdownField from 'components/fields/DropdownField';
import { CheckboxField } from 'components/fields/CheckboxField';
import { useServicesApi, useDictionariesApi } from 'hooks/api';
import { PAYG_PRICE_TYPES_OPTIONS, UNIT_MONETARY_PRICE_TYPES_OPTIONS } from 'consts';

import MappingRow from './MappingRow';
import styles from './ColumnHeader.module.scss';

import CustomDialog from '../CustomDialog';

const ColumnHeader = ({
  column,
  resourceAttributes,
}) => {
  const dropdownStyles = {
    item: {
      fontSize: 14,
      minHeight: 'unset',
    },
    overlay: {
      maxHeight: 445,
    },
  };

  const { t } = useTranslation();
  const {
    rateImportInfo,
    resourceAttributesDictionary,
    serviceProperties,
    patchColumnSettings,
    deleteColumnSettings,
    patchColumnMappings,
    patchColumnProperties,
    billingRules,
    quantityRules,
    isPendingPatchColumnSettings,
    isPendingDeleteColumnSettings,
    isPendingPatchColumnMappings,
    isPendingPatchColumnProperties,
  } = useServicesApi();
  const {
    attributes,
    dictionariesWithLinks,
    definitionsList,
  } = useDictionariesApi();
  const {
    taskId,
    serviceType,
    sourceNode,
    parentInfoRules,
  } = useSelector(state => state.states.rateImportConfig);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [selectedDictionaryAttributeId, setSelectedDictionaryAttributeId] = useState(null);
  const [normalizeMccmnc, setNormalizeMccmnc] = useState(false);
  const [isManualMappingOpen, toggleIsManualMappingOpen] = useToggle(false);
  const [searchByKey, setSearchByKey] = useState('');
  const [searchByValue, setSearchByValue] = useState('');
  const [mappingRowsToUpdate, setMappingRowsToUpdate] = useState([]);
  const [rowsStates, setRowsStates] = useState({});

  const appliedColumnSettings = useMemo(
    () => rateImportInfo?.columns?.find(item => String(item.idx) === String(column.colId)),
    [column.colId, rateImportInfo],
  );
  const missedMatchMapping = useMemo(() => {
    if (appliedColumnSettings) {
      return appliedColumnSettings?.valueMappings?.some(item => (item.v === null || item.v === undefined) && item.r);
    }
    return false;
  }, [appliedColumnSettings]);
  const containsNonNormalizedMccmnc = useMemo(() => {
    if (selectedEntityId?.endsWith('108') && appliedColumnSettings && !normalizeMccmnc) {
      return appliedColumnSettings?.valueMappings?.some(I => I.k?.length <= 3 && I.r);
    }
    return false;
  }, [appliedColumnSettings, selectedEntityId, normalizeMccmnc]);
  const {
    isPriceCol,
    isBillingRuleCol,
    isQuantityRuleCol,
  } = useMemo(
    () => ({
      isPriceCol: appliedColumnSettings?.entityType === 2 && appliedColumnSettings?.entityId === 12,
      isBillingRuleCol: appliedColumnSettings?.entityType === 2 && appliedColumnSettings?.entityId === 21,
      isQuantityRuleCol: appliedColumnSettings?.entityType === 2 && appliedColumnSettings?.entityId === 31,
    }),
    [appliedColumnSettings],
  );
  const propertiesOptions = useMemo(() => serviceProperties
    .filter(item => !rateImportInfo?.columns
      ?.find(c => String(c.idx) !== String(column.colId) && c.entityType === 2 && c.entityId === item.value)
      && item.value !== 11 && item.value !== 13 && item.value !== 14)
    .map(item => ({
      value: `2/${item.value}`, // <entity-type/entity_id>
      label: `${item.label[0]?.toUpperCase()}${item.label?.slice(1)}`,
    })), [serviceProperties, rateImportInfo]);
  const resourceAttributesOptions = useMemo(() => resourceAttributes
    ?.filter(item => appliedColumnSettings?.entityId === item.value || !rateImportInfo?.treeStructureElements
      ?.find(treeElement => treeElement.attributeId === item.value))
    ?.map(item => ({
      value: `1/${item.value}`, // <entity-type/entity_id>
      label: item.label,
    })), [resourceAttributes, rateImportInfo, appliedColumnSettings]);
  const dictionaryIdOfSelectedEntity = useMemo(() => {
    if (selectedEntityId) {
      return resourceAttributes.find(item => String(item.value) === selectedEntityId.split('/')[1])?.dictionaryId;
    }

    return null;
  }, [selectedEntityId, resourceAttributes]);
  const dictionaryIdAttributeOptions = useMemo(() => {
    if (dictionaryIdOfSelectedEntity) {
      return [
        ...attributes.reduce((acc, item) => {
          if (item.trfImportDisplayMode && item.dictionaryId === dictionaryIdOfSelectedEntity) {
            acc.push({
              value: item.id,
              label: item.trfImportDisplayMode,
              labelChip: {
                tag: item.trfImportMatchMode === 1 ? 'full match' : 'best match',
                color: item.trfImportMatchMode === 1 ? 'primary_alt' : 'primary',
                style: { marginLeft: 'auto' },
              },
            });
          }
          return acc;
        }, []),
        {
          value: 0,
          label: dictionariesWithLinks[dictionaryIdOfSelectedEntity]?.trfImportKeyDisplayName,
          labelChip: {
            tag: 'full match',
            color: 'primary',
            style: { marginLeft: 'auto' },
          },
        },
      ];
    }

    return [];
  }, [dictionaryIdOfSelectedEntity, attributes, dictionariesWithLinks, resourceAttributes]);
  const selectedAttributeDisplayName = useMemo(() => {
    if (appliedColumnSettings) {
      if (appliedColumnSettings.entityType === 1) {
        return resourceAttributesDictionary[appliedColumnSettings.entityId]?.displayName;
      }
      return propertiesOptions.find(item => item.value === `2/${appliedColumnSettings.entityId}`)?.label;
    }

    return null;
  }, [appliedColumnSettings, propertiesOptions, resourceAttributesDictionary]);
  const modifiedValueMappings = useMemo(() => {
    if (appliedColumnSettings?.valueMappings) {
      return orderBy(appliedColumnSettings.valueMappings.map(item => ({
        ...item,
        valueDisplayName: definitionsList[appliedColumnSettings?.dictionaryId]?.find(I => I.value === item.v)?.label,
      })), ['v', 'r'], 'desc');
    }
    return [];
  }, [appliedColumnSettings, definitionsList]);
  const displayedValueMappings = useMemo(
    () => modifiedValueMappings
      .filter(item => (!searchByKey || item.k.toLowerCase().includes(searchByKey?.toLowerCase()))
     && (!searchByValue || item.valueDisplayName?.toLowerCase()?.includes(searchByValue?.toLowerCase()))),
    [searchByKey, searchByValue, modifiedValueMappings],
  );
  const mappingOptions = useMemo(() => {
    if (isPriceCol) {
      if (serviceType === 1) {
        return PAYG_PRICE_TYPES_OPTIONS.reduce((acc, item) => {
          if (item.value !== '2/price') {
            acc.push({
              ...item,
              value: +item.value.split('/')[0],
              label: t(item.label),
            });
          }
          return acc;
        }, []);
      }
      return UNIT_MONETARY_PRICE_TYPES_OPTIONS.map(item => ({
        ...item,
        value: +item.value.split('/')[0],
        label: t(item.label),
      }));
    }
    if (isBillingRuleCol || isQuantityRuleCol) {
      const branchResourceId = String(parentInfoRules?.resourceId || sourceNode?.matchAttributeValues);
      return (isBillingRuleCol ? billingRules : quantityRules).reduce((acc, item) => {
        if (String(item.resourceId) === branchResourceId) {
          acc.push({
            value: item.id,
            label: item.name,
          });
        }

        return acc;
      }, []);
    }

    const definitionItemsForDictionary = cloneDeep(definitionsList?.[appliedColumnSettings?.dictionaryId] || []);
    return [
      {
        value: 'null',
        label: 'Others',
        style: {
          fontStyle: 'italic',
          color: 'var(--grey60)',
        },
      },
      ...definitionItemsForDictionary.reduce((acc, item) => {
        const existingItem = acc.find(el => el.label === item.label);

        if (existingItem) {
          if (!existingItem.potentialValues) {
            existingItem.potentialValues = [existingItem.value];
          }
          existingItem.potentialValues.push(item.value);
          existingItem.value = `${item.label}AccumulativeValue`;
        } else {
          acc.push(item);
        }

        return acc;
      }, []),
    ];
  }, [isPriceCol, definitionsList, appliedColumnSettings, serviceType]);

  const handleChangeEntityId = (newVal) => {
    setSelectedEntityId(newVal);
    setNormalizeMccmnc(false);
    if (newVal) {
      setSelectedDictionaryAttributeId(0);
    } else {
      setSelectedDictionaryAttributeId(null);
    }
  };
  const handleChangeNormalizeMccmnc = (newVal) => {
    patchColumnProperties({
      taskId,
      colId: column.colId,
      body: {
        normalized: newVal,
      },
      successCallback: () => setNormalizeMccmnc(newVal),
    });
  };
  const onChangeSearchByKey = ({ target }) => setSearchByKey(target.value);
  const onChangeSearchByValue = ({ target }) => setSearchByValue(target.value);
  const closePopover = () => {
    if (!appliedColumnSettings) {
      handleChangeEntityId(null);
    } else {
      setSelectedEntityId(`${appliedColumnSettings.entityType}/${appliedColumnSettings.entityId}`);
      setSelectedDictionaryAttributeId(appliedColumnSettings.dictionaryAttributeId);
    }
    setIsPopoverOpen(false);
  };
  const onClearColumnSettings = () => {
    deleteColumnSettings({
      taskId,
      colId: column.colId,
      successCallback: () => {
        handleChangeEntityId(null);
      },
    });
  };
  const onCloseMappingModal = () => {
    toggleIsManualMappingOpen();
    setRowsStates({});
    setMappingRowsToUpdate([]);
  };
  const onApplyColumnMappings = () => {
    patchColumnMappings({
      taskId,
      colId: column.colId,
      body: mappingRowsToUpdate,
      successCallback: onCloseMappingModal,
    });
  };
  const onApply = () => {
    const [entityType, entityId] = selectedEntityId.split('/');

    patchColumnSettings({
      taskId,
      body: {
        idx: Number(column.colId),
        dictionaryAttributeId: selectedDictionaryAttributeId,
        entityType: Number(entityType),
        entityId: Number(entityId),
      },
    });
  };
  const itemRenderer = ({
    index, key, style,
  }) => {
    const initialValue = displayedValueMappings[index].v === '' ? 'null' : displayedValueMappings[index].v;

    return (
      <MappingRow
        key={key}
        initialKey={displayedValueMappings[index].k}
        initialValue={initialValue}
        isManual={displayedValueMappings[index].m}
        isRequired={displayedValueMappings[index].r}
        dictionaryId={appliedColumnSettings.dictionaryId}
        mappingRowsToUpdate={mappingRowsToUpdate}
        setMappingRowsToUpdate={setMappingRowsToUpdate}
        isPriceCol={isPriceCol}
        options={mappingOptions}
        style={style}
        selectedValue={rowsStates[key]?.selectedValue || (initialValue?.priceType ?? initialValue)}
        setSelectedValue={newVal => setRowsStates(prev => ({
          ...prev,
          [key]: {
            ...(prev[key] || {}),
            selectedValue: newVal,
          },
        }))}
        flatValue={rowsStates[key]?.flatValue || initialValue?.price}
        setFlatValue={newVal => setRowsStates(prev => ({
          ...prev,
          [key]: {
            ...(prev[key] || {}),
            flatValue: newVal,
          },
        }))}
      />
    );
  };

  useEffect(() => {
    if (!appliedColumnSettings && !isPopoverOpen && !!selectedEntityId) {
      handleChangeEntityId(null);
    } else if (appliedColumnSettings && !selectedEntityId) {
      setSelectedEntityId(`${appliedColumnSettings.entityType}/${appliedColumnSettings.entityId}`);
      setSelectedDictionaryAttributeId(appliedColumnSettings.dictionaryAttributeId);
      setNormalizeMccmnc(appliedColumnSettings.properties?.normalized);
    }
  }, [appliedColumnSettings]);

  if (column.colId === 'id') {
    return null;
  }

  return (
    <>
      <Popover2
        isOpen={isPopoverOpen}
        onInteraction={setIsPopoverOpen}
        onClosing={closePopover}
        placement="bottom-start"
        minimal
        usePortal
        transitionDuration={0}
        interactionKind="click"
        disabled={!rateImportInfo.startRow}
        renderTarget={({ ref, isOpen, ...targetProps }) => (
          <div
            ref={ref}
            {...targetProps}
            className={cx(styles.target, {
              [styles.target_active]: isOpen,
              [styles.target_disabled]: !rateImportInfo.startRow,
              [styles.target_setted]: !!appliedColumnSettings,
            })}
          >
            {appliedColumnSettings ? (
              <>
                <Icon
                  icon={(missedMatchMapping || containsNonNormalizedMccmnc) ? 'warning-sign' : 'tick-circle'}
                  color={`var(--${(missedMatchMapping || containsNonNormalizedMccmnc) ? 'orange' : 'green'}60)`}
                  size={14}
                />
                {selectedAttributeDisplayName}
              </>
            ) : <span>Import as</span>}
          </div>
        )}
        content={(
          <div className={styles.overlay}>
            <div className={styles.overlayHeader}>
              {`Column ${Number(column.colId) + 1} import settings`}
              <Icon
                icon="cross"
                onClick={closePopover}
                className={styles.closePopoverButton}
                size={14}
              />
            </div>
            <DropdownField
              label="Import as"
              options={[
                ...propertiesOptions,
                {
                  value: 3913912,
                  label: 'Resource attributes',
                  disabled: true,
                  style: {
                    fontSize: 12,
                    borderBottom: '1px solid var(--grey10)',
                    minHeight: 'unset',
                    backgroundColor: '#fff',
                    borderRadius: 0,
                    cursor: 'initial',
                  },
                },
                ...resourceAttributesOptions,
              ]}
              value={selectedEntityId}
              onChange={handleChangeEntityId}
              styles={dropdownStyles}
              disabled={!!appliedColumnSettings}
              dense
            />
            {dictionaryIdOfSelectedEntity && (
              <DropdownField
                label="My values are"
                value={selectedDictionaryAttributeId}
                onChange={setSelectedDictionaryAttributeId}
                options={dictionaryIdAttributeOptions}
                styles={{
                  item: {
                    fontSize: 14,
                    minHeight: 'unset',
                  },
                }}
                disabled={!selectedEntityId || !!appliedColumnSettings}
                dense
              />
            )}
            {/* If mccmnc selected as entity */}
            {selectedEntityId?.endsWith('108') && !!appliedColumnSettings && !missedMatchMapping && (
              <>
                <CheckboxField
                  value={normalizeMccmnc}
                  onChange={handleChangeNormalizeMccmnc}
                  text="To correct MCCMNC"
                  type="dense"
                  disabled={isPendingPatchColumnProperties}
                />
                {containsNonNormalizedMccmnc && (
                  <div className={styles.row}>
                    <Icon
                      icon="warning-sign"
                      color="var(--orange60)"
                      size={14}
                    />
                    Your file contains non-normalized mccmnc, it is recommended to enable conversion for correct operation of the service
                  </div>
                )}
              </>
            )}
            {!!appliedColumnSettings
              && `${appliedColumnSettings.entityType}/${appliedColumnSettings.entityId}` === selectedEntityId
              && selectedDictionaryAttributeId === appliedColumnSettings.dictionaryAttributeId && (
                <div className={styles.row}>
                  <Icon
                    icon={missedMatchMapping ? 'warning-sign' : 'tick-circle'}
                    color={`var(--${missedMatchMapping ? 'orange' : 'green'}60)`}
                    size={14}
                  />
                  {missedMatchMapping ? 'No match for some values' : 'All values mapped'}
                  <Icon
                    icon="cog"
                    size={14}
                    className={styles.cogButton}
                    onClick={toggleIsManualMappingOpen}
                  />
                </div>
            )}
            <div className={styles.buttonsWrapper}>
              {appliedColumnSettings ? (
                <>
                  <Button
                    view="outlined"
                    color="danger"
                    icon="eraser"
                    text="Clear settings"
                    onClick={onClearColumnSettings}
                    loading={isPendingDeleteColumnSettings}
                    dense
                  />
                  <Button
                    view="outlined"
                    text="CONTROLS.CLOSE"
                    onClick={closePopover}
                    style={{ marginLeft: 'auto' }}
                    disabled={isPendingDeleteColumnSettings}
                    dense
                  />
                </>
              ) : (
                <>
                  <Button
                    icon="reset"
                    view="outlined"
                    disabled={isPendingPatchColumnSettings}
                    onClick={closePopover}
                    style={{ marginLeft: 'auto' }}
                    dense
                  />
                  <Button
                    icon="tick"
                    color="primary"
                    onClick={onApply}
                    loading={isPendingPatchColumnSettings}
                    disabled={!selectedEntityId
                      || (!!dictionaryIdOfSelectedEntity
                        && (selectedDictionaryAttributeId === null || selectedDictionaryAttributeId === undefined))}
                    dense
                  />
                </>
              )}
            </div>
          </div>
        )}
      />
      <CustomDialog
        isOpen={isManualMappingOpen}
        title={`Column ${Number(column.colId) + 1} - ${selectedAttributeDisplayName} - Value mappings`}
        onClose={onCloseMappingModal}
        classNames={{
          modal: styles.modal,
          content: styles.modalContent,
        }}
      >
        <div className={styles.mappingCard}>
          <div className={styles.cardHeader}>
            <div>Value</div>
            {isPriceCol ? (
              <>
                <div>Price type</div>
                <div>Price</div>
              </>
            ) : (
              <div>Import as</div>
            )}
          </div>
          <div className={styles.cardSearch}>
            <div>
              <Icon
                icon="search"
                size={10}
              />
              <input
                type="text"
                placeholder="Search"
                value={searchByKey}
                onChange={onChangeSearchByKey}
              />
            </div>
            {!isPriceCol && (
              <div>
                <Icon
                  icon="search"
                  size={10}
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchByValue}
                  onChange={onChangeSearchByValue}
                />
              </div>
            )}
          </div>
          <div className={styles.cardList} style={{ height: 200 }}>
            <AutoSizer>
              {({ height, width: containerWidth }) => (
                <List
                  width={containerWidth}
                  height={height}
                  rowCount={displayedValueMappings.length}
                  rowHeight={28}
                  rowRenderer={itemRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </div>
        <div className={styles.buttonsWrapper} style={{ paddingBottom: 2 }}>
          <Button
            icon="reset"
            view="outlined"
            onClick={onCloseMappingModal}
            style={{ marginLeft: 'auto' }}
            disabled={isPendingPatchColumnMappings}
            dense
          />
          <Button
            icon="tick"
            color="primary"
            onClick={onApplyColumnMappings}
            loading={isPendingPatchColumnMappings}
            dense
          />
        </div>
      </CustomDialog>
    </>
  );
};

export default ColumnHeader;
