import endpoints from 'consts/endpoints';

export default ({
  params,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getPackPriceUrl(params),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    hideToast: true,
    successCallback,
    errorCallback,
  },
});
