import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getResourceAttributesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => ({
    resourceAttributes: data.map(({ id, displayName, ...rest }) => ({
      value: id,
      label: displayName, // `${displayName} // D ID: ${rest.dictionaryId}`,
      ...rest,
    })),
    resourceAttributesDictionary: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    resourceAttributes: (oldValue, newValue) => newValue,
    resourceAttributesDictionary: (oldValue, newValue) => newValue,
  },
});
