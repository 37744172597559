import * as Yup from 'yup';

const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\/(3[0-2]|[1-2]?[0-9]))?$/;
const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}(\/(12[0-8]|[1-9]?[0-9]))?$/;

const PAYMENTS_SCHEMA = Yup.object().shape({
  companyId: Yup.mixed()
    .required('ERRORS.SELECT_PARTNER'),
  agreementId: Yup.string()
    .required('You should select a account').nullable(true),
  date: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  paymentCurrencyAmount: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  accountCurrencyAmount: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  referenceCode: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  expiryDate: Yup.date().when('isConfirmed', {
    is: isConfirmed => !isConfirmed,
    then: Yup.date()
      .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  }),
});

const COMPANY_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  parentCompanyLEId: Yup.string().nullable(true).when('id', {
    is: id => !id,
    then: Yup.string()
      .required('ERRORS.REQUIRED').nullable(true),
  }),
});

const ACCOUNTS_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
  legalCompanyName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
  partnerId: Yup.number()
    .required('ERRORS.SELECT_PARTNER'),
  currencyCode: Yup.string()
    .required('ERRORS.SELECT_CURRENCY'),
});

const AGREEMENTS_SCHEMA = Yup.object().shape({
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  legalEntityId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  agreementCode: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  parentCompanyLegalEntityId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  agreementDate: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  currency: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveFrom: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveFromTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveTill: Yup.date()
    .notRequired('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveTillTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualPaymentPeriod: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingPeriod: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingDay: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const AGREEMENT_VERSION_SCHEMA = Yup.object().shape({
  actualEffectiveFrom: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveFromTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveTill: Yup.date()
    .notRequired('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualEffectiveTillTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingTz: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualPaymentPeriod: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingPeriod: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  actualBillingDay: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const MCCMNC_SCHEMA = Yup.object().shape({
  mccMnc: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  countryName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  startDate: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
});

const PREFIXES_SCHEMA = Yup.object().shape({
  mccMnc: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  dialCode: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const MKITUSERS_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  language: Yup.string()
    .required('ERRORS.SELECT_LANGUAGE').nullable(true),
  email: Yup.string()
    .email('ERRORS.WRONG_EMAIL_FORMAT')
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  firstName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  lastName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  timeZoneId: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  phoneNumber: Yup.string().nullable(true).when('twoFactorAuthentication', {
    is: true,
    then: Yup.string()
      .required('ERRORS.REQUIRED').nullable(true),
  }),
});

const BOUSERS_SCHEMA = Yup.object().shape({
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  username: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  language: Yup.string()
    .required('ERRORS.SELECT_LANGUAGE').nullable(true),
  email: Yup.string()
    .email('ERRORS.WRONG_EMAIL_FORMAT')
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  firstName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  lastName: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  timeZoneId: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  phoneNumber: Yup.string().nullable(true).when('twoFactorAuthentication', {
    is: true,
    then: Yup.string()
      .required('ERRORS.REQUIRED').nullable(true),
  }),
});

const PASSWORD_SCHEMA = Yup.object({
  password: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .min(8, 'ERRORS.PASSWORD_MIN_LENGTH')
    .matches(/^[^:/]*$/, 'ERRORS.INVALID_CHARACTERS')
    .nullable(true),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'ERRORS.PASSWORDS_NOT_MATCH')
    .required('ERRORS.PASSWORD_CONFIRM_REQUIRED').nullable(true),
});

const PASSWORD_WITHOUT_RE_SCHEMA = Yup.object({
  password: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .min(8, 'ERRORS.PASSWORD_MIN_LENGTH').nullable(true),
});

const RESET_PASSWORD_SCHEMA = Yup.object({
  login: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  oldPassword: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const PASSWORD_LENGTH_SCHEMA = Yup.object({
  password: Yup.string()
    .min(8, 'ERRORS.PASSWORD_MIN_LENGTH')
    .matches(/^[^:/]*$/, 'ERRORS.INVALID_CHARACTERS')
    .nullable(true),
  rePassword: Yup.string()
    .when('password', {
      is: password => password && password.length > 0,
      then: Yup.string()
        .required('ERRORS.PASSWORD_CONFIRM_REQUIRED'),
    })
    .oneOf([Yup.ref('password'), null], 'ERRORS.PASSWORDS_NOT_MATCH').nullable(true),
});

const SENDER_SCHEMA = (isTenantCompany = false) => Yup.object({
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  agreementId: isTenantCompany ? undefined : Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  senderStatus: Yup.number()
    .required('ERRORS.REQUIRED'),
  senderId: Yup.string().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true)
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string()
        .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
    }),
  displayName: Yup.string().nullable(true)
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  webHookGuid: Yup.string().nullable(true)
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  vendorType: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(true),
  vendorConfig: Yup.object()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.object().when('vendorType', {
        is: vendorType => vendorType === 'google_rbm',
        then: Yup.object()
          .required('ERRORS.REQUIRED').nullable(true),
      }),
    }).nullable(true),
  login: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_gsma',
        then: Yup.string()
          .required('ERRORS.REQUIRED')
          .max(16, { key: 'ERRORS.MAX_LENGTH', values: { count: 16 } }).nullable(true),
      }),
    }).nullable(true),
  password: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_gsma',
        then: Yup.string()
          .required('ERRORS.REQUIRED')
          .max(64, { key: 'ERRORS.MAX_LENGTH', values: { count: 64 } }).nullable(true),
      }),
    }).nullable(true),
  tokenDuration: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_gsma',
        then: Yup.string()
          .required('ERRORS.REQUIRED')
          .max(6, { key: 'ERRORS.MAX_LENGTH', values: { count: 6 } }).nullable(true),
      }),
    }).nullable(true),
  color: Yup.string().matches(
    /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    'ERRORS.WRONG_HASH_FORMAT',
  ).nullable(true),
});

const SIMPLE_SENDERS_SCHEMA = (isTenantCompany = false) => Yup.object().shape({
  companyId: Yup.number()
    .required('ERRORS.SELECT_PARTNER'),
  agreementId: isTenantCompany ? undefined : Yup.number()
    .required('ERRORS.REQUIRED'),
  displayName: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  senderId: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  vendorType: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus === 2,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(true),
  connectionId: Yup.string()
    .when('vendorType', {
      is: vendorType => [
        'smpp',
        'mkit_http_vk_ok',
        'mkit_http_viber',
        'mkit_http_telin',
        'mkit_http_gupshup',
        'mkit_http_elasticemail',
        'mkit_http_dialog360cloud',
        'mkit_http_wechat',
        'mkit_http_telegramgw',
      ].includes(vendorType),
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(),
});

const SMS_SENDER_SCHEMA = Yup.object().shape({
  login: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_http_sms',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }).nullable(true),
    }),
  password: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_http_sms',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }).nullable(true),
    }),
});

const VIBER_SENDER_SCHEMA = Yup.object().shape({
  login: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_http_viber',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }).nullable(true),
    }),
  password: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'alaris_http_viber',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }).nullable(true),
    }),
});

const EMAIL_SENDER_SCHEMA = Yup.object().shape({
  apiKey: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .nullable(true),
  senderEmail: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .email('ERRORS.WRONG_EMAIL_FORMAT')
    .nullable(true),
});

const WA_SENDER_SCHEMA = Yup.object().shape({
  mediaUrl: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'dialog360',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(),
      }),
    }).nullable(),
  phoneNumberId: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'mkit_http_telin',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }),
    }).nullable(),
  accessToken: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string().when('vendorType', {
        is: vendorType => vendorType === 'mkit_http_telin',
        then: Yup.string()
          .required('ERRORS.REQUIRED').nullable(true),
      }),
    }).nullable(),
  waba_id: Yup.string()
    .when('senderStatus', {
      is: senderStatus => senderStatus !== 0,
      then: Yup.string()
        .required('ERRORS.REQUIRED'),
    }).nullable(),
  appId: Yup.string()
    .when('vendorType', {
      is: vendorType => vendorType === 'mkit_http_telin' || vendorType === 'mkit_http_gupshup',
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(),
    }).nullable(),
  apiKey: Yup.string()
    .when('vendorType', {
      is: vendorType => vendorType === 'dialog360'
        || vendorType === 'mkit_http_dialog360cloud'
        || vendorType === 'mkit_http_gupshup',
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(),
    }).nullable(),
  accessMedia: Yup.string()
    .when('vendorType', {
      is: vendorType => vendorType === 'mkit_http_gupshup',
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(),
    }).nullable(),
});

const WECHAT_SENDER_SCHEMA = Yup.object().shape({
  token: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  appId: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  appSecret: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
});

const TELEGRAM_GATEWAY_SENDER_SCHEMA = Yup.object().shape({
  apiKey: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
});

const REPORT_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  sqlQuery: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  categories: Yup.array()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .min(1, 'ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  parameters: Yup.array().of(
    Yup.object().shape({
      typeID: Yup.number().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
      name: Yup.string().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
      bindName: Yup.string().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
    }),
  ),
});

const SMPP_CONNECTION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  hostName: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  port: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  systemId: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
});

const HTTP_CONNECTION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  vendorType: Yup.string()
    .required('ERRORS.REQUIRED'),
  service_id: Yup.string()
    .when('vendorType', {
      is: vendorType => vendorType === 'mkit_http_viber',
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(),
  vendorUrl: Yup.string()
    .matches(urlRegex, 'ERRORS.INVALID_URL').nullable(true),
  template_url: Yup.string()
    .matches(urlRegex, 'ERRORS.INVALID_URL').nullable(true),
  media_url: Yup.string()
    .when('vendorType', {
      is: vendorType => vendorType === 'mkit_http_dialog360cloud',
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(),
  // sender_email: Yup.string()
  //   .when('vendorType', {
  //     is: vendorType => vendorType === 'mkit_http_elasticemail',
  //     then: Yup.string()
  //       .email('ERRORS.WRONG_EMAIL_FORMAT')
  //       .required('ERRORS.REQUIRED').nullable(true),
  //   }).nullable(),
  // sender_name: Yup.string()
  //   .when('vendorType', {
  //     is: vendorType => vendorType === 'mkit_http_elasticemail',
  //     then: Yup.string()
  //       .required('ERRORS.REQUIRED').nullable(true),
  //   }).nullable(),
});

const SERVICE_ENABLED_SCHEMA = Yup.object().shape({
  agreementId: Yup.string()
    .required('ERRORS.REQUIRED'),
  serviceId: Yup.string()
    .required('ERRORS.REQUIRED'),
  effectiveFrom: Yup.date()
    .required('ERRORS.REQUIRED'),
});

const PACKS_ENABLED_SCHEMA = Yup.object().shape({
  agreementId: Yup.number()
    .required('ERRORS.REQUIRED'),
  serviceId: Yup.number()
    .required('ERRORS.REQUIRED'),
  effectiveFrom: Yup.date()
    .required('ERRORS.REQUIRED'),
  billing: Yup.bool()
    .required('ERRORS.REQUIRED'),
});

const PERIODIC_FEE_SCHEMA = Yup.object().shape({
  daysInAdvance: Yup.number()
    .min(-31, { key: 'ERRORS.NUMBER_RANGE', values: { from: -31, to: 31 } })
    .max(31, { key: 'ERRORS.NUMBER_RANGE', values: { from: -31, to: 31 } }).nullable(true),
  effectiveFrom: Yup.date()
    .required('ERRORS.REQUIRED'),
});

const LEGAL_ENTITY_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  legalCompanyName: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
});

const SELF_REGISTRATION_SCHEMA = Yup.object().shape({
  portalId: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  legalEntityId: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  reportEmail: Yup.string()
    .email('ERRORS.WRONG_EMAIL_FORMAT')
    .required('ERRORS.REQUIRED')
    .nullable(true),
  integrationId: Yup.string()
    .when('phoneVerify', {
      is: true,
      then: schema => schema
        .required('ERRORS.REQUIRED')
        .nullable(true),
      otherwise: schema => schema.nullable(true),
    }),
  billingTz: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  billingPeriod: Yup.number()
    .required('ERRORS.REQUIRED')
    .integer('ERRORS.INTEGER')
    .nullable(true),
  billingDay: Yup.number()
    .required('ERRORS.REQUIRED')
    .integer('ERRORS.INTEGER')
    .nullable(true),
  currency: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  companyModeration: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  company2FA: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  splitByMonth: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  codePrefix: Yup.string()
    .required('ERRORS.REQUIRED')
    .nullable(true),
  creditLimit: Yup.string()
    .nullable(true)
    .notRequired()
    .min(0, 'ERRORS.MIN_VALUE'),
  serviceInfo: Yup.array()
    .of(
      Yup.object().shape({
        serviceId: Yup.string()
          .nullable(true),
        serviceType: Yup.string()
          .nullable(true),
      }),
    )
    .nullable(true),
  services: Yup.array()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY')
    .min(1, 'ERRORS.REQUIRED_AND_NOT_EMPTY')
    .nullable(true),
});

const EXPAND_LEGAL_ENTITY_SCHEMA = Yup.object().shape({
  companyId: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
});

const SETUP_FEE_SCHEMA = Yup.object().shape({
  senderIdType: Yup.string()
    .required('ERRORS.REQUIRED'),
  mno: Yup.string()
    .required('ERRORS.REQUIRED'),
});

const NOTIFICATIONS_CHANNELS_SCHEMA = Yup.object().shape({
  channelType: Yup.number()
    .required('ERRORS.REQUIRED'),
  name: Yup.string()
    .required('ERRORS.REQUIRED'),
  token: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 5,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  uri: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 1,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  host: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  port: Yup.number().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.number()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  emailSender: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  emailLogin: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  emailPassword: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  sslContextProtocol: Yup.string().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
  useTls: Yup.bool().nullable(true)
    .when('channelType', {
      is: type => type === 6,
      then: Yup.bool()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
});

const SERVICE_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  type: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  packSize: Yup.number().nullable(true)
    .when('type', {
      is: type => (type === 2 || type === 3),
      then: Yup.number()
        .min(1, { key: 'ERRORS.MIN_NUMBER', values: { min: 1 } })
        .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
    }),
  currency: Yup.string().nullable(true)
    .when('type', {
      is: type => type === 1 || type === 3,
      then: Yup.string()
        .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
    }),
});

const SCOPE_SCHEMA = Yup.object({
  effectiveFrom: Yup.date().nullable(true)
    .when('isDraft', {
      is: isDraft => !isDraft,
      then: Yup.date()
        .required('ERRORS.REQUIRED').nullable(true),
    }),
});

const PRINTABLE_FORM_SCHEMA = Yup.object().shape({
  type: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  file: Yup.mixed()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const WABA_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  companyId: Yup.number()
    .required('ERRORS.REQUIRED'),
  wabaId: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  tz: Yup.string()
    .required('ERRORS.REQUIRED'),
  namespace: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
});

const TREE_ROW_SCHEMA = ({ isFreeInput, serviceType }) => Yup.object().shape({
  matchAttributeValues: isFreeInput ? undefined : Yup.mixed()
    .required('ERRORS.REQUIRED'),
  priceTypeCell: Yup.string()
    .required('ERRORS.REQUIRED'),
  price: serviceType === 1 ? Yup.mixed()
    .when('priceTypeCell', {
      is: '2/price',
      then: Yup.array().of(
        Yup.object().shape({
          tier: Yup.string()
            .nullable()
            .test(
              'not-empty-string',
              'ERRORS.REQUIRED',
              value => value === null || (typeof value === 'string' && value.trim() !== ''),
            ),
          price: Yup.string().required('ERRORS.REQUIRED'),
        }),
      ),
    })
    .when('priceTypeCell', {
      is: '1/price',
      then: Yup.string().required('ERRORS.REQUIRED').nullable(true),
    })
    .nullable(true) : undefined,
  scaleType: Yup.mixed()
    .when('priceTypeCell', {
      is: '2/price',
      then: Yup.string().required('ERRORS.REQUIRED').nullable(true),
    })
    .nullable(true),
  billingRule: serviceType !== 3 ? Yup.string()
    .when(['level', 'priceTypeCell'], {
      is: (level, priceTypeCell) => {
        if (level === 2) {
          const priceType = Number(priceTypeCell?.split?.('/')?.[0]);

          if (priceType > 0 || priceTypeCell === undefined) {
            return true;
          }
          return false;
        }
        return false;
      },
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(true) : undefined,
  quantityRule: serviceType !== 3 ? Yup.string()
    .when(['level', 'priceTypeCell'], {
      is: (level, priceTypeCell) => {
        if (level === 2) {
          const priceType = Number(priceTypeCell?.split?.('/')?.[0]);

          if (priceType > 0 || priceTypeCell === undefined) {
            return true;
          }
          return false;
        }
        return false;
      },
      then: Yup.string()
        .required('ERRORS.REQUIRED').nullable(true),
    }).nullable(true) : undefined,
});

const PORTAL_SCHEMA = Yup.object().shape({
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY'),
  name: Yup.string()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  fqdn: Yup.string()
    .matches(
      /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/,
      'ERRORS.WRONG_DOMAIN_FORMAT',
    ).nullable(true),
});

const ADDITIONAL_FEE_SCHEMA = Yup.object().shape({
  companyId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
  agreementId: Yup.number()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
  volume: Yup.string()
    .when('supplyType', {
      is: 1,
      then: Yup.string().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
    }),
  preRatedCost: Yup.string()
    .when('supplyType', {
      is: 2,
      then: Yup.string().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(),
    }),
});

const CLIENT_SMPP_CONNECTION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  companyId: Yup.number()
    .required('ERRORS.REQUIRED').nullable(),
  ip: Yup.string()
    .required('ERRORS.REQUIRED')
    .matches(
      new RegExp(`(${ipv4Regex.source})|(${ipv6Regex.source})`),
      'ERRORS.INVALID_CIDR',
    ).nullable(true),
  systemId: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  sslType: Yup.number()
    .required('ERRORS.REQUIRED').nullable(),
  defaultDc: Yup.number()
    .required('ERRORS.REQUIRED').nullable(),
});

const INTEGRATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('ERRORS.REQUIRED').nullable(true),
  companyId: Yup.string()
    .required('ERRORS.REQUIRED'),
  senderId: Yup.string()
    .required('ERRORS.REQUIRED'),
  codeSize: Yup.mixed()
    .when('integrationType', {
      is: '2fa-sms-verify',
      then: Yup.string().required('ERRORS.REQUIRED').nullable(true),
    })
    .nullable(true),
  codeTimeout: Yup.mixed()
    .when('integrationType', {
      is: '2fa-sms-verify',
      then: Yup.number()
        .required('ERRORS.REQUIRED')
        .max(600, { key: 'NEW_TRANSLATES.ERRORS.MAX_VALUE', values: { value: 600 } })
        .nullable(true),
    })
    .nullable(true),
  text: Yup.string().required('ERRORS.REQUIRED').nullable(true),
  apiKey: Yup.string().required('ERRORS.REQUIRED').nullable(true),
});

const NUMBER_FILTER_SCHEMA = Yup.object().shape({
  fromValue: Yup.string().nullable()
    .when('filterConfig', {
      is: filterConfig => (filterConfig === undefined || filterConfig === 'all'),
      then: Yup.string().required('ERRORS.REQUIRED').nullable(true),
    }),
  toValue: Yup.string().nullable()
    .when(['range', 'filterConfig'], {
      is: (range, filterConfig) => range && (filterConfig === undefined || filterConfig === 'all'),
      then: Yup.string().required('ERRORS.REQUIRED').nullable(true),
    }),
});

const DATE_FILTER_SCHEMA = Yup.object().shape({
  fromValue: Yup.date().nullable()
    .when('filterConfig', {
      is: filterConfig => (filterConfig === undefined || filterConfig === 'all'),
      then: Yup.date().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
    }),
  toValue: Yup.date().nullable()
    .when(['range', 'filterConfig'], {
      is: (range, filterConfig) => range && (filterConfig === undefined || filterConfig === 'all'),
      then: Yup.date().required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
    }),
});

const MASK_FILTER_SCHEMA = Yup.object().shape({
  value: Yup.string()
    .when('filterConfig', {
      is: filterConfig => (filterConfig === undefined || filterConfig === 'all'),
      then: Yup.string().required('ERRORS.REQUIRED'),
    }),
});

const EDR_RERATE_TASK_SCHEMA = Yup.object().shape({
  timeFrom: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  timeTill: Yup.date()
    .required('ERRORS.REQUIRED_AND_NOT_EMPTY').nullable(true),
  companyIds: Yup.array().default([]).min(1, 'ERRORS.REQUIRED_AND_NOT_EMPTY'),
});

export {
  MCCMNC_SCHEMA,
  PAYMENTS_SCHEMA,
  COMPANY_SCHEMA,
  ACCOUNTS_SCHEMA,
  AGREEMENTS_SCHEMA,
  PREFIXES_SCHEMA,
  MKITUSERS_SCHEMA,
  BOUSERS_SCHEMA,
  PASSWORD_SCHEMA,
  PASSWORD_LENGTH_SCHEMA,
  REPORT_SCHEMA,
  SIMPLE_SENDERS_SCHEMA,
  SMS_SENDER_SCHEMA,
  VIBER_SENDER_SCHEMA,
  SENDER_SCHEMA,
  SMPP_CONNECTION_SCHEMA,
  HTTP_CONNECTION_SCHEMA,
  PASSWORD_WITHOUT_RE_SCHEMA,
  PERIODIC_FEE_SCHEMA,
  SETUP_FEE_SCHEMA,
  LEGAL_ENTITY_SCHEMA,
  EXPAND_LEGAL_ENTITY_SCHEMA,
  AGREEMENT_VERSION_SCHEMA,
  SERVICE_ENABLED_SCHEMA,
  PACKS_ENABLED_SCHEMA,
  NOTIFICATIONS_CHANNELS_SCHEMA,
  SCOPE_SCHEMA,
  PRINTABLE_FORM_SCHEMA,
  SERVICE_SCHEMA,
  WA_SENDER_SCHEMA,
  WABA_SCHEMA,
  TREE_ROW_SCHEMA,
  PORTAL_SCHEMA,
  RESET_PASSWORD_SCHEMA,
  EMAIL_SENDER_SCHEMA,
  WECHAT_SENDER_SCHEMA,
  TELEGRAM_GATEWAY_SENDER_SCHEMA,
  CLIENT_SMPP_CONNECTION_SCHEMA,
  ADDITIONAL_FEE_SCHEMA,
  INTEGRATION_SCHEMA,
  SELF_REGISTRATION_SCHEMA,
  NUMBER_FILTER_SCHEMA,
  DATE_FILTER_SCHEMA,
  MASK_FILTER_SCHEMA,
  EDR_RERATE_TASK_SCHEMA,
};
