import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { Icon } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useToggle } from 'react-use';

import Color from '@setproduct-ui/styles/color.module.css';
import { useServicesApi, useDictionariesApi } from 'hooks/api';
import Button from '@setproduct-ui/core/Button';
import Dialog from '@setproduct-ui/core/Dialog';

import styles from './TreeStructure.module.scss';

import Dropdown from '../../ServiceTab/TreeTable/Row/Dropdown';

const TreeStructure = ({
  attribute,
  attributeValue,
}) => {
  const {
    rateImportInfo: { treeStructureElements, columns },
    resourceAttributesDictionary,
    postReorderTreeStructure,
    patchTreeStructure,
    deleteTreeStructure,
    deleteColumnSettings,
    isPendingPatchTreeStructure,
    isPendingDeleteTreeStructure,
  } = useServicesApi();
  const { dictionariesWithLinks } = useDictionariesApi();
  const { taskId, dependsOptions } = useSelector(state => state.states.rateImportConfig);
  const [displayedTreeStructure, setDisplayedTreeStructure] = useState(null);
  const [editableRow, setEditableRow] = useState(null);
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  const attributesOptions = useMemo(() => {
    const alreadyUsedDictionaries = treeStructureElements?.reduce((acc, item) => {
      const dictionaryId = resourceAttributesDictionary[item.attributeId]?.dictionaryId;
      if (dictionaryId && !acc.includes(dictionaryId)) {
        acc.push(dictionaryId);
      }
      return acc;
    }, []);

    return dependsOptions?.reduce((acc, item) => {
      if (item.dictionaryId
        && !treeStructureElements?.find(I => I.attributeId === item.value)
        && dictionariesWithLinks[item.dictionaryId]?.linkedDictionaries?.some(dictionary => alreadyUsedDictionaries?.includes(dictionary))) {
        acc.push(item);
      }
      return acc;
    }, []);
  }, [dependsOptions, treeStructureElements]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setDisplayedTreeStructure((prev) => {
      const reorderedTreeStructure = reorder(
        prev,
        result.source.index,
        result.destination.index,
      );

      postReorderTreeStructure({
        taskId,
        body: {
          order: reorderedTreeStructure.map(item => item.attributeId),
        },
        errorCallback: () => setDisplayedTreeStructure(prev),
      });

      return reorderedTreeStructure;
    });
  };
  const onAddClick = () => {
    if (!editableRow) {
      setEditableRow({
        isNewRow: true,
      });
    }
  };
  const onChangeAttributeId = newVal => setEditableRow(prev => ({
    ...prev,
    sourceAttributeId: null,
    attributeId: newVal,
  }));
  const onChangeSourceAttributeId = newVal => setEditableRow(prev => ({
    ...prev,
    sourceAttributeId: newVal,
  }));
  const onCancelEditClick = () => setEditableRow(null);
  const onEditClick = ({ attributeId, sourceAttributeId }) => {
    if (!editableRow) {
      setEditableRow({
        attributeId,
        sourceAttributeId: sourceAttributeId === null ? 'null' : sourceAttributeId,
      });
    }
  };
  const onSaveTreeStructureElement = (initSourceAttributeId) => {
    if (!isPendingPatchTreeStructure) {
      if (initSourceAttributeId === null) {
        toggleIsModalOpen(true);
      } else {
        toggleIsModalOpen(false);
        patchTreeStructure({
          taskId,
          attributeId: editableRow.isNewRow ? undefined : editableRow.attributeId,
          body: {
            attributeId: editableRow.attributeId,
            sourceAttributeId: editableRow.sourceAttributeId,
            sourceMode: 2,
          },
          successCallback: () => {
            const columnIndex = columns?.find(col => col.entityId === editableRow.attributeId)?.idx;

            if (columnIndex) {
              deleteColumnSettings({
                taskId,
                colId: columnIndex,
                haveToFilterTreeStructure: false,
              });
            }
            setEditableRow(null);
          },
        });
      }
    }
  };
  const onDeleteTreeStructureElement = (attributeId) => {
    deleteTreeStructure({
      taskId,
      attributeId,
    });
  };

  const getSourceAttributeOptions = useCallback((attributeId) => {
    if (attributeId) {
      const relevantDictionaries = dictionariesWithLinks[resourceAttributesDictionary[attributeId]?.dictionaryId]?.linkedDictionaries;

      const options = dependsOptions?.reduce((acc, item) => {
        if (treeStructureElements?.some(I => I.sourceMode === 1 && I.attributeId === item.value) && relevantDictionaries?.includes(item.dictionaryId)) {
          acc.push({
            ...item,
            label: `Infer from ${item.label}`,
          });
        }
        return acc;
      }, []);

      const columnIndex = columns?.find(col => col.entityId === attributeId)?.idx;

      if (columnIndex !== undefined) {
        return [
          {
            value: 'null',
            label: `Import from column ${columnIndex + 1}`,
          },
          ...options,
        ];
      }

      return options;
    }
    return [];
  }, [dependsOptions, treeStructureElements, columns]);

  useEffect(() => {
    setDisplayedTreeStructure(treeStructureElements);
  }, [treeStructureElements]);

  return (
    <div className={styles.container}>
      <div className={styles.hint}>
        Tree structure
        <Icon
          icon="help"
          htmlTitle="Drag attribute to change its level"
          size={10}
        />
      </div>
      <div className={styles.header}>
        {`${attribute}:`}
        <span>{attributeValue}</span>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="treeStructure">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.list}
            >
              {displayedTreeStructure?.map((item, index) => (
                <Draggable
                  key={String(item.attributeId)}
                  draggableId={String(item.attributeId)}
                  index={index}
                >
                  {draggableProvided => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      className={cx(styles.listItem, {
                        [styles.listItem_active]: editableRow?.attributeId === item.attributeId,
                      })}
                      style={draggableProvided.draggableProps.style}
                    >
                      <Icon
                        icon="drag-handle-vertical"
                        color="var(--grey40)"
                        style={{ opacity: 1 }}
                      />
                      <span style={{ color: 'var(--grey40)' }}>{index + 1}</span>
                      <Dropdown
                        value={item.attributeId}
                        onChange={onChangeAttributeId}
                        options={dependsOptions}
                        disabled
                      />
                      <Dropdown
                        value={editableRow?.attributeId === item.attributeId
                          ? editableRow?.sourceAttributeId
                          : (item.sourceAttributeId || 'null')}
                        onChange={onChangeSourceAttributeId}
                        options={getSourceAttributeOptions(item.attributeId)}
                        disabled={editableRow?.attributeId !== item.attributeId}
                      />
                      {editableRow?.attributeId === item.attributeId ? (
                        <Icon
                          icon="reset"
                          className={styles.iconButton}
                          onClick={onCancelEditClick}
                        />
                      ) : item.sourceMode === 1 ? <div /> : (
                        <Icon
                          icon="trash"
                          className={cx(styles.iconButton, Color.danger, {
                            [styles.iconButton_disabled]: isPendingDeleteTreeStructure,
                          })}
                          onClick={() => onDeleteTreeStructureElement(item.attributeId)}
                        />
                      )}
                      {editableRow?.attributeId === item.attributeId ? (
                        <Icon
                          icon="floppy-disk"
                          className={cx(
                            styles.iconButton,
                            Color.primary,
                            { [styles.iconButton_disabled]: isPendingPatchTreeStructure || !editableRow?.sourceAttributeId },
                          )}
                          onClick={() => onSaveTreeStructureElement(item.sourceAttributeId)}
                        />
                      ) : (
                        <Icon
                          icon="edit"
                          className={cx(styles.iconButton, Color.primary, {
                            [styles.iconButton_disabled]: !!editableRow,
                          })}
                          onClick={() => onEditClick(item)}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {editableRow?.isNewRow && (
        <div
          className={cx(styles.listItem, {
            [styles.listItem_active]: true,
          })}
        >
          <span />
          <span style={{ color: 'var(--grey40)' }}>{(displayedTreeStructure?.length || 0) + 1}</span>
          <Dropdown
            value={editableRow?.attributeId}
            onChange={onChangeAttributeId}
            options={attributesOptions}
          />
          <Dropdown
            value={editableRow?.sourceAttributeId}
            onChange={onChangeSourceAttributeId}
            options={getSourceAttributeOptions(editableRow?.attributeId)}
            disabled={!editableRow?.attributeId}
          />
          <Icon
            icon="reset"
            className={styles.iconButton}
            onClick={onCancelEditClick}
          />
          <Icon
            icon="floppy-disk"
            className={cx(
              styles.iconButton,
              Color.primary,
              { [styles.iconButton_disabled]: isPendingPatchTreeStructure || !editableRow?.sourceAttributeId },
            )}
            onClick={onSaveTreeStructureElement}
          />
        </div>
      )}
      {!!displayedTreeStructure?.length && (
        <Icon
          icon="add"
          className={cx(styles.addButton, { [styles.addButton_disabled]: !!editableRow })}
          size={14}
          onClick={onAddClick}
        />
      )}
      <Dialog
        withCloseButton={false}
        view="raised"
        color="warning"
        icon="warning-sign"
        iconColor="var(--orange30)"
        title="Warning"
        className={styles.confirmDialog}
        text={`Your changes will cause the previously mapped column by the ${resourceAttributesDictionary[editableRow?.attributeId]?.displayName} attribute to be removed. Are you sure you want to save the changes?`}
        leftButton={(
          <Button
            text="CONTROLS.CANCEL"
            view="flat"
            onClick={toggleIsModalOpen}
          />
        )}
        rightButton={(
          <Button
            text="CONTROLS.SAVE"
            view="smooth"
            color="primary"
            onClick={onSaveTreeStructureElement}
          />
        )}
        isOpen={isModalOpen}
      />
    </div>
  );
};

export default TreeStructure;
