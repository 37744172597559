import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getFeeRules as getFeeRulesApi,
  deleteFeeRule as deleteFeeRuleApi,
  putFeeRule as putFeeRuleApi,
  postFeeRule as postFeeRuleApi,
  getMnos as getMnosApi,
  postSetupFee as postSetupFeeApi,
  getFeePrice as getFeePriceApi,
} from 'queries/feeRules';

import {
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetFreeRules,
    },
    getFeeRules,
  ] = useMutation(getFeeRulesApi);
  const [, getMnos] = useMutation(getMnosApi);
  const [
    {
      isPending: isPendingDeleteFeeRule,
    },
    deleteFeeRule,
  ] = useMutation(deleteFeeRuleApi);
  const [
    {
      isPending: isPendingPutFeeRule,
    },
    putFeeRule,
  ] = useMutation(putFeeRuleApi);
  const [
    {
      isPending: isPendingPostFeeRule,
    },
    postFeeRule,
  ] = useMutation(postFeeRuleApi);
  const [
    {
      isPending: isPendingPostSetupFee,
    },
    postSetupFee,
  ] = useMutation(postSetupFeeApi);
  const [
    {
      isPending: isPendingGetFeePrice,
    },
    getFeePrice,
  ] = useMutation(getFeePriceApi);

  const selector = useCallback(state => ({
    feeRules: getArray(state, 'feeRules'),
    mnos: getArray(state, 'mnos'),
  }), []);
  const data = useISESelector(selector);

  return {
    getFeeRules,
    deleteFeeRule,
    putFeeRule,
    postFeeRule,
    getMnos,
    postSetupFee,
    getFeePrice,
    isPendingGetFreeRules,
    isPendingDeleteFeeRule,
    isPendingPutFeeRule,
    isPendingPostFeeRule,
    isPendingPostSetupFee,
    isPendingGetFeePrice,
    ...data,
  };
};
