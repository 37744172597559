import endpoints from 'consts/endpoints';

export default ({
  id,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getSenderUrl(id),
  queryKey: endpoints.getSenderUrl(id),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  // transform: response => ({
  //   sender: response,
  // }),
  // update: {
  //   sender: (oldValue, newValue) => newValue,
  // },
});
