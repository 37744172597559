import React, { useMemo } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import {
  DocFileIcon,
  FileIcon,
  PdfIconNew,
  TxtIcon,
  XlsIcon,
} from 'assets/icons';
import { ImagePlaceholder } from 'components';
import Button from '@setproduct-ui/core/Button';
import { MIME_TYPES } from 'consts';

import styles from './MediaPreview.module.scss';

const MediaPreview = ({
  file,
}) => {
  const { t } = useTranslation();
  const getUrl = () => file?.fileUrl
        || file?.thumbnailUri
        || file?.imageUrl
        || file?.image?.link
        || file?.image?.fileUrl
        || file?.document?.link
        || file?.audio?.link
        || file?.video?.link
        || file?.thumbnailUrl;

  const contentMedia = useMemo(() => {
    const fileUrl = getUrl();
    const fileExt = fileUrl?.split('.').pop().toLowerCase();
    const fileType = MIME_TYPES[fileExt];
    switch (fileType) {
      case 'video/mp4':
      case 'video/3gpp':
      case 'video/webm':
        return (
          <video width="362" height="198" className={styles.video} controls muted>
            <source src={fileUrl} type="video/mp4" />
            {t('ERRORS.SUPPORT_VIDEO')}
          </video>
        );
      case 'audio/mpeg':
      case 'audio/webm':
      case 'audio/3gpp':
        return (
          <audio controls muted>
            <source src={fileUrl} type="audio/mpeg" />
            {t('ERRORS.SUPPORT_AUDIO')}
          </audio>
        );
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <div className={styles.fileIcon}>
            <DocFileIcon className={styles.media} />
          </div>
        );
      case 'application/pdf':
        return (
          <div className={styles.fileIcon}>
            <PdfIconNew className={styles.media} />
          </div>
        );
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <div className={styles.fileIcon}>
            <XlsIcon className={styles.media} />
          </div>
        );
      case 'text/plain':
        return (
          <div className={styles.fileIcon}>
            <TxtIcon className={styles.media} />
          </div>
        );
      case 'image/jpeg':
      case 'image/gif':
      case 'image/png':
      case 'image/webp':
        return (
          <ImagePlaceholder
            containerClassname={styles.image}
            src={fileUrl}
          />
        );
      default:
        return (
          <div className={styles.fileIcon}>
            <FileIcon className={styles.media} />
          </div>
        );
    }
  }, [file]);

  const onDownloadCLick = () => {
    const fileUrl = getUrl();
    const fileName = decodeURIComponent(fileUrl?.split('/').pop());
    saveAs(fileUrl, fileName);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {contentMedia}
        <Button
          type="icon"
          view="smooth"
          color="default"
          icon="download"
          className={styles.downloadButton}
          onClick={onDownloadCLick}
        />
      </div>
    </div>
  );
};

export default MediaPreview;
