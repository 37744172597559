import { uniqueId } from 'lodash';

const getVariables = ({
  text = '',
  variables = [],
  category = '',
}) => {
  const foundVariables = text?.match(/{{([0-9]+)}}/gm);
  if (foundVariables?.length) {
    const variablesValues = foundVariables?.map(I => I.replace(/([{}])/gm, ''));
    return variablesValues.map((I, indexVar) => {
      let prevVariableValue = '';
      if (variables?.length) {
        prevVariableValue = variables?.[indexVar];
      }
      return { key: I, value: prevVariableValue, category };
    });
  }
  return [];
};

const convertTemplateToConstructorFormat = ({
  components,
  category,
}) => {
  const content = {};

  components?.forEach((component) => {
    const { type } = component;
    switch (type) {
      case 'HEADER': {
        if (component.format === 'TEXT') {
          const headerVariables = getVariables({
            text: component.text,
            category: 'Header',
            variables: component?.example?.header_text || [],
          });
          content.header = {
            format: 'TEXT',
            text: component.text,
            variables: headerVariables,
          };
        }

        // if (!useEmptyValues) {
        if (component.format === 'LOCATION') {
          content.header = {
            format: 'LOCATION',
          };
        }

        if (component.format === 'IMAGE'
              || component.format === 'VIDEO'
              || component.format === 'DOCUMENT'
        ) {
          content.header = {
            format: component.format,
            [component.format.toLowerCase()]: {
              link: component?.example?.header_handle?.[0],
            },
          };
        }
        break;
      }

      case 'BODY': {
        if (category === 'AUTHENTICATION') {
          content.securityDisclaimer = component.add_security_recommendation;
        } else {
          const bodyVariables = getVariables({
            text: component.text,
            category: 'Body',
            variables: component?.example?.body_text?.[0] || [],
          });
          content.body = {
            text: component.text,
            variables: bodyVariables,
          };
        }
        break;
      }

      case 'FOOTER': {
        if (category === 'AUTHENTICATION' && component.code_expiration_minutes) {
          content.expirationTimer = component.code_expiration_minutes;
          content.expirationWarning = true;
        } else {
          content.footer = {
            text: component.text,
          };
        }
        break;
      }

      case 'LIMITED_TIME_OFFER': {
        content.hasExpiration = component.limited_time_offer.has_expiration;
        content.limitedTimeOffer = {
          text: component.limited_time_offer.text,
          expirationTime: component.limited_time_offer.expiration_time_ms,
        };
        break;
      }

      case 'CAROUSEL': {
        content.cards = component.cards.map((card) => {
          const cardComponents = {};
          card.components.forEach((cardComponent) => {
            switch (cardComponent.type) {
              case 'HEADER': {
                if (cardComponent.format === 'TEXT') {
                  const headerVariables = getVariables({
                    text: cardComponent.text,
                    category: 'Header',
                  });
                  cardComponents.header = {
                    format: 'TEXT',
                    text: cardComponent.text,
                    variables: headerVariables,
                  };
                }

                if (cardComponent.format === 'IMAGE'
                    || cardComponent.format === 'VIDEO'
                ) {
                  cardComponents.header = {
                    format: cardComponent.format,
                  };
                }

                if (cardComponent.format === 'IMAGE'
                      || cardComponent.format === 'VIDEO'
                ) {
                  cardComponents.header = {
                    format: cardComponent.format,
                    [cardComponent.format.toLowerCase()]: {
                      link: cardComponent?.example?.header_handle?.[0],
                    },
                  };
                }

                break;
              }

              case 'BODY': {
                const bodyVariables = getVariables({
                  text: cardComponent.text,
                  category: 'Body',
                  variables: cardComponent?.example?.body_text?.[0] || [],
                });
                cardComponents.body = {
                  text: cardComponent.text,
                  variables: bodyVariables,
                };
                break;
              }

              case 'FOOTER': {
                cardComponents.footer = {
                  text: cardComponent.text,
                };
                break;
              }

              case 'BUTTONS': {
                cardComponents.suggestionsInside = cardComponent.buttons.reduce((acc, btn) => {
                  const buttonVariables = getVariables({
                    text: btn.text,
                    category: 'Buttons',
                  });
                  switch (btn.type) {
                    case 'QUICK_REPLY': {
                      acc.push({
                        id: uniqueId(),
                        category: 'replyAction',
                        text: btn.text,
                        variables: buttonVariables,
                        suggestionType: 'reply',
                      });
                      break;
                    }
                    case 'URL': {
                      acc.push({
                        id: uniqueId(),
                        category: 'urlAction',
                        text: btn.text,
                        url: btn.url,
                        variables: buttonVariables,
                        suggestionType: 'action',
                      });
                      break;
                    }
                    default:
                      break;
                  }
                  return acc;
                }, []);
                break;
              }

              default:
                break;
            }
          });
          return cardComponents;
        });
        break;
      }

      case 'BUTTONS': {
        content.suggestionsInside = component.buttons?.reduce((acc, btn) => {
          switch (btn.type) {
            case 'QUICK_REPLY': {
              acc.push({
                id: uniqueId(),
                category: 'replyAction',
                suggestionType: 'reply',
                text: btn.text,
              });
              break;
            }
            case 'URL': {
              if (category === 'AUTHENTICATION') {
                acc.push({
                  id: uniqueId(),
                  category: 'urlAction',
                  suggestionType: 'action',
                  text: btn.text,
                  url: btn.url,
                });
              } else {
                const buttonVariables = getVariables({
                  text: btn.url,
                  category: 'Buttons',
                  variables: btn?.example,
                });
                acc.push({
                  id: uniqueId(),
                  category: 'urlAction',
                  suggestionType: 'action',
                  text: btn.text,
                  url: btn.url,
                  variables: buttonVariables,
                });
              }
              break;
            }
            case 'OTP':
              acc.push({
                id: uniqueId(),
                category: 'copyAction',
                suggestionType: 'action',
                text: btn.text,
              });
              break;
            case 'COPY_CODE': {
              acc.push({
                id: uniqueId(),
                category: 'copyAction',
                suggestionType: 'action',
                text: btn.text,
                displayText: 'Copy code',
                code: btn.example?.[0],
              });
              break;
            }
            default:
              break;
          }
          return acc;
        }, []);

        break;
      }

      default:
        break;
    }
  });

  content.selectedType = 'whatsAppRichCard';

  if (!components?.length) {
    // For templates sent via API
    content.body = {
      text: 'This WhatsApp template was sent using the API.',
    };
  } else if (category === 'AUTHENTICATION') {
    // AUTHENTICATION
    content.selectedType = 'whatsAppAuthenticationCard';
  } else if (content?.hasExpiration || content?.limitedTimeOffer) {
    // LTO
    content.selectedType = 'whatsAppLimitedTimeOfferCard';
  } else if (content?.cards) {
    // CAROUSEL
    content.selectedType = 'whatsAppCarouselCard';
  } else if (!(content?.hasExpiration || content?.limitedTimeOffer)
      && !content?.expirationTimer
      && content?.suggestionsInside?.some(item => item.category === 'copyAction')
  ) {
    // COUPON
    content.selectedType = 'whatsAppCouponCodeCard';
  }

  return content;
};

export default convertTemplateToConstructorFormat;
