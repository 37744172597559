import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';
import moment from 'moment';
import { sortBy } from 'lodash';

import Spinner from '@setproduct-ui/core/Spinner';
import Button from '@setproduct-ui/core/Button';
import { useServicesApi } from 'hooks/api';
import { useModalLogic } from 'hooks';
import { SERVICE_TYPES_DICTIONARY } from 'consts';
import { ConfirmModal } from 'components/modals';
import toastRef from 'helpers/toast';

import ScopeItem from './ScopeItem';
import TreeTable from './TreeTable';
import styles from './ServiceTab.module.scss';

const ServiceTab = ({
  imperativeRef,
  id,
  name,
  type,
  currency,
  archivedAt,
  readOnly = !!archivedAt,
}) => {
  const { t } = useTranslation();
  const [activeScope, setActiveScope] = useState();
  const [scopesList, setScopesList] = useState(null);
  const [restServicesWithScopes, setRestServicesWithScopes] = useState([]);
  const scopesListRef = useRef();
  const activeRowRef = useRef(null);
  const listRef = useRef(null);
  const [isOpenModal, { closeModal, openModal }] = useModalLogic(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const {
    isPendingGetAllServicesWithScopes,
    postScope,
    patchScope,
    deleteScope,
    getAllServicesWithScopes,
    getServiceById,
    isPendingGetServiceById,
    isPendingPostScope,
    isPendingPatchScope,
    isPendingDeleteScope,
  } = useServicesApi();

  const getTransformedScopeData = (effectiveFrom, effectiveTill, isDraft) => {
    const effectiveFromText = effectiveFrom ? moment(effectiveFrom).format('DD.MM.YYYY')
      : '—';
    const effectiveTillText = effectiveTill ? moment(effectiveTill).format('DD.MM.YYYY')
      : effectiveFrom ? `∞ ${t('CONTROLS.INDEFINITELY')}` : '—';
    const label = `${effectiveFromText} - ${effectiveTillText}`;

    if (isDraft) {
      return {
        chipsProps: {
          tag: 'draft',
          color: 'warning',
        },
        label,
      };
    }

    const effectiveTillModify = effectiveTill === null
      ? moment().set({ day: 31, month: 11, year: 2999 })
      : effectiveTill;

    if (moment().isBetween(
      moment(effectiveFrom).local(),
      moment(effectiveTillModify).local(),
      null,
      '()',
    )) {
      return {
        chipsProps: {
          tag: 'current',
          color: 'success',
        },
        label,
      };
    }
    if (moment().isBefore(effectiveFrom)) {
      return {
        chipsProps: {
          tag: 'upcoming',
          color: 'primary',
        },
        label,
      };
    }
    return {
      chipsProps: {
        tag: 'previous',
        color: 'default',
      },
      label,
    };
  };

  const onDeleteScope = (scopeId) => {
    setIdToDelete(scopeId);
    openModal(true);
  };
  const onDeleteConfirm = () => {
    deleteScope({
      id: idToDelete,
      successCallback: () => {
        if (idToDelete === activeScope) {
          setActiveScope(undefined);
        }
        setScopesList(prev => prev.filter(scope => scope.id !== idToDelete));
        closeModal();
      },
    });
  };
  const onAddNewScope = () => {
    setActiveScope('newScope');
    scopesListRef.current.scroll({ top: 0, behavior: 'smooth' });
  };
  const onSaveScope = (values, successCallback) => {
    if (!id) {
      toastRef.current.showMessage({ message: 'TOASTS.SERVICES.SAVE_SERVICE', intent: 'warning' });
      return;
    }
    (values.id === 'newScope' ? postScope : patchScope)({
      body: {
        serviceId: id,
        ...values,
      },
      successCallback: ({ data }) => {
        if (values.id === 'newScope') {
          setScopesList(prev => [
            ...prev,
            {
              ...data,
              ...getTransformedScopeData(data.effectiveFrom, data.effectiveTill, data.isDraft),
            },
          ]);
          setActiveScope(data.id);
        } else {
          setScopesList(prev => prev.map((scope) => {
            if (scope.id === values.id) {
              return {
                ...data,
                ...getTransformedScopeData(data.effectiveFrom, data.effectiveTill, data.isDraft),
              };
            }
            return { ...scope };
          }));
          successCallback();
        }
      },
    });
  };
  const highlightActiveRow = () => {
    listRef.current?.scrollToRow(localStorage.getItem('savedInitialValues')?.rowIndex);

    setTimeout(() => {
      if (!activeRowRef.current?.className.includes('animatedRow')) {
        activeRowRef.current?.classList?.toggle(styles.animatedRow);
        activeRowRef.current?.children?.[7]?.classList.toggle(styles.animatedActionCell);

        setTimeout(() => {
          activeRowRef.current?.children?.[7]?.classList.toggle(styles.animatedActionCell);
          activeRowRef.current?.classList.toggle(styles.animatedRow);
        }, 2000);
      }
    }, 100);
  };
  const onScopeClick = (scopeId) => {
    if (localStorage.getItem('savedInitialValues')) {
      highlightActiveRow();
      return;
    }
    setActiveScope(scopeId);
  };

  useKey('Escape', () => setActiveScope(undefined));

  useEffect(() => {
    getServiceById({
      id,
      successCallback: ({ data }) => {
        const modifiedScopesList = data.scopes?.map(scope => ({
          ...scope,
          ...getTransformedScopeData(scope.effectiveFrom, scope.effectiveTill, scope.isDraft),
        })) || [];

        setScopesList(modifiedScopesList);
        if (modifiedScopesList.length) {
          const currentScope = modifiedScopesList.find(scope => scope.chipsProps.tag === 'current');
          if (currentScope) {
            setActiveScope(currentScope.id);
            return;
          }

          const upcomingScopes = modifiedScopesList.filter(scope => scope.chipsProps.tag === 'upcoming');
          if (upcomingScopes.length) {
            setActiveScope(sortBy(upcomingScopes, ['effectiveFrom'])[0].id);
            return;
          }
          const previousScopes = modifiedScopesList.filter(scope => scope.chipsProps.tag === 'previous');
          if (previousScopes.length) {
            setActiveScope(sortBy(previousScopes, ['effectiveTill']).at(-1).id);
            return;
          }
          setActiveScope(sortBy(modifiedScopesList, ['value']).at(-1).id);
        } else if (data.scopes) {
          setActiveScope('newScope');
        }
      },
    });
    getAllServicesWithScopes({
      serviceType: type,
      successCallback: ({ data }) => {
        setRestServicesWithScopes(data.reduce((acc, service) => {
          if (service.id === id || !service.scopes.length) {
            return acc;
          }

          acc.push({
            ...service,
            scopes: service.scopes.map(scope => ({
              ...scope,
              ...getTransformedScopeData(scope.effectiveFrom, scope.effectiveTill, scope.isDraft),
            })),
          });
          return acc;
        }, []));
      },
    });
  }, []);

  return (
    <div className={styles.serviceTab}>
      <div className={styles.headerRow}>
        <div className={styles.headerItem}>
          <span>
            {t('INSTANCES.ID')}
          </span>
          <span>
            {id}
          </span>
        </div>
        <div className={styles.headerItem}>
          <span>
            {t('INSTANCES.NAME')}
          </span>
          <span>
            {name}
          </span>
        </div>
        <div className={styles.headerItem}>
          <span>
            {t('INSTANCES.TYPE')}
          </span>
          <span>
            {SERVICE_TYPES_DICTIONARY[+type]}
          </span>
        </div>
        {currency && (
          <div className={styles.headerItem}>
            <span>
              {t('INSTANCES.CURRENCY')}
            </span>
            <span>
              {currency}
            </span>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.listWrapper}>
          {(isPendingGetAllServicesWithScopes || isPendingGetServiceById) ? (
            <Spinner className={styles.spinner} />
          ) : (
            <>
              <span className={styles.contentTitle}>
                {t('SCREENS.SERVICES.SCOPES')}
              </span>
              <div className={styles.list} ref={scopesListRef}>
                <ScopeItem
                  id="newScope"
                  activeScope={activeScope}
                  onClick={Function.prototype}
                  currentServiceScopes={scopesList}
                  restServicesWithScopes={restServicesWithScopes}
                  onSubmit={onSaveScope}
                  isPending={isPendingPostScope}
                  setActiveScope={setActiveScope}
                  isDraft
                  isNewScope
                />
                {scopesList?.map(scope => (
                  <ScopeItem
                    key={scope.id}
                    activeScope={activeScope}
                    onClick={() => onScopeClick(scope.id)}
                    onSubmit={onSaveScope}
                    onDelete={() => onDeleteScope(scope.id)}
                    isPending={isPendingPostScope || isPendingPatchScope || isPendingDeleteScope}
                    readOnly={readOnly}
                    {...scope}
                  />
                ))}
              </div>
              {!readOnly && (
                <Button
                  color="primary"
                  text="CONTROLS.SERVICES.NEW_SCOPE"
                  onClick={onAddNewScope}
                  className={styles.addButton}
                  style={{ marginTop: scopesList?.length || activeScope === 'newScope' ? 16 : 0 }}
                  disabled={activeScope === 'newScope'}
                />
              )}
            </>
          )}
        </div>
        {!!activeScope && activeScope !== 'newScope' && (
          <TreeTable
            scopeId={activeScope}
            currency={currency}
            serviceType={+type}
            imperativeRef={imperativeRef}
            activeRowRef={activeRowRef}
            listRef={listRef}
            highlightActiveRow={highlightActiveRow}
            readOnly={readOnly}
          />
        )}
      </div>
      {isOpenModal && (
        <ConfirmModal
          onConfirm={onDeleteConfirm}
          closeModal={closeModal}
          isPending={isPendingDeleteScope}
          isDelete
        />
      )}
    </div>
  );
};

export default ServiceTab;
