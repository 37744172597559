import toastRef from './toast';

export default ({
  url,
  fileName,
  callback,
  withAuth = true,
}) => {
  const headers = withAuth
    ? { Authorization: `Bearer ${localStorage.getItem('boToken')}` }
    : {};
  fetch(url, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob();
      }

      throw `${response.status} (${response.statusText})`;
    })
    .then((response) => {
      const objectURL = URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectURL;
      if (fileName) {
        a.download = fileName;
      }
      document.body.appendChild(a);
      a.click();
      a.remove();

      callback?.();
    })
    .catch((err) => {
      toastRef.current.showMessage({ message: err?.key ? err?.key : err, intent: 'danger' });
      callback?.();
    });
};
