import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import TextField from 'components/fields/TextField';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

const ContactArrayField = ({
  push,
  remove,
  name,
  values,
  firstLabel,
  secondLabel = ' ',
  firstPlaceholder,
  secondPlaceholder,
  instance,
  maxLength,
  readOnly,
  withCopyButton,
  style,
  color = 'default',
  leftRegex,
}) => {
  const { t } = useTranslation();

  return (
    <div className="contact-array-field" style={style}>
      {values[name] && values[name].map((field, index) => (
        <div
          className="contact-array-field__row"
          style={(readOnly && values[name].length === 1) ? { marginBottom: 0 } : {}}
          // eslint-disable-next-line react/no-array-index-key
          key={`${name}.${index}.value`}
        >
          <Field name={`${name}.${index}.value`}>
            {props => (
              <TextField
                label={firstLabel}
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}.${index}.value`}
                classNames={{ container: 'contact-array-field__input' }}
                disabled={readOnly}
                withCopyButton={withCopyButton}
                placeholder={firstPlaceholder}
                color={color}
                regex={leftRegex}
                {...props}
                field={{
                  ...props?.field,
                  value: readOnly ? values[name][index].value : props?.field?.value,
                }}
              />
            )}
          </Field>
          <Field name={`${name}.${index}.label`}>
            {props => (
              <TextField
                label={secondLabel}
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}.${index}.label`}
                classNames={{ container: 'contact-array-field__input' }}
                disabled={readOnly}
                withCopyButton={withCopyButton}
                placeholder={secondPlaceholder}
                color={color}
                maxLength={maxLength}
                {...props}
                field={{
                  ...props?.field,
                  value: readOnly ? values[name][index].label : props?.field?.value,
                }}
              />
            )}
          </Field>
          {
            index !== 0 && !readOnly && (
              <Icon
                icon="cross"
                className="contact-array-field__delete-button"
                size={20}
                onClick={() => remove(index)}
              />
            )
          }
        </div>
      ))}
      {/* todo: Костыль. Без обертки кнопка почему-то занимает всю ширину. */}
      {
        values[name]?.length < 3 && !readOnly && (
          <div style={{ display: 'flex' }}>
            <Button
              view="outlined"
              icon="add"
              text={t('CONTROLS.SENDER_IDS.ADD_EXTRA', { instance: t(instance).toLowerCase() })}
              onClick={() => push({ value: null, label: null })}
              disabled={!values[name][0]?.value}
            />
          </div>
        )
      }
    </div>
  );
};

export default ContactArrayField;
