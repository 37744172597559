import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getUsers as getUsersApi,
  deleteUser as deleteUserApi,
  postUser as postUserApi,
  putUser as putUserApi,
  putUserRoles as putUserRolesApi,
  getUserById as getUserByIdApi,
  getCurrentUser as getCurrentUserApi,
  putUpdateUserPassword as putUpdateUserPasswordApi,
} from 'queries/users';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetUsers,
      lastUpdated: lastUpdatedGetUsers,
    },
    getUsers,
  ] = useMutation(getUsersApi);
  const [
    {
      isPending: isPendingDeleteUser,
    },
    deleteUser,
  ] = useMutation(deleteUserApi);
  const [
    {
      isPending: isPendingPostUser,
    },
    postUser,
  ] = useMutation(postUserApi);
  const [
    {
      isPending: isPendingPutUser,
    },
    putUser,
  ] = useMutation(putUserApi);
  const [
    {
      isPending: isPendingPutUserRoles,
    },
    putUserRoles,
  ] = useMutation(putUserRolesApi);
  const [
    {
      isPending: isPendingGetUserById,
    },
    getUserById,
  ] = useMutation(getUserByIdApi);
  const [
    {
      isPending: isPendingGetCurrentUser,
    },
    getCurrentUser,
  ] = useMutation(getCurrentUserApi);
  const [
    {
      isPending: isPendingPutUpdateUserPassword,
    },
    putUpdateUserPassword,
  ] = useMutation(putUpdateUserPasswordApi);

  const selector = useCallback(state => ({
    backofficeUsers: getArray(state, 'backofficeUsers'),
    backofficeUsersMeta: getObject(state, 'backofficeUsersMeta'),
    mkitUsers: getArray(state, 'mkitUsers'),
    mkitUsersMeta: getObject(state, 'mkitUsersMeta'),
    currentUser: getObject(state, 'currentUser'),
  }), []);
  const data = useISESelector(selector);

  return {
    getUsers,
    deleteUser,
    postUser,
    putUser,
    putUserRoles,
    getUserById,
    getCurrentUser,
    putUpdateUserPassword,
    isPendingGetUsers,
    isPendingDeleteUser,
    isPendingPostUser,
    isPendingPutUser,
    isPendingPutUserRoles,
    isPendingGetUserById,
    isPendingGetCurrentUser,
    isPendingPutUpdateUserPassword,
    lastUpdatedGetUsers,
    ...data,
  };
};
