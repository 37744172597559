import React, { useMemo } from 'react';

import {
  DocFileIcon,
  FileIcon,
  GifIcon,
  Mp3Icon,
  PdfIconNew,
  TxtIcon,
  XlsIcon,
} from 'assets/icons';
import { ImagePlaceholder } from 'components';
import Badge from '@setproduct-ui/core/Badge';

import styles from './FileCellRenderer.module.scss';

const FileCellRenderer = ({
  valueFormatted = [],
}) => {
  const contentMedia = useMemo(() => {
    let previews = [];
    if (valueFormatted.length) {
      previews = valueFormatted.map((I) => {
        const typeProp = I?.fileMIMEType || I?.fileMimeType;
        const imgUrl = I?.fileUrl || I?.thumbnailUri || I?.imageUrl || I?.image?.link || I?.thumbnailUrl;
        switch (typeProp) {
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <DocFileIcon className={styles.file} />;
          case 'application/pdf':
            return <PdfIconNew className={styles.file} />;
          case 'audio/mpeg':
            return <Mp3Icon className={styles.file} />;
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <XlsIcon className={styles.file} />;
          case 'image/gif':
            return <GifIcon className={styles.file} />;
          case 'text/plain':
            return <TxtIcon className={styles.file} />;
          case 'image/jpeg':
          case 'image/png':
            return (
              <ImagePlaceholder
                containerClassname={styles.file}
                alt={I.fileName}
                src={imgUrl}
              />
            );
          default:
            return <FileIcon className={styles.file} />;
        }
      });
    }
    return previews;
  }, [valueFormatted]);

  const firstMedia = useMemo(() => contentMedia[0], [contentMedia]);

  return (
    <div className={styles.files}>
      {firstMedia && (
        <Badge
          view="outlined"
          value={contentMedia.length}
          hide={contentMedia.length < 2}
          dense
          badgeClassName={styles.badge}
        >
          <div className={styles.file}>
            {firstMedia}
          </div>
        </Badge>
      )}
    </div>
  );
};

export default FileCellRenderer;
