import { uniq, uniqBy } from 'lodash';

const getRoleDependenciesList = dependencies => dependencies.filter(dependency => !dependency.isPrime)
  .reduce((acc, dependency) => {
    acc.push({
      code: dependency.code,
      name: dependency.name,
    });

    if (dependency.dependencies.length) {
      acc = [
        ...acc,
        ...getRoleDependenciesList(dependency.dependencies),
      ];
    }

    return acc;
  }, []);

export default roles => roles.reduce((acc, {
  categoryCode, categoryName, categoryKey, dependencies, ...role
}) => {
  // не учитываем админскую роль, у нее нет категории и она зашита в RolesController
  if (categoryCode) {
    // определяем к какой подкатегории относится разрешение
    const accessKey = role.code.includes('view') ? 'view' : 'fullAccess';
    // массив разрешений подкатегории
    const permissionsModify = uniq([
      ...(acc[categoryCode]?.[accessKey]?.permissions || []),
      role.code,
    ]);

    acc[categoryCode] = {
      categoryName,
      categoryKey,
      ...(acc[categoryCode] || {}),
      [accessKey]: {
        ...(acc[categoryCode]?.[accessKey] || {}),
        permissions: permissionsModify,
        // составляем список зависимых разрешений в виде массива объектов, для того, чтоб можно было визуально
        // отобразить его в форме
        dependencies: uniqBy([
          ...(acc[categoryCode]?.[accessKey]?.dependencies || []),
          ...getRoleDependenciesList(dependencies),
        ], 'code').reduce((depAcc, dependency) => {
          // проходимся по зависимым разрешениям и оставляем только те, которые не совпадают с разрешениями
          // в подкатегории, чтобы исбежать кейса, когда выбор подкатегории Full access блокирует сам себя,
          // из-за того, что в ролях подкатегории могут в зависимости иметь роли той же подкатегории
          if (!permissionsModify.some(I => I === dependency.code)) {
            depAcc.push(dependency);
          }
          return depAcc;
        }, []),
      },
    };
  }

  return acc;
}, {});
