import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import Button from '@setproduct-ui/core/Button/Button';
import { Typography } from 'components';
import { useDropdownLogic } from 'hooks';
import { copyInClipboard } from 'helpers';

import './style.scss';

export default ({
  id,
  createdAt,
  updatedAt,
  style,
}) => {
  const { t } = useTranslation();

  const {
    dropdownRef,
    buttonRef,
    isDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const dateString = (value) => {
    const date = moment(value).format('YYYY MMM DD HH:mm:ss');
    if (date === 'Invalid date') {
      return t('CONTROLS.UNKNOWN');
    }
    return date;
  };
  const onCopyClick = () => {
    const dateCreateAt = createdAt ? `${t('INSTANCES.CREATED')}: ${dateString(createdAt)}` : '';
    const dateUpdateAt = updatedAt ? `${t('INSTANCES.UPDATED')}: ${dateString(updatedAt)}` : '';
    copyInClipboard(
      `${dateCreateAt}\n${dateUpdateAt}`,
    );
  };

  return (
    <button
      ref={buttonRef}
      type="button"
      className="record-info"
      onClick={toggleDropdown}
      style={style}
    >
      <Icon icon="info-sign" color="#2264D1" size={12} />
      <span className="record-info__label">ID</span>
      {id}
      {
        isDropdownOpen && (
          <div className="record-info__overlay" ref={dropdownRef}>
            { createdAt && (
            <p className="record-info__value">
              {`${t('INSTANCES.CREATED')}:`}
              <span>
                { dateString(createdAt) }
              </span>
            </p>
            )}
            { updatedAt && (
            <p className="record-info__value">
              {`${t('INSTANCES.UPDATED')}:`}
              <span>
                { dateString(updatedAt) }
              </span>
            </p>
            )}
            <Button
              dense
              type="default"
              view="smooth"
              color="primary"
              className="record-info__copy"
              onClick={onCopyClick}
            >
              <Typography className="record-info__text">
                CONTROLS.COPY_TO_CLIPBOARD
              </Typography>
            </Button>
          </div>
        )
      }
    </button>
  );
};
