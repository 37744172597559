import endpoints from 'consts/endpoints';

export default ({
  taskId,
  attributeId,
  body,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getTreeStructureUrl(taskId, attributeId),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: treeStructureElements => ({
    rateImportInfo: treeStructureElements,
  }),
  update: {
    rateImportInfo: (oldValue = {}, newValue) => ({
      ...oldValue,
      treeStructureElements: newValue,
    }),
  },
});
