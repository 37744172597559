import { useScroll } from 'react-use';
import { useMemo } from 'react';

export default (contentRef) => {
  const { y } = useScroll(contentRef);

  const {
    showTopShadow,
    showBottomShadow,
  } = useMemo(() => {
    const { current } = contentRef;

    let showTopShadowModify = false;
    let showBottomShadowModify = false;

    if (current && current.scrollHeight > current.offsetHeight) {
      // -1 это костыль для формы юзеров
      const maxScrollValue = current.scrollHeight - current.offsetHeight - 1;

      if (y > 0) {
        showTopShadowModify = true;
      }
      if (y < maxScrollValue) {
        showBottomShadowModify = true;
      }
    }

    return {
      showTopShadow: showTopShadowModify,
      showBottomShadow: showBottomShadowModify,
    };
  }, [contentRef.current?.scrollHeight, contentRef.current?.offsetHeight, y]);

  return {
    showBottomShadow,
    showTopShadow,
  };
};
