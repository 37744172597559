import endpoints from 'consts/endpoints';

export default ({
  id,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getScopeItemsUrl({ id }),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
