import bytesToSize from 'helpers/bytesToSize';
import getTransformedRcs from 'helpers/transforms/getTransformedRcs';
import getTransformedWhatsApp from 'helpers/transforms/getTransformedWhatsApp';
import getTransformedViber from 'helpers/transforms/getTransformedViber';
import getTransformedWeChat from 'helpers/transforms/getTransformedWeChat';

export default (body) => {
  try {
    const parsedBody = JSON.parse(body);
    if (typeof parsedBody === 'object') {
      // whatsapp interactive
      if (parsedBody.type === 'interactive') {
        return ({
          type: 'interactive',
          body: {
            ...parsedBody.interactive,
            suggestionsInside: parsedBody.interactive.action?.buttons?.map(I => ({
              id: I.reply.id,
              text: I.reply.title,
              suggestionType: I.type,
            })),
          },
        });
      }
      // whatsapp text
      if (parsedBody.text?.body) {
        return ({
          type: 'text',
          body: parsedBody.text.body,
        });
      }
      // viber pic card
      if (parsedBody.text && parsedBody.buttonCaption && (parsedBody.imageUrl || parsedBody.videoUrl)) {
        return ({
          type: 'picCard',
          body: getTransformedViber(parsedBody),
        });
      }
      // viber text card
      if (parsedBody.text && parsedBody.buttonCaption) {
        return ({
          type: 'textCard',
          body: {
            ...parsedBody,
          },
        });
      }
      // viber text
      if (parsedBody.text) {
        return ({
          type: 'text',
          body: parsedBody.text,
        });
      }
      // viber file
      if (parsedBody?.imageUrl || parsedBody?.videoUrl || parsedBody?.fileUrl) {
        const fileUrl = parsedBody?.imageUrl || parsedBody?.videoUrl || parsedBody?.fileUrl;
        const fileName = fileUrl.split('/').at(0) || '';

        return {
          name: fileName,
          type: 'file',
          preview: fileUrl,
          body: getTransformedViber(parsedBody),
        };
      }
      // whatsapp location
      if (parsedBody.type === 'location') {
        return ({
          type: 'geolocation',
          name: 'Geolocation',
          body: getTransformedWhatsApp({
            contentPattern: parsedBody,
          }),
        });
      }
      // whatsapp any file
      if (['image', 'video', 'document'].includes(parsedBody.type)) {
        return {
          type: 'file',
          preview: parsedBody.thumbnailUri || parsedBody.thumbnailUrl || parsedBody?.image?.link,
          name: parsedBody.fileName
                        || parsedBody[parsedBody.type]?.link?.split('/')?.pop()
                        || '',
          body: getTransformedWhatsApp({
            contentPattern: parsedBody,
          }),
        };
      }
      // whatsapp template
      if (parsedBody.type === 'template') {
        return ({
          type: 'template',
          name: parsedBody.template.name,
          body: {
            selectedType: 'whatsAppTemplate',
            components: parsedBody.template.templateComponents,
            ...getTransformedWhatsApp({
              contentPattern: parsedBody,
            }),
          },
        });
      }
      // rcs file
      if (parsedBody?.RCSMessage?.fileMessage || parsedBody?.fileMessage) {
        const fileMessage = parsedBody.fileMessage || parsedBody.RCSMessage.fileMessage;

        return {
          name: fileMessage.fileName || '',
          size: bytesToSize(fileMessage.fileSize),
          type: 'file',
          preview: fileMessage.thumbnailUrl,
          body: getTransformedRcs({
            contentPattern: {
              RCSMessage: {
                fileMessage,
              },
            },
          }),
        };
      }
      // rcs richcard
      if (parsedBody?.RCSMessage?.richcardMessage) {
        const bodyForEditor = getTransformedRcs({
          contentPattern: parsedBody,
        });

        return ({
          type: bodyForEditor.selectedType,
          name: bodyForEditor.selectedType === 'carousel' ? 'Carousel' : 'Rich Card',
          body: bodyForEditor,
        });
      }
      // rcs MT message
      if (parsedBody.RCSMessage?.textMessage) {
        if (parsedBody.RCSMessage?.suggestedChipList?.suggestions?.length) {
          const bodyForEditor = getTransformedRcs({
            contentPattern: parsedBody,
          });
          const message = parsedBody.RCSMessage?.textMessage;

          return ({
            type: 'textWithSuggestions',
            name: message.length > 30 ? `${message.substring(0, 30)}...` : message,
            body: bodyForEditor,
          });
        }

        return ({
          type: 'text',
          body: parsedBody.RCSMessage?.textMessage,
        });
      }
      // emulated message
      if (parsedBody.textMessage) {
        return ({
          type: 'text',
          body: parsedBody.textMessage,
        });
      }
      // weChat MO/MT text
      if (parsedBody.MsgType === 'text' && parsedBody.Content) {
        return {
          type: 'text',
          name: 'NAME???',
          body: getTransformedWeChat(parsedBody, 'weChatText'),
        };
      }
      // weChat MO/MT file
      if (parsedBody.MsgType === 'image' && parsedBody.PicUrl) {
        const constructorBody = getTransformedWeChat(parsedBody, 'weChatFile');

        return {
          type: 'file',
          name: 'NAME???',
          body: constructorBody,
        };
      }
      return ({
        type: 'parseError',
      });
    }
    return ({
      type: 'text',
      body: parsedBody,
    });
  } catch (e) {
    return ({
      type: 'text',
      body,
    });
  }
};
