import React from 'react';

import styles from './Checkbox.module.scss';

import { ReactComponent as CheckIcon } from '../../icons/check.svg';

const Checkbox = ({
  checked,
  disabled,
  onChange,
  id,
}) => {
  const handleChange = ({ target }) => {
    onChange(target.checked);
  };

  return (
    <label htmlFor={id} className={styles.container}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        id={id}
      />
      <div className={styles.checkmark}>
        {checked && <CheckIcon width={10} fill="var(--grey100)" />}
      </div>
    </label>
  );
};

export default Checkbox;
