import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { Formik, Form, Field } from 'formik';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';

import { useToken } from 'hooks';
import { useAccountApi } from 'hooks/api';
import TextField from 'components/fields/TextField';
import { CheckboxField } from 'components/fields/CheckboxField';
import Button from '@setproduct-ui/core/Button';
import endpoints from 'consts/endpoints';
import toastRef from 'helpers/toast';
import { PASSWORD_SCHEMA, RESET_PASSWORD_SCHEMA } from 'consts/validationSchemas';
import Color from '@setproduct-ui/styles/color.module.css';

import styles from './SignInScreen.module.scss';

const SignInScreen = ({ useTokenHook }) => {
  const { t } = useTranslation();
  const {
    postResetPassword,
    postConfirmCode,
    postActivatePassword,
    putResetPassword,
    isPendingPostResetPassword,
    isPendingPostConfirmCode,
    isPendingPostActivatePassword,
    isPendingPutResetPassword,
  } = useAccountApi();
  const [showPassword, toggleShowPassword] = useToggle(false);
  const [showConfirmPassword, toggleShowConfirmPassword] = useToggle(false);
  const [showOldPassword, toggleShowOldPassword] = useToggle(false);
  const [isPending, setIsPending] = useState(false);
  const {
    setToken,
    setTokenExpiresIn,
    setRefreshTokenExpiresIn,
    setIdToken,
  } = (useTokenHook || useToken)();
  const history = useHistory();
  const [screenMode, setScreenMode] = useState('signIn');
  const [isMailSent, setIsMailSent] = useState(false);
  const [signIn2FaKey, setSignIn2FaKey] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [loginToReset, setLoginToReset] = useState('');
  const formRef = useRef();

  const {
    backgroundImage,
    loginLogo,
    loginTitle,
  } = useMemo(() => {
    try {
      const parsedPortal = JSON.parse(localStorage.getItem('boPortal'));

      return {
        backgroundImage: endpoints.getPortalAssetsUrl(parsedPortal.loginBgs?.[0])?.replace(/ /g, '%20'),
        loginLogo: endpoints.getPortalAssetsUrl(parsedPortal.loginLogo),
        loginTitle: parsedPortal.loginTitle,
      };
    } catch (error) {
      return {};
    }
  }, []);
  const {
    title,
    subtitle,
    validationSchema,
  } = useMemo(() => {
    switch (screenMode) {
      case 'signIn':
        return {
          title: loginTitle ? (loginTitle.en || loginTitle[Object.keys(loginTitle)[0]]) : null,
          subtitle: t('SCREENS.SIGN_IN.WELCOME_BACK'),
        };
      case 'forgotPassword':
        return {
          title: t('SCREENS.SIGN_IN.FORGOT_PASSWORD'),
          subtitle: t('SCREENS.SIGN_IN.FORGOT_PASSWORD_HINT'),
        };
      case '2fa':
        return {
          title: t('SCREENS.SIGN_IN.TWO_FA'),
          subtitle: t('SCREENS.SIGN_IN.TWO_FA_HINT'),
        };
      case 'enterNewPassword':
        return {
          title: t('SCREENS.SIGN_IN.PASSWORD_UPDATE'),
          validationSchema: PASSWORD_SCHEMA,
        };
      case 'requiredEnterNewPassword':
        return {
          title: t('SCREENS.SIGN_IN.PASSWORD_UPDATE'),
          validationSchema: RESET_PASSWORD_SCHEMA.concat(PASSWORD_SCHEMA),
        };
      default: return {};
    }
  }, [screenMode]);

  const changeModeToForgotPass = () => {
    formRef.current.resetForm();
    setScreenMode('forgotPassword');
    setIsMailSent(false);
  };
  const returnToSignInModeAndResetForm = () => {
    formRef.current.resetForm();
    setScreenMode('signIn');
  };
  const onSubmit = ({
    login,
    password,
    rememberMe,
    code,
    oldPassword,
  }) => {
    const signIn = (loginParam = login) => {
      const loginPass = String.fromCodePoint(...new TextEncoder().encode(`${loginParam}:${password}`));

      setIsPending(true);
      fetch(endpoints.getSignInUrl(), {
        method: 'POST',
        headers: new Headers({
          Authorization: `Basic ${btoa(`${loginPass}`)}`,
        }),
        credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
        body: {
          rememberMe,
        },
      })
        .then(response => response.json())
        .then(async ({
          token,
          expiresIn,
          refreshExpiresIn,
          idToken,
          ...rest
        }) => {
          setIsPending(false);
          if (rest?.error) {
            toastRef.current.showMessage({ message: rest.key });
            if (rest.error === 'Change password') {
              formRef.current.resetForm();
              setScreenMode('requiredEnterNewPassword');
            }
          } else if (rest?.['2fa'] || rest?.['2fa'] === null) {
            setScreenMode('2fa');
            setSignIn2FaKey(rest['2fa']);
          } else {
            const urlParams = new URLSearchParams(window.location.search);

            await setToken(token);
            setTokenExpiresIn(moment().add(expiresIn, 'seconds'));
            setRefreshTokenExpiresIn(moment().add(refreshExpiresIn, 'seconds'));
            setIdToken(idToken);
            history.push(urlParams.get('redirectTo')?.replace('/bo', '') || '/');
          }
        });
    };
    switch (screenMode) {
      case 'signIn': {
        signIn();
        break;
      }
      case 'forgotPassword':
        postResetPassword({
          body: {
            email: login,
          },
          successCallback: () => {
            setIsMailSent(true);
            returnToSignInModeAndResetForm();
          },
        });
        break;
      case '2fa':
        postConfirmCode({
          body: {
            code,
            requestId: signIn2FaKey,
          },
          successCallback: async ({
            token,
            expiresIn,
            refreshExpiresIn,
            idToken,
          }) => {
            const urlParams = new URLSearchParams(window.location.search);

            await setToken(token);
            setTokenExpiresIn(moment().add(expiresIn, 'seconds'));
            setRefreshTokenExpiresIn(moment().add(refreshExpiresIn, 'seconds'));
            setIdToken(idToken);
            history.push(urlParams.get('redirectTo')?.replace('/bo', '') || '/');
          },
        });
        break;
      case 'enterNewPassword':
        postActivatePassword({
          body: {
            password,
            token: resetToken,
          },
          successCallback: () => {
            signIn(loginToReset);
          },
        });
        break;
      case 'requiredEnterNewPassword':
        putResetPassword({
          body: {
            username: login,
            password,
            oldPassword,
          },
          successCallback: () => {
            signIn(login);
          },
        });
        break;
      default: break;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const reset = urlParams.get('reset');

    if (reset) {
      setLoginToReset(urlParams.get('login'));
      setResetToken(reset);
      setScreenMode('enterNewPassword');
      history.push('/sign-in');
    }
  }, [history.location.search]);

  return (
    <div
      className={styles.screen}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.card}>
        <img src={loginLogo} className={styles.logo} alt="logo" />
        {title && (
          <span className={styles.title}>
            {title}
          </span>
        )}
        {subtitle && (
          <span className={styles.subtitle}>
            {subtitle}
          </span>
        )}
        {isMailSent && (
          <div className={cx(styles.infoRow, Color.success)}>
            <Icon
              icon="tick-circle"
              color="var(--green60)"
              size={18}
            />
            <span>
              {t('SCREENS.SIGN_IN.EMAIL_SENT')}
            </span>
          </div>
        )}
        {screenMode === 'enterNewPassword' && (
          <div className={cx(styles.infoRow, Color.warning)}>
            <Icon
              icon="warning-sign"
              color="var(--orange60)"
              size={18}
            />
            <span>
              {t('SCREENS.SIGN_IN.HAVE_TO_CHANGE_PASSWORD')}
            </span>
          </div>
        )}
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          innerRef={formRef}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form className={styles.form}>
              {screenMode === 'signIn' && (
                <>
                  <Field
                    name="login"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.LOGIN"
                    full
                  />
                  <Field
                    name="password"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.PASSWORD"
                    fieldType={showPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    clearButton={false}
                    full
                  />
                  <div className={styles.row}>
                    <Field
                      name="rememberMe"
                      component={CheckboxField}
                      text="CONTROLS.SIGN_IN.REMEMBER_ME"
                    />
                    <div
                      className={styles.textButton}
                      role="presentation"
                      onClick={changeModeToForgotPass}
                    >
                      {t('SCREENS.SIGN_IN.FORGOT_PASSWORD')}
                    </div>
                  </div>
                  <Button
                    text="CONTROLS.SIGN_IN.SIGN_IN"
                    onClick={handleSubmit}
                    color="primary"
                    style={{ alignSelf: 'auto' }}
                    loading={isPending}
                  />
                </>
              )}
              {screenMode === 'forgotPassword' && (
                <>
                  <Field
                    name="login"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.USERNAME_OR_EMAIL"
                    full
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.CONFIRM"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isPendingPostResetPassword}
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.BACK"
                    onClick={returnToSignInModeAndResetForm}
                    color="default"
                    view="outlined"
                    icon="chevron-left"
                  />
                </>
              )}
              {screenMode === '2fa' && (
                <>
                  <Field
                    name="code"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.PIN"
                    fieldType={showPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.CONFIRM_AND_SIGN_IN"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isPendingPostConfirmCode}
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.BACK"
                    onClick={returnToSignInModeAndResetForm}
                    color="default"
                    view="outlined"
                    icon="chevron-left"
                  />
                  <span className={styles.twoFaHint}>
                    {t('SCREENS.SIGN_IN.RESEND_CODE')}
                  </span>
                </>
              )}
              {screenMode === 'enterNewPassword' && (
                <>
                  <Field
                    name="password"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.NEW_PASS"
                    fieldType={showPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Field
                    name="rePassword"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.CONFIRM_PASS"
                    fieldType={showConfirmPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showConfirmPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowConfirmPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.CONFIRM_AND_SIGN_IN"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isPendingPostActivatePassword}
                  />
                </>
              )}
              {screenMode === 'requiredEnterNewPassword' && (
                <>
                  <Field
                    name="login"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.USERNAME"
                    full
                  />
                  <Field
                    name="oldPassword"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.OLD_PASSWORD"
                    fieldType={showOldPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showOldPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowOldPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Field
                    name="password"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.NEW_PASS"
                    fieldType={showPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Field
                    name="rePassword"
                    component={TextField}
                    placeholder="PLACEHOLDERS.SIGN_IN.CONFIRM_PASS"
                    fieldType={showConfirmPassword ? 'text' : 'password'}
                    rightElement={(
                      <Icon
                        icon={showConfirmPassword ? 'eye-off' : 'eye-open'}
                        size={20}
                        onClick={toggleShowConfirmPassword}
                        className={styles.eyeButton}
                      />
                    )}
                    full
                  />
                  <Button
                    text="CONTROLS.SIGN_IN.CONFIRM_AND_SIGN_IN"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isPendingPutResetPassword}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignInScreen;
