import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';

import styles from './ExpandRowCellRenderer.module.scss';

const ExpandRowCellRenderer = ({
  node,
  data,
  api,
  rowKey = 'id',
}) => {
  const [isExpanded, setIsExpanded] = useState(node.expanded);

  const onClickHandle = () => {
    setIsExpanded(prev => !prev);

    api.forEachNode((nodeItem) => {
      if (data[rowKey] === nodeItem.id) {
        nodeItem.setExpanded(!nodeItem.expanded);
        nodeItem.setSelected(!nodeItem.selected);
      } else if (nodeItem.expanded) {
        nodeItem.setExpanded(false);
        nodeItem.setSelected(false);
      }
    });
  };

  return (
    <Icon
      icon="chevron-down"
      className={cx(styles.chevron, { [styles.chevron_reversed]: isExpanded })}
      onClick={onClickHandle}
    />
  );
};

export default ExpandRowCellRenderer;
