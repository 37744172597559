import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@setproduct-ui/core/Button';
import {
  TextField,
  DropdownField,
  TextAreaField,
} from 'components/fields';
import { inputPatterns, SERVICE_TYPES_OPTIONS } from 'consts';
import { SERVICE_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import { Typography } from 'components/Typography';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';

import './style.scss';

const ServiceForm = ({
  initialValues = {
    type: 1,
  },
  onSubmit,
  onCancel,
  onArchiveClick,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
  currencies,
  openTab,
}) => {
  const fieldStyle = marginTop => ({
    label: {
      marginTop,
    },
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);

  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitHandler = (values) => {
    let data = { ...values };
    if (values.type === 2 || values.type === 3) {
      data = {
        ...values,
        packSize: Number(values.packSize),
      };
    }
    onSubmit(data);
  };
  const onArchiveClickModify = () => {
    onArchiveClick({ id: initialValues.id, archivedAt: initialValues.archivedAt });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={SERVICE_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="service-form">
          {showTopShadow && <div className="service-form__shadow" />}
          <div className="service-form__content" ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              styles={fieldStyle()}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="type"
              component={DropdownField}
              styles={fieldStyle(24)}
              options={SERVICE_TYPES_OPTIONS}
              label="INSTANCES.TYPE"
              placeholder="PLACEHOLDERS.SELECT"
              isRequired={!isView}
              disabled={isView || isEdit}
            />
            {(values.type === 1 || values.type === 3) && (
              <Field
                name="currency"
                component={DropdownField}
                styles={fieldStyle(24)}
                options={currencies}
                label="INSTANCES.CURRENCY"
                placeholder="PLACEHOLDERS.SELECT"
                disabled={isView || isEdit}
                isRequired={!isView}
              />
            )}
            {(values.type === 2 || values.type === 3) && (
              <Field
                name="packSize"
                label="INSTANCES.SERVICES.SIZE"
                component={TextField}
                styles={fieldStyle(24)}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView || isEdit}
                regex={inputPatterns.decimal({ withSpaces: true })}
                isRequired={!isView}
                splitOnDigits
                fill
              />
            )}
            <Field
              name="description"
              label="INSTANCES.DESCRIPTION"
              component={TextAreaField}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              disabled={isView}
              styles={fieldStyle(24)}
              minRows={2}
            />
          </div>
          <FormButtonsGroup
            onDelete={onArchiveClickModify}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
            showSave={!values.archivedAt}
            deleteButtonText={`CONTROLS.SERVICES.${values.archivedAt ? 'UN' : ''}ARCHIVE`}
            deleteButtonColor={values.archivedAt ? 'success' : 'danger'}
            additionalLeftButton={initialValues.id ? (
              <Button
                type="default"
                view="outlined"
                color="primary"
                onClick={() => openTab(initialValues)}
                style={{ marginRight: 24 }}
              >
                <Typography color="primary" colorStep={50}>
                  CONTROLS.SERVICES.VIEW_RATES
                </Typography>
              </Button>
            ) : null}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default ServiceForm;
