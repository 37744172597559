import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-xcode';

import React, { useMemo, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useToggle } from 'react-use';

import Toaster from '@setproduct-ui/core/Toast/Toaster';
import { Typography } from 'components/Typography';
import Button from '@setproduct-ui/core/Button/Button';

import './style.scss';

export const CodeField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  label = '',
  classNames = {},
  isRequired,
  onCancel,
  showControls = true,
  resizable = false,
  fullScreenMode = false,
  theme = 'xcode',
  mode = 'json',
  fieldError,
  fieldTouched,
  ...props
}) => {
  const [stateValue, setStateValue] = useState(value);
  const [fullscreen, setFullscreen] = useToggle(fullScreenMode);
  const toastRef = useRef();
  const { t } = useTranslation();

  const isHaveError = useMemo(
    () => (errors[name] && touched[name]) || (fieldError && fieldTouched),
    [errors, touched, fieldError, fieldTouched, name],
  );

  const labelClass = cx('code-field__label', {
    'code-field__label_required': isRequired,
  });

  const containerClass = cx('code-field', {
    'code-field_fullscreen': fullscreen,
    'code-field_error': isHaveError,
  });

  const inputClass = cx('code-field__input', {
    'code-field__input_error': isHaveError,
  });

  const onSave = () => {
    if (mode === 'json') {
      try {
        if (!stateValue || !stateValue.trim() || JSON.parse(stateValue)) {
          if (setFieldValue) {
            setFieldValue(name, stateValue);
          } else {
            onChange(stateValue);
          }
          onCancel();
        }
      } catch (e) {
        toastRef.current.show({
          message: t('ERRORS.WRONG_JSON'),
          icon: <Icon
            icon="info-sign"
            color="#9C2B23"
            iconSize={21}
            className="code-field__error__icon"
          />,
        });
      }
    } else if (mode === 'mysql') {
      if (setFieldValue) {
        setFieldValue(name, stateValue);
      } else {
        onChange(stateValue);
      }
      onCancel();
    }
  };

  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    } else {
      onChange(val);
    }
    setStateValue(val);
  };

  return (
    <div className={`${containerClass} ${classNames.container}`}>
      {label && (
        <Typography className={`${labelClass} ${classNames.label}`}>
          {label}
        </Typography>
      )}
      <AceEditor
        name={name}
        mode={mode}
        theme={theme}
        className={`${inputClass} ${classNames.input}`}
        value={stateValue}
        onChange={showControls ? setStateValue : handleChange}
        setOptions={{
          useWorker: false,
        }}
        data-testid={name
          ? `${window.location.pathname === '/'
            ? '/companies'
            : window.location.pathname}/code-field/${name}`
          : undefined}
        {...props}
      />
      {resizable && (
        <div className="code-field__fullscreen-button">
          <Button
            dense
            type="default"
            view={fullscreen ? 'filled' : 'raised'}
            color="primary"
            icon={fullscreen ? 'minimize' : 'maximize'}
            onClick={setFullscreen}
            data-testid={name
              ? `${window.location.pathname === '/'
                ? '/companies'
                : window.location.pathname}/code-field-button/minimize-maximize`
              : undefined}
          />
        </div>
      )}
      {
        showControls && (
          <div className="code-field__buttons">
            <Button
              type="default"
              view="outlined"
              color="primary"
              onClick={onCancel}
              className="code-field__buttons__cancel"
              data-testid={name
                ? `${window.location.pathname === '/'
                  ? '/companies'
                  : window.location.pathname}/code-field-button/cancel`
                : undefined}
            >
              <Typography color="primary" colorStep={50}>
                CONTROLS.CANCEL
              </Typography>
            </Button>
            <Button
              type="default"
              view="filled"
              color="primary"
              onClick={onSave}
              className="code-field__buttons__save"
              data-testid={name
                ? `${window.location.pathname === '/'
                  ? '/companies'
                  : window.location.pathname}/code-field-button/save`
                : undefined}
            >
              <Typography
                color="primary"
                colorStep={0}
              >
                CONTROLS.SAVE
              </Typography>
            </Button>
          </div>
        )
      }
      {isHaveError && (
        <div className="code-field__error-helper-text">
          {t(errors[name])}
        </div>
      )}
      <Toaster
        type="def"
        view="smooth"
        color="danger"
        usePortal
        position="top"
        ref={toastRef}
        maxToasts={2}
        className="code-field__error"
      />
    </div>
  );
};
