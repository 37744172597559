import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getIndustriesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    industries: response.resourceList.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  }),
  update: {
    industries: (oldValue, newValue) => newValue,
  },
});
