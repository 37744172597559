import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';

import './style.scss';

export default ({
  value,
}) => (
  <Popover2
    minimal
    placement="left"
    interactionKind="hover"
    transitionDuration={0}
    popoverClassName="receivers-cell-renderer__overlay"
    className="receivers-cell-renderer"
    content={(
      <div className="receivers-cell-renderer__content">
        {value?.map(I => (
          <div className="receivers-cell-renderer__content__item">{I}</div>
        ))}
      </div>
    )}
  >
    {value?.join(', ')}
  </Popover2>
);
