import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getPortalsUrl(),
  body: {
    limit: 1000,
    offset: 0,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ resourceList }) => ({
    portalsOptions: resourceList.map(({
      id,
      name,
      ...rest
    }) => ({
      value: id,
      label: name,
      ...rest,
    })),
    portalsDictionary: resourceList.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    portalsOptions: (oldValue, newValue) => newValue,
    portalsDictionary: (oldValue, newValue) => newValue,
  },
});
