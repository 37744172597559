import endpoints from 'consts/endpoints';

export default ({
  successCallback = () => {},
  errorCallback = () => {},
  ...queries
} = {}) => ({
  url: endpoints.getServicesDictionaryUrl(),
  body: {
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ data }) => ({
    servicesOptions: data.map(({ id, name, ...values }) => ({
      value: id,
      label: name,
      ...values,
    })),
    servicesDictionary: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    servicesOptions: (oldValue, newValue) => newValue,
    servicesDictionary: (oldValue, newValue) => newValue,
  },
});
