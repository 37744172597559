import endpoints from 'consts/endpoints';

export default ({
  errorCallback,
  successCallback,
} = {}) => ({
  url: endpoints.getGroupsUrl(),
  queryKey: endpoints.getGroupsUrl(),
  meta: {
    errorCallback,
    successCallback,
    authToken: true,
  },
  options: {
    method: 'GET',
  },
  transform: response => ({ groups: response }),
  update: {
    groups: (oldValue, newValue) => newValue,
  },
});
