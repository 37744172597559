import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import Spinner from '@setproduct-ui/core/Spinner';
import fetchFile from 'helpers/fetchFile';
import endpoints from 'consts/endpoints';
import TextField from 'components/fields/TextField';
import toastRef from 'helpers/toast';

import './style.scss';

const FileField = ({
  field: {
    value,
    name,
  } = {},
  form: {
    setFieldValue,
    errors = {},
    touched = {},
  } = {},
  styles = {},
  accept = '',
  maxSize,
  rejectFormat,
  idToDownload,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef();
  const [isPendingDownloadFile, setIsPendingDownloadFile] = useState(false);

  const onUploadFiles = (e) => {
    const targetFiles = e.target.files;

    Object.keys(targetFiles).forEach((key) => {
      let sizePassed = true;
      let formatPassed = true;

      if (maxSize && targetFiles[key].size * 9.537 * (10 ** -7) >= maxSize) {
        sizePassed = false;
      }
      if (rejectFormat && targetFiles[key].name.includes(rejectFormat)) {
        formatPassed = false;
      }

      if (sizePassed && formatPassed) {
        setFieldValue(name, targetFiles[key]);
      }
      if (!sizePassed) {
        toastRef?.current?.show({ message: t('TOAST.FILE_SIZE_MORE_THAN', { fileName: targetFiles[key].name, maxSize }) });
      }
      if (!formatPassed) {
        toastRef?.current?.show({ message: t('TOAST.INCORRECT_FILE_FORMAT', { fileName: targetFiles[key].name, format: rejectFormat }) });
      }
    });
    e.target.value = '';
  };
  const onLabelClick = () => {
    if (!value) {
      fileInputRef?.current?.click();
    }
  };
  const onClear = (e) => {
    e.stopPropagation();
    if (!disabled) {
      setFieldValue(name, undefined);
    }
  };
  const downloadFile = (e) => {
    e.stopPropagation();
    setIsPendingDownloadFile(true);
    fetchFile({
      url: endpoints.getDownloadPrintableFormUrl(idToDownload),
      fileName: value,
      callback: () => setIsPendingDownloadFile(false),
    });
  };

  return (
    <div
      className="file-field"
      role="presentation"
      onClick={onLabelClick}
      style={{ cursor: value ? 'initial' : 'pointer' }}
    >
      <TextField
        label="INSTANCES.PRINTABLE_FORMS.FILE"
        placeholder="PLACEHOLDERS.SELECT"
        value={value?.name || value}
        fieldError={errors[name]}
        fieldTouched={touched[name]}
        styles={styles}
        clearButton={false}
        disabled={disabled}
        rightElement={(
          <div className="file-field__right-element">
            {value && !disabled && (
              <Icon
                icon="cross"
                color="var(--red50)"
                size={18}
                onClick={onClear}
                style={{ cursor: disabled ? 'inherit' : 'pointer' }}
              />
            )}
            {idToDownload && typeof value === 'string' && (
              isPendingDownloadFile ? <Spinner size={18} color="primary" /> : (
                <Icon
                  icon="download"
                  size={18}
                  onClick={downloadFile}
                  color="var(--blue70)"
                  style={{ zIndex: 2, cursor: 'pointer' }}
                />
              )
            )}
          </div>
        )}
        full
        readOnly
        {...restProps}
      />
      <input
        id={name}
        type="file"
        onChange={onUploadFiles}
        accept={accept}
        ref={fileInputRef}
      />
    </div>
  );
};

export default FileField;
