import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Spinner from '@setproduct-ui/core/Spinner';

import styles from './Dropdown.module.scss';

const Dropdown = ({
  name: externalName,
  value: externalValue,
  onChange,
  field: {
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue,
    errors = {},
    touched = {},
  } = {},
  options = [],
  typeOptions = [],
  placeholder,
  wrapperStyle,
  textInputStyles = {},
  isPriceType,
  withSearch,
  disabled,
  dependsOnDisabled,
  isLoading,
  onImportFromFileClick,
  containerClassName,
  importFromFileDisabled,
}) => {
  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  const [isAll, setIsAll] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef(null);

  const selectedOption = useMemo(
    () => ((value !== null && value !== undefined) ? ([
      ...(options || []),
      ...typeOptions,
    ].find(I => String(I.value) === String(value) || I.potentialValues?.includes(value))
      || { value, label: `Missed option: ${value}` }) : {}),
    [value, options],
  );
  const displayOptions = useMemo(() => {
    if (!searchValue || isAll) {
      return options;
    }
    return options.filter(I =>
      I.label?.toLowerCase()?.includes(searchValue?.toLowerCase()));
  }, [options, searchValue]);
  const error = useMemo(() => {
    if (touched[name] && errors[name]) {
      return errors[name];
    }

    return '';
  }, [touched, errors, name]);
  // const isDependsOnSelected = useMemo(
  //   () => isPriceType && value && !value.includes?.('/price'),
  //   [isPriceType, value],
  // );

  const onTargetClick = () => {
    if (!disabled) {
      setIsOpenMenu();
    }
  };
  const onSearchChange = ({ target }) => {
    setIsAll(false);
    setSearchValue(target.value);
  };
  const onItemClick = (newVal) => {
    if (onChange) {
      onChange(newVal.value);
    } else {
      setFieldValue(name, newVal.value);
    }
    setSearchValue(t(newVal.label));
    setIsAll(true);
    setIsOpenMenu(false);
  };
  const startImportAndCloseMenu = () => {
    if (!importFromFileDisabled) {
      onImportFromFileClick();
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    if (!isOpenMenu && selectedOption?.label !== searchValue) {
      setSearchValue(t(selectedOption?.label) || '');
      setIsAll(true);
    }
  }, [selectedOption, isOpenMenu]);

  return (
    <Popover2
      placement="bottom-start"
      transitionDuration={0}
      isOpen={isOpenMenu}
      interactionKind="click"
      onClose={() => setIsOpenMenu(false)}
      onClosed={() => inputRef.current?.blur?.()}
      autoFocus={false}
      portalClassName={styles.portalContent}
      minimal
      usePortal
      lazy
      renderTarget={({
        ref, isOpen, className, ...rest
      }) => (
        <div
          {...rest}
          ref={ref}
          className={cx(styles.inputWrapper, containerClassName, {
            [styles.inputWrapper_active]: isOpen,
            [styles.inputWrapper_disabled]: disabled,
            [styles.inputWrapper_withDependsIcon]: isPriceType && value && !value.includes?.('/price'),
          })}
          style={wrapperStyle}
          onClick={onTargetClick}
          role="presentation"
        >
          {selectedOption?.icon && <selectedOption.icon className={styles.inputIcon} />}
          <input
            value={searchValue}
            onChange={onSearchChange}
            placeholder={t(placeholder)}
            className={cx(styles.input, className, {
              [styles.input_active]: isOpen,
              [styles.input_error]: !!error,
              [styles.input_withIcon]: !!selectedOption?.icon,
            })}
            style={{
              ...textInputStyles,
              ...(selectedOption?.style || {}),
            }}
            ref={inputRef}
            readOnly={!withSearch || disabled}
          />
          {isLoading && <Spinner size={14} className={styles.spinner} />}
          {error && (
            <span className={styles.error}>{t(error)}</span>
          )}
        </div>
      )}
      content={(
        <div className={styles.overlay} style={{ minWidth: textInputStyles.minWidth || textInputStyles.width }}>
          {isPriceType ? (
            <>
              <Popover2
                placement="right-start"
                transitionDuration={0}
                interactionKind="hover"
                portalClassName={styles.portalContent}
                disabled={dependsOnDisabled}
                openOnTargetFocus={false}
                minimal
                usePortal
                renderTarget={({
                  ref, className, isOpen, ...rest
                }) => (
                  <div
                    ref={ref}
                    className={cx(styles.overlayItem, className, {
                      [styles.overlayItem_hoveredByChild]: isOpen,
                      [styles.overlayItem_disabled]: dependsOnDisabled,
                    })}
                    {...rest}
                  >
                    Depends on ...
                  </div>
                )}
                content={(
                  <div className={styles.overlay}>
                    {options?.map(I => (
                      <div
                        className={styles.overlayItem}
                        key={I.value}
                        onClick={() => onItemClick(I)}
                        role="presentation"
                      >
                        {I.label}
                      </div>
                    ))}
                  </div>
                )}
              />
              <div
                className={cx(styles.overlayItem, { [styles.overlayItem_disabled]: importFromFileDisabled })}
                role="presentation"
                onClick={startImportAndCloseMenu}
                title={importFromFileDisabled ? 'Please ensure all mandatory fields are filled before commencing the import procedure' : undefined}
              >
                Import from file
              </div>
              {typeOptions?.map(I => (
                <div
                  key={I.value}
                  className={styles.overlayItem}
                  style={{ ...(I.style || {}), ...(I.onlyOptionStyle || {}) }}
                  onClick={() => onItemClick(I)}
                  role="presentation"
                >
                  {t(I.label)}
                </div>
              ))}
            </>
          ) : (
            displayOptions?.map(I => (
              <div
                key={I.value || I.label}
                className={cx(styles.overlayItem, { [styles.overlayItem_withIcon]: !!I.icon })}
                onClick={() => onItemClick(I)}
                style={{ ...(I.style || {}), ...(I.onlyOptionStyle || {}) }}
                role="presentation"
              >
                {I?.icon && <I.icon className={styles.itemIcon} />}
                {I.label}
              </div>
            ))
          )}
        </div>
      )}
    />
  );
};

export default Dropdown;
