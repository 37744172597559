import React, { useMemo } from 'react';
import {
  Axis, Chart, Legend, LineAdvance, Slider, Tooltip,
} from 'bizcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import kFormatter from 'helpers/kFormatter';
import Spinner from '@setproduct-ui/core/Spinner';

import { axisGridConfig, axisTextStyle, tooltipDomStyles } from '../chartConfig';

const DeliveryChart = ({
  data,
  chartContainerRef,
  fractionalType,
}) => {
  const { t } = useTranslation();
  const style = getComputedStyle(document.body);

  const processedData = data.reduce((acc, item) => {
    acc.push(
      {
        type: t('SCREENS.TWO_FACTOR.CHARTS.SENT'),
        count: Number(item.cnt_sent),
        averageTime: Number(item.avg_delivery_delay),
        dt: item.dt,
      },
      {
        type: t('SCREENS.TWO_FACTOR.CHARTS.DELIVERED'),
        count: Number(item.cnt_delivered),
        averageTime: Number(item.avg_delivery_delay),
        dt: item.dt,
      },
    );

    return acc;
  }, []);

  const legendFormatter = (value) => {
    const counts = {
      cnt_sent: t('SCREENS.TWO_FACTOR.CHARTS.SENT'),
      cnt_delivered: t('SCREENS.TWO_FACTOR.CHARTS.DELIVERED'),
      moMessages: t('SCREENS.TWO_FACTOR.CHARTS.MO_MESSAGES'),
      averageTime: t('SCREENS.TWO_FACTOR.CHARTS.AVERAGE_DELIVERY_TIME'),
    };

    const valueCount = processedData.reduce((acc, item) => {
      if (value === 'averageTime') {
        acc += item.averageTime;
      } else if (item.type === counts[value]) {
        acc += item.count;
      }
      return acc;
    }, 0);

    return Number.isInteger(valueCount) ? valueCount : valueCount.toFixed(3);
  };
  const legendItemValue = {
    formatter: legendFormatter,
    style: config => ({
      fontWeight: 'bold',
      fontSize: 13,
      lineHeight: 20,
      opacity: config.unchecked ? 0.1 : 1,
      fill: style.getPropertyValue('--grey90'),
    }),
  };

  const timeAxisLabel = {
    ...axisTextStyle,
    formatter: val => t('SCREENS.TWO_FACTOR.CHARTS.TIME_SECONDS', { value: val }),
  };

  const scale = useMemo(() => {
    const baseScale = {
      count: {
        alias: t('SCREENS.TWO_FACTOR.CHARTS.TRAFFIC'),
        tickCount: 5,
        min: 0,
        type: 'linear-strict',
        formatter: val => kFormatter(+val, 1000, 3),
      },
      dt: {
        alias: 'dt',
        formatter: (v) => {
          switch (fractionalType) {
            case '15 minute':
              return moment(v).format('hh:mm');
            case '1 hour':
              return moment(v).format('hh:mm');
            case '1 day':
              return moment(v).format('DD MMM');
            case '1 week':
              return moment(v).format('DD MMM');
            case '1 month':
              return moment(v).format('DD MMM YYYY');
            case '1 year':
            default:
              return moment(v).format('MMM YYYY');
          }
        },
      },
    };

    baseScale.averageTime = {
      alias: t('SCREENS.TWO_FACTOR.CHARTS.AVERAGE_DELIVERY_TIME'),
      tickCount: 5,
      min: 0,
      type: 'linear-strict',
      formatter: val => `${val?.toFixed(0)}s`,
    };

    return baseScale;
  }, [data]);

  const items = [
    {
      name: t('SCREENS.TWO_FACTOR.CHARTS.SENT'),
      value: 'cnt_sent',
      marker: {
        symbol: 'circle',
        style: {
          fill: '#65B168',
          r: 6,
          lineHeight: 6,
        },
      },
    },
    {
      name: t('SCREENS.TWO_FACTOR.CHARTS.DELIVERED'),
      value: 'cnt_delivered',
      marker: {
        symbol: 'circle',
        style: {
          fill: '#4F91FF',
          r: 6,
          lineHeight: 6,
        },
      },
    },
    {
      name: t('SCREENS.TWO_FACTOR.CHARTS.AVERAGE_DELIVERY_TIME'),
      value: 'averageTime',
      marker: {
        symbol: 'circle',
        style: {
          fill: '#FB8200',
          r: 6,
          lineHeight: 6,
        },
      },
    },
  ];

  if (!chartContainerRef?.current?.offsetHeight) {
    return (
      <div>
        <Spinner size={30} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Chart
        padding={[45, 75, 85, 75]}
        autoFit
        height={chartContainerRef?.current?.offsetHeight
          ? Number(chartContainerRef.current.offsetHeight) - 50
          : 500}
        data={processedData}
        scale={scale}
      >
        <Legend
          position="top"
          itemValue={legendItemValue}
          maxItemWidth={250}
          items={items}
        />
        <Axis
          name="dt"
          grid={axisGridConfig()}
          label={axisTextStyle}
        />
        <Axis
          name="count"
          grid={axisGridConfig()}
          label={axisTextStyle}
          title
        />
        <Axis
          name="averageTime"
          grid={axisGridConfig()}
          label={timeAxisLabel}
          title
        />

        <LineAdvance
          area={false}
          type="line"
          position="dt*count"
          size={2}
          color={['type', ['#65B168', '#4F91FF', '#FB8200']]}
          shape="area"
        />
        <LineAdvance
          area
          type="line"
          position="dt*averageTime"
          color="#FB8200"
          shape="smooth"
        />
        <Tooltip
          shared
          showCrosshairs
          showMarkers
          domStyles={tooltipDomStyles()}
        />

        <Slider
          start={0}
          backgroundStyle={{
            fillOpacity: 1,
            fill: '#ededf0',
          }}
          foregroundStyle={{
            fillOpacity: 1,
            fill: '#d8e6ff',
          }}
          textStyle={{
            opacity: 1,
            fill: '#787885',
          }}
        />
      </Chart>
    </div>
  );
};
export default DeliveryChart;
