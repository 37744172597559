import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  offset = 0,
  group,
  ...queries
} = {}) => ({
  url: endpoints.getUserUrl(group),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    group,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      [`${group}Users`]: resourceList,
      [`${group}UsersMeta`]: other,
    };
  },
  update: {
    [`${group}Users`]: (oldValue, newValue) => newValue,
    [`${group}UsersMeta`]: (oldValue, newValue) => newValue,
  },
});
