import React, { useMemo } from 'react';
import { Field } from 'formik';

import { TextField } from 'components/fields';
import { Typography } from 'components/Typography';

import './style.scss';

export default ({
  options,
  accountCurrency,
  currency,
  label,
  name,
  disabled,
}) => {
  const textFieldStyles = {
    container: {
      clipPath: 'inset(-5px 1px -5px -5px)',
      borderRadius: ' 0.25rem 0 0 0.25rem ',
      background: disabled ? 'var(--gray0)' : '#fff',
      width: 104,
    },
  };

  const currencyValue = useMemo(() => options.find(I =>
    I.value === currency)?.label, [currency, options]);

  return (
    <div className="exchange-rate-field">
      <Typography className="exchange-rate-field__label">
        {label}
      </Typography>
      <div className="exchange-rate-field__row">
        <span className="exchange-rate-field__row__unit">
          {`1 ${accountCurrency || ''}=`}
        </span>
        <Field
          name={name}
          component={TextField}
          styles={textFieldStyles}
          disabled
        />
        <div className="exchange-rate-field__currency">
          {currencyValue}
        </div>
      </div>
    </div>
  );
};
