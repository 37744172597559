import { useKey, useWindowSize } from 'react-use';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { FILTER_BUTTONS } from 'consts';
import Card from '@setproduct-ui/core/Card';
import Button from '@setproduct-ui/core/Button/Button';
import { Typography } from 'components/Typography';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import {
  CheckboxField,
  SwitchField,
  ButtonGroupField,
  DatePickerField,
  InnerField,
} from 'components/fields';
import { DATE_FILTER_SCHEMA } from 'consts/validationSchemas';

import './style.scss';

export default forwardRef(({
  colDef: {
    field,
    fieldId,
    headerComponentParams: {
      withoutEmpty = false,
      withoutExclude = false,
      onlyRange = false,
      startWithTime = true,
      endWithTime = true,
      withIndefinitely,
    } = {},
  } = {},
  filterChangedCallback,
  api,
}, ref) => {
  const isApply = useRef();
  const formRef = useRef();
  const { t } = useTranslation();
  const { height } = useWindowSize();

  useKey('Enter', () => {
    formRef.current.handleSubmit();
  });

  const rightFieldStyles = {
    input: {
      borderRadius: ' 0 4px 4px 0',
      boxShadow: 'none',
      margin: 0,
    },
    overlay: {
      width: 320,
      alignItems: 'center',
    },
  };
  const leftFieldStyles = {
    container: {
      textAlign: 'center',
      width: 36,
      background: 'var(--grey0)',
      color: 'var(--grey50)',
      borderRadius: ' 4px 0 0 4px',
      margin: 0,
    },
  };
  const topSwitchStyles = {
    container: {
      marginBottom: 16,
    },
    label: {
      fontSize: 14,
    },
  };
  const bottomSwitchStyles = {
    container: {
      marginTop: 16,
    },
    label: {
      fontSize: 14,
    },
  };
  const checkBoxStyles = {
    container: {
      marginBottom: 16,
    },
  };

  const initialValues = {
    range: onlyRange,
  };

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      return {
        values: formRef.current.values,
        type: 'date',
        isWithoutEmpty: withoutEmpty,
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;

        if (formRef?.current?.setValues) {
          formRef.current.setValues(values);
        }

        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          formRef.current.resetForm();
          if (formRef?.current?.setValues) {
            formRef.current.setValues(values);
          }
        });
      } else {
        formRef.current.resetForm();
      }
    },
    isFilterActive() {
      const { values } = formRef.current;

      if (!values || !isApply.current) {
        return false;
      }

      // нужно для возможности фильтрации по бесконечности (null)
      return Object.values(values).some(item => item || item === null);
    },
    doesFilterPass() {
      return true;
    },
  }));
  const onChangeRange = ({ form, values, value }) => {
    if (values.fromValue === null && value === true) {
      form.setFieldValue('fromValue', undefined);
    }
    if (values.toValue && value === false) {
      form.setFieldValue('toValue', undefined);
    }
  };

  const dateFilterToClass = classNames('date-filter__to', {
    'date-filter__to_small': height < 1000,
  });

  return (
    <Formik
      keepDirtyOnReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={DATE_FILTER_SCHEMA}
      validateOnMount
      onSubmit={(values) => {
        if (values.filterConfig === 'empty' || values.filterConfig === 'notempty') {
          formRef.current.resetForm();
          formRef.current.setFieldValue('filterConfig', values.filterConfig);
        }

        isApply.current = true;
        filterChangedCallback({ values, field, fieldId });

        if (api) {
          api.menuFactory.hidePopup();
        }
        api.redrawRows();
        setTimeout(() => api.sendQuery());
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
      }) => (
        <Form
          className="date-filter"
          data-testid={`${window.location.pathname}/filter/date/${field}`}
        >
          {!withoutEmpty && (
          <Field
            name="filterConfig"
            className="date-filter__button-group"
            component={ButtonGroupField}
            options={FILTER_BUTTONS}
            fieldName={field}
            data-testid={`${window.location.pathname}/filter/date/${field}/filter-config`}
          />
          )}
          {values.filterConfig === 'notempty' || values.filterConfig === 'empty' ? (
            <Card
              type="def"
              view="smooth"
              color="warning_alt"
              interactive={false}
              className="date-filter__card"
            >
              <div className="date-filter__card__content">
                <Icon color="#FDC68B" icon="info-sign" iconSize={16} />
                <Typography
                  tagName="span"
                  className="date-filter__card__content__text"
                >
                  {t('CONTROLS.FILTER.DISPLAYED_CELLS', {
                    what: t(`CONTROLS.FILTER.${values.filterConfig === 'empty' ? 'EMPTY_CELLS' : 'NOT_EMPTY_CELLS'}`),
                  })}
                </Typography>
              </div>
            </Card>
          ) : (
            <>
              <div className="date-filter__top-controls">
                { !withoutExclude && (
                  <Field
                    name="exclude"
                    type="dense"
                    component={CheckboxField}
                    styles={checkBoxStyles}
                    text={t('CONTROLS.FILTER.EXCLUDE')}
                    data-testid={`${window.location.pathname}/filter/date/${field}/exclude`}
                  />
                )}
                {height < 1000 && (
                  <Field
                    name="range"
                    color="primary"
                    label={t('CONTROLS.FILTER.BY_RANGE')}
                    className="date-filter__range-switch"
                    component={SwitchField}
                    styles={topSwitchStyles}
                    data-testid={`${window.location.pathname}/filter/date/${field}/range`}
                  />
                )}
              </div>
              <InnerField
                name="fromValue"
                chipValue={values.range ? '>=' : '='}
                rightFieldComponent={DatePickerField}
                leftFieldStyles={leftFieldStyles}
                rightFieldStyles={rightFieldStyles}
                leftProps={{
                  type: 'zero-padding',
                }}
                rightProps={{
                  applyButton: true,
                  format: values.range && startWithTime ? 'DD.MM.YYYY, HH:mm:ss' : 'DD.MM.YYYY',
                  timePrecision: values.range && startWithTime ? 'second' : null,
                  placeholder: values.range && startWithTime ? 'DD.MM.YYYY, HH:mm:ss' : 'DD.MM.YYYY',
                  pickerDense: true,
                  minimal: false,
                  withIndefinitely: withIndefinitely && !values.range,
                  placement: 'right',
                  usePortal: false,
                  dataTestid: `${window.location.pathname}/filter/date/${field}/from-value`,
                }}
                rtl
                placeholder={t(`PLACEHOLDERS.${values.range ? 'DATETIME' : 'DATE'}`)}
                className="date-filter__from"
              />
              {height >= 1000 && (
                <Field
                  name="range"
                  color="primary"
                  label={t('CONTROLS.FILTER.BY_RANGE')}
                  className="date-filter__range-switch"
                  component={SwitchField}
                  styles={bottomSwitchStyles}
                  data-testid={`${window.location.pathname}/filter/date/${field}/range`}
                />
              )}
              {values.range && (
                <InnerField
                  name="toValue"
                  type="date"
                  rightFieldComponent={DatePickerField}
                  leftFieldStyles={leftFieldStyles}
                  rightFieldStyles={rightFieldStyles}
                  styles={{
                    wrapper: {
                      paddingTop: 16,
                    },
                  }}
                  leftProps={{
                    type: 'zero-padding',
                  }}
                  rightProps={{
                    applyButton: true,
                    format: endWithTime ? 'DD.MM.YYYY, HH:mm:ss' : 'DD.MM.YYYY',
                    timePrecision: endWithTime ? 'second' : null,
                    placeholder: endWithTime ? 'DD.MM.YYYY, HH:mm:ss' : 'DD.MM.YYYY',
                    pickerDense: true,
                    minimal: false,
                    placement: 'right',
                    usePortal: false,
                    dataTestid: `${window.location.pathname}/filter/date/${field}/to-value`,
                  }}
                  rtl
                  chipValue="<"
                  fieldComponent={DatePickerField}
                  placeholder={t('PLACEHOLDERS.DATETIME')}
                  className={dateFilterToClass}
                />
              )}
            </>
          )}
          <div className="date-filter__filter-controls">
            <Button
              dense
              view="filled"
              color="primary"
              onClick={handleSubmit}
              className="date-filter__filter-controls__button"
              data-testid={`${window.location.pathname}/filter/date/${field}/apply`}
              disabled={!isEmpty(errors)}
            >
              <Typography
                tagName="span"
                className="date-filter__filter-controls__button__text"
              >
                {t('CONTROLS.FILTER.APPLY_FILTER')}
              </Typography>
            </Button>
          </div>
          <OnChangeComponent field="range" onChange={onChangeRange} />
        </Form>
      )}
    </Formik>
  );
});
