import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getWhatsAppCategoriesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      whatsAppCategories: resourceList.map(({ categoryName }) => ({
        value: categoryName,
        label: categoryName,
      })),
      whatsAppCategoriesMeta: other,
    };
  },
  update: {
    whatsAppCategories: (oldValue, newValue) => newValue,
    whatsAppCategoriesMeta: (oldValue, newValue) => newValue,
  },
});
