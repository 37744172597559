import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getLegalEntities as getLegalEntitiesApi,
  getOwnLegalEntities as getOwnLegalEntitiesApi,
  deleteLegalEntity as deleteLegalEntityApi,
  putLegalEntity as putLegalEntityApi,
  postLegalEntity as postLegalEntityApi,
  getResellingAgreementList as getResellingAgreementListApi,
  postResellingAgreement as postResellingAgreementApi,
  putResellingAgreement as putResellingAgreementApi,
  deleteResellingAgreement as deleteResellingAgreementApi,
} from 'queries/legalEntities';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetLegalEntities,
      lastUpdated: lastUpdatedGetLegalEntities,
    },
    getLegalEntities,
  ] = useMutation(getLegalEntitiesApi);
  const [
    {
      isPending: isPendingGetOwnLegalEntities,
      lastUpdated: lastUpdatedGetOwnLegalEntities,
    },
    getOwnLegalEntities,
  ] = useMutation(getOwnLegalEntitiesApi);
  const [
    {
      isPending: isPendingDeleteLegalEntity,
    },
    deleteLegalEntity,
  ] = useMutation(deleteLegalEntityApi);
  const [
    {
      isPending: isPendingPutLegalEntity,
    },
    putLegalEntity,
  ] = useMutation(putLegalEntityApi);
  const [
    {
      isPending: isPendingPostLegalEntity,
    },
    postLegalEntity,
  ] = useMutation(postLegalEntityApi);
  const [
    {
      isPending: isPendingGetResellingAgreementList,
    },
    getResellingAgreementList,
  ] = useMutation(getResellingAgreementListApi);
  const [
    {
      isPending: isPendingDeleteResellingAgreement,
    },
    deleteResellingAgreement,
  ] = useMutation(deleteResellingAgreementApi);
  const [
    {
      isPending: isPendingPutResellingAgreement,
    },
    putResellingAgreement,
  ] = useMutation(putResellingAgreementApi);
  const [
    {
      isPending: isPendingPostResellingAgreement,
    },
    postResellingAgreement,
  ] = useMutation(postResellingAgreementApi);

  const selector = useCallback(state => ({
    legalEntities: getArray(state, 'legalEntities'),
    legalEntitiesAll: getArray(state, 'legalEntitiesAll'),
    legalEntitiesMeta: getObject(state, 'legalEntitiesMeta'),
    ownLegalEntities: getArray(state, 'ownLegalEntities'),
    ownLegalEntitiesAll: getArray(state, 'ownLegalEntitiesAll'),
    ownLegalEntitiesMeta: getObject(state, 'ownLegalEntitiesMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getLegalEntities,
    getOwnLegalEntities,
    deleteLegalEntity,
    putLegalEntity,
    postLegalEntity,
    getResellingAgreementList,
    postResellingAgreement,
    putResellingAgreement,
    deleteResellingAgreement,
    isPendingGetLegalEntities,
    isPendingGetOwnLegalEntities,
    isPendingDeleteLegalEntity,
    isPendingPutLegalEntity,
    isPendingPostLegalEntity,
    isPendingGetResellingAgreementList,
    isPendingPostResellingAgreement,
    isPendingPutResellingAgreement,
    isPendingDeleteResellingAgreement,
    lastUpdatedGetLegalEntities,
    lastUpdatedGetOwnLegalEntities,
    ...data,
  };
};
