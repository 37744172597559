import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getHttpConnections as getHttpConnectionsApi,
  deleteHttpConnection as deleteHttpConnectionApi,
  putHttpConnection as putHttpConnectionApi,
  postHttpConnection as postHttpConnectionApi,
} from 'queries/httpConnections';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetHttpConnections,
      lastUpdated: lastUpdatedGetHttpConnections,
    },
    getHttpConnections,
  ] = useMutation(getHttpConnectionsApi);
  const [
    {
      isPending: isPendingDeleteHttpConnection,
    },
    deleteHttpConnection,
  ] = useMutation(deleteHttpConnectionApi);
  const [
    {
      isPending: isPendingPutHttpConnection,
    },
    putHttpConnection,
  ] = useMutation(putHttpConnectionApi);
  const [
    {
      isPending: isPendingPostHttpConnection,
    },
    postHttpConnection,
  ] = useMutation(postHttpConnectionApi);

  const selector = useCallback(state => ({
    httpConnections: getArray(state, 'httpConnections'),
    httpConnectionsMeta: getObject(state, 'httpConnectionsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getHttpConnections,
    deleteHttpConnection,
    putHttpConnection,
    postHttpConnection,
    isPendingGetHttpConnections,
    isPendingDeleteHttpConnection,
    isPendingPutHttpConnection,
    isPendingPostHttpConnection,
    lastUpdatedGetHttpConnections,
    ...data,
  };
};
