export const isPrefixMatch = (prefix = '', str) => {
  if (prefix === str) {
    return false;
  }

  const prefixParts = prefix.split('.');
  const strParts = str.split('.');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < prefixParts.length; i++) {
    if (prefixParts[i] !== strParts[i]) {
      return false;
    }
  }
  return true;
};
export const getTreeKey = (level, index, parentName) => {
  if (level === 1) {
    return `root${index + 1}`;
  }
  return `${parentName}.child${index + 1}`;
};
export const makeTreeFromRawFlatData = (rawFlatData, resourceAttributesDictionary, maxLevelRef) => {
  const result = [];
  const childrenById = {};

  rawFlatData.forEach((item) => {
    if (item.parentId === null) {
      result.push(item);
    } else {
      if (!childrenById[item.parentId]) {
        childrenById[item.parentId] = [];
      }
      childrenById[item.parentId].push(item);
    }
  });

  const addChildren = (items, level, parent) => {
    items.forEach((item, index) => {
      item.level = level;
      item.treeKey = getTreeKey(level, index, parent?.treeKey);
      item.hiddenBySearch = false;
      item.hiddenByCollapse = false;

      if (items.length > 1) {
        item.siblingValues = items
          .filter(I => I.id !== item.id)
          .map(I => I.matchAttributeValues?.[0] || 'null');
      }
      if (level > 1) {
        item.attributesBlacklist = !item.matchAttributeValues?.length || item.matchAttributeValues?.[0] === null
          ? parent.attributesBlacklist
          : [
            ...(parent.attributesBlacklist || []),
            String(parent.childrenMatchAttributeId),
          ];
        item.displayLinksFor = [
          ...(parent.displayLinksFor || []),
        ];

        if (index + 1 < parent.children?.length) {
          item.displayLinksFor = [
            ...(item.displayLinksFor || []),
            item.level,
          ];
        }
      }
      if (level === 2) {
        item.parentServiceDomainId = parent.matchAttributeValues?.[0];
      }
      if (level > 2) {
        item.dictionaryId = resourceAttributesDictionary?.[item.matchAttributeId]?.dictionaryId;
        item.parentInfoRules = parent.parentInfoRules || {
          resourceId: parent.matchAttributeValues?.[0],
          billingRule: parent.billingRule,
          quantityRule: parent.quantityRule,
        };
      }
      if (level >= 3) {
        if ((item.matchAttributeValues && item.matchAttributeValues.length === 0 && !item.siblingValues?.length)
          || !item.dictionaryId) {
          item.definitionsFilters = parent.definitionsFilters;
        } else {
          item.definitionsFilters = {
            ...(parent.definitionsFilters || {}),
            filters: [
              ...(parent.definitionsFilters?.filters || []),
              {
                dictionaryId: item.dictionaryId,
                type: item.matchAttributeValues?.length === 0 ? 'nin' : 'in',
                keys: item.matchAttributeValues?.length === 0
                  ? item.siblingValues : [item.matchAttributeValues?.[0]?.toString?.()],
              },
            ],
          };
        }
      }

      if (childrenById[item.id]) {
        item.children = childrenById[item.id];
        if (level + 1 > maxLevelRef.current) {
          maxLevelRef.current = level + 1;
        }

        addChildren(item.children, level + 1, item);
      }
    });
  };

  addChildren(result, 1);

  return result;
};
export const makeTreeFromImportPreviewData = (
  data,
  resourceAttributesDictionary,
  maxLevelRef,
  initialLevel,
  parentInfoRules,
) => {
  const result = [];
  const childrenById = {};

  data.forEach((item) => {
    if (item.parentId === null) {
      result.push(item);
    } else {
      if (!childrenById[item.parentId]) {
        childrenById[item.parentId] = [];
      }
      childrenById[item.parentId].push(item);
    }
  });

  const addChildren = (items, level, parent) => {
    items.forEach((item, index) => {
      item.level = level;
      item.treeKey = getTreeKey(level, index, parent?.treeKey);

      if (level > 1) {
        item.displayLinksFor = [
          ...(parent?.displayLinksFor || []),
        ];

        if (index + 1 < parent?.children?.length) {
          item.displayLinksFor = [
            ...(item.displayLinksFor || []),
            item.level,
          ];
        }
      }
      if (level === 2) {
        item.parentServiceDomainId = parent?.matchAttributeValues?.[0];
      }
      if (level > 2) {
        item.dictionaryId = resourceAttributesDictionary?.[item.matchAttributeId]?.dictionaryId;
        item.parentInfoRules = parent?.parentInfoRules || parentInfoRules;
      }

      if (childrenById[item.id]) {
        item.children = childrenById[item.id];
        if (level + 1 > maxLevelRef.current) {
          maxLevelRef.current = level + 1;
        }

        addChildren(item.children, level + 1, item);
      }
    });
  };

  addChildren(result, initialLevel);

  return result;
};

const updateChildren = ({
  node,
  parent,
  initialParent,
  callback,
  index,
}) => {
  const updatedNode = {
    ...node,
    ...callback({
      node, parent, initialParent, index,
    }),
  };

  return {
    ...updatedNode,
    children: node.children?.map((item, _index) => ({
      ...item,
      ...updateChildren({
        node: item,
        parent: updatedNode,
        initialParent,
        callback,
        index: _index,
      }),
    })),
  };
};
export const modifyNodeById = ({
  tree,
  nodeId,
  updateNodeCallback,
}) => tree.map((node) => {
  if (node.id === nodeId) {
    return ({
      ...node,
      ...updateNodeCallback(node),
    });
  }

  if (node.children?.length) {
    return {
      ...node,
      children: modifyNodeById({
        tree: node.children,
        nodeId,
        updateNodeCallback,
      }),
    };
  }

  return node;
});
export const modifyNodeWithChildrenById = ({
  tree,
  nodeId,
  updateNodeCallback,
  updateChildCallback,
}) => tree.map((node) => {
  if (node.id === nodeId) {
    const updatedNode = {
      ...node,
      ...updateNodeCallback(node),
    };

    return {
      ...updatedNode,
      children: node.children?.map((item, index) => ({
        ...item,
        ...updateChildren({
          node: item,
          parent: updatedNode,
          initialParent: updatedNode,
          callback: updateChildCallback,
          index,
        }),
      })),
    };
  }
  if (node.children?.length) {
    return {
      ...node,
      children: modifyNodeWithChildrenById({
        tree: node.children,
        nodeId,
        updateNodeCallback,
        updateChildCallback,
      }),
    };
  }

  return node;
});
export const recalculateDisplayLinksFor = (tree, parent = null, level = 1) => {
  if (!tree || tree.length === 0) return [];

  return tree.map((node, index) => {
    const newNode = { ...node };

    if (level > 1) {
      newNode.displayLinksFor = [
        ...(parent?.displayLinksFor || []),
      ];

      const isLastVisibleChild = parent?.children
        ? parent.children.slice(index + 1).every(child => child.hiddenBySearch)
        : tree.slice(index + 1).every(child => child.hiddenBySearch);

      if (!isLastVisibleChild && !newNode.hiddenBySearch) {
        newNode.displayLinksFor.push(level);
      }
    }

    if (newNode.children && newNode.children.length > 0) {
      newNode.children = recalculateDisplayLinksFor(newNode.children, newNode, level + 1);
    }

    return newNode;
  });
};
export const removeNodeById = (tree, idToRemove) => tree.filter((item) => {
  if (item.id === idToRemove) {
    return false;
  } if (item.children?.length) {
    item.children = removeNodeById(item.children, idToRemove);
  }
  return true;
});
const initializeNewChild = (newChildParams = {}, parent, level, resourceAttributesDictionary) => {
  const newChild = {
    billingRule: null,
    childrenMatchAttributeId: null,
    matchAttributeId: parent.childrenMatchAttributeId,
    matchAttributeValues: null,
    price: null,
    priceType: null,
    quantityRule: null,
    scaleCounterGroupBy: null,
    scaleType: null,
    id: 'newRow',
    parentId: parent.id,
    level: level + 1,
    treeKey: getTreeKey(level + 1, parent.children?.length || 0, parent.treeKey),
    children: [],
    attributesBlacklist: parent?.attributesBlacklist || [],
    displayLinksFor: [],
    ...newChildParams,
  };

  if (parent.children?.length) {
    const existingChild = parent.children[0];

    newChild.attributesBlacklist = existingChild.attributesBlacklist;
    newChild.displayLinksFor = existingChild.displayLinksFor;
    newChild.siblingValues = parent.children
      .map(I => I.matchAttributeValues?.[0] || 'null');

    if (newChild.level === 2) {
      newChild.parentServiceDomainId = existingChild.parentServiceDomainId;
    }
    if (newChild.level > 2) {
      newChild.dictionaryId = existingChild.dictionaryId;
      newChild.parentInfoRules = existingChild.parentInfoRules;
    }
    if (newChild.level >= 3) {
      newChild.definitionsFilters = existingChild.definitionsFilters;
    }
  } else {
    if (newChild.level > 1) {
      newChild.attributesBlacklist = !newChild.matchAttributeValues?.length || newChild.matchAttributeValues?.[0] === null
        ? parent.attributesBlacklist
        : [
          ...(parent.attributesBlacklist || []),
          String(parent.childrenMatchAttributeId),
        ];
      newChild.displayLinksFor = [
        ...(parent.displayLinksFor || []),
      ];

      // todo: можна вырезать?
      if (parent.children?.length > 0) {
        newChild.displayLinksFor = [
          ...(newChild.displayLinksFor || []),
          newChild.level,
        ];
      }
    }

    if (newChild.level === 2) {
      newChild.parentServiceDomainId = parent.matchAttributeValues?.[0];
    }
    if (newChild.level > 2) {
      newChild.dictionaryId = resourceAttributesDictionary?.[newChild.matchAttributeId]?.dictionaryId;
      newChild.parentInfoRules = parent.parentInfoRules || {
        resourceId: parent.matchAttributeValues?.[0],
        billingRule: parent.billingRule,
        quantityRule: parent.quantityRule,
      };
    }
    if (newChild.level >= 3) {
      if ((newChild.matchAttributeValues && newChild.matchAttributeValues.length === 0 && !newChild.siblingValues?.length)
        || !newChild.dictionaryId) {
        newChild.definitionsFilters = parent.definitionsFilters;
      } else {
        newChild.definitionsFilters = {
          ...(parent.definitionsFilters || {}),
          filters: [
            ...(parent.definitionsFilters?.filters || []),
            {
              dictionaryId: newChild.dictionaryId,
              type: newChild.matchAttributeValues?.length === 0 ? 'nin' : 'in',
              keys: newChild.matchAttributeValues?.length === 0
                ? newChild.siblingValues : [newChild.matchAttributeValues?.[0]?.toString?.()],
            },
          ],
        };
      }
    }
  }

  return newChild;
};
const recursiveAddChild = (nodes, parentNodeId, newChildParams, level, resourceAttributesDictionary, maxLevelRef) => {
  nodes.forEach((node) => {
    if (node.id === parentNodeId) {
      const newChild = initializeNewChild(newChildParams, node, level, resourceAttributesDictionary);

      node.children = node.children || [];
      node.children.push(newChild);

      if (newChild.level > maxLevelRef.current) {
        maxLevelRef.current = newChild.level;
      }
    } else if (node.children) {
      recursiveAddChild(node.children, parentNodeId, newChildParams, level + 1, resourceAttributesDictionary, maxLevelRef);
    }
  });
};
export const addChildToNode = ({
  tree, parentNodeId, newChildParams, resourceAttributesDictionary, maxLevelRef,
}) => {
  const newTree = JSON.parse(JSON.stringify(tree));

  recursiveAddChild(newTree, parentNodeId, newChildParams, 1, resourceAttributesDictionary, maxLevelRef);

  return newTree;
};
export const recalculateSiblingValues = (tree) => {
  const updateValuesRecursively = (nodes) => {
    nodes.forEach((node) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          child.siblingValues = node.children
            .filter(sibling => sibling.id !== child.id)
            .map(sibling => sibling.matchAttributeValues?.[0] || 'null');

          updateValuesRecursively(child.children || []);
        });
      }
    });
  };

  const newTree = JSON.parse(JSON.stringify(tree));

  updateValuesRecursively(newTree);

  return newTree;
};

export const recalculateLinksAndSiblings = (tree, parent = null, level = 1) => {
  if (!tree || tree.length === 0) return [];

  return tree.map((node, index) => {
    const newNode = { ...node };

    if (newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child) => {
        child.siblingValues = newNode.children
          .filter(sibling => sibling.id !== child.id)
          .map(sibling => sibling.matchAttributeValues?.[0] || 'null');
      });
    }

    if (level > 1) {
      newNode.displayLinksFor = [
        ...(parent?.displayLinksFor || []),
      ];

      const isLastVisibleChild = parent?.children
        ? parent.children.slice(index + 1).every(child => child.hiddenBySearch)
        : tree.slice(index + 1).every(child => child.hiddenBySearch);

      if (!isLastVisibleChild && !newNode.hiddenBySearch) {
        newNode.displayLinksFor.push(level);
      }
    }

    if (newNode.children && newNode.children.length > 0) {
      newNode.children = recalculateLinksAndSiblings(newNode.children, newNode, level + 1);
    }

    return newNode;
  });
};

export const recalculateMetaData = (tree, parent = null, level = 1, resourceAttributesDictionary) => {
  if (!tree || tree.length === 0) return [];

  return tree.map((node, index) => {
    const newNode = { ...node };

    if (newNode.children && newNode.children.length > 0) {
      newNode.children.forEach((child) => {
        child.siblingValues = newNode.children
          .filter(sibling => sibling.id !== child.id)
          .map(sibling => sibling.matchAttributeValues?.[0] || 'null');
      });
    }

    if (level > 1) {
      newNode.attributesBlacklist = !newNode.matchAttributeValues?.length || newNode.matchAttributeValues?.[0] === null
        ? parent.attributesBlacklist
        : [
          ...(parent.attributesBlacklist || []),
          String(parent.childrenMatchAttributeId),
        ];

      newNode.displayLinksFor = [
        ...(parent?.displayLinksFor || []),
      ];

      const isLastVisibleChild = parent?.children
        ? parent.children.slice(index + 1).every(child => child.hiddenBySearch)
        : tree.slice(index + 1).every(child => child.hiddenBySearch);

      if (!isLastVisibleChild && !newNode.hiddenBySearch) {
        newNode.displayLinksFor.push(level);
      }
    }

    if (level >= 3) {
      if ((newNode.matchAttributeValues && newNode.matchAttributeValues.length === 0 && !newNode.siblingValues?.length)
        || !newNode.dictionaryId) {
        newNode.definitionsFilters = parent.definitionsFilters;
      } else {
        newNode.definitionsFilters = {
          ...(parent.definitionsFilters || {}),
          filters: [
            ...(parent.definitionsFilters?.filters || []),
            {
              dictionaryId: newNode.dictionaryId,
              type: newNode.matchAttributeValues?.length === 0 ? 'nin' : 'in',
              keys: newNode.matchAttributeValues?.length === 0
                ? newNode.siblingValues : [newNode.matchAttributeValues?.[0]?.toString?.()],
            },
          ],
        };
      }
    }

    if (newNode.children && newNode.children.length > 0) {
      newNode.children = recalculateMetaData(newNode.children, newNode, level + 1, resourceAttributesDictionary);
    }

    return newNode;
  });
};
