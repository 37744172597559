import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Chips from '@setproduct-ui/core/Chips/Chips';

import './styles.scss';

export default ({
  value,
}) => {
  const classes = classNames('invoice-cell-renderer', {
    'invoice-cell-renderer_draft': value === 0,
  });

  const { t } = useTranslation();

  const text = useMemo(() => {
    switch (value) {
      case 0:
        return t('STATES.CHARGES.DRAFT');
      default: return '';
    }
  }, [value]);

  return (
    <Chips
      type="dense"
      view="smooth"
      round={false}
      className={classes}
      tag={text}
    />
  );
};
