import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getClientSmppConnections as getClientSmppConnectionsApi,
  deleteClientSmppConnection as deleteClientSmppConnectionApi,
  putClientSmppConnection as putClientSmppConnectionApi,
  postClientSmppConnection as postClientSmppConnectionApi,
} from 'queries/clientSmppConnections';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetClientSmppConnections,
      lastUpdated: lastUpdatedGetClientSmppConnections,
    },
    getClientSmppConnections,
  ] = useMutation(getClientSmppConnectionsApi);
  const [
    {
      isPending: isPendingDeleteClientSmppConnection,
    },
    deleteClientSmppConnection,
  ] = useMutation(deleteClientSmppConnectionApi);
  const [
    {
      isPending: isPendingPutClientSmppConnection,
    },
    putClientSmppConnection,
  ] = useMutation(putClientSmppConnectionApi);
  const [
    {
      isPending: isPendingPostClientSmppConnection,
    },
    postClientSmppConnection,
  ] = useMutation(postClientSmppConnectionApi);

  const selector = useCallback(state => ({
    clientSmppConnections: getArray(state, 'clientSmppConnections'),
    clientSmppConnectionsMeta: getObject(state, 'clientSmppConnectionsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getClientSmppConnections,
    deleteClientSmppConnection,
    putClientSmppConnection,
    postClientSmppConnection,
    isPendingGetClientSmppConnections,
    isPendingDeleteClientSmppConnection,
    isPendingPutClientSmppConnection,
    isPendingPostClientSmppConnection,
    lastUpdatedGetClientSmppConnections,
    ...data,
  };
};
