import { Icon } from '@blueprintjs/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { RCSIcon, WidgetCarousel } from 'assets/icons';
import { SuggestionItem, MediaPreview } from 'components';

import styles from '../preview.module.scss';

const getStyledText = ({ text, style }) => {
  switch (style) {
    case 'bold':
      return (
        <strong>
          <Highlighter
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={text}
            autoEscape={false}
          />
        </strong>
      );
    case 'italic':
      return (
        <i>
          <Highlighter
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={text}
            autoEscape={false}
          />
        </i>
      );
    case 'underline':
      return (
        <u>
          <Highlighter
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={text}
            autoEscape={false}
          />
        </u>
      );
    default:
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          highlightTag="span"
          searchWords={[/{{.([^]+?)}}/gm]}
          textToHighlight={text}
          autoEscape={false}
        />
      );
  }
};
const getRcsCard = (message, showChannelIcon, isCarousel = false) => (
  <div className={isCarousel ? styles.messageContainerCarousel : styles.messageContainer}>
    {!isCarousel && (
    <div className={styles.messageTile}>
      {showChannelIcon && <RCSIcon />}
      <Icon icon="widget-footer" color="var(--grey30)" size={16} />
      Card
    </div>
    )}
    <div className={styles.bodyContainer}>
      <div className={styles.messageHeader}>
        <MediaPreview file={message.body} />
      </div>
      <div className={styles.messageBody}>
        {getStyledText({
          text: message.body?.title,
          style: message.body?.titleFontStyle,
        })}
      </div>
      <div className={styles.messageFooter}>
        {getStyledText({
          text: message.body?.message,
          style: message.body?.messageFontStyle,
        })}
      </div>
      {!!message.body?.suggestionsInside?.length && (
        <div className={message.body?.suggestionsOutside?.length
          ? styles.messageButtonsBordered
          : styles.messageButtons}
        >
          {message.body?.suggestionsInside?.map(I => (
            <SuggestionItem {...I} />
          ))}
        </div>
      )}
      {!!message.body?.suggestionsOutside?.length && (
        <div className={message.body?.suggestionsInside?.length
          ? styles.messageButtonsOutside
          : styles.messageButtons}
        >
          {message.body?.suggestionsOutside?.map(I => (
            <SuggestionItem isInside={false} {...I} />
          ))}
        </div>
      )}
    </div>
  </div>
);

const RcsPreview = ({ message, showChannelIcon }) => {
  let result;
  switch (message.type) {
    case 'carousel':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <RCSIcon />}
            <WidgetCarousel className={styles.titleIcon} />
            Carousel
          </div>
          <div className={styles.bodyCarouselContainer}>
            {message.body.cards.map(I => getRcsCard({
              body: {
                ...I,
                messageFontStyle: message.messageFontStyle,
                titleFontStyle: message.titleFontStyle,
              },
            }, showChannelIcon, true))}
            {!!message.body?.suggestionsOutside?.length && (
            <div className={styles.messageCarouselButtons}>
              {message.body?.suggestionsOutside?.map(I => (
                <SuggestionItem isInside={false} {...I} />
              ))}
            </div>
            )}
          </div>
        </div>
      );
      break;
    case 'richcardMessage': {
      result = getRcsCard(message, showChannelIcon);
      break;
    }
    case 'text':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <RCSIcon />}
            <Icon icon="font" color="var(--grey30)" size={16} />
            Text
          </div>
          <Highlighter
            className={styles.bodyContainer}
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={message.body}
            autoEscape={false}
          />
        </div>
      );
      break;
    case 'textWithSuggestions':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <RCSIcon />}
            <Icon icon="font" color="var(--grey30)" size={16} />
            Text
          </div>
          <div className={styles.bodyContainer}>
            <Highlighter
              className={styles.bodyContainer}
              highlightClassName={styles.highlight}
              highlightTag="span"
              searchWords={[/{{.([^]+?)}}/gm]}
              textToHighlight={message.body.message}
              autoEscape={false}
            />
            <div className={styles.messageButtons}>
              {message.body.suggestionsOutside.map(I => (
                <SuggestionItem {...I} />
              ))}
            </div>
          </div>
        </div>
      );
      break;
    case 'file':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <RCSIcon />}
            <Icon icon="document" color="var(--grey30)" size={16} />
            File
          </div>
          <div className={styles.bodyContainer}>
            <MediaPreview file={message.body} />
            <div className={styles.messageButtons}>
              {message.body.suggestionsOutside.map(I => (
                <SuggestionItem isInside={false} {...I} />
              ))}
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return result;
};

export default RcsPreview;
