import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default ({
  isFormPristine = {},
  checkFunction,
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (checkFunction(values)) {
      isFormPristine.current = true;
    } else if (isFormPristine.current) {
      isFormPristine.current = false;
    }
  }, [values]);

  return null;
};
