import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components';
import Button from '@setproduct-ui/core/Button';

import SettingField from '../SettingField';

import './style.scss';

export default ({
  name: externalName,
  values: externalValues,
  field: {
    name = externalName,
  } = {},
  form: {
    values = externalValues,
    errors = {},
    touched = {},
  } = {},
  push,
  remove,
  replace,
  unshift,
}) => {
  const { t } = useTranslation();

  const onAdd = () => {
    let parameterOrder = 1;
    const copyOfParameters = [...values[name]];
    if (values[name].length) {
      const lastItem = copyOfParameters[values[name].length - 1];
      parameterOrder = lastItem.parameterOrder + 1;
    }
    unshift({
      parameterOrder,
    });
  };
  const onDelete = (index) => {
    remove(index);
  };
  const onClone = (id) => {
    const prototype = values[name].find(I => I.id === id);
    push({
      id: values[name].length,
      name: prototype.name,
      bindName: prototype.bindName,
      defaultExp: prototype.defaultExp,
      description: prototype.description,
      mandatory: prototype.mandatory,
      parameterOrder: prototype.parameterOrder + 1,
      sqlQuery: prototype.sqlQuery,
      typeID: prototype.typeID,
    });
  };
  const onChange = (id, items) => {
    const copyOfParameters = [...values[name]];
    const targetIndex = copyOfParameters.findIndex(I => I.id === id);
    const target = copyOfParameters.find(I => I.id === id);
    replace(targetIndex, {
      id,
      name: items.name || t('STATES.REPORTS.NEW_PARAMETER'),
      ...items,
      parameterOrder: target.initialValues.parameterOrder,
    });
  };

  return (
    <div className="settings-block">
      <div className="settings-block__header">
        <Icon
          icon="cog"
          color="var(--grey30)"
        />
        <Typography tagName="span" className="settings-block__title">
          SCREENS.REPORTS.PARAMETERS
        </Typography>
      </div>
      <div className="settings-block__footer">
        <Button
          dense
          onClick={onAdd}
          view="outlined"
          color="primary"
          icon="plus"
          text={t('CONTROLS.REPORTS.ADD_NEW_PARAMETER_TO_REPORT')}
        />
      </div>
      <div className="settings-block__body">
        {values[name].map((props, index) => (
          <SettingField
            key={props.parameterOrder}
            onDelete={onDelete}
            onClone={onClone}
            onChange={onChange}
            errors={errors?.parameters?.[index]}
            touched={touched?.parameters?.length ? touched?.parameters?.[index] : touched?.parameters}
            fieldName={name}
            fieldIndex={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};
