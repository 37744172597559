import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';
import cx from 'classnames';

import Chips from '@setproduct-ui/core/Chips';
import TextField from 'components/fields/TextField';

import styles from './Dropdown.module.scss';

const Dropdown = ({
  field: {
    value,
    name,
  } = {},
  form: {
    setFieldValue,
  },
  currentServiceScopes = [],
  restServicesWithScopes = [],
}) => {
  const inputRef = useRef();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  const [searchValue, setSearchValue] = useState('');

  const allRestScopes = useMemo(() => restServicesWithScopes.reduce((acc, service) => {
    service.scopes.forEach((scope) => {
      acc.push(scope);
    });

    return acc;
  }, []), [restServicesWithScopes]);
  const selectedOption = useMemo(
    () => (value ? ([
      ...currentServiceScopes,
      ...allRestScopes,
    ].find(I => I.id === value)
      || { value, label: `Missed option: ${value}` }) : {}),
    [value, currentServiceScopes, allRestScopes],
  );
  const filteredServices = useMemo(() => {
    if (!searchValue) {
      return restServicesWithScopes;
    }

    return restServicesWithScopes.filter(service => service.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, restServicesWithScopes]);

  const onSearchChange = ({ target }) => setSearchValue(target.value);
  const onTargetClick = () => setIsOpenMenu();
  const onItemClick = (newVal) => {
    setFieldValue(name, newVal.id);
    setIsOpenMenu(false);
  };

  useEffect(() => {
    if (!isOpenMenu) {
      setTimeout(() => {
        inputRef.current?.blur?.();
      }, 100);
    }
  }, [isOpenMenu]);

  return (
    <Popover2
      placement="top-start"
      transitionDuration={0}
      isOpen={isOpenMenu}
      interactionKind="click"
      onClose={() => setIsOpenMenu(false)}
      autoFocus={false}
      shouldReturnFocusOnClose={false}
      enforceFocus={false}
      minimal
      usePortal
      lazy
      renderTarget={({ ref, isOpen }) => (
        <div ref={ref}>
          <TextField
            inputRef={inputRef}
            value={selectedOption?.label}
            onClick={onTargetClick}
            clearButton={false}
            placeholder="Select scope to copy from"
            rightElement={(
              <Icon
                icon={isOpen ? 'chevron-up' : 'chevron-down'}
                size={12}
                color="var(--grey50)"
              />
            )}
            dense
            readOnly
            full
          />
        </div>
      )}
      content={(
        <div className={styles.overlay}>
          <div className={styles.sectorTitle}>
            Current service
          </div>
          <div className={styles.list}>
            {currentServiceScopes.map(scope => (
              <div
                className={styles.overlayItem}
                key={`current-scope-${scope.id}`}
                role="presentation"
                onClick={() => onItemClick(scope)}
              >
                <Chips
                  type="dense"
                  view="smooth"
                  round={false}
                  {...scope.chipsProps}
                />
                {scope.label}
              </div>
            ))}
          </div>
          <Popover2
            placement="right-start"
            transitionDuration={0}
            interactionKind="hover"
            openOnTargetFocus={false}
            minimal
            usePortal
            renderTarget={({ ref, isOpen, ...rest }) => (
              <div ref={ref} {...rest} className={cx(styles.sectorTitle, { [styles.sectorTitle_open]: isOpen })}>
                Other services
                <Icon icon="chevron-right" size={12} />
              </div>
            )}
            content={(
              <div className={styles.overlay}>
                <div className={styles.search}>
                  <Icon icon="search" size={12} color="var(--grey50)" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={onSearchChange}
                  />
                </div>
                <div className={cx(styles.list, styles.list_services)}>
                  {filteredServices.map(service => (
                    <Popover2
                      placement="right-start"
                      transitionDuration={0}
                      interactionKind="hover"
                      hoverOpenDelay={400}
                      openOnTargetFocus={false}
                      key={`service-${service.id}`}
                      minimal
                      usePortal
                      renderTarget={targetProps => (
                        <div
                          {...targetProps}
                          className={cx(
                            styles.overlayItem,
                            styles.overlayItem_row,
                            { [styles.overlayItem_open]: targetProps.isOpen },
                          )}
                          key={`service-${service.id}`}
                        >
                          <span title={service.name}>{service.name}</span>
                          <span className={styles.additionalLabel}>{service.packSize}</span>
                          <span className={styles.additionalLabel}>{service.currency}</span>
                          <Icon icon="chevron-right" size={12} />
                        </div>
                      )}
                      content={(
                        <div className={cx(styles.overlay, styles.list)}>
                          {service.scopes.map(scope => (
                            <div
                              className={styles.overlayItem}
                              key={`scope-${scope.id}`}
                              role="presentation"
                              onClick={() => onItemClick(scope)}
                            >
                              <Chips
                                type="dense"
                                view="smooth"
                                round={false}
                                {...scope.chipsProps}
                              />
                              {scope.label}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
          />
        </div>
      )}
    />
  );
};

export default Dropdown;
