import React, { useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { GROUPING_LIST } from 'consts';
import Button from '@setproduct-ui/core/Button';

import DateRangePicker from './DateRangePicker';
import styles from './DateRangeFieldModal.module.scss';

const DateRangeFieldModal = ({
  onClose,
  mode,
  onChange,
  onApply,
  rangeValue,
  onChangeFractional,
  withGrouping,
}) => {
  const { t } = useTranslation();

  const modalClass = cx(styles.container, {
    [styles.small]: !withGrouping,
  });

  const filteredOptions = useMemo(() => {
    const from = moment(rangeValue?.from);
    const to = moment(rangeValue?.to);
    const diffDays = to.diff(from, 'days');

    return GROUPING_LIST.map((option) => {
      let disable;
      switch (option.value) {
        case '1 minute':
          disable = diffDays >= 3;
          break;
        case '5 minute':
          disable = diffDays >= 7;
          break;
        case '15 minute':
          disable = diffDays >= moment(from).add(1, 'month').diff(from, 'days'); // Приблизительный месяц
          break;
        case '1 hour':
          disable = diffDays >= moment(from).add(1, 'quarter').diff(from, 'days'); // Приблизительный квартал
          break;
        case '1 day':
          disable = diffDays >= moment(from).add(3, 'years').diff(from, 'days'); // 3 года
          break;
        default:
          disable = false;
      }

      return { ...option, disabled: disable };
    });
  }, [rangeValue]);

  const pickerProps = useMemo(() => {
    switch (mode) {
      case '1 minute':
        return {
          type: 'datetime',
          picker: 'date',
          minuteStep: 1,
          secondStep: 60,
          timeFormat: 'HH:mm:ss',
        };
      case '5 minute':
        return {
          type: 'datetime',
          picker: 'date',
          minuteStep: 5,
          secondStep: 60,
          timeFormat: 'HH:mm:ss',
        };
      case '15 minute':
        return {
          type: 'datetime',
          picker: 'date',
          minuteStep: 15,
          secondStep: 60,
          timeFormat: 'HH:mm:ss',
        };
      case '1 hour':
        return {
          type: 'datetime',
          picker: 'date',
          minuteStep: 60,
          secondStep: 60,
          timeFormat: 'HH:mm:ss',
        };
      case '1 week':
        return {
          type: 'date',
          picker: 'week',
        };
      case '1 month':
        return {
          type: 'date',
          picker: 'month',
        };
      case '1 year':
        return {
          type: 'date',
          picker: 'year',
        };
      case '1 quarter':
        return {
          type: 'date',
          picker: 'quarter',
        };
      case '1 day':
      default:
        return {
          type: 'date',
          picker: 'date',
        };
    }
  }, [mode]);
  const getItemClass = (value, disabled) => cx(styles.listItem, {
    [styles.selected]: mode === value,
    [styles.disabled]: disabled,
  });

  const onApplyHandler = () => {
    onApply();
    onClose();
  };

  return (
    <div className={modalClass}>
      <div className={styles.content}>
        {withGrouping && (
          <div className={styles.grouping}>
            <div className={styles.title}>
              {t('PLACEHOLDERS.GROUPING')}
            </div>
            <div className={styles.list}>
              {filteredOptions.map(({ label, value, disabled }) => (
                <div
                  key={value}
                  role="presentation"
                  className={getItemClass(value, disabled)}
                  onClick={() => !disabled && onChangeFractional(value)}
                >
                  {t(label)}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.calendar}>
          <DateRangePicker
            {...pickerProps}
            onChange={onChange}
            rangeValue={rangeValue}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          text="CONTROLS.RESET"
          view="smooth"
          color="default"
          onClick={onClose}
        />
        <Button
          text="CONTROLS.APPLY"
          view="filled"
          color="primary"
          onClick={onApplyHandler}
        />
      </div>
    </div>
  );
};

export default DateRangeFieldModal;
