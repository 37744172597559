import React, { useMemo } from 'react';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import './style.scss';

const DetailCell = ({
  id,
  // isNumber,
  isBold,
  title,
  icon,
  iconColor,
  value,
  label,
  editControl = null,
  onClick = Function.prototype,
  parentId,
}) => {
  const { t } = useTranslation();

  const valueClass = cx('detail-cell__value', {
    'detail-cell__value_link': !!id,
    'detail-cell__value_bold': isBold,
  });

  const dataTestId = useMemo(
    () =>
      `${window.location.pathname}/detail-cell/${title?.toLowerCase()?.replaceAll('.', '_')}/${parentId}`,
    [title, parentId],
  );
  const valueModify = useMemo(() => ((value === 'Invalid date' || !value) ? 'N/A' : value), [value]);

  return (
    <div
      className="detail-cell"
      data-testid={dataTestId}
    >
      <span className="detail-cell__header">{t(title)}</span>
      <div
        role="presentation"
        className={valueClass}
        onClick={onClick}
      >
        {
          typeof icon === 'object'
            ? icon
            : !!icon && (
              <Icon
                size={16}
                icon={icon}
                color={iconColor}
              />
            )
        }
        <div title={valueModify.length > 20 && valueModify} className="detail-cell__content">
          {valueModify}
        </div>
        {label && (
          <span className="detail-cell__label">{label}</span>
        )}
        {editControl}
      </div>
    </div>
  );
};

export default DetailCell;
