import endpoints from 'consts/endpoints';

export default ({
  limit,
  offset,
  ...queries
} = {}) => ({
  url: endpoints.getSendersUrl(),
  queryKey: endpoints.getSendersUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      senders: resourceList,
      sendersMeta: other,
    };
  },
  update: {
    senders: (oldValue, newValue) => newValue,
    sendersMeta: (oldValue, newValue) => newValue,
  },
});
