import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getInvoices as getInvoicesApi,
  getInvoiceNotes as getInvoiceNotesApi,
  deleteInvoiceNote as deleteInvoiceNoteApi,
  patchInvoiceNote as patchInvoiceNoteApi,
  postInvoiceNote as postInvoiceNoteApi,
  patchInvoice as patchInvoiceApi,
  getDownloadInvoice as getDownloadInvoiceApi,
} from 'queries/invoices';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetInvoices,
      lastUpdated: lastUpdatedGetInvoices,
    },
    getInvoices,
  ] = useMutation(getInvoicesApi);
  const [
    {
      isPending: isPendingGetInvoiceNotes,
    },
    getInvoiceNotes,
  ] = useMutation(getInvoiceNotesApi);
  const [
    {
      isPending: isPendingDeleteInvoiceNote,
    },
    deleteInvoiceNote,
  ] = useMutation(deleteInvoiceNoteApi);
  const [
    {
      isPending: isPendingPatchInvoiceNote,
    },
    patchInvoiceNote,
  ] = useMutation(patchInvoiceNoteApi);
  const [
    {
      isPending: isPendingPostInvoiceNote,
    },
    postInvoiceNote,
  ] = useMutation(postInvoiceNoteApi);
  const [
    {
      isPending: isPendingPatchInvoice,
    },
    patchInvoice,
  ] = useMutation(patchInvoiceApi);
  const [
    {
      isPending: isPendingGetDownloadInvoice,
    },
    getDownloadInvoice,
  ] = useMutation(getDownloadInvoiceApi);

  const selector = useCallback(state => ({
    invoices: getArray(state, 'invoices'),
    invoicesMeta: getObject(state, 'invoicesMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getInvoices,
    getInvoiceNotes,
    deleteInvoiceNote,
    patchInvoiceNote,
    postInvoiceNote,
    patchInvoice,
    getDownloadInvoice,
    isPendingGetInvoiceNotes,
    isPendingGetInvoices,
    isPendingDeleteInvoiceNote,
    isPendingPatchInvoiceNote,
    isPendingPostInvoiceNote,
    isPendingPatchInvoice,
    isPendingGetDownloadInvoice,
    lastUpdatedGetInvoices,
    ...data,
  };
};
