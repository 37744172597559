import React, { useMemo } from 'react';
import moment from 'moment';
import { Picker } from 'rc-picker';
import generateConfig from 'rc-picker/lib/generate/moment';
import localeEn from 'rc-picker/lib/locale/en_GB';
import localeRu from 'rc-picker/lib/locale/ru_RU';
import localeEs from 'rc-picker/lib/locale/eu_ES';

import { locale } from 'helpers';
import 'rc-picker/assets/index.css';

// import styles from './TimePicker.module.scss';
import './style.scss';

const TimePicker = ({
  // className = '',
  format = 'HH:mm:ss',
  secondStep,
  minuteStep,
  value,
  onChange,
}) => {
  const lang = locale.getLanguage();

  const currentLocale = useMemo(() => {
    switch (lang) {
      case 'ru':
        return localeRu;
      case 'es':
        return localeEs;
      case 'en':
        return localeEn;
      default:
        return localeEn;
    }
  }, [lang]);

  return (
    <div className="timepicker-container">
      <Picker
        defaultValue={moment()}
        locale={currentLocale}
        generateConfig={generateConfig}
        picker="time"
        value={value}
        allowClear={false}
        minuteStep={minuteStep}
        secondStep={secondStep}
        onChange={onChange}
        format={format}
        inputReadOnly
        onOk={onChange}
      />
    </div>
  );
};

export default TimePicker;
