import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  postPortal as postPortalApi,
  getPortals as getPortalsApi,
  deletePortal as deletePortalApi,
  putPortal as putPortalApi,
} from 'queries/portals';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [{ isPending: isPendingPostPortal }, postPortal] = useMutation(postPortalApi);
  const [{ isPending: isPendingDeletePortal }, deletePortal] = useMutation(deletePortalApi);
  const [{ isPending: isPendingPutPortal }, putPortal] = useMutation(putPortalApi);
  const [
    {
      isPending: isPendingGetPortals,
      lastUpdated: lastUpdatedGetPortals,
    },
    getPortals,
  ] = useMutation(getPortalsApi);

  const selector = useCallback(state => ({
    portals: getArray(state, 'portals'),
    portalsMeta: getObject(state, 'portalsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    postPortal,
    getPortals,
    deletePortal,
    putPortal,
    isPendingPostPortal,
    isPendingGetPortals,
    isPendingDeletePortal,
    isPendingPutPortal,
    lastUpdatedGetPortals,
    ...data,
  };
};
