import endpoints from 'consts/endpoints';

export default ({
  limit = 10,
  offset = 0,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
  filter,
  ...queries
} = {}) => ({
  url: endpoints.getChargesUrl(),
  body: {
    filter,
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
