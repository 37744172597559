import React, { useRef, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  SwitchField,
  DropdownField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';
import { numbers } from 'consts/inputPatterns';
import {
  PASSWORD_WITHOUT_RE_SCHEMA,
  SMPP_CONNECTION_SCHEMA,
} from 'consts/validationSchemas';
import './style.scss';

const SSL_TYPES = [
  {
    value: 1,
    label: 'SSLv3',
  },
  {
    value: 2,
    label: 'SSLv23',
  },
  {
    value: 3,
    label: 'TLS',
  },
];

const SmppConnectionsForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {
    useTlv: false,
    moInverseAddressMode: false,
    // по факту тру, там перевернуто, чтоб пользователю было понятнее
    enabled: false,
  },
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = ({
    width, marginTop, marginBottom, ...inputStyles
  } = {}) => ({
    container: {
      width,
      marginTop,
      marginBottom,
    },
    input: {
      ...inputStyles,
    },
  });
  const dropdownFieldStyles = {
    container: {
      width: 268,
    },
  };

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);

  const validationSchema = useMemo(() => (mode === 'add'
    ? SMPP_CONNECTION_SCHEMA.concat(PASSWORD_WITHOUT_RE_SCHEMA)
    : SMPP_CONNECTION_SCHEMA), [mode]);
  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    enabled, port, sslType, ...values
  }) => {
    onSubmit({
      ...values,
      enabled: !enabled,
      port: +port,
      sslType: +sslType || undefined,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="smpp-connections-form">
          {showTopShadow && <div className="smpp-connections-form__shadow" />}
          <div className="smpp-connections-form__content" ref={contentRef}>
            <Field
              name="name"
              component={TextField}
              label="INSTANCES.NAME"
              disabled={isView}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              fill
            />
            <div className="smpp-connections-form__row smpp-connections-form__row_block-closer" style={{ marginTop: 32 }}>
              <Field
                name="hostName"
                component={TextField}
                label="INSTANCES.VENDORS_SMPP.HOST_NAME"
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                isRequired={!isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
              />
              <Field
                name="port"
                component={TextField}
                label="INSTANCES.PORT"
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                isRequired={!isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                regex={numbers({ maxLength: 5 })}
              />
            </div>
            <div className="smpp-connections-form__row smpp-connections-form__row_block-closer">
              <Field
                name="systemId"
                component={TextField}
                label="INSTANCES.VENDORS_SMPP.SYSTEM_ID"
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                isRequired={!isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
              />
              <Field
                name="password"
                component={TextField}
                label="INSTANCES.PASSWORD"
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                isRequired={!isView && mode === 'add'}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
              />
            </div>
            <div className="smpp-connections-form__row smpp-connections-form__row_block-closer">
              <Field
                name="systemType"
                component={TextField}
                label="INSTANCES.SYSTEM_TYPE"
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
              />
              <Field
                name="sslType"
                component={DropdownField}
                label="INSTANCES.VENDORS_SMPP.SSL_TYPE"
                styles={dropdownFieldStyles}
                options={SSL_TYPES}
                disabled={isView}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                clearButton
              />
            </div>
            <div className="smpp-connections-form__row smpp-connections-form__row_block-closer">
              <Field
                name="useTlv"
                label={`CONTROLS.VENDORS_SMPP.USE${values?.useTlv ? 'D' : ''}`}
                component={SwitchField}
                color="primary"
                disabled={isView}
                styles={{ container: { width: '100%' } }}
              />
              <Field
                name="moInverseAddressMode"
                label={`CONTROLS.VENDORS_SMPP.INVER${values?.moInverseAddressMode ? 'TED' : 'SE'}`}
                component={SwitchField}
                color="primary"
                disabled={isView}
                styles={{ container: { width: '100%' } }}
              />
            </div>
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
          >
            <Field
              name="enabled"
              label={`CONTROLS.DISABLE${values?.enabled ? 'D' : ''}`}
              styles={fieldStyle({ marginBottom: 24 })}
              component={SwitchField}
              disabled={isView}
            />
          </FormButtonsGroup>
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default SmppConnectionsForm;
