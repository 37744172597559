import React, { useMemo } from 'react';

import Chips from '@setproduct-ui/core/Chips/Chips';
import {
  ViberChannelIcon,
  SmsChannelIcon,
  TelegramChannelIcon,
  WhatsappChannelIcon,
  EmailChannelIcon,
  RCSIcon,
} from 'assets/icons';
import { ALL_CHANNELS_IDS } from 'consts/channels';

import './style.scss';

export default ({
  value,
  colDef: {
    headerComponentParams: {
      withChips = true,
      byId = false,
      withTitle = true,
    } = {},
  } = {},
}) => {
  let icon = null;
  let chips = null;

  const formattedValue = useMemo(
    () => (withTitle ? (byId
      ? ALL_CHANNELS_IDS.find(I => I.value === value)?.label
      : value) : ''),
    [value, byId, withTitle],
  );

  switch (value) {
    case 'SMS':
    case 1:
      if (withChips) {
        chips = (
          <Chips
            type="dense"
            color="success"
            tag="2 way"
            round={false}
          />
        );
      }
      icon = <SmsChannelIcon className="channel-icon" />;
      break;
    case 'RCS':
    case 2:
      icon = <RCSIcon className="channel-icon" />;
      break;
    case 'Viber':
    case 3:
      icon = <ViberChannelIcon className="channel-icon" />;
      break;
    case 'WhatsApp':
    case 4:
      icon = <WhatsappChannelIcon className="channel-icon" />;
      break;
    case 'Telegram':
    case 5:
      icon = <TelegramChannelIcon className="channel-icon" />;
      break;
    case 'Email':
    case 6:
      icon = <EmailChannelIcon className="channel-icon" />;
      break;
    default:
      break;
  }

  return (
    <div className="channel-container-icon">
      {icon}
      {formattedValue}
      {chips}
    </div>
  );
};
