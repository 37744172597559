import endpoints from 'consts/endpoints';

export default ({
  successCallback = () => {},
  errorCallback = () => {},
} = {}) => ({
  url: endpoints.getOwnAgreementsUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ resourceList }) => ({
    ownAgreementsOptions: resourceList.map(({ id, agreementCode, ...rest }) => ({
      value: id,
      label: `${agreementCode}, ${rest.currency}`,
      ...rest,
    })),
    ownAgreementsDictionary: resourceList.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    ownAgreementsOptions: (oldValue, newValue) => newValue,
    ownAgreementsDictionary: (oldValue, newValue) => newValue,
  },
});
