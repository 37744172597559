import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  offset = 0,
  ...queries
} = {}) => ({
  url: endpoints.getTServicesUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      data,
      meta,
    } = response;

    return {
      servicesT: data,
      servicesTMeta: meta,
    };
  },
  update: {
    servicesT: (oldValue, newValue) => newValue,
    servicesTMeta: (oldValue, newValue) => newValue,
  },
});
