import { actionTypes } from 'redux-query';
import _ from 'lodash';

/**
 * Add Authorization header to api action
 *
 * @return {void}
 */
export default () => next => (action) => {
  const token = localStorage.getItem('boToken');

  if ((
    _.isEqual(action.type, actionTypes.REQUEST_ASYNC)
    || _.isEqual(action.type, actionTypes.MUTATE_ASYNC))
    && action?.meta?.authToken
  ) {
    const callAPI = action;
    delete callAPI.meta.authToken;

    callAPI.options.headers = {
      ...callAPI.options.headers,
      Authorization: `Bearer ${token}`,
    };
    return next(action);
  }
  return next(action);
};
