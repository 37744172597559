import React, { useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Chips from '@setproduct-ui/core/Chips';

import './style.scss';

const HistoryItem = ({
  isActive,
  effectiveFrom,
  effectiveTill,
  onClick,
}) => {
  const itemClass = cx('history-item', {
    'history-item_active': isActive,
  });

  const { t } = useTranslation();

  const status = useMemo(() => {
    const effectiveTillModify = effectiveTill === null
      ? moment().set({ day: 31, month: 11, year: 2999 })
      : effectiveTill;

    if (moment().isBetween(
      moment(effectiveFrom).local(),
      moment(effectiveTillModify).local(),
      null,
      '()',
    )) {
      return 'current';
    }
    if (moment().isBefore(effectiveFrom)) {
      return 'upcoming';
    }
    return 'previous';
  }, [effectiveFrom, effectiveTill]);

  const chipColor = useMemo(() => {
    switch (status) {
      case 'current':
        return 'success';
      case 'upcoming':
        return 'primary';
      default:
        return 'default';
    }
  }, [status]);

  return (
    <div
      className={itemClass}
      role="presentation"
      onClick={onClick}
    >
      <Chips
        tag={t(`STATES.${status?.toUpperCase()}`)}
        color={chipColor}
      />
      <span className="history-item__range">
        {`${moment(effectiveFrom).format('DD.MM.YYYY')} - ${effectiveTill ? moment(effectiveTill).format('DD.MM.YYYY') : `∞ ${t('CONTROLS.INDEFINITELY')}`}`}
      </span>
    </div>
  );
};

export default HistoryItem;
