import endpoints from 'consts/endpoints';

export default ({ dictionaryId, body, successCallback }) => ({
  url: endpoints.getFilteredItems(dictionaryId),
  options: {
    method: 'POST',
  },
  body,
  meta: {
    authToken: true,
    successCallback,
  },
});
