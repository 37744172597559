import { useMemo, useState, useEffect } from 'react';

export default ({ sessionKey = window.location.pathname, initialValues = {} } = {}) => {
  const initialProps = useMemo(
    () =>
      JSON.parse(window.sessionStorage.getItem(`columns_${sessionKey}`)),
    [sessionKey],
  );

  const [isOpenCustomViewDrawer, setIsOpenCustomViewDrawer] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState(initialProps || initialValues);

  const closeCustomViewDrawer = () => {
    setIsOpenCustomViewDrawer(false);
  };
  const openCustomViewDrawer = () => {
    setIsOpenCustomViewDrawer(true);
  };
  const onSaveCustomView = (values) => {
    setFilteredColumns(values);
    window.sessionStorage.setItem(`columns_${sessionKey}`, JSON.stringify(values));
    closeCustomViewDrawer();
  };

  useEffect(() => {
    setFilteredColumns(initialProps || initialValues);
  }, [initialProps, initialProps]);

  return {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  };
};
