import React, { useEffect } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import { useScreen } from 'hooks';
import { ADDITIONAL_FEES } from 'consts/columns';
import {
  useAdditionalFeesApi,
  useDictionariesApi,
} from 'hooks/api';

import AdditionalFeeForm from './AdditionalFeeForm';

export default ({ screenRef }) => {
  const {
    additionalFees,
    additionalFeesMeta,
    getAdditionalFees,
    postAdditionalFee,
    isPendingPostAdditionalFee,
    isPendingGetAdditionalFees,
    lastUpdatedGetAdditionalFees,
  } = useAdditionalFeesApi();
  const {
    getCompaniesDictionary,
    getAgreementsDictionary,
    getUsersDictionary,
    getListWithDefinitions,
  } = useDictionariesApi();
  const {
    openModal,
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isOpenModal,
    onCloseModal,
  } = useScreen({
    screenRef,
    getFunc: getAdditionalFees,
    useNewFilters: true,
  });

  const chargeAdditionalFree = (body) => {
    postAdditionalFee({
      body: {
        ...body,
        preRatedCost: body.preRatedCost ? +body.preRatedCost : undefined,
        volume: body.volume ? +body.volume : undefined,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
    getAgreementsDictionary();
    getUsersDictionary();
    getListWithDefinitions();
  }, []);

  return (
    <div className="payments-layout">
      <Table
        limit={limit}
        total={additionalFeesMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.ADDITIONAL_FEES"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.ADDITIONAL_FEES.ADD"
        columnDefs={ADDITIONAL_FEES}
        rowData={additionalFees}
        sendQuery={sendQuery}
        isPending={isPendingGetAdditionalFees || !lastUpdatedGetAdditionalFees}
        tableRef={tableRef}
      />
      {
        isOpenModal && (
          <Modal
            closeModal={onCloseModal}
            title="SCREENS.ADDITIONAL_FEES.ADDITIONAL_FEE"
          >
            <AdditionalFeeForm
              onSubmit={chargeAdditionalFree}
              isPending={isPendingPostAdditionalFee}
              onCancel={onCloseModal}
            />
          </Modal>
        )
      }
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={ADDITIONAL_FEES}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
    </div>
  );
};
