import endpoints from 'consts/endpoints';

export default ({
  limit = 1000,
  offset = 0,
  ...queries
} = {}) => ({
  url: endpoints.getSmppApiConnectionsUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    smppApiConnections: response.data,
  }),
  update: {
    smppApiConnections: (oldValue, newValue) => newValue,
  },
});
