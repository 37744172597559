import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  deletePackEnabled as deletePacksEnabledApi,
  putPackEnabled as putPacksEnabledApi,
  postPackEnabled as postPacksEnabledApi,
  getPacksEnabled as getPacksEnabledApi,
  getPackPrice as getPackPriceApi,
} from 'queries/packsEnabled';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetPacksEnabled,
      lastUpdated: lastUpdatedGetPacksEnabled,
    },
    getPacksEnabled,
  ] = useMutation(getPacksEnabledApi);
  const [
    {
      isPending: isPendingDeletePackEnabled,
    },
    deletePackEnabled,
  ] = useMutation(deletePacksEnabledApi);
  const [
    {
      isPending: isPendingPutPackEnabled,
    },
    putPackEnabled,
  ] = useMutation(putPacksEnabledApi);
  const [
    {
      isPending: isPendingPostPackEnabled,
    },
    postPackEnabled,
  ] = useMutation(postPacksEnabledApi);
  const [
    {
      isPending: isPendingGetPackPrice,
    },
    getPackPrice,
  ] = useMutation(getPackPriceApi);

  const selector = useCallback(state => ({
    packsEnabled: getArray(state, 'packsEnabled'),
    packsEnabledMeta: getObject(state, 'packsEnabledMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getPacksEnabled,
    deletePackEnabled,
    putPackEnabled,
    postPackEnabled,
    getPackPrice,
    isPendingGetPacksEnabled,
    isPendingDeletePackEnabled,
    isPendingPutPackEnabled,
    isPendingPostPackEnabled,
    isPendingGetPackPrice,
    lastUpdatedGetPacksEnabled,
    ...data,
  };
};
