import React, { useRef, useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Icon, Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import MultiselectField from 'components/fields/MultiselectField';
import DatePickerField from 'components/fields/DatePickerField';
import { CheckboxField } from 'components/fields/CheckboxField';
import { EDR_RERATE_TASK_SCHEMA } from 'consts/validationSchemas';
import { useDictionariesApi, useRerateApi } from 'hooks/api';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import { CHANNELS_OPTIONS } from 'consts';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import OnFormChange from 'components/forms/OnFormChange';
import getFiltersForQueryNew from 'helpers/getFiltersForQueryNew';
import Callout from '@setproduct-ui/core/Callout';

import styles from './EdrRerateForm.module.scss';

const EdrRerateForm = ({
  initialValues = {
    rerateSessions: true,
  },
  onSubmit,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const { t } = useTranslation();
  const [agreementsFilteredOptions, setAgreementsFilteredOptions] = useState([]);
  const [estimatedCount, setEstimatedCount] = useState(null);
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const {
    companiesOptionsWithOwn,
    companiesDictionary,
    agreementsDictionary,
    agreementsOptions,
  } = useDictionariesApi();
  const {
    getEstimateEdrRerateTask,
    isPendingGetEstimateEdrRerateTask,
  } = useRerateApi();

  const yesterdayDate = useMemo(() => moment().subtract(1, 'day').toDate(), []);

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    return isEmpty(values);
  };
  const fieldStyle = ({
    bottom,
    top,
  } = {}) => ({
    container: {
      width: '100%',
    },
    overlay: {
      maxHeight: 200,
      bottom,
      top,
    },
  });

  const handleCompanyIdsFieldChange = ({ value, form, values }) => {
    if (form.dirty) {
      form.setFieldValue(
        'agreementIds',
        values.agreementIds
          ?.filter(agreementId => value.some(companyId => companyId === agreementsDictionary[agreementId]?.companyId))
        || undefined,
      );
    }
    if (value?.length) {
      setAgreementsFilteredOptions(agreementsOptions.reduce((acc, item) => {
        if (value.some(companyId => companyId === item.companyId)) {
          acc.push({
            value: item.value,
            label: `${companiesDictionary[item.companyId]?.name} - ${item.label}`,
          });
        }
        return acc;
      }, []));
    } else {
      setAgreementsFilteredOptions([]);
    }
  };
  const convertValuesForApiData = ({
    timeFrom,
    timeTill,
    companyIds,
    agreementIds,
    channels,
    rerateSessions,
    scheduledFor,
  }) => {
    const filters = {};

    if (companyIds?.length) {
      filters.companyId = {
        values: companyIds.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {}),
        type: 'list',
      };
    }
    if (agreementIds?.length) {
      filters.agreementId = {
        values: agreementIds.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {}),
        type: 'list',
      };
    }
    if (channels?.length) {
      filters.channelType = {
        values: channels.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {}),
        type: 'list',
      };
    }

    return {
      filter: isEmpty(filters) ? undefined : JSON.stringify(getFiltersForQueryNew(filters)),
      timeFrom: `${moment(timeFrom).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`,
      timeTill: `${moment(timeTill).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`,
      scheduledFor: `${moment(scheduledFor).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`,
      properties: {
        rerateSessions,
      },
    };
  };
  const handleFormValuesChanged = (values) => {
    if (values.timeFrom && values.timeTill && values.companyIds?.length) {
      getEstimateEdrRerateTask({
        body: convertValuesForApiData(values),
        successCallback: ({ size }) => setEstimatedCount(size),
      });
    } else if (estimatedCount) {
      setEstimatedCount(null);
    }
  };
  const onSubmitModify = (values) => {
    onSubmit(convertValuesForApiData(values));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={EDR_RERATE_TASK_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.container}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <div className={styles.row}>
              <Field
                name="timeFrom"
                label="INSTANCES.EDR_RERATE.TIME_FROM"
                component={DatePickerField}
                minDate={values.timeTill
                  ? moment(values.timeTill).subtract(3, 'months').toDate()
                  : null}
                maxDate={values.timeTill || yesterdayDate}
                format="DD.MM.YYYY HH:mm:ss"
                timePrecision="second"
                isRequired
                applyButton
                pickerDense
                usePortal
              />
              <Field
                name="timeTill"
                label="INSTANCES.EDR_RERATE.TIME_TILL"
                component={DatePickerField}
                minDate={values.timeFrom}
                maxDate={values.timeFrom
                  ? moment.min(moment(yesterdayDate), moment(values.timeFrom).add(3, 'months')).toDate()
                  : yesterdayDate}
                format="DD.MM.YYYY HH:mm:ss"
                timePrecision="second"
                isRequired
                applyButton
                withIndefinitely
                pickerDense
                usePortal
              />
            </div>
            <Field
              name="companyIds"
              label="INSTANCES.COMPANY"
              component={MultiselectField}
              options={companiesOptionsWithOwn}
              placeholder="PLACEHOLDERS.SELECT"
              styles={fieldStyle()}
              allowCreate={false}
              isRequired
            />
            <Field
              name="agreementIds"
              label="INSTANCES.AGREEMENT"
              component={MultiselectField}
              options={agreementsFilteredOptions}
              placeholder="PLACEHOLDERS.SELECT"
              styles={fieldStyle({
                bottom: '100%',
                top: 'unset',
              })}
              allowCreate={false}
              disabled={!values.companyIds?.length}
            />
            <Field
              name="channels"
              label="INSTANCES.CHANNEL"
              component={MultiselectField}
              options={CHANNELS_OPTIONS}
              placeholder="PLACEHOLDERS.SELECT"
              styles={fieldStyle({
                bottom: '100%',
                top: 'unset',
              })}
              allowCreate={false}
            />
            {values?.channels?.includes(4) && (
              <Field
                name="rerateSessions"
                component={CheckboxField}
                text="INSTANCES.EDR_RERATE.RECALCULATE_SESSIONS"
              />
            )}
            <Field
              name="scheduledFor"
              label="INSTANCES.EDR_RERATE.SCHEDULED_FOR"
              component={DatePickerField}
              minDate={new Date()}
              format="DD.MM.YYYY HH:mm:ss"
              placeholder="PLACEHOLDERS.EDR_RERATE.LEFT_BLANK"
              timePrecision="second"
              applyButton
              pickerDense
              usePortal
            />
            {estimatedCount !== null && (
              <Callout
                view="smooth"
                color="default"
                icon={isPendingGetEstimateEdrRerateTask
                  ? <Spinner size={21} />
                  : <Icon icon="info-sign" color="var(--grey70)" size={20} />}
                className={styles.callout}
                dense
              >
                {t('SCREENS.EDR_RERATE.ESTIMATED_COUNT', { count: estimatedCount })}
              </Callout>
            )}
          </div>
          <FormButtonsGroup
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={handleSubmit}
            isPending={isPending}
            showShadow={showBottomShadow}
            mode="add"
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          <OnChangeComponent field="companyIds" onChange={handleCompanyIdsFieldChange} />
          <OnFormChange callback={handleFormValuesChanged} />
        </Form>
      )}
    </Formik>
  );
};

export default EdrRerateForm;
