import _ from 'lodash';

import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getTimezonesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    timezones: _.sortBy(response.map(({ id, areaName, areaOffset }) => ({
      value: id,
      label: `${areaName} ${areaOffset}`,
      areaName,
      areaOffset,
    })), ['label']),
    timezonesDictionary: response
      .reduce((acc, { areaName, ...values }) => {
        acc[areaName] = {
          ...values,
        };

        return acc;
      }, {}),
  }),
  update: {
    timezones: (oldValue, newValue) => newValue,
    timezonesDictionary: (oldValue, newValue) => newValue,
  },
});
