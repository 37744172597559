import endpoints from 'consts/endpoints';

export default ({
  ...queries
} = {}) => ({
  url: endpoints.getFeeRulesUrl(),
  body: {
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ resourceList }) => ({
    feeRules: resourceList,
  }),
  update: {
    feeRules: (oldValue, newValue) => newValue,
  },
});
