import endpoints from 'consts/endpoints';

export default ({
  id,
  postfix,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getInvoiceUrl(id, postfix),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
