import endpoints from 'consts/endpoints';

export default ({
  body: {
    id,
    ...body
  },
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  body,
  url: endpoints.getSenderUrl(id),
  queryKey: `put${endpoints.getSenderUrl(id)}`,
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
