import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import {
  getWhatsAppLanguages as getWhatsAppLanguagesApi,
  getWhatsAppCategories as getWhatsAppCategoriesApi,
  getWhatsAppTemplates as getWhatsAppTemplatesApi,
} from 'queries/whatsAppTemplates';

import { getArray, getObject, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetTemplates,
      lastUpdated: lastUpdatedGetTemplates,
    },
    getWhatsAppTemplates,
  ] = useMutation(getWhatsAppTemplatesApi);

  const [, getWhatsAppLanguages] = useMutation(getWhatsAppLanguagesApi);
  const [, getWhatsAppCategories] = useMutation(getWhatsAppCategoriesApi);

  const selector = useCallback(state => ({
    whatsAppLanguages: getArray(state, 'whatsAppLanguages'),
    whatsAppCategories: getArray(state, 'whatsAppCategories'),
    whatsAppTemplates: getArray(state, 'whatsAppTemplates'),
    whatsAppTemplatesMeta: getObject(state, 'whatsAppTemplatesMeta'),
  }), []);

  const data = useISESelector(selector);

  return {
    getWhatsAppLanguages,
    getWhatsAppCategories,
    getWhatsAppTemplates,
    isPendingGetTemplates,
    lastUpdatedGetTemplates,
    ...data,
  };
};
