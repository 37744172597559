import React from 'react';
import { Field } from 'formik';

import TextField from 'components/fields/TextField';
import { numbers, lettersOrNumbers } from 'consts/inputPatterns';

import './style.scss';

const RBMFields = ({ readOnly }) => {
  const fieldStyles = {
    label: {
      marginTop: 24,
    },
  };

  return (
    <div className="gsma-fields">
      <Field
        name="login"
        component={TextField}
        label="INSTANCES.LOGIN"
        placeholder="PLACEHOLDERS.ENTER_VALUE"
        styles={fieldStyles}
        disabled={readOnly}
        regex={lettersOrNumbers()}
        full
        isRequired
      />
      <Field
        name="password"
        component={TextField}
        label="INSTANCES.PASSWORD"
        placeholder="PLACEHOLDERS.ENTER_VALUE"
        styles={fieldStyles}
        disabled={readOnly}
        regex={lettersOrNumbers()}
        full
        isRequired
      />
      <Field
        name="tokenDuration"
        component={TextField}
        label="INSTANCES.SENDER_IDS.TOKEN_DURATION"
        placeholder="PLACEHOLDERS.ENTER_VALUE"
        styles={fieldStyles}
        disabled={readOnly}
        regex={numbers()}
        full
        isRequired
      />
    </div>
  );
};

export default RBMFields;
