import React, { useMemo, useEffect } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { WABA_COLUMNS } from 'consts/columns';
import {
  useWabaApi,
  useDictionariesApi,
} from 'hooks/api';
import { ConfirmModal } from 'components/modals';
import Modal from 'components/Modal';
import { useScreen } from 'hooks';

import WabaForm from './WabaForm';

import './style.scss';

const WabaScreen = ({
  screenRef,
}) => {
  const {
    timezones,
    companiesOptionsWithOwn,
    getCompaniesDictionary,
  } = useDictionariesApi();
  const {
    waba,
    wabaMeta,
    getWaba,
    deleteWaba,
    putWaba,
    postWaba,
    isPendingGetWaba,
    isPendingDeleteWaba,
    isPendingPutWaba,
    isPendingPostWaba,
    lastUpdatedGetWaba,
  } = useWabaApi();
  const onRowClickCallback = data => data;
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getWaba,
    deleteFunc: deleteWaba,
    onRowClickCallback,
  });

  const tzOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(T => (T.label === v.label)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);

  const onSubmit = (body) => {
    (editableRecord ? putWaba : postWaba)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
  }, []);

  return (
    <div className="waba-layout">
      <Table
        limit={limit}
        total={wabaMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.WABA"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.WABA.ADD"
        columnDefs={WABA_COLUMNS}
        rowData={waba}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetWaba || !lastUpdatedGetWaba}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={WABA_COLUMNS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.WABA.WABA"
            editableRecord={editableRecord}
          >
            <WabaForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              initialValues={editableRecord}
              tzOptions={tzOptions}
              companiesOptions={companiesOptionsWithOwn}
              isPending={isPendingPostWaba || isPendingPutWaba || isPendingDeleteWaba}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default WabaScreen;
