export default (body, messageType) => {
  try {
    switch (messageType) {
      case 'weChatText':
        return {
          selectedType: messageType,
          message: body.Content,
        };
      case 'weChatFile': {
        return {
          selectedType: messageType,
          image: {
            fileUrl: body.PicUrl,
            thumbnailUrl: body.thumbnailUrl,
          },
        };
      }
      default: return {
        selectedType: 'weChatText',
        message: 'Unknown WeChat message type. Function: convertFromEdrToConstructorWeChat.js',
      };
    }
  } catch (e) {
    console.error(e);

    return {
      selectedType: 'viberText',
      message: 'An error occurred while converting the body. Function: convertFromEdrToConstructorWeChat.js',
    };
  }
};
