import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';

import './styles.scss';

export default ({
  showColumnMenu,
  displayName,
  column,
  api,
  enableSorting,
  setSort,
}) => {
  const { t } = useTranslation();

  const menuButton = useRef();
  const [sortingOrder, setSortingOrder] = useState(column.sort);

  const sortIndicatorClass = classNames('ag-header-sort-indicator', {
    [`ag-header-sort-indicator_${sortingOrder}`]: !!sortingOrder,
  });

  const onMenuClicked = () => {
    showColumnMenu(menuButton.current);
  };

  const onResetFilter = () => {
    api.getFilterInstance(column.colId, (countryFilterComponent) => {
      countryFilterComponent.setModel();
      api.onFilterChanged();
      api.redrawRows();
      setTimeout(() => api.sendQuery());
    });
  };

  const onSort = () => {
    let nextSortingOrder;
    switch (sortingOrder) {
      case 'asc': {
        nextSortingOrder = 'desc';
        break;
      }
      case 'desc': {
        nextSortingOrder = '';
        break;
      }
      default: {
        nextSortingOrder = 'asc';
        break;
      }
    }
    setSort(nextSortingOrder);
    setSortingOrder(nextSortingOrder);
    setTimeout(() => api.sendQuery());
  };

  useEffect(() => {
    const onSortChanged = () => {
      api.redrawRows();
      setSortingOrder(column.sort || '');
    };

    column.addEventListener('sortChanged', onSortChanged);

    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
    };
  }, []);

  return (
    <div
      className="ag-column-header"
      data-testid={`${window.location.pathname}/table-column/${column.colId}`}
    >
      <div className="ag-column-header__filter-point" ref={menuButton} />
      {
        enableSorting ? (
          <div
            className="ag-header-cell-label ag-header-cell-label_button"
            role="presentation"
            onClick={onSort}
          >
            <span className="ag-header-cell-text">
              {t(displayName)}
            </span>
            <div className={sortIndicatorClass}>
              <Icon icon="arrow-down" color="#787885" iconSize={12} />
            </div>
          </div>
        ) : (
          <div className="ag-header-cell-label">
            <span className="ag-header-cell-text">
              {t(displayName)}
            </span>
          </div>
        )
      }
      {
        column.colDef.filter && (
          <div
            role="presentation"
            aria-hidden="true"
            className="ag-column-header__filter"
            onClick={onMenuClicked}
          >
            <Icon icon="filter" iconSize={12} />
          </div>
        )
      }
      <div
        role="presentation"
        aria-hidden="true"
        className="ag-column-header__reset-filter"
        onClick={onResetFilter}
      >
        <Icon icon="cross" iconSize={12} />
      </div>
    </div>
  );
};
