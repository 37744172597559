import React, { memo, useMemo } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { CheckboxField } from 'components/fields/CheckboxField';

import styles from './Multiselect.module.scss';

const Multiselect = memo(({
  field: {
    value = [],
    name,
  } = {},
  form: {
    setFieldValue,
  },
  options = [],
  disabled,
  wrapperStyle,
}) => {
  const { t } = useTranslation();

  const firstSelectedItemLabel = useMemo(() => {
    if (value?.length) {
      return options.find(I => I.value === value[0])?.label || `Missed option: ${value[0]}`;
    }
    return '';
  }, [value, options]);
  const handleChange = (newVal, key) => {
    if (newVal) {
      setFieldValue(name, [...(value || []), key]);
    } else {
      const filteredValue = value.filter(I => I !== key);

      setFieldValue(name, filteredValue.length ? filteredValue : null);
    }
  };

  return (
    <Popover2
      placement="bottom-start"
      transitionDuration={0}
      interactionKind="click"
      autoFocus={false}
      portalClassName={styles.portalContent}
      disabled={disabled && value?.length < 2}
      minimal
      usePortal
      lazy
      renderTarget={({
        ref, isOpen, className, onClick, ...props
      }) => (
        <div
          ref={ref}
          className={cx(className, styles.multiselect, {
            [styles.multiselect_disabled]: disabled,
            [styles.multiselect_active]: isOpen,
            [styles.multiselect_hidden]: disabled && !value?.length,
          })}
          style={wrapperStyle}
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
          {...props}
        >
          <div className={styles.value}>
            {firstSelectedItemLabel
              || <span className={styles.placeholder}>{t('PLACEHOLDERS.SERVICES.SELECT_COUNTER_GROUP')}</span>}
          </div>
          {value?.length > 1 && <span className={styles.count}>{`+${value.length - 1}`}</span>}
        </div>
      )}
      content={(
        <div className={styles.overlay}>
          {options.map(I => (
            <CheckboxField
              key={I.value}
              text={I.label}
              value={value?.includes(I.value)}
              onChange={newVal => handleChange(newVal, I.value)}
              classNames={{ input: styles.checkbox }}
              disabled={disabled}
              type="dense"
            />
          ))}
        </div>
      )}
    />
  );
});

export default Multiselect;
