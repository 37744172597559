import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getItemLinksUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => ({
    itemLinks: data,
  }),
  update: {
    itemLinks: (oldValue, newValue) => newValue,
  },
});
