import { sortBy } from 'lodash';

import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getItemsWithDefinitionsUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => {
    const currentDate = new Date();

    return ({
      definitionsList: data.reduce((result, item) => {
        const { dictionaryId, key, definitions } = item;

        if (!result[dictionaryId]) {
          result[dictionaryId] = [];
        }

        const filteredDefinitions = definitions.reduce((acc, def) => {
          // eslint-disable-next-line camelcase
          const { definition, effective_from, effective_till } = def;

          // eslint-disable-next-line camelcase
          const isEffectiveFromValid = !effective_from || new Date(effective_from) <= currentDate;
          // eslint-disable-next-line camelcase
          const isEffectiveTillValid = !effective_till || new Date(effective_till) >= currentDate;

          if (isEffectiveFromValid && isEffectiveTillValid) {
            if (dictionaryId === 3) {
              acc.push({
                value: key,
                label: `${key.slice(0, 3)} ${key.slice(3)}`,
              });
            } else {
              const label = definition?.name_en || definition?.name || key;
              acc.push({ value: key, label });
            }
          }

          return acc;
        }, []);

        result[dictionaryId] = sortBy([...result[dictionaryId], ...filteredDefinitions], ['label']);

        return result;
      }, {}),
    });
  },
  update: {
    definitionsList: (oldValue, newValue) => newValue,
  },
});
