import endpoints from 'consts/endpoints';

export default ({
  limit = 10,
  offset = 0,
  storeKey = 'ownLegalEntities',
  ...queries
} = {}) => ({
  url: endpoints.getLegalEntitiesOwnUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      [storeKey]: resourceList,
      [`${storeKey}Meta`]: other,
    };
  },
  update: {
    [storeKey]: (oldValue, newValue) => newValue,
    [`${storeKey}Meta`]: (oldValue, newValue) => newValue,
  },
});
