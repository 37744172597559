import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import { Dialog as DialogSource, Icon } from '@blueprintjs/core'

import Type from './_type.module.css'
import View from './_view.module.css'

import Typography from '../Typography'
import Color from '../../styles/color.module.css'
import { ThemeContext } from '../ThemeContext'
import { Popover2 } from '@blueprintjs/popover2'
import Menu from '../Menu'

/**
 __Component 'Dialog'__
 * */

const Dialog = React.forwardRef((props, ref) => {
  const {
    type = 'default',
    view = 'filled',
    color = 'default',
    icon,
    title,
    text,
    leftButton,
    rightButton,
    backdropOpacity = '20',
    onClose,
    className,
    stickyHeader,
    iconColor,
    styles = {},
    withCloseButton = true,
    withContextMenu = false,
    contextMenuItems,
    ...restProps
  } = props;

  const [isOpenMenu, setIsOpenMenu] = useToggle(false)

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => (
        <DialogSource
          {...restProps}
          ref={ref}
          className={cx(
            className,
            Type[type],
            isDark ? View[`${view}-dark`] : View[view],
            Color[color],
          )}
          backdropClassName={View[`backdrop-${backdropOpacity}`]}
          portalClassName={Type.portal}
          onClose={onClose}
        >
          <div className={Type.contentWrapper} style={styles.container}>
            <div className={cx(Type.header, stickyHeader ? Type.stickyHeader : '')} style={styles.title}>
              <Icon color={iconColor} icon={icon} size={type === 'dense' ? 20 : 24}/>
              <Typography type={type === 'dense' ? 'h6' : 'h5'} colorStep={100} style={styles.titleFont}>
                {title}
              </Typography>
              <div className={Type['dialog-header-controls']}>
                {
                  contextMenuItems && (
                    <Popover2
                      content={(
                        <Menu className={Type['menu-dropdown']} type="dense" view="raised" color="default">
                          {contextMenuItems}
                        </Menu>
                      )}
                      minimal
                      placement="bottom-end"
                      transitionDuration={0}
                      isOpen={isOpenMenu}
                      interactionKind="click"
                      onClose={() => setIsOpenMenu(false)}
                    >
                      <Icon
                        icon="more"
                        onClick={setIsOpenMenu}
                        className={Type['menu-button']}
                      />
                    </Popover2>
                  )
                }
                {
                  withCloseButton && (
                    <div className={Type['close-button']} onClick={onClose}>
                      <Icon color={iconColor} icon="cross" size={type === 'dense' ? 20 : 24}/>
                    </div>
                  )
                }
              </div>
            </div>
            {text}
            {props.children}
            {leftButton || rightButton ?
              <div className={Type.footer}>
                <div className={Type['left-button']}>{leftButton}</div>
                <div className={Type['right-button']}>{rightButton}</div>
              </div>
              : null}
          </div>
        </DialogSource>
      )}
    </ThemeContext.Consumer>
  );
});

Dialog.propTypes = {
  /**
   `The type of the component.
   * Variants: `dense` `default`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(['default', 'dense']),
  /**
   ` The view of the component.
   * Variants: `smooth` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(['filled', 'smooth', 'raised']),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Name of a icon to render in the dialog's header. Note that the header
   *      will only be rendered if title is provided.
   */
  icon: PropTypes.string,
  /**
   * Title of the dialog.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Action text. Havn't default state.
   */
  text: PropTypes.string,
  /**
   * Opacity of backdrop. From 0 to 100 with step 10.
   */
  backdropOpacity: PropTypes.oneOf([
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
  ]),
  /**
   * Left button of dialog.
   */
  leftButton: PropTypes.element,
  /**
   * Right button of dialog.
   */
  rightButton: PropTypes.element,
  /**
   * Toggles the visibility of the overlay and its children. This prop is required because the component is controlled.
   */
  isOpen: PropTypes.bool,
  /**
   * A callback that is invoked when user interaction causes the overlay to close, such as clicking on the overlay or pressing the esc key.
   */
  onClose: PropTypes.func,
};

export default Dialog;
