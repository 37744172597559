import React, { useState, useRef, useMemo } from 'react';
import { useClickAway } from 'react-use';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/Typography';
import Chips from '@setproduct-ui/core/Chips';

import './style.scss';

const MultiselectField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  label = '',
  styles = {},
  options = [],
  isRequired,
  allowCreate = true,
  translateOptions = true,
  fieldError,
  fieldTouched,
  classNames = {},
  disabled,
  placeholder,
  children,
}) => {
  const { t } = useTranslation();

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [firstBackspace, setFirstBackspace] = useState(false);

  useClickAway(containerRef, () => {
    setFocused(false);
  });

  const isHaveError = useMemo(
    () => (errors[name] && touched[name]) || (fieldError && fieldTouched),
    [errors, touched, fieldError, fieldTouched, name],
  );

  const displayedItems = useMemo(() => {
    const itemsModify = options.filter(I => !value?.includes(I.value || I));

    if (searchValue.trim()) {
      return itemsModify.filter(I => (I.label?.toLowerCase() || I?.toLowerCase())
        ?.includes(searchValue.trim()?.toLowerCase()));
    }

    return itemsModify;
  }, [searchValue, value, options]);

  const multiselectClass = cx('multiselect-field__input', {
    'multiselect-field__input_focused': focused,
    'multiselect-field__input_error': isHaveError,
    'multiselect-field__input_disabled': disabled,
  });

  const labelClass = cx('multiselect-field__label', {
    'multiselect-field__label_required': isRequired,
  });

  const addButtonClass = cx('multiselect-field__add-button', {
    'multiselect-field__add-button_selected': !options.length,
  });

  const onChangeAddValue = (e) => {
    setSearchValue(e.target.value.trim());
  };

  const onBodyClick = () => {
    if (!disabled) {
      if (!focused) {
        setFocused(true);
      }
      inputRef.current.focus();
    }
  };

  const handleChange = (newValue) => {
    if (setFieldValue) {
      setFieldValue(name, newValue);
    } else {
      onChange(newValue);
    }
  };

  const onRemoveTag = (id) => {
    handleChange(value.filter(item => item !== id));
  };

  const onAddKey = (e) => {
    if (
      (e.which === 32 || e.which === 13 || e.which === 188)
      && searchValue.trim()
      && allowCreate
    ) {
      handleChange([...(value || []), searchValue.trim()]);
      setSearchValue('');
      setFirstBackspace(false);
      e.preventDefault();
    } else if (e.which === 8 && !searchValue && firstBackspace) {
      const valueModify = [...(value || [])];
      valueModify.pop();
      handleChange(valueModify);
      setFirstBackspace(false);
      e.preventDefault();
    } else if (e.which === 8 && !searchValue && !firstBackspace) {
      setFirstBackspace(true);
    }
  };
  const onAddOptionClick = (e) => {
    handleChange([...(value || []), searchValue.trim()]);
    setSearchValue('');
    setFirstBackspace(false);
    e.preventDefault();
  };

  const handleOptionClick = (newItem = searchValue) => {
    handleChange([...(value || []), newItem]);
    setSearchValue('');
    setFirstBackspace(false);
  };

  return (
    <div
      role="presentation"
      className={`multiselect-field ${classNames.container}`}
      ref={containerRef}
      onClick={onBodyClick}
      style={styles.container}
    >
      <Typography className={labelClass} style={styles.label}>
        {label}
      </Typography>
      <div
        role="presentation"
        className={multiselectClass}
        style={styles.input}
      >
        <div className="multiselect-field__items">
          {value?.map((item, index) => {
            const optionLabel = options && options.find(I => I.value === item)?.label;
            const isLast = value?.length === index + 1;
            const itemClass = cx('multiselect-field__item', {
              'multiselect-field__item_last': isLast && firstBackspace,
            });

            return (
              <div
                role="presentation"
                className={itemClass}
                key={item?.value || optionLabel}
              >
                <Chips
                  type="dense"
                  color="primary_alt"
                  style={{
                    maxWidth: 350,
                  }}
                  tag={optionLabel || item}
                  removable={!disabled}
                  disabled={disabled}
                  round={false}
                  onRemove={() => onRemoveTag(item || optionLabel)}
                />
              </div>
            );
          })}
          <div
            style={{
              flex: 1,
            }}
          >
            <input
              ref={inputRef}
              placeholder={disabled ? '' : t(placeholder)}
              type="text"
              className="multiselect-field__container-input"
              value={searchValue}
              onChange={onChangeAddValue}
              onKeyDown={onAddKey}
              disabled={disabled}
              style={{
                width: '100%',
              }}
            />
          </div>
        </div>
        <div className="multiselect-field__icon">
          <Icon size={20} color="var(--grey40)" icon="list-columns" />
        </div>
      </div>
      {isHaveError && (
        <div className="multiselect-field__error-helper-text">
          {t(errors[name])}
        </div>
      )}
      {focused && (
        <div className="multiselect-field__overlay" style={styles.overlay}>
          <div className="multiselect-field__overlay-content">
            {displayedItems.map(I => (
              <div
                role="presentation"
                className="multiselect-field__overlay-item"
                title={translateOptions ? t(I.label) : I.label}
                onClick={() => handleOptionClick(I.value)}
                key={I.value}
              >
                {translateOptions ? t(I.label) : I.label}
              </div>
            ))}
          </div>
          {allowCreate && searchValue && (
            <div
              className={addButtonClass}
              role="presentation"
              onClick={onAddOptionClick}
            >
              {t('CONTROLS.REPORTS.ADD', { what: searchValue })}
            </div>
          )}
          {displayedItems.length > 5 && (
            <div className="multiselect-field__overlay-helper">
              {t('CONTROLS.SHOW_RESULTS', { count: displayedItems.length })}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default MultiselectField;
