import React, { useState, useEffect } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { useRerateApi, useDictionariesApi } from 'hooks/api';
import Modal from 'components/Modal';
import { useScreen } from 'hooks';
import { EDR_RERATE } from 'consts/columns';
import ConfirmModal from 'components/modals/ConfirmModal';

import EdrRerateForm from './EdrRerateForm';
import EdrRerateViewModal from './EdrRerateViewModal';

const EdrRerateScreen = ({ screenRef }) => {
  const {
    edrRerateTasks,
    edrRerateTasksMeta,
    getEdrRerateTasks,
    postEdrRerateTask,
    isPendingGetEdrRerateTask,
    isPendingPostEdrRerateTask,
    lastUpdatedGetEdrRerateTask,
  } = useRerateApi();
  const {
    getCompaniesDictionary,
    getUsersDictionary,
    getAgreementsDictionary,
  } = useDictionariesApi();
  const {
    openModal,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isOpenModal,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onConfirmClose,
  } = useScreen({
    screenRef,
    getFunc: getEdrRerateTasks,
    useNewFilters: true,
  });
  const [recordToView, setRecordToView] = useState(null);

  const handleSubmit = (body) => {
    postEdrRerateTask({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const handleRowClicked = ({ data }) => setRecordToView(data);
  const closeViewModal = () => setRecordToView(null);

  useEffect(() => {
    getCompaniesDictionary();
    getUsersDictionary();
    getAgreementsDictionary();
  }, []);

  return (
    <>
      <Table
        limit={limit}
        total={edrRerateTasksMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.EDR_RERATE"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.EDR_RERATE.ADD"
        columnDefs={EDR_RERATE}
        rowData={edrRerateTasks}
        sendQuery={sendQuery}
        isPending={isPendingGetEdrRerateTask || !lastUpdatedGetEdrRerateTask}
        tableRef={tableRef}
        onRowClicked={handleRowClicked}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={EDR_RERATE}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.EDR_RERATE.EDR_RERATE_TASK"
          >
            <EdrRerateForm
              onSubmit={handleSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              changeMode={openModal}
              isPending={isPendingPostEdrRerateTask}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            closeModal={closeConfirm}
            onConfirm={onConfirmClose}
          />
        )
      }
      {
        !!recordToView && (
          <EdrRerateViewModal
            onClose={closeViewModal}
            refreshTableData={sendQuery}
            {...recordToView}
          />
        )
      }
    </>
  );
};

export default EdrRerateScreen;
