import React from 'react';
import cx from 'classnames';
import { useBoolean } from 'react-use';

import { EmptyIcon } from 'assets/icons';

import styles from './ImagePlaceholder.module.scss';

const ImagePlaceholder = ({
  containerStyles = {},
  activityStyles = {},
  containerClassname = {},
  onClick,
  src = '',
  alt = '',
}) => {
  const [isAnimationGoing, setIsAnimationGoing] = useBoolean(!!src);
  const [isError, setIsError] = useBoolean(false);

  const containerClassName = cx(styles.container, {
    [containerClassname]: containerClassname,
    [styles.withoutErrors]: !isAnimationGoing && !isError,
    [styles.withErrors]: isError,
  });

  const onLoaded = () => {
    setIsAnimationGoing(false);
  };
  const onError = (e) => {
    setIsError(e);
    setIsAnimationGoing(false);
  };

  return (
    <div
      role="presentation"
      onClick={onClick}
      style={containerStyles}
      className={containerClassName}
    >
      {isAnimationGoing && (
      <div className={styles.activityContainer}>
        <div style={activityStyles} className={styles.activity} />
      </div>
      )}
      {(isError || !src) ? (
        <div className={styles.emptyIcon}>
          <EmptyIcon
            key={src}
            className={styles.image}
          />
        </div>
      ) : (
        <img
          key={src}
          className={styles.image}
          src={src}
          alt={alt}
          onLoad={onLoaded}
          onError={onError}
        />
      )}
    </div>
  );
};

export default ImagePlaceholder;
