import React from 'react';

import { InnerField, DropdownField } from 'components/fields';

import './style.scss';

export default ({
  options,
  caretOptions,
  dropdownBackgroundColor,
  disableDropdown,
  label,
  textName,
  dropdownName,
  placeholder,
  isView,
  style,
  regex,
  disableInput,
}) => {
  const innerStyle = {
    wrapper: {
      width: 268,
      ...style,
    },
    row: {
      overflow: 'visible',
    },
  };
  const leftFieldStyles = {
    container: {
      borderRadius: ' 4px 0 0 4px',
      margin: 0,
      boxShadow: 'none',
      clipPath: 'inset(0 1px 0 0)',
    },
  };
  const rightFieldStyles = {
    container: {
      width: 105,
    },
    input: {
      borderRadius: '0 4px 4px 0',
      background: dropdownBackgroundColor,
      color: 'var(--grey50)',
      boxShadow: 'none',
      clipPath: 'inset(0 0 0 1px)',
      margin: 0,
    },
  };

  return (
    <InnerField
      name={textName}
      label={label}
      styles={innerStyle}
      placeholder={placeholder}
      disabled={isView || disableInput}
      leftFieldStyles={leftFieldStyles}
      rightFieldStyles={rightFieldStyles}
      rightFieldComponent={DropdownField}
      isRequired={!isView}
      leftProps={{
        showErrorIcon: false,
        regex,
      }}
      rightProps={{
        options,
        caretOptions,
        readOnly: disableDropdown,
        disabled: isView,
        name: dropdownName,
      }}
      bothFields
    />
  );
};
