/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import {Icon} from '@blueprintjs/core'
import SourcesCellRenderer from '../SourcesCellRenderer'

import './style.scss'

export default ({
  value,
  data,
  api,
  node,
}) => {
  const idCount = useRef(0);
  const rowDataExpanded = useRef([]);
  const gridOptionsRef = useRef(node.gridOptionsWrapper.gridOptions)

  useEffect(() => {
    if (gridOptionsRef.current) {
      addIdInData(gridOptionsRef.current.rowData);
    }
  }, [])

  const makeData = (arrayData) => {
    arrayData.map(mainRow => {
      rowDataExpanded.current.push({
        ...mainRow,
        expandedParentRow: mainRow.expanded
      });
      if (mainRow?.details && mainRow.expanded) {
        mainRow.details.map(e => {
          rowDataExpanded.current.push({
            eventType: mainRow.eventType,
            countryName: mainRow.countryName,
            networkName: mainRow.networkName,
            mccMnc: mainRow.mccMnc,
            countryCode: mainRow.countryCode,
            rate: mainRow.rate,
            ...e,
            sources: [e.source],
            expandedChildRow: true
          })
        })
      }
    });
  }

  const addIdInData = (arrayData) => {
    arrayData.map(mainRow => {
      mainRow.customId = idCount.current;
      idCount.current++;
      if (mainRow?.details) {
        addIdInData(mainRow.details)
      }
    });
  }

  const updateData = (customId) => {
    const foundData = gridOptionsRef.current.rowData.find(I => I.customId === customId);
    if (foundData) {
      foundData.expanded = !foundData.expanded;
      makeData(gridOptionsRef.current.rowData);
      api.setRowData(rowDataExpanded.current);
    }
  }

  const handleClick = () => {
    updateData(data.customId)
  }

  return (
      <div
        role="presentation"
        onClick={handleClick}
        className="tree-cell-renderer"
      >
        <div style={{width: 34}}>
          {data?.details && (
            <Icon
              color={data?.expanded ? 'var(--white)' : 'var(--grey40)'}
              style={{
                marginRight: 18
              }}
              className={data?.expanded && 'tree-cell-renderer__collapsed-icon'}
              icon={data?.expanded ? 'chevron-up' : 'chevron-down'}
            />
          )}
        </div>
        {value && (
          <SourcesCellRenderer
            value={value}
          />
        )}
      </div>
  );
};
