import React, { useEffect } from 'react';

import { useClientSmppConnectionsApi, useDictionariesApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { CLIENT_SMPP_CONNECTIONS } from 'consts/columns';
import Modal from 'components/Modal';

import ClientSmppConnectionForm from './ClientSmppConnectionForm';

const SmppConnectionsScreen = ({ screenRef }) => {
  const {
    getClientSmppConnections,
    postClientSmppConnection,
    putClientSmppConnection,
    deleteClientSmppConnection,
    clientSmppConnections,
    clientSmppConnectionsMeta,
    isPendingGetClientSmppConnections,
    isPendingPostClientSmppConnection,
    isPendingPutClientSmppConnection,
    isPendingDeleteClientSmppConnection,
    lastUpdatedGetClientSmppConnections,
  } = useClientSmppConnectionsApi();
  const { getCompaniesDictionary } = useDictionariesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getClientSmppConnections,
    deleteFunc: deleteClientSmppConnection,
    useNewFilters: true,
    onRowClickCallback: data => ({
      ...data,
      enabled: !data.enabled,
    }),
  });

  const onSubmit = (body) => {
    (editableRecord ? putClientSmppConnection : postClientSmppConnection)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
  }, []);

  return (
    <>
      <Table
        tableHeader="TABS.CLIENT_SMPP_CONNECTIONS"
        columnDefs={CLIENT_SMPP_CONNECTIONS}
        rowData={clientSmppConnections}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.CLIENT_SMPP.ADD"
        limit={limit}
        total={clientSmppConnectionsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetClientSmppConnections || !lastUpdatedGetClientSmppConnections}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={CLIENT_SMPP_CONNECTIONS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.CLIENT_SMPP.SMPP_CONNECTION"
            editableRecord={editableRecord}
          >
            <ClientSmppConnectionForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingDeleteClientSmppConnection || isPendingPostClientSmppConnection || isPendingPutClientSmppConnection
              }
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </>
  );
};

export default SmppConnectionsScreen;
