import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { PRINTABLE_FORMS } from 'consts/columns';
import {
  usePrintableFormsApi,
  useDictionariesApi,
} from 'hooks/api';
import { ConfirmModal } from 'components/modals';
import Modal from 'components/Modal';
import { useScreen } from 'hooks';

import PrintableFormForm from './PrintableFormForm';

import './style.scss';

const PrintableFormsScreen = ({
  screenRef,
}) => {
  const {
    ownLegalEntitiesOptions,
    getOwnLegalEntitiesDictionary,
    getResourcesDictionary,
    getServiceDomainsDictionary,
    resourcesOptions,
    serviceDomainsDictionary,
  } = useDictionariesApi();
  const {
    printableForms,
    printableFormsMeta,
    printableFormTypes,
    isPendingGetPrintableForms,
    isPendingPostPrintableForm,
    isPendingPutPrintableForm,
    isPendingDeletePrintableForm,
    lastUpdatedGetPrintableForms,
    getPrintableForms,
    getPrintableFormTypes,
    postPrintableForm,
    putPrintableForm,
    deletePrintableForm,
  } = usePrintableFormsApi();
  const onRowClickCallback = ({
    type, filePath, dateFrom, ...data
  }) => ({
    ...data,
    type: type.id,
    file: filePath,
    dateFrom: dateFrom ? moment(dateFrom).toDate() : undefined,
  });
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getPrintableForms,
    deleteFunc: deletePrintableForm,
    onRowClickCallback,
  });

  const typesOptions = useMemo(() => printableFormTypes.map(({ id, name, ...rest }) => ({
    ...rest,
    value: id,
    label: name,
  })), [printableFormTypes]);
  const resourcesOptionsModify = useMemo(() => orderBy(resourcesOptions.map(option => ({
    label: `${serviceDomainsDictionary[option.serviceDomainId]?.name} - ${option.label}`,
    value: option.value,
  })), ['label']), [resourcesOptions, serviceDomainsDictionary]);

  const onSubmit = ({
    file, type, id, ...values
  }) => {
    const body = new FormData();
    const request = {
      type: {
        id: type,
      },
      ...values,
    };

    body.append('request', new Blob([
      JSON.stringify(request),
    ], {
      type: 'application/json',
    }));
    if (typeof file !== 'string') {
      body.append('file', file);
    }

    (editableRecord ? putPrintableForm : postPrintableForm)({
      id,
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  useEffect(() => {
    getOwnLegalEntitiesDictionary();
    getPrintableFormTypes();
    getResourcesDictionary();
    getServiceDomainsDictionary();
  }, []);

  return (
    <div className="printable-forms-layout">
      <Table
        limit={limit}
        total={printableFormsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.PRINTABLE_FORMS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.PRINTABLE_FORMS.ADD"
        columnDefs={PRINTABLE_FORMS}
        rowData={printableForms}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetPrintableForms || !lastUpdatedGetPrintableForms}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={PRINTABLE_FORMS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.PRINTABLE_FORMS.PRINTABLE_FORM"
            editableRecord={editableRecord}
          >
            <PrintableFormForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              initialValues={editableRecord}
              legalEntitiesOptions={ownLegalEntitiesOptions}
              typesOptions={typesOptions}
              isPending={isPendingPostPrintableForm || isPendingPutPrintableForm || isPendingDeletePrintableForm}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
              resourcesOptions={resourcesOptionsModify}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default PrintableFormsScreen;
