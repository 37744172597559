import React from 'react';
import { Field } from 'formik';
import { Icon } from '@blueprintjs/core';

import { Typography } from 'components/Typography';
import { TextField, TextAreaField, DropdownField } from 'components/fields';
import { PdfIconNew } from 'assets/icons';
import fetchFile from 'helpers/fetchFile';
import endpoints from 'consts/endpoints';
import { SENDER_STATUSES, SMS_VENDOR_TYPES } from 'consts';
import { generateUuid } from 'helpers';

import styles from './SmsContent.module.scss';

import FieldsBlockWrapper from '../FieldsBlockWrapper';

const SmsContent = ({
  initialValues = {},
  senderValues,
  readOnly,
  highlightChanged,
  showTabs,
  companiesOptions,
  generateButtonStyles,
  agreementsOptions,
  values,
  setFieldValue,
  smppConnectionsOptions,
  channelsOptions,
  isTenantCompanySelected,
}) => {
  const textareaFieldStyles = {
    container: {
      width: '100%',
    },
  };
  const fieldStyle = marginTop => ({
    container: {
      width: '100%',
    },
    label: {
      marginTop,
    },
  });
  const onDownload = name => fetchFile({ url: endpoints.getApplicationFileUrl(initialValues.id, name), fileName: name });

  const isDiff = (key) => {
    if (!senderValues) {
      return false;
    }

    if (!senderValues[key] && !initialValues[key]) {
      return !!senderValues[key] !== !!initialValues[key];
    }

    return senderValues[key] !== initialValues[key];
  };

  return (
    ((!readOnly || showTabs) ? (
      <>
        <div className={styles.generalInfo}>
          <Field
            name="companyId"
            component={DropdownField}
            options={companiesOptions}
            styles={fieldStyle(24)}
            label="INSTANCES.COMPANY"
            placeholder="PLACEHOLDERS.SELECT_PARTNER"
            disabled={readOnly}
            isRequired
          />
          <Field
            name="agreementId"
            label="INSTANCES.AGREEMENT"
            component={DropdownField}
            options={agreementsOptions}
            styles={fieldStyle(24)}
            placeholder="PLACEHOLDERS.SELECT"
            disabled={readOnly || !values.companyId}
            isRequired={!isTenantCompanySelected}
          />
          <DropdownField
            options={channelsOptions}
            value={1}
            styles={fieldStyle(24)}
            label="INSTANCES.CHANNEL"
            placeholder="PLACEHOLDERS.SELECT"
            disabled
            isRequired
          />
          <Field
            name="senderStatus"
            component={DropdownField}
            label="INSTANCES.STATUS"
            placeholder="PLACEHOLDERS.SELECT"
            options={SENDER_STATUSES.filter(I => I.value !== 1)}
            styles={fieldStyle(24)}
            disabled={readOnly}
            isRequired
          />
        </div>
        <div className={styles.content}>
          <div className={styles.column}>
            <Field
              name="senderId"
              label="INSTANCES.SENDER_ID"
              component={TextField}
              placeholder="PLACEHOLDERS.SENDER_ID.ENTER_SENDER_ID"
              disabled={readOnly}
              isRequired
              full
            />
            <Field
              name="mcc"
              label="INSTANCES.SENDER_IDS.MCC"
              styles={fieldStyle(24)}
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={readOnly}
              full
            />
            <Field
              name="vendorType"
              label="INSTANCES.VENDOR_TYPE"
              styles={fieldStyle(24)}
              component={DropdownField}
              options={SMS_VENDOR_TYPES}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              isRequired
              disabled={readOnly}
              full
            />
          </div>
          <div className={styles.column}>
            <Field
              name="displayName"
              label="INSTANCES.NAME"
              component={TextField}
              placeholder="PLACEHOLDERS.SENDER_ID.SENDER_ID_NAME"
              isRequired
              disabled={readOnly}
              full
            />
            <Field
              name="webHookGuid"
              component={TextField}
              styles={fieldStyle(24)}
              rightElement={!values.webHookGuid && (
                <Icon
                  icon="new-link"
                  size={20}
                  color="var(--blue70)"
                  style={generateButtonStyles}
                  onClick={() => setFieldValue('webHookGuid', generateUuid())}
                />
              )}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              label="INSTANCES.SENDER_IDS.WEBHOOK_GUID"
              disabled={readOnly}
              full
            />
            <Field
              name="keyword"
              label="INSTANCES.SENDER_IDS.KEYWORD"
              styles={fieldStyle(24)}
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={readOnly}
              full
            />
            {values.vendorType === 'alaris_http_sms' && (
              <Field
                name="login"
                label="INSTANCES.LOGIN"
                styles={fieldStyle(24)}
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={readOnly}
                isRequired
                full
              />
            )}
            {values.vendorType === 'alaris_http_sms' && (
              <Field
                name="password"
                label="INSTANCES.PASSWORD"
                styles={fieldStyle(24)}
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={readOnly}
                isRequired
                full
              />
            )}
            { values.vendorType === 'smpp' && (
              <Field
                name="connectionId"
                label="INSTANCES.SENDER_IDS.CONNECTION"
                styles={fieldStyle(24)}
                component={DropdownField}
                options={smppConnectionsOptions}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={readOnly}
                isRequired
                full
              />
            )}
            {!!values.vendorType && (
              <Field
                name="serviceType"
                label="INSTANCES.SENDER_IDS.SERVICE_TYPE"
                styles={fieldStyle(24)}
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={readOnly}
                full
              />
            )}
          </div>
        </div>
      </>
    ) : (
      <div className={styles.content}>
        <div className={styles.column}>
          <Typography className={styles.subtitle}>
            SCREENS.SENDER_IDS.CHANNELS
          </Typography>
          <DropdownField
            options={channelsOptions}
            value={1}
            label="INSTANCES.CHANNEL"
            placeholder="PLACEHOLDERS.SELECT"
            disabled
            isRequired
          />
          <Typography className={styles.subtitle}>
            SCREENS.SENDER_IDS.BOT_INFORMATION
          </Typography>
          <FieldsBlockWrapper
            title="INSTANCES.SENDER_IDS.SENDER_ID_NAME"
            isRequired
            isDiff={isDiff('displayName')}
            highlightChanged={highlightChanged}
            style={{ marginTop: readOnly ? 0 : 24 }}
            formComponent={(
              <Field
                name="displayName"
                component={TextField}
                placeholder="Alaris Labs"
                color={isDiff('displayName') && readOnly ? 'primary' : 'default'}
                maxLength={40}
                disabled
                withCopyButton
                full
              />
          )}
            hintComponent={(
              <TextField
                value={senderValues?.displayName}
                onChange={Function.prototype}
                withCopyButton
                disabled
                full
              />
          )}
          />
          <FieldsBlockWrapper
            title="INSTANCES.DESCRIPTION"
            isDiff={isDiff('description')}
            highlightChanged={highlightChanged}
            formComponent={(
              <Field
                name="description"
                component={TextAreaField}
                styles={textareaFieldStyles}
                placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
                minRows={2}
                maxLength={100}
                withCopyButton
                disabled
                color={isDiff('description') && readOnly ? 'primary' : 'default'}
                showCharCount
              />
          )}
            hintComponent={(
              <TextAreaField
                value={senderValues?.description}
                onChange={Function.prototype}
                styles={textareaFieldStyles}
                placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
                minRows={2}
                maxLength={100}
                withCopyButton
                disabled
              />
          )}
          />
        </div>
        <div className={styles.column}>
          <Typography className={styles.subtitle}>
            SCREENS.SENDER_IDS.DOCUMENTS
          </Typography>
          <Typography className={styles.label}>
            SCREENS.SENDER_IDS.FILE_FORMATS_AVAILABLE
          </Typography>
          <div className={styles.documents}>
            {
              initialValues?.documents && initialValues?.documents.map(item => (
                <div className={styles.file} key={item}>
                  <PdfIconNew className={styles.fileIcon} />
                  <button type="button" className={styles.fileDownload}>
                    <Icon
                      icon="cloud-download"
                      size={18}
                      onClick={() => onDownload(item)}
                    />
                  </button>
                  <div className={styles.fileInfoWrapper}>
                    <Typography type="caption" className={styles.fileInfoText}>
                      {item?.name || item}
                    </Typography>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    ))

  );
};

export default SmsContent;
