import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Popover2 } from '@blueprintjs/popover2';

import Link from './Link';
import styles from './MenuItem.module.scss';

const MenuItem = ({
  icon,
  label,
  href,
  isCollapsed,
  subLinks,
  activePinnedRoute,
}) => {
  const { location: { pathname } } = useHistory();
  const dispatch = useDispatch();

  const isActiveRoute = useMemo(
    () =>
      href === pathname || subLinks?.some(item => item.href === pathname),
    [pathname, href, subLinks],
  );

  const resetActiveTab = () => {
    dispatch({ type: 'setActiveTab', activeTab: '' });
  };

  return (
    <Popover2
      minimal
      placement="right-start"
      interactionKind="hover"
      transitionDuration={0}
      hoverCloseDelay={100}
      hoverOpenDelay={100}
      usePortal={false}
      content={!!subLinks?.length && (
        <div className={styles.overlay}>
          {subLinks.map(route => (
            <Link
              key={route.href}
              isActive={route.href === pathname}
              href={route.href}
              onClick={resetActiveTab}
              icon={route.icon}
              label={route.label}
              activePinnedRoute={activePinnedRoute}
            />
          ))}
        </div>
      )}
      renderTarget={({
        ref,
        isOpen,
        onFocus,
        ...rest
      }) => (
        <Link
          {...rest}
          isActive={isActiveRoute}
          href={href || subLinks?.[0]?.href}
          onClick={resetActiveTab}
          icon={icon}
          label={label}
          activePinnedRoute={activePinnedRoute}
          isHovered={isOpen}
          hasSubLinks={!!subLinks?.length}
          isCollapsed={isCollapsed}
          innerRef={ref}
        />
      )}
    />
  );
};

export default MenuItem;
