import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckboxField,
  DropdownField,
  TextField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import IsFormChanged from 'components/forms/IsFormChanged';
import { NOTIFICATION_CHANNELS_IDS } from 'consts/channels';
import { EMAIL_NOTIFICATION_PROTOCOLS } from 'consts';
import { NOTIFICATIONS_CHANNELS_SCHEMA } from 'consts/validationSchemas';

const NotificationsChannelsForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = {
    label: {
      marginTop: 24,
    },
  };

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);

  const isView = useMemo(() => mode === 'view', [mode]);

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }
    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  const changeToEdit = () => changeMode('edit');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={NOTIFICATIONS_CHANNELS_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="service-enabled-form">
          <div className="service-enabled-form__content">
            <Field
              name="channelType"
              component={DropdownField}
              label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.TYPE"
              options={NOTIFICATION_CHANNELS_IDS}
              placeholder="PLACEHOLDERS.SELECT"
              disabled
              isRequired
            />
            <Field
              name="name"
              label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.NAME"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              styles={fieldStyle}
              disabled={isView}
              isRequired
              fill
            />
            {values.channelType === 1 && (
            <Field
              name="uri"
              label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.URI"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              styles={fieldStyle}
              disabled={isView}
              isRequired
              fill
            />
            )}
            {values.channelType === 5 && (
            <Field
              name="token"
              label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.TOKEN"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              styles={fieldStyle}
              disabled={isView}
              isRequired
              fill
            />
            )}
            {values.channelType === 6 && (
            <>
              <Field
                name="host"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.HOST"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="port"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.PORT"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                fieldType="number"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="emailSender"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.SENDER"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="emailLogin"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.LOGIN"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="emailPassword"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.PASSWORD"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="sslContextProtocol"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.PROTOCOL"
                component={DropdownField}
                options={EMAIL_NOTIFICATION_PROTOCOLS}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                styles={fieldStyle}
                disabled={isView}
                isRequired
                fill
              />
              <Field
                name="useTls"
                text="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.USE_TLS"
                label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.TLS"
                component={CheckboxField}
                styles={fieldStyle}
                disabled={isView}
                isRequired
              />
            </>
            )}
          </div>
          <FormButtonsGroup
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showDelete={false}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default NotificationsChannelsForm;
