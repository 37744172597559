import endpoints from 'consts/endpoints';

export default ({
  body,
  successCallback,
  errorCallback,
}) => ({
  body,
  url: endpoints.getNewReportUrl(),
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
