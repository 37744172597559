import React from 'react';

import Chips from '@setproduct-ui/core/Chips/Chips';

export default ({
  valueFormatted = {},
}) => {
  if (!valueFormatted.tag) {
    return null;
  }

  return (
    <Chips
      type="dense"
      view="smooth"
      round={false}
      {...valueFormatted}
    />
  );
};
