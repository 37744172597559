import React, { useRef, useEffect } from 'react';

import endpoints from 'consts/endpoints';

import styles from './PortalImageCell.module.scss';

const PortalImageCell = ({
  value,
  data,
}) => {
  const imageRef = useRef();

  useEffect(() => {
    if (value?.logoSquareLight) {
      fetch(endpoints.getPortalFileUrl(data.id, value.logoSquareLight), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('boToken')}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          }

          throw `${response.status} (${response.statusText})`;
        })
        .then((response) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            imageRef.current.src = e.target.result;
          };

          reader.readAsDataURL(response);
        });
    }
  }, []);

  if (!value?.logoSquareLight) {
    return null;
  }

  return (
    <img
      src=""
      alt=""
      ref={imageRef}
      className={styles.image}
    />
  );
};

export default PortalImageCell;
