import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './style.scss';

export default ({
  node, value, instanceKey, data,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
    node.gridOptionsWrapper.gridOptions.isPropagationStopped = true;
    setTimeout(() => {
      node.gridOptionsWrapper.gridOptions.isPropagationStopped = false;
    });
    history.push({
      pathname: `/${instanceKey}-enabled`,
      state: {
        tableFilters: {
          agreementId: {
            values: {
              [data.id]: true,
            },
            type: 'list',
          },
        },
      },
    });
  };

  if (!value) {
    return t(`CONTROLS.AGREEMENTS.NO_${instanceKey.toUpperCase()}_ENABLED`);
  }

  return (
    <div
      className="services-cell-renderer"
      role="presentation"
      onClick={onClick}
    >
      {t(`CONTROLS.AGREEMENTS.${instanceKey.toUpperCase()}_ENABLED`, { count: value })}
    </div>
  );
};
