import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Radio from '@setproduct-ui/core/Radio';
import { Typography } from 'components/Typography';

import './style.scss';

const RadioButtonField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
  } = {},
  disabled,
  options = [],
  label,
  color = 'primary',
  isRequired,
  styles = {},
  type = 'dense',
}) => {
  const { t } = useTranslation();

  const labelClass = cx('radio-button-field__label', {
    'radio-button-field__label_required': isRequired,
  });

  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    } else {
      onChange(val);
    }
  };

  return (
    <div className="radio-button-field" style={styles.container}>
      {
        label && (
          <Typography className={labelClass} style={styles.label}>
            {label}
          </Typography>
        )
      }
      <div className="radio-button-field__options">
        {
          options.map(option => (
            <Radio
              key={option.value}
              checked={option.value === value}
              label={t(option.label)}
              helperText={option.helperText && t(option.helperText)}
              onChange={() => handleChange(option.value)}
              color={color}
              className="radio-button-field__radio"
              disabled={disabled}
              type={type}
              valueKey={option.value}
              name={name}
            />
          ))
        }
      </div>
    </div>
  );
};

export default RadioButtonField;
