import endpoints from 'consts/endpoints';

export default ({
  limit,
  offset,
  saveToRedux = true,
  successCallback = () => {},
  errorCallback = () => {},
  storeKey = 'accounts',
  ...queries
} = {}) => ({
  url: endpoints.getAccountsUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      [storeKey]: resourceList,
      [`${storeKey}Meta`]: other,
    };
  },
  update: {
    [storeKey]: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    [`${storeKey}Meta`]: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
