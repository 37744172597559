import endpoints from 'consts/endpoints';

export default ({
  limit = 10,
  offset = 0,
  ...queries
} = {}) => ({
  url: endpoints.getEdrRerateTasksUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data, meta }) => ({
    edrRerateTasks: data,
    edrRerateTasksMeta: meta,
  }),
  update: {
    edrRerateTasks: (oldValue, newValue) => newValue,
    edrRerateTasksMeta: (oldValue, newValue) => newValue,
  },
});
