import React, { useEffect } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { useScreen } from 'hooks';
import { WHATSAPP_TEMPLATES_COLUMNS } from 'consts/columns';
import { useDictionariesApi, useWhatsAppTemplatesApi } from 'hooks/api';
import Modal from 'components/Modal';

import WhatsAppTemplateForm from './WhatsAppTemplateForm';

const WhatsAppTemplatesScreen = ({
  screenRef,
}) => {
  const {
    getWhatsAppTemplates,
    whatsAppTemplates,
    whatsAppTemplatesMeta,
    isPendingGetTemplates,
    lastUpdatedGetTemplates,
    getWhatsAppLanguages,
    getWhatsAppCategories,
  } = useWhatsAppTemplatesApi();

  const {
    getSendersDictionary,
  } = useDictionariesApi();

  const {
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isOpenModal,
    onCloseModal,
    onRowClicked,
    editableRecord,
  } = useScreen({
    screenRef,
    getFunc: getWhatsAppTemplates,
  });

  useEffect(() => {
    getWhatsAppCategories();
    getWhatsAppLanguages();
    getSendersDictionary();
  }, []);

  return (
    <div>
      <Table
        limit={limit || whatsAppTemplatesMeta.size}
        total={whatsAppTemplatesMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        openCustomColumns={openCustomViewDrawer}
        tableHeader="TABS.WHATSAPP_TEMPLATES"
        columnDefs={WHATSAPP_TEMPLATES_COLUMNS}
        isPending={isPendingGetTemplates || !lastUpdatedGetTemplates}
        rowData={whatsAppTemplates}
        onRowClicked={onRowClicked}
        tableRef={tableRef}
        sendQuery={sendQuery}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={WHATSAPP_TEMPLATES_COLUMNS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="Template"
            editableRecord={{
              id: editableRecord?.id,
              createdAt: editableRecord?.createdAt,
              updatedAt: editableRecord?.updatedAt,
            }}
          >
            <WhatsAppTemplateForm
              initialValues={editableRecord}
            />
          </Modal>
        )
      }
    </div>
  );
};

export default WhatsAppTemplatesScreen;
