import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getSelfRegisterList as getSelfRegisterListApi,
  postSelfRegister as postSelfRegisterApi,
  putSelfRegister as putSelfRegisterApi,
  deleteSelfRegister as deleteSelfRegisterApi,
  getSelfRegisterFile as getSelfRegisterFileApi,
} from 'queries/selfRegister';

import { getArray, getObject, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSelfRegisterList,
      lastUpdated: lastUpdatedGetSelfRegisterList,
    },
    getSelfRegisterList,
  ] = useMutation(getSelfRegisterListApi);

  const [
    { isPending: isPendingPostSelfRegister },
    postSelfRegister,
  ] = useMutation(postSelfRegisterApi);

  const [
    { isPending: isPendingPutSelfRegister },
    putSelfRegister,
  ] = useMutation(putSelfRegisterApi);

  const [
    { isPending: isPendingDeleteSelfRegister },
    deleteSelfRegister,
  ] = useMutation(deleteSelfRegisterApi);

  const [
    { isPending: isPendingGetSelfRegisterFile },
    getSelfRegisterFile,
  ] = useMutation(getSelfRegisterFileApi);

  const selector = useCallback(state => ({
    selfRegisterList: getArray(state, 'selfRegisterList'),
    selfRegisterListMeta: getObject(state, 'selfRegisterListMeta'),
  }), []);

  const data = useISESelector(selector);

  return {
    getSelfRegisterList,
    postSelfRegister,
    putSelfRegister,
    deleteSelfRegister,
    getSelfRegisterFile,
    isPendingGetSelfRegisterList,
    isPendingPostSelfRegister,
    isPendingPutSelfRegister,
    isPendingDeleteSelfRegister,
    isPendingGetSelfRegisterFile,
    lastUpdatedGetSelfRegisterList,
    ...data,
  };
};
