import React from 'react';
import { saveAs } from 'file-saver';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';

import toastRef from 'helpers/toast';

import './style.scss';

const GRBM_REQUIRED_FIELDS = [
  ['client_email', 'email'],
  ['private_key', 'privateKey'],
  ['private_key_id', 'privateKeyId'],
  ['token_uri', 'tokenUrl'],
];

const JSONFileField = ({
  form: { setFieldValue, errors = {}, touched = {} } = {},
  field: {
    value,
    name,
  } = {},
  disabled,
}) => {
  const textClass = cx('json-file-field__text', {
    'json-file-field__text_filled': value,
    'json-file-field__text_error': errors[name] && touched[name],
  });

  const handleChange = (e) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      let parsedResult;

      try {
        parsedResult = JSON.parse(event.target.result);

        const missedValues = GRBM_REQUIRED_FIELDS.reduce((acc, possibleKeys) => {
          if (!possibleKeys.some(possibleKey => parsedResult[possibleKey])) {
            acc.push(possibleKeys.toString().replace(',', ' or '));
          }
          return acc;
        }, []);

        if (missedValues.length) {
          toastRef?.current?.showMessage({
            message: { key: 'TOASTS.SENDER_IDS.MISSED_VALUES', values: { fields: missedValues.toString() } },
            intent: 'danger',
          });
          return;
        }
      } catch (error) {
        toastRef?.current?.showMessage({
          message: 'TOASTS.SENDER_IDS.WRONG_JSON',
          intent: 'danger',
        });
        return;
      }
      setFieldValue(name, parsedResult);
    };
    reader.readAsText(e.target.files[0]);
    e.target.value = '';
  };
  const loadFile = () => {
    saveAs(
      new Blob(
        [JSON.stringify(value)],
        { type: 'text/plain;charset=utf-8' },
      ),
      'access-key.json',
    );
  };
  const deleteFile = () => setFieldValue(name, undefined);

  if (value) {
    return (
      <div className="json-file-field">
        <span className={textClass}>
          File JSON downloaded
        </span>
        <div>
          <Icon
            icon="cloud-download"
            size={20}
            color="var(--blue70)"
            className="json-file-field__button"
            onClick={loadFile}
          />
          {
            !disabled && (
              <Icon
                icon="delete"
                size={20}
                color="var(--red60)"
                className="json-file-field__button"
                onClick={deleteFile}
              />
            )
          }
        </div>
      </div>
    );
  }
  return (
    <label htmlFor={name} className="json-file-field">
      <span className={textClass}>
        No file JSON
      </span>
      <input
        id={name}
        type="file"
        onChange={handleChange}
        accept=".json"
        className="json-file-field__input"
        disabled={value || disabled}
      />
    </label>
  );
};

export default JSONFileField;
