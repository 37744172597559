import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import './i18n';
import './@setproduct-ui/styles/setproduct.css';

import App from './App';
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';
import TokenProvider from './context/TokenProvider';

const getQueries = state => state.queries;

const storeQuery = configureStore();

ReactDOM.render(
  <Provider store={storeQuery}>
    <ReduxQueryProvider queriesSelector={getQueries}>
      <TokenProvider>
        <App />
      </TokenProvider>
    </ReduxQueryProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
