import React, { useState, useEffect } from 'react';
import { Dialog as BlueprintDialog, Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { useServicesApi } from 'hooks/api';
import { useToken } from 'hooks';
import toastRef from 'helpers/toast';
import endpoints from 'consts/endpoints';
import Spinner from '@setproduct-ui/core/Spinner';

import styles from './UploadModal.module.scss';

const ALLOWED_EXTENSIONS = '.xls, .xlsx, .csv';
const UploadModal = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { postRateImport } = useServicesApi();
  const { token } = useToken();
  const [isPending, setIsPending] = useState(false);
  const rateImportConfig = useSelector(state => state.states.rateImportConfig);

  const handleChange = (e) => {
    const file = e.dataTransfer?.files?.[0] || e.target.files[0];

    if (file && ALLOWED_EXTENSIONS.includes(file.name.split('.').at(-1))) {
      // load file
      const form = new FormData();
      const { sourceNode } = rateImportConfig;

      form.append('body', JSON.stringify(sourceNode.id === 'newRow' ? {
        parentId: sourceNode.parentId,
        matchAttributeId: sourceNode.matchAttributeId,
        matchAttributeValues: [sourceNode.matchAttributeValues],
        billingRule: sourceNode?.billingRule,
        quantityRule: sourceNode?.quantityRule,
      } : { id: sourceNode.id }));
      form.append('file', file);

      postRateImport({
        body: form,
        successCallback: ({ taskId }) => {
          const ws = new ReconnectingWebSocket(endpoints.getRateImportSocketUrl(taskId, token));

          ws.onmessage = () => {
            dispatch({ type: 'updateRateImportConfig', taskId });
            setIsPending(false);
            ws.close();
          };
        },
      });
    } else {
      toastRef.current.showMessage({ message: 'Wrong file format' });
    }

    e.target.value = '';
  };

  useEffect(() => {
    const dragHandler = (e) => {
      e.preventDefault();
    };

    window.addEventListener('dragover', dragHandler);
    window.addEventListener('drop', dragHandler);

    return () => {
      window.removeEventListener('dragover', dragHandler);
      window.removeEventListener('drop', dragHandler);
    };
  }, []);

  return (
    <BlueprintDialog
      isOpen={isOpen}
      onClose={onClose}
      backdropClassName={styles.backdrop}
      className={styles.uploadModalWindow}
      canOutsideClickClose
    >
      <label
        htmlFor="fileUpload"
        className={styles.uploadArea}
        onDrop={handleChange}
      >
        {isPending ? <Spinner size={44} color="primary" /> : (
          <Icon
            icon="document"
            size={44}
            color="var(--blue30)"
          />
        )}
        <div className={styles.uploadHint}>
          Drop your files here or
          <span>
            Browse on your computer
          </span>
        </div>
        <span className={styles.acceptFormats}>
          Supports XLS, XLSX and CSV files only
        </span>
        <input
          type="file"
          id="fileUpload"
          accept={ALLOWED_EXTENSIONS}
          onChange={handleChange}
        />
      </label>
    </BlueprintDialog>
  );
};

export default UploadModal;
