import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import en from './translates/en.json';
import ru from './translates/ru.json';
import enBe from './translates/be/en-be.json';
import ruBe from './translates/be/ru-be.json';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'ru'],
    debug: false,
    resources: {
      en: {
        translation: {
          ...en,
          ...enBe,
        },
      },
      ru: {
        translation: {
          ...ru,
          ...ruBe,
        },
      },
    },
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

moment.locale(i18n.language);

export default i18n;
