import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getSmppConnections as getSmppConnectionsApi,
  deleteSmppConnection as deleteSmppConnectionApi,
  putSmppConnection as putSmppConnectionApi,
  postSmppConnection as postSmppConnectionApi,
  getSmppApiConnections as getSmppApiConnectionsApi,
} from 'queries/smppConnections';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSmppConnections,
      lastUpdated: lastUpdatedGetSmppConnections,
    },
    getSmppConnections,
  ] = useMutation(getSmppConnectionsApi);
  const [
    {
      isPending: isPendingDeleteSmppConnection,
    },
    deleteSmppConnection,
  ] = useMutation(deleteSmppConnectionApi);
  const [
    {
      isPending: isPendingPutSmppConnection,
    },
    putSmppConnection,
  ] = useMutation(putSmppConnectionApi);
  const [
    {
      isPending: isPendingPostSmppConnection,
    },
    postSmppConnection,
  ] = useMutation(postSmppConnectionApi);
  const [
    {
      isPending: isPendingGetSmppApiConnections,
    },
    getSmppApiConnections,
  ] = useMutation(getSmppApiConnectionsApi);

  const selector = useCallback(state => ({
    smppConnections: getArray(state, 'smppConnections'),
    smppConnectionsMeta: getObject(state, 'smppConnectionsMeta'),
    smppApiConnections: getArray(state, 'smppApiConnections'),
  }), []);
  const data = useISESelector(selector);

  return {
    getSmppConnections,
    deleteSmppConnection,
    putSmppConnection,
    postSmppConnection,
    getSmppApiConnections,
    isPendingGetSmppConnections,
    isPendingDeleteSmppConnection,
    isPendingPutSmppConnection,
    isPendingPostSmppConnection,
    isPendingGetSmppApiConnections,
    lastUpdatedGetSmppConnections,
    ...data,
  };
};
