import Textarea from 'react-textarea-autosize';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBuilder from 'classnames';
import { Icon } from '@blueprintjs/core';

import { Typography } from 'components/Typography';
import Color from '@setproduct-ui/styles/color.module.css';
import copyInClipboard from 'helpers/copyInClipboard';

import './style.scss';

export const TextAreaField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  placeholder,
  label,
  styles = {},
  classNames = {},
  minRows = 1,
  maxRows = 300,
  disabled,
  maxLength = 524288,
  isRequired,
  newDesign,
  showCharCount = false,
  color,
  withCopyButton,
  fieldError,
  fieldTouched,
  ...props
}) => {
  const { t } = useTranslation();

  const labelClass = classNamesBuilder('text-area-field__label', {
    'text-area-field__label_required': isRequired,
    'text-area-field__label_new-design': newDesign,
  });

  const isHaveError = useMemo(
    () => (errors[name] && touched[name]) || (fieldError && fieldTouched),
    [errors, touched, fieldError, fieldTouched, name],
  );

  const inputClass = classNamesBuilder('text-area-field__input', Color[color], {
    'text-area-field__input_new-design': newDesign,
    'text-area-field__input_error': isHaveError,
    [classNames.input]: classNames.input,
    [`text-area-field__input_${color}`]: color,
  });

  const handleChange = ({ target }) => {
    if (setFieldValue) {
      setFieldValue(name, target.value);
    } else {
      onChange(target.value);
    }
  };

  return (
    <div
      className={`text-area-field ${classNames.container}`}
      style={styles.container}
    >
      <Typography className={`${labelClass} ${classNames.label}`} style={styles.label}>
        {label}
      </Typography>
      <Textarea
        id={name}
        name={name}
        onChange={handleChange}
        value={value || ''}
        placeholder={disabled ? '' : t(placeholder)}
        style={styles.input}
        className={inputClass}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        maxLength={maxLength}
        data-testid={name
          ? `${window.location.pathname}/textarea-field/${name}`
          : undefined}
        {...props}
      />
      {showCharCount && (
        <div className="text-area-field__counter">
          {`${value?.length || 0} / ${maxLength}`}
        </div>
      )}
      {
        withCopyButton && (
          <Icon
            icon="duplicate"
            size={20}
            color="var(--blue70)"
            className="text-area-field__copy-button"
            onClick={() => copyInClipboard(value)}
          />
        )
      }
      {isHaveError && (
        <div className="text-area-field__error-helper-text">
          {t(errors[name])}
        </div>
      )}
    </div>
  );
};
