import React from 'react';

import Button from '@setproduct-ui/core/Button/Button';
import { Typography } from 'components';
import { useUserPermissions } from 'hooks';

import './style.scss';

const CONFIGURATION_REQUIRED_FIELDS = [
  'webHookGuid',
  'vendorType',
  'vendorConfig',
  'login',
  'password',
  'tokenDuration',
];
const PROPERTIES_REQUIRED_FIELDS = [
  'senderId',
  'color',
  'displayName',
];

export default ({
  onLeftButtonClick,
  onCancel,
  setFieldTouched,
  errors,
  isPending,
  leftButtonText,
  rightButtonText,
  onRightButtonClick,
  showControls,
  submitDisabled,
  activeTab,
  setActiveTab,
}) => {
  const {
    editAllowed,
    deleteAllowed,
  } = useUserPermissions();

  const onApplyClick = () => {
    const errorsKeys = Object.keys(errors);
    const propertiesHaveErrors = errorsKeys.some(fieldKey => PROPERTIES_REQUIRED_FIELDS.includes(fieldKey));
    const configurationHaveErrors = errorsKeys.some(fieldKey => CONFIGURATION_REQUIRED_FIELDS.includes(fieldKey));

    onRightButtonClick();
    errorsKeys.map(i => setFieldTouched(i, true));

    if (activeTab === 'display' && !propertiesHaveErrors && configurationHaveErrors) {
      setActiveTab('tech');
    }
    if (activeTab === 'tech' && !configurationHaveErrors && propertiesHaveErrors) {
      setActiveTab('display');
    }
  };

  return (
    <div className="application-form-footer">
      <div className="application-form-footer__buttons">
        {
          leftButtonText && showControls && (
            <Button
              type="default"
              view="outlined"
              color="danger"
              onClick={onLeftButtonClick}
              disabled={isPending || !deleteAllowed}
              title={!deleteAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
            >
              <Typography color="danger" colorStep={50}>
                {leftButtonText}
              </Typography>
            </Button>
          )
        }
        <div className="application-form-footer__right-buttons">
          <Button
            type="default"
            view="outlined"
            color="primary"
            onClick={onCancel}
            style={{ marginRight: showControls ? 24 : 0 }}
          >
            <Typography color="primary" colorStep={50}>
              CONTROLS.CANCEL
            </Typography>
          </Button>
          {
            showControls && (
              <Button
                type="default"
                view="filled"
                color="primary"
                onClick={onApplyClick}
                disabled={isPending || submitDisabled || !editAllowed}
                title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
              >
                <Typography
                  color="primary"
                  colorStep={0}
                >
                  {rightButtonText}
                </Typography>
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
};
