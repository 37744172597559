import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getMccMnc as getMccMncApi,
  deleteMccMnc as deleteMccMncApi,
  postMccMnc as postMccMncApi,
  putMccMnc as putMccMncApi,
  getDialCodes as getDialCodesApi,
  deleteDialCode as deleteDialCodeApi,
  postDialCode as postDialCodeApi,
  putDialCode as putDialCodeApi,
} from 'queries/clients';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetMccMnc,
      lastUpdated: lastUpdatedGetMccMnc,
      isFinished: isFinishedGetMccMnc,
    },
    getMccMnc,
  ] = useMutation(getMccMncApi);
  const [
    {
      isPending: isPendingGetDialCodes,
      lastUpdated: lastUpdatedGetDialCodes,
      isFinished: isFinishedGetDialCodes,
    },
    getDialCodes,
  ] = useMutation(getDialCodesApi);
  const [
    { isPending: isPendingDeleteMccMnc },
    deleteMccMnc,
  ] = useMutation(deleteMccMncApi);
  const [
    { isPending: isPendingDeleteDialCode },
    deleteDialCode,
  ] = useMutation(deleteDialCodeApi);
  const [
    { isPending: isPendingPostMccMnc },
    postMccMnc,
  ] = useMutation(postMccMncApi);
  const [
    { isPending: isPendingPutMccMnc },
    putMccMnc,
  ] = useMutation(putMccMncApi);
  const [
    { isPending: isPendingPostDialCode },
    postDialCode,
  ] = useMutation(postDialCodeApi);
  const [
    { isPending: isPendingPutDialCode },
    putDialCode,
  ] = useMutation(putDialCodeApi);

  const selector = useCallback(state => ({
    mccMnc: getArray(state, 'mccMnc'),
    mccMncMeta: getObject(state, 'mccMncMeta'),
    dialCodes: getArray(state, 'dialCodes'),
    dialCodesMeta: getObject(state, 'dialCodesMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getMccMnc,
    getDialCodes,
    deleteMccMnc,
    deleteDialCode,
    postMccMnc,
    postDialCode,
    putMccMnc,
    putDialCode,
    isPendingGetMccMnc,
    isPendingGetDialCodes,
    isPendingDeleteMccMnc,
    isPendingDeleteDialCode,
    isPendingPostMccMnc,
    isPendingPostDialCode,
    isPendingPutMccMnc,
    isPendingPutDialCode,
    lastUpdatedGetMccMnc,
    lastUpdatedGetDialCodes,
    isFinishedGetMccMnc,
    isFinishedGetDialCodes,
    ...data,
  };
};
