// eslint-disable-next-line import/no-named-default
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tab as DefaultTab } from '@blueprintjs/core/lib/esm/components/tabs/tab';

import DefaultTabs from '@setproduct-ui/core/Tabs';
import SearchInput from '@setproduct-ui/core/Inputs/SearchInput';
import { TreeDropdownField } from 'components/fields';
import { RunReportForm } from 'components/forms';

export default ({
  searchValue,
  setSearchValue,
  displayedList,
  selectedSection,
  setSelectedSection,
  selectedSubsection,
  onSelectSubsection,
  reportParameters,
  initialValues,
  onRunReport,
  onEditReport,
  isPendingPostCreateReport,
  setInternalReports,
  internalReports,
  formValuesRef,
  initialFormValues,
  isTenant,
}) => {
  const { t } = useTranslation();

  return (
    <div className="run-report-block__body">
      <div className="run-report-block__report-sidebar">
        <div className="run-report-block__tabs">
          <DefaultTabs
            type="def"
            view="flat"
            color="primary"
            fill="false"
            id="tabs"
            onChange={setInternalReports}
            selectedTabId={internalReports}
          >
            <DefaultTab
              id="client"
              title={t('SCREENS.REPORTS.CLIENT')}
            />
            <DefaultTab
              id="internal"
              title={t('SCREENS.REPORTS.INTERNAL')}
            />
          </DefaultTabs>
        </div>
        <div className="run-report-block__input">
          <SearchInput
            type="def"
            view="outlined"
            color="default"
            value={searchValue}
            onChange={setSearchValue}
            placeholder={t('PLACEHOLDERS.REPORTS.SEARCH_REPORT')}
            fill
          />
        </div>
        <div className="run-report-block__list">
          {
            displayedList.map(item => (
              <TreeDropdownField
                id={item.id}
                title={item.name}
                subItems={item.reports.map(template => ({
                  id: template.id,
                  title: template.name,
                  description: template.description,
                  public: template.public,
                  generateQuery: template.generateQuery,
                }))}
                isSelected={selectedSection.id === item.id}
                onSelectSection={setSelectedSection}
                selectedSubsection={selectedSubsection.id}
                key={item.id}
                onSelectItem={onSelectSubsection}
              />
            ))
          }
        </div>
      </div>
      <RunReportForm
        title={selectedSubsection?.title}
        description={selectedSubsection?.description}
        selectedSubsectionId={selectedSubsection?.id}
        selectedSubsection={selectedSubsection}
        params={reportParameters}
        initialValues={initialFormValues || initialValues}
        onSubmit={onRunReport}
        onEdit={onEditReport}
        loading={isPendingPostCreateReport}
        formValuesRef={formValuesRef}
        isTenant={isTenant}
      />
    </div>
  );
};
