import endpoints from 'consts/endpoints';

export default ({
  body,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  body,
  url: endpoints.getAgreementUpdateLimitUrl(),
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({
    agreements: response,
  }),
  update: {
    agreements: (oldValue, newValue) => oldValue.map((item) => {
      if (item.id === newValue.id) {
        return {
          ...item,
          creditLimit: newValue.creditLimit,
        };
      }

      return item;
    }),
  },
});
