import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getServiceDomainsUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => ({
    serviceDomainsOptions: data.map(({ id, name, ...values }) => ({
      value: id,
      label: name,
      ...values,
    })),
    serviceDomainsDictionary: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    serviceDomainsOptions: (oldValue, newValue) => newValue,
    serviceDomainsDictionary: (oldValue, newValue) => newValue,
  },
});
