import { useDictionariesApi } from 'hooks/api';

export default ({
  value,
  dictionaryKey,
  fieldKeyToDisplay = 'name',
}) => {
  const dictionaries = useDictionariesApi();

  return dictionaries?.[dictionaryKey]?.[value]?.[fieldKeyToDisplay]
    || (value ? `Missed option: ${value}` : '');
};
