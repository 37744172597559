import endpoints from 'consts/endpoints';

export default ({
  id,
  storeKey = 'report',
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getNewReportUrl(id),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({
    [storeKey]: response.result,
  }),
  update: {
    [storeKey]: (oldValue, newValue) => newValue,
  },
});
