import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getSenderGroups as getSenderGroupsApi,
  postSender as postSenderApi,
  getSenders as getSendersApi,
  putSender as putSenderApi,
  getApplications as getApplicationsApi,
  getSenderById as getSenderByIdApi,
  putApplication as putApplicationApi,
  deleteSender as deleteSenderApi,
  senderDeleteCheck as senderDeleteCheckApi,
} from 'queries/senders';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSenders,
      lastUpdated: lastUpdatedGetSenders,
    },
    getSenders,
  ] = useMutation(getSendersApi);
  const [
    {
      isPending: isPendingGetSenderGroups,
    },
    getSenderGroups,
  ] = useMutation(getSenderGroupsApi);
  const [
    {
      isPending: isPendingPostSender,
    },
    postSender,
  ] = useMutation(postSenderApi);
  const [
    {
      isPending: isPendingPutSender,
    },
    putSender,
  ] = useMutation(putSenderApi);
  const [
    {
      isPending: isPendingGetApplications,
      lastUpdated: lastUpdatedGetApplications,
    },
    getApplications,
  ] = useMutation(getApplicationsApi);
  const [
    {
      isPending: isPendingGetSenderById,
    },
    getSenderById,
  ] = useMutation(getSenderByIdApi);
  const [
    {
      isPending: isPendingPutApplication,
    },
    putApplication,
  ] = useMutation(putApplicationApi);
  const [
    {
      isPending: isPendingDeleteSender,
    },
    deleteSender,
  ] = useMutation(deleteSenderApi);
  const [
    {
      isPending: isPendingSenderDeleteCheck,
    },
    senderDeleteCheck,
  ] = useMutation(senderDeleteCheckApi);

  const selector = useCallback(state => ({
    senderGroups: getArray(state, 'senderGroups'),
    senderGroupsMeta: getObject(state, 'senderGroupsMeta'),
    senders: getArray(state, 'senders'),
    sendersMeta: getObject(state, 'sendersMeta'),
    applications: getArray(state, 'applications'),
    applicationsMeta: getObject(state, 'applicationsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getSenderGroups,
    getSenders,
    postSender,
    putSender,
    getApplications,
    getSenderById,
    putApplication,
    deleteSender,
    senderDeleteCheck,
    isPendingGetSenderGroups,
    isPendingSenderDeleteCheck,
    isPendingPostSender,
    isPendingGetSenders,
    isPendingPutSender,
    isPendingGetApplications,
    isPendingGetSenderById,
    isPendingPutApplication,
    isPendingDeleteSender,
    lastUpdatedGetSenders,
    lastUpdatedGetApplications,
    ...data,
  };
};
