import React from 'react';

import { NotificationSettings } from 'assets/icons';
import * as screens from 'screens';

export default [
  // {
  //   icon: 'people',
  //   href: '/clients',
  //   label: 'TABS.CLIENTS_MANAGEMENT',
  //   Component: screens.ClientsScreen,
  // },
  {
    icon: 'briefcase',
    label: 'TABS.ACCOUNT_MANAGEMENT',
    subLinks: [
      {
        href: '/companies',
        label: 'TABS.COMPANIES',
        Component: screens.CompaniesScreen,
        requiredPermission: 'bo-companies-view',
      },
      {
        href: '/legal-entities',
        label: 'TABS.LEGAL_ENTITIES',
        Component: screens.LegalEntitiesScreen,
        requiredPermission: 'bo-legal-entities-view',
      },
      {
        href: '/agreements',
        label: 'TABS.AGREEMENTS',
        Component: screens.AgreementsScreen,
        requiredPermission: 'bo-agreements-view',
      },
      {
        href: '/services-enabled',
        label: 'TABS.SERVICES_ENABLED',
        Component: screens.ServicesEnabledScreen,
        requiredPermission: 'bo-enabled-services-view',
      },
      {
        href: '/packs-enabled',
        label: 'TABS.PACKS_ENABLED',
        Component: screens.PacksScreen,
        requiredPermission: 'bo-enabled-packs-view',
      },
      {
        href: '/sender-ids',
        label: 'TABS.SENDER_IDS',
        Component: screens.SenderIdsScreen,
        requiredPermission: 'bo-sender-ids-view',
      },
      {
        href: '/waba',
        label: 'TABS.WABA',
        Component: screens.WabaScreen,
        requiredPermission: 'bo-whatsapp-business-account-view',
      },
      {
        href: '/whats-app-templates',
        label: 'TABS.WHATSAPP_TEMPLATES',
        Component: screens.WhatsAppTemplatesScreen,
        requiredPermission: 'bo-whatsapp-templates-view',
      },
      {
        href: '/applications',
        label: 'TABS.APPLICATIONS',
        Component: screens.ApplicationsScreen,
        requiredPermission: 'bo-sender-id-applications-view',
      },
      {
        href: '/printable-forms',
        label: 'TABS.PRINTABLE_FORMS',
        Component: screens.PrintableFormsScreen,
        requiredPermission: 'bo-printable-forms-view',
      },
    ],
  },
  {
    icon: 'badge',
    label: 'TABS.FINANCES',
    subLinks: [
      {
        href: '/payments',
        label: 'TABS.PAYMENTS',
        Component: screens.PaymentsScreen,
        requiredPermission: 'bo-payments-view',
      },
      {
        href: '/invoices',
        label: 'TABS.INVOICES',
        Component: screens.InvoicesScreen,
        requiredPermission: 'bo-invoices-view',
      },
      {
        href: '/services',
        label: 'TABS.SERVICES',
        Component: screens.ServicesScreen,
        requiredPermission: 'bo-services-view',
      },
      {
        href: '/additional-fees',
        label: 'TABS.ADDITIONAL_FEES',
        Component: screens.AdditionalFeesScreen,
        requiredPermission: 'bo-additional-fees-view',
      },
      {
        href: '/edr-rerate',
        label: 'TABS.EDR_RERATE',
        Component: screens.EdrRerateScreen,
        requiredPermission: 'bo-edr-rerate-view',
      },
    ],
  },
  {
    icon: 'learning',
    href: '/mcc-mnc',
    label: 'TABS.MCCMNC',
    Component: screens.MccMncScreen,
    requiredPermission: 'bo-mccmnc-view', // bo-prefixes-view
    isForDevOnly: true,
  },
  {
    icon: 'user',
    label: 'TABS.USERS',
    subLinks: [
      {
        href: '/backoffice-users',
        label: 'TABS.BACKOFFICE_USERS',
        Component: screens.BackOfficeUsersScreen,
        requiredPermission: 'bo-users-backoffice-view',
      },
      {
        href: '/mKit-users',
        label: 'TABS.MKIT_USERS',
        Component: screens.MKitUsersScreen,
        requiredPermission: 'bo-users-mkit-view',
      },
    ],
  },
  {
    icon: 'series-search',
    href: '/reports',
    label: 'TABS.REPORTS_AND_ANALYTICS',
    Component: screens.ReportsScreen,
    requiredPermission: 'bo-reports-view',
  },
  {
    icon: <NotificationSettings width={20} height={20} />,
    href: '/notifications-settings',
    label: 'TABS.NOTIFICATIONS_SETTINGS',
    Component: screens.NotificationsScreens,
    isForTenantOnly: true,
    requiredPermission: 'bo-notifications-settings-view',
  },
  {
    icon: 'cog',
    label: 'TABS.SETTINGS',
    subLinks: [
      {
        href: '/settings',
        label: 'TABS.GENERAL_SETTINGS',
        Component: screens.SettingsScreen,
        isForTenantOnly: true,
        requiredPermission: 'bo-general-settings-view',
      },
      {
        href: '/smpp-connections',
        label: 'TABS.VENDORS_SMPP_CONNECTIONS',
        Component: screens.SmppConnectionsScreen,
        isForTenantOnly: true,
        requiredPermission: 'bo-vendor-smpp-connections-view',
      },
      {
        href: '/client-smpp-connections',
        label: 'TABS.CLIENT_SMPP_CONNECTIONS',
        Component: screens.ClientSmppConnections,
        // isForTenantOnly: true,
        requiredPermission: 'bo-client-smpp-connections-view',
      },
      {
        href: '/http-connections',
        label: 'TABS.HTTP_CONNECTIONS',
        Component: screens.HttpConnectionsScreen,
        isForTenantOnly: true,
        requiredPermission: 'bo-http-connections-view',
      },
      {
        href: '/own-legal-entities/legal-entities',
        path: '/own-legal-entities/:initialTab?',
        label: 'TABS.OWN_LEGAL_ENTITIES',
        Component: screens.OwnLegalEntitiesScreen,
        requiredPermission: 'bo-own-legal-entities-view', // bo-self-registration-settings-view
      },
      {
        href: '/notifications-channels-settings',
        label: 'TABS.NOTIFICATIONS_CHANNELS_SETTINGS',
        Component: screens.NotificationsChannelsScreens,
        isForTenantOnly: true,
        requiredPermission: 'bo-notifications-channels-settings-view',
      },
      {
        href: '/2fa-sms',
        label: 'TABS.2FA_SMS',
        Component: screens.TwoFactorSmsScreen,
        requiredPermission: 'bo-2fa-sms-view',
      },
    ],
  },
  {
    icon: 'manually-entered-data',
    label: 'TABS.AUDIT',
    href: '/audit',
    Component: screens.AuditScreen,
    requiredPermission: 'bo-audit-view',
  },
  {
    icon: 'eye-on',
    label: 'TABS.PRE_MODERATION',
    href: '/pre-moderation',
    Component: screens.PreModerationScreen,
    requiredPermission: 'bo-pre-moderation-view',
  },
  {
    icon: 'projects',
    label: 'TABS.PORTALS',
    href: '/portals',
    Component: screens.PortalsScreen,
    requiredPermission: 'bo-portals-view',
  },
];
