import endpoints from 'consts/endpoints';

export default ({
  ...queries
} = {}) => ({
  url: endpoints.getMnosUrl(),
  body: {
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    mnos: response,
  }),
  update: {
    mnos: (oldValue, newValue) => newValue,
  },
});
