import React from 'react';
import moment from 'moment';

import { Typography } from 'components';

import './style.scss';

const ApplicationModalTitle = ({
  applicationType,
  submitted,
  updated,
  managerUser,
}) => (
  <div className="application-modal-title">
    <Typography type="h5" colorStep={100} className="application-modal-title__title">
      {`SCREENS.APPLICATIONS.${applicationType === 2 ? 'CHANGE' : 'CREATE'}_APPLICATION`}
    </Typography>
    <div className="application-modal-title__cell">
      <span className="application-modal-title__value">
        {managerUser || '-'}
      </span>
      <Typography className="application-modal-title__label">
        INSTANCES.APPLICATIONS.ASSIGNED_TO
      </Typography>
    </div>
    <div className="application-modal-title__cell">
      <span className="application-modal-title__value">
        {moment(submitted).format('DD.MM.YYYY HH:mm')}
      </span>
      <Typography className="application-modal-title__label">
        INSTANCES.CREATED
      </Typography>
    </div>
    <div className="application-modal-title__cell">
      <span className="application-modal-title__value">
        {moment(updated).format('DD.MM.YYYY HH:mm')}
      </span>
      <Typography className="application-modal-title__label">
        INSTANCES.UPDATED
      </Typography>
    </div>
  </div>
);

export default ApplicationModalTitle;
