import React, { useState, useMemo } from 'react';

const initTokenContext = {
  token: localStorage.getItem('boToken'),
  tokenExpiresIn: localStorage.getItem('boTokenExpiresIn'),
  refreshTokenExpiresIn: localStorage.getItem('boRefreshTokenExpiresIn'),
  idToken: localStorage.getItem('boIdToken'),
};

export const TokenContext = React.createContext(initTokenContext);

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(initTokenContext.token);
  const [tokenExpiresIn, setTokenExpiresIn] = useState(initTokenContext.tokenExpiresIn);
  const [refreshTokenExpiresIn, setRefreshTokenExpiresIn] = useState(initTokenContext.refreshTokenExpiresIn);
  const [idToken, setIdToken] = useState(initTokenContext.idToken);

  const onChangeToken = async (newToken) => {
    if (newToken) {
      await localStorage.setItem('boToken', newToken);
    } else {
      await localStorage.removeItem('boToken');
    }
    setToken(newToken);
  };
  const onChangeTokenExpiresIn = async (newTokenExpiresIn) => {
    if (newTokenExpiresIn) {
      await localStorage.setItem('boTokenExpiresIn', newTokenExpiresIn);
    } else {
      await localStorage.removeItem('boTokenExpiresIn');
    }
    setTokenExpiresIn(newTokenExpiresIn);
  };
  const onChangeRefreshTokenExpiresIn = async (newRefreshTokenExpiresIn) => {
    if (newRefreshTokenExpiresIn) {
      await localStorage.setItem('boRefreshTokenExpiresIn', newRefreshTokenExpiresIn);
    } else {
      await localStorage.removeItem('boRefreshTokenExpiresIn');
    }
    setRefreshTokenExpiresIn(newRefreshTokenExpiresIn);
  };
  const onChangeIdToken = async (newIdToken) => {
    if (newIdToken) {
      await localStorage.setItem('boIdToken', newIdToken);
    } else {
      await localStorage.removeItem('boIdToken');
    }
    setIdToken(newIdToken);
  };

  const contextValue = useMemo(() => ({
    token,
    setToken: onChangeToken,
    tokenExpiresIn,
    setTokenExpiresIn: onChangeTokenExpiresIn,
    refreshTokenExpiresIn,
    setRefreshTokenExpiresIn: onChangeRefreshTokenExpiresIn,
    idToken,
    setIdToken: onChangeIdToken,
  }), [token, setToken, tokenExpiresIn, setTokenExpiresIn]);

  return (
    <TokenContext.Provider
      value={contextValue}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
