import React, { useEffect, useMemo, useState } from 'react';
import { PickerPanel } from 'rc-picker';
import generateConfig from 'rc-picker/lib/generate/moment';
import { Icon } from '@blueprintjs/core';
import localeRu from 'rc-picker/lib/locale/ru_RU';
import localeEs from 'rc-picker/lib/locale/es_ES';
import localeEn from 'rc-picker/lib/locale/en_US';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { locale } from 'helpers';

import 'rc-picker/assets/index.css';

import TimePicker from './TimePicker';
import styles from './DateRangePicker.module.scss';

const DateRangePicker = ({
  picker = 'date',
  type = 'datetime',
  step = 0,
  timeFormat,
  secondStep,
  minuteStep,
  rangeValue,
  onChange,
}) => {
  const lang = locale.getLanguage();
  moment.locale(lang, {
    week: {
      dow: 1,
      doy: 7,
    },
  });

  const { t } = useTranslation();

  const pickerProps = {
    prefixCls: 'rc-picker',
    hoverValue: null,
    multiplePanel: true,
    mode: picker,
    prevIcon: <Icon icon="chevron-left" size={20} color="var(--grey20)" htmlTitle={t('TOOLTIPS.STATISTICS.PREVIOUS_MONTH')} />,
    nextIcon: <Icon icon="chevron-right" size={20} color="var(--grey20)" htmlTitle={t('TOOLTIPS.STATISTICS.NEXT_MONTH')} />,
    superPrevIcon: <Icon icon="double-chevron-left" size={20} color="var(--grey20)" htmlTitle={t('TOOLTIPS.STATISTICS.LAST_YEAR')} />,
    superNextIcon: <Icon icon="double-chevron-right" size={20} color="var(--grey20)" htmlTitle={t('TOOLTIPS.STATISTICS.NEXT_YEAR')} />,
  };

  const [startDate, setStartDate] = useState(moment(rangeValue?.from));
  const [endDate, setEndDate] = useState(moment(rangeValue?.to));

  const [startDateH, setStartDateH] = useState(moment(rangeValue?.from));
  const [endDateH, setEndDateH] = useState(moment(rangeValue?.to));

  const [startTime, setStartTime] = useState(moment(rangeValue?.from));
  const [endTime, setEndTime] = useState(moment(rangeValue?.to));

  const currentLocale = useMemo(() => {
    switch (lang) {
      case 'ru':
        return localeRu;
      case 'es':
        return localeEs;
      case 'en':
        return localeEn;
      default:
        return localeEn;
    }
  }, [lang]);

  const extendDate = (value, isStart = true) => {
    const newDate = moment(value);
    switch (picker) {
      case 'week':
        if (isStart) {
          return newDate.startOf('isoWeek');
        }
        return newDate.endOf('isoWeek');
      case 'month':
        if (isStart) {
          return newDate.startOf('month');
        }
        return newDate.endOf('month');
      case 'quarter':
        if (isStart) {
          return newDate.startOf('quarter');
        }
        return newDate.endOf('quarter');
      case 'year':
        if (isStart) {
          return newDate.startOf('year');
        }
        return newDate.endOf('year');
      default:
        return value;
    }
  };

  const disabledStartDate = (current) => {
    if (!endDate) {
      return false;
    }
    return current.isAfter(endDate, 'day');
  };

  const disabledEndDate = (current) => {
    if (!startDate) {
      return false;
    }
    return current.isBefore(startDate, 'day');
  };

  const handleStartDateChange = (value) => {
    const extendedDate = extendDate(value);
    const date = moment(extendedDate).format('DD.MM.YYYY');
    const time = moment(startTime).format('HH:mm:ss');
    const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss').toDate();
    setStartDate(value);
    onChange(prev => ({
      ...prev,
      from: dateTime,
    }));
  };

  const handleEndDateChange = (value) => {
    const extendedDate = extendDate(value, false);
    const date = moment(extendedDate).format('DD.MM.YYYY');
    const time = moment(endTime).format('HH:mm:ss');
    const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss').toDate();
    setEndDate(value);
    onChange(prev => ({
      ...prev,
      to: dateTime,
    }));
  };

  const handleStartDateHover = (value) => {
    setStartDateH(value || startDate);
  };

  const handleEndDateHover = (value) => {
    setEndDateH(value || endDate);
  };

  const handleStartTimeChange = (value) => {
    const date = moment(startDate).format('DD.MM.YYYY');
    const time = moment(value).format('HH:mm:ss');
    const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss').toDate();

    setStartTime(value);
    onChange({
      to: endDate,
      from: dateTime,
    });
  };

  const handleEndTimeChange = (value) => {
    const date = moment(endDate).format('DD.MM.YYYY');
    const time = moment(value).format('HH:mm:ss');
    const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss').toDate();

    setEndTime(value);
    onChange({
      from: startDate,
      to: dateTime,
    });
  };

  useEffect(() => {
    setStartDate(moment(rangeValue?.from));
    setEndDate(moment(rangeValue?.to));

    setStartDateH(moment(rangeValue?.from));
    setEndDateH(moment(rangeValue?.to));

    setStartTime(moment(rangeValue?.from));
    setEndTime(moment(rangeValue?.to));
  }, [rangeValue?.from, rangeValue?.to]);

  return (
    <div className={styles.container}>
      <div className={styles.calendars}>
        <div>
          <PickerPanel
            {...pickerProps}
            hoverRangeValue={[startDateH, endDateH]}
            generateConfig={generateConfig}
            locale={currentLocale}
            value={startDate}
            onSelect={handleStartDateChange}
            onHover={handleStartDateHover}
            maxDate={endDate}
            disabledDate={disabledStartDate}
          />
          {type === 'datetime' && (
            <TimePicker
              step={step}
              format={timeFormat}
              secondStep={secondStep}
              minuteStep={minuteStep}
              value={startTime}
              onChange={handleStartTimeChange}
            />
          )}
        </div>
        <div>
          <PickerPanel
            {...pickerProps}
            hoverRangeValue={[startDateH, endDateH]}
            generateConfig={generateConfig}
            locale={currentLocale}
            value={endDate}
            onSelect={handleEndDateChange}
            onHover={handleEndDateHover}
            minDate={startDate}
            disabledDate={disabledEndDate}
          />
          {type === 'datetime' && (
            <TimePicker
              step={step}
              format={timeFormat}
              secondStep={secondStep}
              minuteStep={minuteStep}
              value={endTime}
              onChange={handleEndTimeChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
