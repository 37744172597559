import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchInput from '@setproduct-ui/core/Inputs/SearchInput';

import './style.scss';

export const MaskField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: { setFieldValue = externalSetFieldValue } = {},
  placeholder = 'Input mask',
  fill = false,
  styles = {},
  fieldType = 'def',
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    } else {
      onChange(val);
    }
  };

  return (
    <SearchInput
      type={fieldType}
      view="outlined"
      color="default"
      name={name}
      placeholder={t(placeholder)}
      fill={fill}
      value={value}
      style={styles.container}
      onChange={handleChange}
      onSearch={handleChange}
      data-testid={name
        ? `${window.location.pathname}/mask-field/${name}`
        : undefined}
      {...props}
    />
  );
};
