import endpoints from 'consts/endpoints';

export default ({
  type = 'timeline',
  entityName = 'timeline',
  paramIntegrationId,
  paramPeriodStart,
  paramPeriodEnd,
  paramCompanyId,
  paramChannelType,
  paramSenderId,
  paramTz,
  paramGranularity,
  errorCallback = Function.prototype,
  successCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getIntegrationStatisticsUrl(type),
  body: {
    param_integration_id: paramIntegrationId,
    param_period_start: paramPeriodStart,
    param_period_end: paramPeriodEnd,
    param_tz: paramTz,
    param_granularity: paramGranularity,
    param_company_id: paramCompanyId,
    param_channel_type: paramChannelType,
    param_sender_id: paramSenderId,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    errorCallback,
    successCallback,
  },
  transform: response => ({
    [`${entityName}IntegrationsStatistic`]: response.data,
    [`${entityName}IntegrationsStatisticMeta`]: response.meta,
  }),
  update: {
    [`${entityName}IntegrationsStatistic`]: (oldValue, newValue) => newValue,
    [`${entityName}IntegrationsStatisticMeta`]: (oldValue, newValue) => newValue,
  },
});
