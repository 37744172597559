import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';

import changeState from './changeState';

export default combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  states: changeState,
});
