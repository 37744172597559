import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default ({ field, onChange }) => {
  const { values, ...form } = useFormikContext();
  useEffect(() => {
    onChange({
      value: values[field],
      values,
      form,
      field,
    });
  }, [values[field]]);

  return null;
};
