import { DEFAULT_LANGUAGES_OPTIONS, LANGUAGES_DICTIONARY } from 'consts';

import switchRoutes from '../routes/switchRoutes';

const rateImportConfigInitialValues = {
  isDropFileOpen: false,
  isImportModalOpen: false,
  sourceNode: null,
  dependsOptions: null,
};
const initialState = {
  sidebarShow: 'responsive',
  darkMode: false,
  routes: {
    activeTab: '',
    openTabs: {},
  },
  rateImportConfig: rateImportConfigInitialValues,
  languagesOptions: DEFAULT_LANGUAGES_OPTIONS,
};

export default (state = initialState, { type, ...rest }) => {
  const { openTabs, activeTab } = state.routes;

  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'saveRoute': {
      const foundRoute = switchRoutes.find(I => I.path === window.location.pathname.replace('/bo', ''));
      const directory = foundRoute.path.replace('/', '') || 'companies';
      const tabKey = `${directory} ${rest?.tabKey || (openTabs[directory]?.length || 0) + 1 || 1}`;

      if (activeTab) {
        const tabData = openTabs[directory].find(I => I.key === activeTab);
        const tabIndex = openTabs[directory].findIndex(I => I.key === activeTab);

        const openTabsModify = { ...openTabs };
        openTabsModify[directory][tabIndex] = {
          ...tabData,
          ...rest,
        };

        return {
          ...state,
          routes: {
            ...state.routes,
            openTabs: { ...openTabsModify },
          },
        };
      }

      return {
        ...state,
        routes: {
          activeTab: tabKey,
          openTabs: {
            ...openTabs,
            [directory]: openTabs[directory] ? [
              {
                name: foundRoute.name,
                key: tabKey,
                ...rest,
              },
              ...openTabs[directory],
            ] : [
              {
                name: foundRoute.name,
                key: tabKey,
                ...rest,
              },
            ],
          },
        },
      };
    }
    case 'deleteDirectory': {
      delete openTabs[rest.key];

      return {
        ...state,
        routes: {
          activeTab: activeTab.includes(rest.key) ? '' : activeTab,
          openTabs,
        },
      };
    }
    case 'deleteRoute': {
      const directory = rest.key.substring(0, rest.key.indexOf(' '));
      const directoryItems = [...openTabs[directory].filter(I => I.key !== rest.key)];

      if (!directoryItems.length) {
        delete openTabs[directory];
      } else {
        openTabs[directory] = [...directoryItems];
      }
      return {
        ...state,
        routes: {
          activeTab: activeTab === rest.key ? '' : activeTab,
          openTabs: {
            ...openTabs,
          },
        },
      };
    }
    case 'setActiveTab':
      return {
        ...state,
        routes: {
          ...state.routes,
          activeTab: rest.activeTab,
        },
      };
    case 'startRateImport':
      return {
        ...state,
        rateImportConfig: {
          isDropFileOpen: true,
          isImportModalOpen: false,
          sourceNode: rest.sourceNode,
          dependsOptions: rest.dependsOptions,
          serviceType: rest.serviceType,
          serviceCurrency: rest.serviceCurrency,
          parentInfoRules: rest.parentInfoRules,
        },
      };
    case 'resetRateImportConfig':
      return {
        ...state,
        rateImportConfig: rateImportConfigInitialValues,
      };
    case 'updateRateImportConfig':
      return {
        ...state,
        rateImportConfig: {
          ...state.rateImportConfig,
          isDropFileOpen: false,
          isImportModalOpen: true,
          taskId: rest.taskId,
        },
      };
    case 'setAvailableLanguages':
      return {
        ...state,
        languagesOptions: rest?.languages?.length
          ? rest.languages.map(languageKey => ({
            value: languageKey,
            label: LANGUAGES_DICTIONARY[languageKey],
          }))
          : DEFAULT_LANGUAGES_OPTIONS,
      };
    default:
      return state;
  }
};
