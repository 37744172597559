import React, {
  useEffect, useMemo,
} from 'react';

import { CustomColumnsView } from 'components/blocks';
import Table from 'components/Table';
import { Modal } from 'components';
import { ConfirmModal } from 'components/modals';
import { MKIT_USERS } from 'consts/columns';
import {
  useAccountsApi,
  useDictionariesApi,
  useUsersApi,
} from 'hooks/api';
import { UsersForm } from 'components/forms';
import {
  MKITUSERS_SCHEMA,
  PASSWORD_SCHEMA,
  PASSWORD_LENGTH_SCHEMA,
} from 'consts/validationSchemas';
import { useScreen } from 'hooks';

import getGroupedRoles from '../getGroupedRoles';

export default ({ screenRef }) => {
  // hooks
  const { getRoles, mkitRoles, isPendingGetRoles } = useAccountsApi();
  const {
    countries,
    timezones,
    companiesOptionsWithOwn,
    getCompaniesDictionary,
  } = useDictionariesApi();
  const {
    mkitUsers,
    mkitUsersMeta,
    getUsers,
    deleteUser,
    postUser,
    putUser,
    putUpdateUserPassword,
    isPendingDeleteUser,
    isPendingPostUser,
    isPendingPutUser,
    isPendingGetUsers,
    isPendingPutUpdateUserPassword,
    lastUpdatedGetUsers,
  } = useUsersApi();
  const getUsersQuery = (queries) => {
    getUsers({
      group: 'mkit',
      ...queries,
    });
  };
  const onRowClickCallback = data => data;
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    setEditableRecord,
  } = useScreen({
    screenRef,
    getFunc: getUsersQuery,
    deleteFunc: params => deleteUser({ ...params, group: 'mkit' }),
    onRowClickCallback,
  });

  // memed vars
  const countryOptions = useMemo(
    () =>
      countries.map(item => ({
        value: item.value,
        label: item.label,
      })),
    [countries],
  );
  const groupedRoles = useMemo(() => getGroupedRoles(mkitRoles), [mkitRoles]);

  // functions
  const onSubmit = ({
    password,
    rePassword,
    ...values
  }) => (editableRecord ? putUser : postUser)({
    body: {
      ...values,
      group: 'mkit',
    },
    successCallback: (res) => {
      sendQuery();

      if (password) {
        putUpdateUserPassword({
          id: res.id,
          group: 'mkit',
          body: {
            password,
          },
          successCallback: () => onCloseModal({ isSave: true }),
          errorCallback: () => setEditableRecord(res),
        });
      } else {
        onCloseModal({ isSave: true });
      }
    },
  });

  // effects
  useEffect(() => {
    getCompaniesDictionary();
    getRoles({ group: 'mkit' });
  }, []);

  const validationSchema = isOpenModal === 'add'
    ? MKITUSERS_SCHEMA.concat(PASSWORD_SCHEMA)
    : MKITUSERS_SCHEMA.concat(PASSWORD_LENGTH_SCHEMA);

  return (
    <div>
      <Table
        limit={limit}
        total={mkitUsersMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.MKIT_USERS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.USERS.ADD"
        columnDefs={MKIT_USERS}
        rowData={mkitUsers}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetUsers || !lastUpdatedGetUsers}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={MKIT_USERS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenModal && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="SCREENS.USERS.USER"
          editableRecord={editableRecord}
        >
          <UsersForm
            mode={isOpenModal}
            onCancel={onCloseModal}
            initialValues={editableRecord}
            changeMode={openModal}
            onDelete={onDeleteClick}
            onSubmit={onSubmit}
            isPending={
              isPendingDeleteUser
              || isPendingPostUser
              || isPendingPutUser
              || isPendingPutUpdateUserPassword
            }
            isPendingGetRoles={isPendingGetRoles}
            partnersOptions={companiesOptionsWithOwn}
            countriesOptions={countryOptions}
            timezones={timezones}
            roles={groupedRoles}
            isFormPristine={isFormPristine}
            validationSchema={validationSchema}
            formValuesRef={formValuesRef}
            group="mkit"
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
            isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
          }
        />
      )}
    </div>
  );
};
