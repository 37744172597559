import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { useFeeRulesApi } from 'hooks/api';
import {
  RadioButtonField,
  DatePickerField,
  TextField,
  DropdownField,
  MultiselectField,
} from 'components/fields';
import Button from '@setproduct-ui/core/Button';
import RecordInfo from 'components/Modal/RecordInfo';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import OnFormChange from 'components/forms/OnFormChange';
import FormButtonsGroup from 'components/blocks/FormButtonsGroup';
import ChargePrice from 'components/blocks/ChargePrice';
import { BILLING_DAY, BILLING_PERIOD_DAYS, SENDER_ID_TYPES } from 'consts';
import { PERIODIC_FEE_SCHEMA } from 'consts/validationSchemas';
import { numbers } from 'consts/inputPatterns';
import { round } from 'helpers';

import HistoryItem from './HistoryItem';

import './style.scss';

const PeriodicFee = ({
  id,
  channel,
  onCancel,
}) => {
  const formButtonsStyles = {
    container: {
      marginTop: 'auto',
      padding: 0,
    },
  };
  const fieldStyle = ({ marginTop, ...styles }) => ({
    container: {
      ...(!!styles && styles),
    },
    label: {
      marginTop,
    },
  });

  const { t } = useTranslation();
  const {
    feeRules,
    mnos,
    getFeeRules,
    postFeeRule,
    putFeeRule,
    deleteFeeRule,
    getMnos,
    getFeePrice,
    isPendingPostFeeRule,
    isPendingPutFeeRule,
  } = useFeeRulesApi();
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState();

  const modifyFeeRules = useMemo(() => feeRules.map(({ effectiveFrom, effectiveTill, ...item }) => ({
    ...item,
    effectiveFrom: new Date(effectiveFrom),
    effectiveTill: effectiveTill === '0001-01-01' ? null : new Date(effectiveTill),
  })), [feeRules]);
  const mnosOptions = useMemo(() => mnos.map(mno => ({
    value: mno.id,
    label: mno.name,
  })), [mnos]);

  const onItemClick = ({ settings, ...values }) => {
    setPrice(undefined);
    setSelectedItem({
      ...values,
      ...JSON.parse(settings),
    });
  };
  const onAddItem = () => {
    setSelectedItem({});
    setPrice(undefined);
  };
  const onChangePeriodicity = ({ form, values, value }) => {
    if (values.periodStartDay > 7 && value === 7) {
      form.setFieldValue('periodStartDay', undefined);
    }
  };
  const onSubmit = ({
    periodStartDay,
    daysInAdvance,
    retryInterval,
    maxRetries,
    senderIdType,
    mno,
    effectiveFrom,
    effectiveTill,
    ...body
  }) => {
    (isEmpty(selectedItem) ? postFeeRule : putFeeRule)({
      body: {
        senderId: id,
        periodStartDay: +periodStartDay,
        daysInAdvance: +daysInAdvance,
        retryInterval: +retryInterval,
        maxRetries: +maxRetries,
        effectiveFrom: moment(effectiveFrom).format('YYYY-MM-DD'),
        effectiveTill: effectiveTill ? moment(effectiveTill).format('YYYY-MM-DD') : effectiveTill,
        settings: JSON.stringify(channel === 1 ? {
          senderIdType,
          mno,
        } : {}),
        ...body,
      },
      successCallback: () => {
        setSelectedItem(null);
        getFeeRules({ senderId: id });
      },
    });
  };
  const onDeleteClick = () => {
    deleteFeeRule({
      id: selectedItem.id,
      successCallback: () => {
        setSelectedItem(null);
        getFeeRules({ senderId: id });
      },
    });
  };
  const onChangeFormValues = (values) => {
    if (values.effectiveFrom && values.periodicity && values.periodStartDay && values.daysInAdvance) {
      getFeePrice({
        feeType: 'rules',
        params: {
          senderId: id,
          effectiveFrom: moment(values.effectiveFrom).format('YYYY-MM-DD'),
          periodicity: values.periodicity,
          periodStartDay: values.periodStartDay,
          daysInAdvance: values.daysInAdvance,
          effectiveTill: values.effectiveTill
            ? moment(values.effectiveFrom).format('YYYY-MM-DD')
            : undefined,
          senderIdType: values.senderIdType,
          mno: values.mno,
        },
        successCallback: (res) => {
          setPrice({
            price: `${round({ num: res.value })} ${res.agreementCurrency}`,
            service: res.serviceName,
            date: `Effective: ${moment(res.effectiveFrom).format('DD.MM.YYYY HH:mm:ss')}${res.effectiveTill
              ? `- ${moment(res.effectiveTill).format('DD.MM.YYYY HH:mm:ss')}` : ''}`,
          });
        },
        errorCallback: ({ data } = {}) => {
          setPrice({ errorMessage: data?.message });
        },
      });
    }
  };

  useEffect(() => {
    getFeeRules({ senderId: id });

    if (channel === 1) {
      getMnos();
    }
  }, [id, channel]);

  return (
    <div className="periodic-fee">
      <div className="periodic-fee__shadow" />
      <div className="periodic-fee__history-list">
        {
          modifyFeeRules.map(item => (
            <HistoryItem
              key={item.id}
              onClick={() => onItemClick(item)}
              isActive={item.id === selectedItem?.id}
              {...item}
            />
          ))
        }
        <Button
          onClick={onAddItem}
          icon="plus"
          text={t('CONTROLS.ADD')}
          view="flat"
          color="primary"
        />
      </div>
      {
        !!selectedItem && (
          <div className="periodic-fee__content">
            {
              !isEmpty(selectedItem) && (
                <RecordInfo
                  {...selectedItem}
                  style={{ marginBottom: 32 }}
                />
              )
            }
            <Formik
              initialValues={isEmpty(selectedItem) ? {
                periodStartDay: 1,
                daysInAdvance: 1,
                retryInterval: 1,
                periodicity: 30,
                effectiveTill: null,
              } : selectedItem}
              onSubmit={onSubmit}
              validationSchema={PERIODIC_FEE_SCHEMA}
              validateOnMount
              enableReinitialize
            >
              {({
                values,
                errors,
                setFieldTouched,
                handleSubmit,
              }) => (
                <Form className="periodic-fee__form">
                  <div className="periodic-fee__row">
                    <Field
                      name="effectiveFrom"
                      label="INSTANCES.EFFECTIVE_FROM"
                      component={DatePickerField}
                      styles={fieldStyle({ width: '100%', marginRight: 12 })}
                      maxDate={values.effectiveTill}
                      format="DD.MM.YYYY"
                      isRequired
                    />
                    <Field
                      name="effectiveTill"
                      label="INSTANCES.EFFECTIVE_TILL"
                      component={DatePickerField}
                      styles={fieldStyle({ width: '100%', marginLeft: 12 })}
                      minDate={values.effectiveFrom}
                      format="DD.MM.YYYY"
                      withIndefinitely
                    />
                  </div>
                  <div className="periodic-fee__divider" />
                  <div className="periodic-fee__row">
                    <div className="periodic-fee__col">
                      <span className="periodic-fee__subtitle">
                        {t('SCREENS.SENDER_IDS.PERIOD_PROPERTIES')}
                      </span>
                      <Field
                        name="periodicity"
                        label="INSTANCES.SENDER_IDS.CHARGE_EVERY"
                        component={RadioButtonField}
                        options={[
                          {
                            value: 30,
                            label: 'month',
                          },
                          {
                            value: 7,
                            label: 'week',
                          },
                        ]}
                      />
                      <Field
                        name="periodStartDay"
                        label="INSTANCES.SENDER_IDS.START_DAY"
                        component={DropdownField}
                        options={values.periodicity === 7 ? BILLING_DAY : BILLING_PERIOD_DAYS}
                        styles={fieldStyle({ width: 124, marginTop: 16 })}
                        disabled={!values.periodicity}
                      />
                    </div>
                    <div className="periodic-fee__col" style={{ marginTop: 46, marginRight: 24 }}>
                      <Field
                        name="daysInAdvance"
                        component={TextField}
                        regex={numbers({ withMinus: true })}
                        styles={fieldStyle({ width: 130 })}
                        label="INSTANCES.SENDER_IDS.CHARGE_BEFOREHAND"
                        clearButton={false}
                      />
                    </div>
                    <div className="periodic-fee__col">
                      <span className="periodic-fee__subtitle">
                        {t('SCREENS.SENDER_IDS.ON_FAILURE')}
                      </span>
                      <div className="periodic-fee__col">
                        <Field
                          name="retryInterval"
                          component={TextField}
                          regex={numbers()}
                          styles={fieldStyle({ width: 130 })}
                          label="INSTANCES.SENDER_IDS.RETRY_EVERY"
                          clearButton={false}
                        />
                        <Field
                          name="maxRetries"
                          component={TextField}
                          regex={numbers()}
                          styles={fieldStyle({ marginTop: 16, width: 130 })}
                          label="INSTANCES.SENDER_IDS.MAX_RETRIES"
                          clearButton={false}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    channel === 1 && (
                      <>
                        <div className="periodic-fee__divider" />
                        <span className="periodic-fee__subtitle">
                          {t('SCREENS.SENDER_IDS.PROPERTIES')}
                        </span>
                        <div className="periodic-fee__row">
                          <Field
                            name="senderIdType"
                            label="INSTANCES.SENDER_IDS.SENDER_ID_TYPE"
                            component={RadioButtonField}
                            options={SENDER_ID_TYPES}
                            styles={fieldStyle({ width: '100%' })}
                          />
                          <Field
                            name="mno"
                            label="INSTANCES.SENDER_IDS.MNO"
                            component={MultiselectField}
                            styles={fieldStyle({ width: '100%', marginLeft: 12 })}
                            options={mnosOptions}
                            allowCreate={false}
                          />
                        </div>
                      </>
                    )
                  }
                  <ChargePrice {...(price && price)} />
                  <OnChangeComponent field="periodicity" onChange={onChangePeriodicity} />
                  <OnFormChange callback={onChangeFormValues} />
                  <FormButtonsGroup
                    onCancel={onCancel}
                    onApply={handleSubmit}
                    onDelete={onDeleteClick}
                    errors={errors}
                    setFieldTouched={setFieldTouched}
                    showDelete={!!selectedItem?.id}
                    styles={formButtonsStyles}
                    isPending={isPendingPostFeeRule || isPendingPutFeeRule}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )
      }
    </div>
  );
};

export default PeriodicFee;
