import React, { useEffect } from 'react';

import Table from 'components/Table';
import Modal from 'components/Modal';
import { ConfirmModal } from 'components/modals';
import { useScreen } from 'hooks';
import { useCompaniesApi, useDictionariesApi } from 'hooks/api';
import { COMPANIES } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';

import CompanyForm from './CompanyForm';

import './style.scss';

const CompaniesScreen = ({ screenRef }) => {
  // hooks
  const {
    companies,
    companiesMeta,
    getCompanies,
    postCompany,
    putCompany,
    deleteCompany,
    isPendingPostCompany,
    isPendingPutCompany,
    isPendingDeleteCompany,
    isPendingGetCompanies,
    lastUpdatedGetCompanies,
  } = useCompaniesApi();
  const { getOwnLegalEntitiesDictionary } = useDictionariesApi();
  const onRowClickCallback = data => ({ ...data });
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getCompanies,
    deleteFunc: deleteCompany,
    onRowClickCallback,
  });
  // functions
  const onSubmit = (body) => {
    (editableRecord ? putCompany : postCompany)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getOwnLegalEntitiesDictionary();
  }, []);

  return (
    <div className="companies-layout">
      <Table
        limit={limit}
        total={companiesMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.COMPANIES"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.COMPANIES.ADD"
        columnDefs={COMPANIES}
        rowData={companies}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetCompanies || !lastUpdatedGetCompanies}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={COMPANIES}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenModal && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="INSTANCES.COMPANY"
          editableRecord={editableRecord}
        >
          <CompanyForm
            mode={isOpenModal}
            onCancel={onCloseModal}
            initialValues={editableRecord}
            changeMode={openModal}
            onDelete={onDeleteClick}
            onSubmit={onSubmit}
            isFormPristine={isFormPristine}
            isPending={
              isPendingPostCompany
              || isPendingPutCompany
              || isPendingDeleteCompany
            }
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
            isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
          }
        />
      )}
    </div>
  );
};

export default CompaniesScreen;
