import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { FormButtonsGroup } from 'components/blocks';
import { PREFIXES_SCHEMA } from 'consts/validationSchemas';
import {
  TextField,
  TextAreaField,
  DatePickerField,
} from 'components/fields';
import { inputPatterns } from 'consts';
import { useContentShadow } from 'hooks';

import IsFormChanged from '../IsFormChanged';

import './style.scss';

const PrefixesForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = ({
    width, marginTop, marginRight,
  } = {}) => ({
    container: {
      width,
      marginRight,
    },
    label: {
      marginTop,
    },
  });
  const formButtonsStyles = {
    container: {
      paddingTop: 28,
    },
  };

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          prefixFormValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PREFIXES_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="prefixes-form">
          {showTopShadow && <div className="prefixes-form__shadow" />}
          <div className="prefixes-form__content" ref={contentRef}>
            <div className="prefixes-form__row">
              <Field
                name="mccMnc"
                label="INSTANCES.MCCMNC"
                component={TextField}
                styles={fieldStyle({ width: 268 })}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                isRequired={!isView}
                regex={inputPatterns.numbers()}
              />
              <Field
                name="dialCode"
                label="INSTANCES.PREFIXES.PREFIX"
                component={TextField}
                styles={fieldStyle({ width: 268 })}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                isRequired={!isView}
              />
            </div>
            <div className="prefixes-form__row mt24">
              <Field
                name="startDate"
                label="INSTANCES.START_DATE"
                component={DatePickerField}
                styles={fieldStyle({ width: 268, marginRight: 'auto' })}
                disabled={isView}
                maxDate={values?.endDate}
              />
              <Field
                name="endDate"
                label="INSTANCES.END_DATE"
                component={DatePickerField}
                styles={fieldStyle({ width: 268 })}
                disabled={isView}
                minDate={values?.startDate}
              />
            </div>
            <Field
              name="description"
              label="INSTANCES.DESCRIPTION"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              minRows={2}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              disabled={isView}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            styles={formButtonsStyles}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};
export default PrefixesForm;
