import ReconnectingWebSocket from 'reconnecting-websocket';
import { useMutation } from 'redux-query-react';
import { useEffect, useCallback } from 'react';

import { useToken } from 'hooks';
import { getCompanies as getCompaniesApi } from 'queries/companies';
import endpoints from 'consts/endpoints';
import { getAccounts as getAccountsApi } from 'queries/accounts';
import { getAgreements as getAgreementsApi } from 'queries/agreements';

let ws;

export default () => {
  const [, getAccounts] = useMutation(getAccountsApi);
  const [, getCompanies] = useMutation(getCompaniesApi);
  const [, getAgreements] = useMutation(getAgreementsApi);
  const { token } = useToken();

  const onMessage = useCallback((event) => {
    switch (event.data) {
      case 'account': {
        // todo времено, лучше сделать отдельный запрос на получение всех записей
        getAccounts({
          storeKey: 'accountsAll',
          limit: 100,
        });
        break;
      }
      case 'company': {
        // todo времено, лучше сделать отдельный запрос на получение всех записей
        getCompanies({
          storeKey: 'companiesAll',
          limit: 1000,
        });
        break;
      }
      case 'rate_plan': {
        break;
      }
      case 'agreement': {
        // todo времено, лучше сделать отдельный запрос на получение всех записей
        getAgreements({
          storeKey: 'agreementsAll',
          limit: 1000,
        });
        break;
      }
      default: break;
    }
  }, []);

  useEffect(() => {
    ws = new ReconnectingWebSocket(
      endpoints.getSocketUrl({ token }),
      [],
      {
        debug: process.env.NODE_ENV !== 'production',
      },
    );
    ws.onmessage = onMessage;
    window.wsObject = ws;
    return () => ws.close();
  }, []);

  useEffect(() => {
    if (window.wsObject) {
      window.wsObject.onmessage = onMessage;
    }
  }, [onMessage]);
};
