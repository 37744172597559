import React, { useMemo } from 'react';
import {
  Axis, Chart, Coordinate, Interaction, Interval, Legend, Tooltip,
} from 'bizcharts';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { kFormatter } from 'helpers';

import styles from './OperatorChart.module.scss';

import { axisGridConfig, axisTextStyle, tooltipDomStyles } from '../chartConfig';

const OperatorChart = ({
  data,
}) => {
  const { t } = useTranslation();
  const style = getComputedStyle(document.body);

  const tooltipDomStylesModify = {
    ...tooltipDomStyles(),
    'g2-tooltip-marker': {
      display: 'none',
    },
  };

  const reversedData = useMemo(() => {
    const dataArr = [...data];
    return dataArr.reverse();
  }, [data]);
  const maxBarValue = useMemo(() => {
    const allValues = data.map(I => (Number(I.cnt_total)));
    return Math.max(...allValues);
  }, [data]);
  const totalTraffic = useMemo(() => data.reduce((acc, item) => {
    acc += (Number(item.cnt_total));
    return acc;
  }, 0), [data]);
  const transformedData = useMemo(() => reversedData.flatMap((item) => {
    let networkValue = item.network;
    let countryValue = item.country;

    if (!networkValue) {
      networkValue = 'Unknown';
    }

    if (!countryValue) {
      countryValue = 'Unknown';
    }

    return [{
      type: t('SCREENS.TWO_FACTOR.CHARTS.SENT'),
      country: countryValue,
      network: networkValue,
      count: Number(item.cnt_sent),
    },
    {
      type: t('SCREENS.TWO_FACTOR.CHARTS.DELIVERED'),
      country: countryValue,
      network: networkValue,
      count: Number(item.cnt_delivered),
    }];
  }), [reversedData]);
  const intervalLabel = useMemo(() => [
    'traffic',
    () => ({
      position: 'middle',
      offsetX: -15,
      style: {
        fill: style.getPropertyValue('--white'),
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 24,
      },
    }),
  ], [maxBarValue]);
  const chartHeight = useMemo(() => data.length * 44 + 14, [data]);
  const itemsGap = useMemo(() => (chartHeight - data.length * 24 - 50) / data.length, [data]);
  const networkAxis = useMemo(() => ({
    style: {
      ...axisTextStyle.style,
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      fill: style.getPropertyValue('--black'),
    },
    formatter: (val) => {
      const traffic = data.find(I => I.network === val)?.cnt_total;

      return `${Number(traffic / totalTraffic * 100).toFixed(1)}%`;
    },
  }), [totalTraffic, data, style]);

  const countAxis = {
    ...axisTextStyle,
    formatter: val => kFormatter(+val),
  };

  return (
    <div className={styles.chart}>
      <div style={{ width: 265 }} className={styles.list}>
        {
          data.map(item => (
            <div
              className={styles.item}
              key={item.network}
              style={{ marginBottom: itemsGap }}
            >
              <ReactCountryFlag
                countryCode={item.country}
                title={item.network}
                style={{
                  marginRight: 4,
                  fontSize: 17,
                }}
              />
              <span title={item.network}>
                {item.network}
              </span>
            </div>
          ))
        }
      </div>
      <div style={{ display: 'flex', flex: 1 }}>
        <Chart
          height={chartHeight}
          data={transformedData}
          autoFit
          appendPadding={[0, 0, 0, 0]}
        >
          <Legend
            position="top"
            marker={{
              symbol: 'circle',
              style: {
                r: 6,
              },
            }}
          />
          <Axis
            name="network"
            verticalLimitLength={150}
            grid={axisGridConfig()}
            label={networkAxis}
          />
          <Axis
            name="count"
            grid={axisGridConfig()}
            label={countAxis}
          />
          <Coordinate transpose />
          <Tooltip
            position="right"
            domStyles={tooltipDomStylesModify}
          />
          <Interval
            adjust="stack"
            color={['type', ['#65B168', '#4F91FF']]}
            position="network*count"
            size={20}
            label={intervalLabel}
          />
          <Interaction type="active-region" />
        </Chart>
      </div>
    </div>
  );
};

export default OperatorChart;
