import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Formik, Form, Field,
} from 'formik';
import { Callout, Icon } from '@blueprintjs/core';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import Button from '@setproduct-ui/core/Button';
import {
  TextField,
  DatePickerField,
  MultiselectField,
  DropdownField,
  CheckboxField,
  YearPickerField,
  MonthYearPickerField,
} from 'components/fields';
import { Typography } from 'components';

import IsFormChanged from '../IsFormChanged';

import './style.scss';

export default ({
  title,
  description,
  params,
  initialValues,
  onSubmit,
  onEdit,
  selectedSubsectionId,
  selectedSubsection,
  loading,
  formValuesRef,
  isTenant,
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const formRef = useRef();
  const { t } = useTranslation();

  const fieldStyle = ({
    width,
    marginTop,
    marginBottom,
  } = {}) => ({
    container: {
      width,
      marginBottom,
    },
    label: {
      marginTop,
    },
  });

  const mixFieldStyle = ({
    wrapperWidth,
    wrapperMarginBottom,
    wrapperMarginTop,
    rowOverflow,
  } = {}) => ({
    wrapper: {
      width: wrapperWidth,
      marginBottom: wrapperMarginBottom,
      marginTop: wrapperMarginTop,
    },
    row: {
      overflow: rowOverflow,
    },
  });

  const rightFieldStyles = ({ width }) => ({
    container: {
      margin: 0,
      width,
      boxShadow: 'none',
      clipPath: 'inset(0 1px 0 0)',
    },
  });

  const leftFieldStyles = ({ width }) => ({
    container: {
      width,
    },
    input: {
      background: 'var(--grey0)',
      color: 'var(--grey50)',
      borderRadius: ' 4px 0 0 4px',
      margin: 0,
      boxShadow: 'none',
      clipPath: 'inset(0 1px 0 0)',
    },
  });

  const validationSchema = useMemo(() => {
    const validationParams = params.reduce((acc, item) => {
      if (item.mandatory) {
        if (item.parameterType === 'Multiselect') {
          acc[item.bindName] = Yup.array()
            .ensure()
            .min(1, 'ERRORS.REQUIRED_AND_NOT_EMPTY')
            .required('ERRORS.REQUIRED_AND_NOT_EMPTY');
        } else {
          acc[item.bindName] = Yup.mixed()
            .required('ERRORS.REQUIRED_AND_NOT_EMPTY');
        }
      }
      return acc;
    }, []);
    return Yup.object().shape({
      ...validationParams,
    });
  }, [params]);

  const getFieldProps = (field) => {
    let fieldProps = {
      name: field.bindName,
      isRequired: field.mandatory,
      label: field.name,
      key: field.id,
    };
    switch (field.parameterType) {
      case 'Text field':
        fieldProps = {
          ...fieldProps,
          component: TextField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Multiselect':
        fieldProps = {
          ...fieldProps,
          component: MultiselectField,
          allowCreate: false,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          options: field.value.map(item => ({ value: item, label: item })),
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Date':
        fieldProps = {
          ...fieldProps,
          component: DatePickerField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          format: 'YYYY-MM-DD',
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Checkbox':
        fieldProps = {
          ...fieldProps,
          type: 'def',
          color: 'primary',
          styles: fieldStyle({ marginTop: 24 }),
          component: CheckboxField,
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Number field':
        fieldProps = {
          ...fieldProps,
          component: TextField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Date and time':
        fieldProps = {
          ...fieldProps,
          component: DatePickerField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          timePrecision: 'second',
          placeholder: 'DD.MM.YYYY, HH:mm:ss',
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Month and year':
        fieldProps = {
          ...fieldProps,
          component: MonthYearPickerField,
          styles: mixFieldStyle({
            wrapperMarginTop: 24,
            wrapperWidth: 443,
            rowOverflow: 'visible',
          }),
          leftStyles: leftFieldStyles({ width: 133 }),
          rightStyles: rightFieldStyles({ width: 310 }),
          placeholder: 'YYYY-MM',
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Year':
        fieldProps = {
          ...fieldProps,
          component: YearPickerField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          placeholder: 'YYYY',
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Combobox':
        fieldProps = {
          ...fieldProps,
          component: DropdownField,
          options: field.value.map(item => ({ value: item, label: item })),
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      case 'Spinner field':
        fieldProps = {
          ...fieldProps,
          fieldType: 'number',
          component: TextField,
          styles: fieldStyle({ marginTop: 24, width: 443 }),
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
      default:
        fieldProps = {
          ...fieldProps,
          label: 'TEST',
          component: TextField,
          children: field.description && (
            <Callout
              type="def"
              view="smooth"
              color="primary_alt"
              className="run-report-form__description-callout"
            >
              <Typography
                className="run-report-form__description-callout-text"
              >
                {field.description}
              </Typography>
            </Callout>
          ),
        };
        break;
    }

    return fieldProps;
  };

  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  const handleEdit = () => {
    onEdit({ selectedSubsection });
  };

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          fv: values,
        });
      }
    }
  };

  return (
    <div className="run-report-form">
      <div className="run-report-form__block">
        <div className="run-report-form__form">
          <Typography
            tagName="span"
            className="run-report-form__title"
          >
            {title}
          </Typography>
          <div className="run-report-form__body">
            {(selectedSubsectionId && params) && (
              <Formik
                enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                validateOnMount
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  onSubmit(params.reduce((acc, item) => ({
                    ...acc,
                    [item.bindName]: values[item.bindName] || '',
                  }), {}));
                }}
              >
                <Form>
                  <div>
                    {params.map((field) => {
                      const props = getFieldProps(field);

                      return <Field {...props} />;
                    })}
                  </div>
                  <IsFormChanged checkFunction={checkFunction} />
                </Form>
              </Formik>
            )}
          </div>
        </div>
        <div className="run-report-form__description">
          <div className="run-report-form__description-title">
            <Icon
              icon="info-sign"
              color="var(--grey30)"
              className="run-report-form__description-icon"
            />
            {t('SCREENS.REPORTS.REPORT_INFO')}
          </div>
          <div className="run-report-form__description-body">
            <Typography
              tagName="span"
              className="run-report-form__description-body-title"
            >
              INSTANCES.DESCRIPTION
            </Typography>
            {description}
          </div>
        </div>
      </div>
      <div className="run-report-form__controls">
        {selectedSubsectionId && (
        <>
          {isTenant && (
            <Button
              type="default"
              view="outlined"
              color="primary"
              className="run-report-form__button_edit"
              onClick={handleEdit}
              text="CONTROLS.REPORTS.EDIT_REPORT"
            />
          )}
          <Button
            icon="play"
            type="default"
            view="filled"
            color="success"
            className="run-report-form__button_run"
            onClick={handleSubmit}
            loading={loading}
            text="CONTROLS.REPORTS.RUN_REPORT"
          />
        </>
        )}
      </div>
    </div>
  );
};
