import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getCurrenciesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    currencies: response.map(({ isoCode }) => ({
      label: isoCode,
      value: isoCode,
    })),
  }),
  update: {
    currencies: (oldValue, newValue) => newValue,
  },
});
