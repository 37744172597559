import React from 'react';
import cx from 'classnames';

import styles from './ListItem.module.scss';

import Checkbox from '../../Checkbox';
import { ReactComponent as TrashIcon } from '../../../icons/trash-xmark.svg';
import { ReactComponent as RollbackIcon } from '../../../icons/arrow-rotate-left.svg';

const ListItem = ({
  checked,
  label,
  value,
  childrenLength,
  setCheckedList,
  disabled,
  isToDelete,
  setListToDelete,
  scopeItemId,
}) => {
  const onChange = (newVal) => {
    setCheckedList(prev => (newVal ? [...prev, { value }] : prev.filter(item => item.value !== value)));
  };
  const onDeleteClick = () => setListToDelete(prev => [...prev, scopeItemId]);
  const onRollbackClick = () => setListToDelete(prev => prev.filter(item => item !== scopeItemId));

  return (
    <label
      htmlFor={value}
      className={cx(styles.container, {
        [styles.container_toDelete]: isToDelete,
        [styles.container_disabled]: disabled,
      })}
    >
      <Checkbox
        id={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={styles.label}>
        {label}
      </span>
      {disabled && (
        <>
          <span className={styles.subItemsLabel}>
            {`- ${childrenLength || 'No'} sub-item(s)`}
          </span>
          {isToDelete
            ? <RollbackIcon className={styles.button} onClick={onRollbackClick} />
            : <TrashIcon className={styles.button} onClick={onDeleteClick} />}
        </>
      )}
    </label>
  );
};

export default ListItem;
