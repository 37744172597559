import React, {
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDictionariesApi, useServicesEnabledApi } from 'hooks/api';
import { SERVICE_TYPES_DICTIONARY } from 'consts';
import { SERVICES_ENABLED_MODAL } from 'consts/columns';
import ModalTable from 'components/ModalTable';

const ServicesTable = ({
  agreementId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    getServicesEnabled,
    servicesEnabled,
  } = useServicesEnabledApi();
  const {
    getServicesDictionary,
    servicesDictionary,
  } = useDictionariesApi();

  const onClickServiceId = (id) => {
    history.push({
      pathname: '/services-enabled',
      state: {
        tableFilters: {
          id: {
            values: {
              [id]: true,
            },
            type: 'list',
          },
        },
      },
    });
  };
  const conversionRowData = item => (
    {
      id: item.id,
      config: {
        servicesId: {
          type: 'number',
          value: item.id,
          onClick: onClickServiceId,
        },
        serviceName: {
          type: 'string',
          value: servicesDictionary[item.serviceId] ? servicesDictionary[item.serviceId].name
            : t('SCREENS.AGREEMENTS.AGREEMENT_SERVICES_ENABLED.MISSED_OPTION', { id: item.serviceId }),
        },
        serviceType: {
          type: 'string',
          value: SERVICE_TYPES_DICTIONARY[item.serviceType],
        },
        createdAt: {
          type: 'date',
          value: item.createdAt,
        },
        effectiveFrom: {
          type: 'date',
          value: item.effectiveFrom,
        },
        effectiveTill: {
          type: 'date',
          value: item.effectiveTill,
        },
      },
    }
  );

  useEffect(() => {
    getServicesDictionary();
    getServicesEnabled({
      limit: 1000,
      offset: 0,
      'agreementId[of]': agreementId,
    });
  }, []);

  return (
    <ModalTable
      rowData={servicesEnabled.map(item => conversionRowData(item))}
      columnsName={SERVICES_ENABLED_MODAL}
    />
  );
};
export default ServicesTable;
