import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button/Button';
import Spinner from '@setproduct-ui/core/Spinner';
import {
  CheckboxField, DatePickerField, DropdownField, TextAreaField, TextField,
} from 'components/fields';
import { Typography } from 'components';
import {
  AGREEMENT_DIRECTIONS,
  AGREEMENTS_BILLING_PERIOD,
  BILLING_DAY,
  BILLING_PERIOD_DAYS,
  inputPatterns,
} from 'consts';
import { AGREEMENT_VERSION_SCHEMA, AGREEMENTS_SCHEMA } from 'consts/validationSchemas';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow, useModalLogic, useUserPermissions } from 'hooks';
import { useAgreementsApi, useDictionariesApi } from 'hooks/api';
import Modal from 'components/Modal';
import toastRef from 'helpers/toast';

import ServicesTable from './ServicesTable';

import OnChangeComponent from '../OnChangeComponent';
import IsFormChanged from '../IsFormChanged';

import './style.scss';

export default ({
  changeMode = '',
  mode = '',
  isPending = false,
  isFormPristine = {},
  formValuesRef = {},
  timezonesOptions = [],
  currenciesOptions = [],
  onSubmit = () => {},
  initialValues = {},
  onCancel = () => {},
  onDeleteAgreementVersion = () => {},
  setSelectedVersionId = () => {},
  showBtnServicesEnabled = false,
}) => {
  // styles
  const fieldStyle = ({
    width,
    marginTop,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });
  const dropdownStyle = ({
    width,
  } = {}) => ({
    content: {
      width,
    },
  });
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { editAllowed } = useUserPermissions();
  const formRef = useRef(null);
  const contentRef = useRef(null);
  const filteredLegalEntitiesOptionsRef = useRef([]);
  const [isNewVersion, setIsNewVersion] = useToggle(false);
  const { activeTab } = useSelector(state => state.states.routes);
  const { showTopShadow, showBottomShadow } = useContentShadow(contentRef);
  const [isOpenModal, { openModal, closeModal }] = useModalLogic(false);
  const [creditLimit, setCreditLimit] = useState(initialValues?.creditLimit);
  const [creditLimitMode, setCreditLimitMode] = useState('view');
  const {
    agreementVersionsById,
    getAgreementVersions,
    putAgreementCreditLimit,
    isPendingGetAgreementVersions,
    isPendingPutAgreementCreditLimit,
  } = useAgreementsApi();
  const {
    companiesOptions,
    companiesDictionary,
    legalEntitiesOptions,
    ownLegalEntitiesOptions,
  } = useDictionariesApi();

  // memed vars
  const isView = useMemo(() => mode === 'view', [mode]);
  const agreementVersionsOptions = useMemo(() => {
    const result = agreementVersionsById.reduce((acc, item) => {
      const effectiveFrom = item.actualEffectiveFrom;
      const effectiveTill = item.actualEffectiveTill;
      const formattedEffectiveFrom = moment(effectiveFrom).format('DD.MM.YYYY');
      const formattedEffectiveTill = item.actualEffectiveTill
        ? moment(effectiveTill).format('DD.MM.YYYY')
        : 'Indefinitely';
      const effectiveTillModify = effectiveTill === null
        ? moment().set({ day: 31, month: 11, year: 2999 })
        : effectiveTill;
      let timePosition = '';

      if (moment().isBetween(
        moment(effectiveFrom).local(),
        moment(effectiveTillModify).local(),
        null,
        '()',
      )) {
        timePosition = 'current';
      } else if (moment().isBefore(effectiveFrom)) {
        timePosition = 'upcoming';
      } else {
        timePosition = 'expired';
      }

      acc.push({
        value: item.id,
        label: `${formattedEffectiveFrom} - ${formattedEffectiveTill} (ID ${item.id}) - ${timePosition}`,
        status: timePosition,
      });

      return acc;
    }, []);

    if (isNewVersion) {
      result.unshift({
        value: 0,
        label: 'New',
      });
    }
    return result;
  }, [agreementVersionsById, isNewVersion]);

  // functions
  const changeToEdit = () => changeMode('edit');
  const onChangeCompanyId = ({ form, value }) => {
    filteredLegalEntitiesOptionsRef.current = legalEntitiesOptions
      .filter(legalEntity => legalEntity.companyId === value);

    form.setFieldValue('parentCompanyLegalEntityId', companiesDictionary[value]?.parentCompanyLEId);
    if (form.dirty) {
      form.setFieldValue('legalEntityId', undefined);
    }
  };
  const onChangeBillingZone = ({ value, form }) => {
    const { actualEffectiveFromTz, actualEffectiveTillTz } = formRef.current.values;
    if (value) {
      if (!actualEffectiveFromTz) {
        form.setFieldValue('actualEffectiveFromTz', value);
      }
      if (!actualEffectiveTillTz) {
        form.setFieldValue('actualEffectiveTillTz', value);
      }
    } else {
      form.setFieldValue('actualBillingDay', 1);
    }
  };
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;
      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }
    const valuesModify = { ...values };
    const initialValuesModify = { ...initialValues };
    switch (mode) {
      case 'edit':
        return isEqual(valuesModify, initialValuesModify);
      case 'add':
        return isEmpty(valuesModify);
      default: return true;
    }
  };
  const dateFormatter = ({
    value,
    format = 'YYYY-MM-DD',
    toDate = false,
  }) => {
    if (value && !toDate) {
      return moment(value).format(format);
    } if (value && toDate) {
      return moment(value).toDate();
    }
    return null;
  };
  const onSubmitHandler = (values) => {
    const datesData = {
      actualEffectiveFrom: dateFormatter({ value: values.actualEffectiveFrom, format: 'YYYY-MM-DD 00:00:00' }),
      actualEffectiveTill: dateFormatter({ value: values.actualEffectiveTill, format: 'YYYY-MM-DD 00:00:00' }),
    };
    const agreementVersionData = {
      actualAccountAlertEmails: values.actualAccountAlertEmails,
      actualRateChangeEmails: values.actualRateChangeEmails,
      actualServiceEmails: values.actualServiceEmails,
      actualInvoiceEmails: values.actualInvoiceEmails,
      actualBillingDay: values.actualBillingDay,
      actualBillingPeriod: values.actualBillingPeriod,
      actualBillingTz: values.actualBillingTz,
      actualCode: values.actualCode,
      actualEffectiveFromTz: values.actualEffectiveFromTz,
      actualEffectiveTillTz: values.actualEffectiveTillTz,
      actualPaymentPeriod: values.actualPaymentPeriod,
      actualSplitByMonth: values.actualSplitByMonth,
      actualMaxBillingPeriods: values.actualMaxBillingPeriods,
      actualMinInvoiceAmount: values.actualMinInvoiceAmount,
      ...datesData,
    };
    if (mode === 'add') {
      onSubmit({
        ...values,
        ...datesData,
        agreementDate: dateFormatter({ value: values.agreementDate }),
      });
    } else if (mode === 'edit') {
      if (isNewVersion) {
        onSubmit({
          ...agreementVersionData,
          agreementId: values.id,
          agreementDate: dateFormatter({ value: values.agreementDate }),
        }, true);
      } else {
        onSubmit({
          ...agreementVersionData,
          id: values.id,
          agreementId: values.agreementId,
        });
      }
    }
  };
  const onChangeEffectiveFrom = ({ value }) => {
    if (agreementVersionsById.length && value && isNewVersion) {
      const unixValueDate = moment(moment(value).format('YYYY-MM-DD 00:00:00')).unix();
      const smallestVersionsByDate = agreementVersionsById.filter(I => moment(I.actualEffectiveFrom).unix() < unixValueDate);
      const smallestVersionByDate = smallestVersionsByDate.reduce((a, b) => (moment(a.actualEffectiveFrom).unix() > moment(b.actualEffectiveFrom).unix() ? a : b), {});
      if (!isEmpty(smallestVersionByDate)) {
        const {
          actualEffectiveFromTz,
          actualEffectiveTillTz,
          actualSplitByMonth,
          actualBillingDay,
          actualBillingPeriod,
          actualBillingTz,
          actualPaymentPeriod,
        } = formRef.current.values;
        formRef.current.setValues({
          ...formRef.current.values,
          actualEffectiveFromTz: actualEffectiveFromTz || smallestVersionByDate.actualEffectiveTillTz,
          actualEffectiveTillTz: actualEffectiveTillTz || smallestVersionByDate.actualBillingTz,
          actualSplitByMonth: actualSplitByMonth || smallestVersionByDate.actualSplitByMonth,
          actualBillingDay: actualBillingDay || smallestVersionByDate.actualBillingDay,
          actualBillingPeriod: actualBillingPeriod || smallestVersionByDate.actualBillingPeriod,
          actualBillingTz: actualBillingTz || smallestVersionByDate.actualBillingTz,
          actualPaymentPeriod: actualPaymentPeriod || smallestVersionByDate.actualPaymentPeriod,
        });
      }
    }
  };
  const onChangeActualVersionId = ({ value }) => {
    if (value) {
      const selectedVersion = agreementVersionsById.find(I => I.id === value);
      if (selectedVersion) {
        formRef.current.setValues(prevState => ({
          ...prevState,
          ...selectedVersion,
          actualVersionId: value,
          actualEffectiveFrom: dateFormatter({ value: selectedVersion.actualEffectiveFrom, toDate: true }),
          actualEffectiveTill: dateFormatter({ value: selectedVersion.actualEffectiveTill, toDate: true }),
        }));
        setIsNewVersion(false);
        setSelectedVersionId(value);
      }
    }
  };
  const formatAbsoluteDate = date => (date ? dayjs(date).tz().format('DD.MM.YYYY hh:mm:ss zzz (UTCZ)') : undefined);
  const goToCompany = () => {
    history.push({
      pathname: '/companies',
      state: {
        tableFilters: {
          id: {
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: initialValues.companyId,
            },
            type: 'number',
            isWithoutEmpty: false,
          },
        },
      },
    });
  };
  const setEditCreditLimitMode = () => setCreditLimitMode('edit');
  const resetCreditLimit = () => {
    setCreditLimit(initialValues?.creditLimit);
    setCreditLimitMode('view');
  };
  const submitNewCreditLimit = () => {
    putAgreementCreditLimit({
      body: {
        agreementId: initialValues.id,
        creditLimit,
      },
      successCallback: () => {
        setCreditLimitMode('view');
      },
      errorCallback: (err) => {
        toastRef.current.showMessage({ message: err?.key ? err?.key : err, intent: 'danger' });
        setCreditLimit(initialValues?.creditLimit);
        setCreditLimitMode('view');
      },
    });
  };

  // effects
  useEffect(() => {
    const tomorrowDate = moment().add(1, 'days').toDate();
    const defaultValues = {
      direction: 0,
      actualEffectiveTill: null,
      actualBillingDay: 1,
      actualSplitByMonth: false,
      actualInvoiceEmails: [],
      actualAccountAlertEmails: [],
      actualRateChangeEmails: [],
      actualServiceEmails: [],
      actualMaxBillingPeriods: null,
      actualMinInvoiceAmount: null,
      actualEffectiveFrom: tomorrowDate,
    };
    if (isNewVersion) {
      formRef.current.setValues({
        ...defaultValues,
        id: initialValues.id,
        actualVersionId: 0,
        companyName: initialValues.companyName,
        agreementCode: initialValues.agreementCode,
        direction: initialValues.direction,
        legalEntityName: initialValues.legalEntityName,
        agreementDate: initialValues.agreementDate,
        currency: initialValues.currency,
        parentCompanyLegalEntityName: initialValues.parentCompanyLegalEntityName,
      });
    } else {
      formRef.current.setValues({
        ...defaultValues,
        ...initialValues,
      });
    }
  }, [isNewVersion]);

  useEffect(() => {
    getAgreementVersions({
      'agreementId[of]': initialValues.id,
      storeKey: 'agreementVersionsById',
      limit: 1000,
    });
  }, []);

  if (isPendingGetAgreementVersions) {
    return <Spinner className="agreements-form__spinner" />;
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        direction: 0,
        actualEffectiveTill: null,
        actualBillingDay: 1,
        actualSplitByMonth: false,
        actualInvoiceEmails: [],
        actualAccountAlertEmails: [],
        actualRateChangeEmails: [],
        actualServiceEmails: [],
        creditLimit: null,
        actualMaxBillingPeriods: null,
        actualMinInvoiceAmount: null,
        ...initialValues,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={mode === 'edit' ? AGREEMENT_VERSION_SCHEMA : AGREEMENTS_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="agreements-form">
          {showTopShadow && <div className="agreements-form__shadow" />}
          <div className="agreements-form__content" ref={contentRef}>
            {mode !== 'add' && showBtnServicesEnabled === true && (
              <Button
                onClick={openModal}
                text={t('INSTANCES.AGREEMENTS.SERVICES_ENABLED')}
                color="primary_alt"
                view="outlined"
                style={{ marginLeft: 24, minHeight: 40 }}
              />
            )}
            {(mode === 'edit' || mode === 'view') && (
            <div className="agreements-form__agreement-info">
              <div className="agreements-form__row mt24">
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.COMPANY
                  </Typography>
                  <Typography color="primary" colorStep={60} className="agreements-form__link" onClick={goToCompany}>
                    {values.companyName}
                  </Typography>
                </div>
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.AGREEMENTS.AGREEMENT_CODE
                  </Typography>
                  <Typography>
                    {values.agreementCode}
                  </Typography>
                </div>
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.DIRECTION
                  </Typography>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 5,
                    alignItems: 'center',
                  }}
                  >
                    <Icon
                      size={14}
                      icon={values.direction ? 'arrow-up' : 'arrow-down'}
                      color={values.direction ? 'red' : 'green'}
                    />
                    <Typography>
                      {values.direction ? 'INSTANCES.AGREEMENTS.VENDOR' : 'INSTANCES.AGREEMENTS.CLIENT'}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="agreements-form__row mt24">
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.LEGAL_ENTITY
                  </Typography>
                  <Typography>
                    {values.legalEntityName}
                  </Typography>
                </div>
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.AGREEMENTS.AGREEMENT_DATE
                  </Typography>
                  <Typography>
                    {moment(values.agreementDate).format('DD.MM.YYYY')}
                  </Typography>
                </div>
                <div className="agreements-form__agreement-top-block">
                  <Typography small colorStep={40}>
                    INSTANCES.CURRENCY
                  </Typography>
                  <Typography>
                    {`${values.currency === 'EUR' ? '€' : values.currency === 'USD' ? '$' : '₽'} ${values.currency}`}
                  </Typography>
                </div>
              </div>
              <div className="agreements-form__row mt24" style={{ alignItems: 'flex-start' }}>
                <div className="agreements-form__agreement-top-block" style={{ flex: 3.5 }}>
                  <Typography small colorStep={40}>INSTANCES.OWN_LEGAL_ENTITY</Typography>
                  <Typography>
                    {values.parentCompanyLegalEntityName}
                  </Typography>
                  {companiesDictionary[values.companyId]?.parentCompanyLEId !== values.parentCompanyLegalEntityId && (
                    <Typography small color="warning" colorStep={40}>Warning</Typography>
                  )}
                </div>
                <div className="agreements-form__row" style={{ flex: 7, justifyContent: 'unset' }}>
                  <div className="agreements-form__agreement-top-block" style={{ flex: 'unset' }}>
                    <Typography small colorStep={40}>INSTANCES.AGREEMENTS.CREDIT_LIMIT</Typography>
                    <TextField
                      value={creditLimit === null && creditLimitMode === 'view'
                        ? t('PLACEHOLDERS.AGREEMENTS.UNLIMITED')
                        : creditLimit}
                      onChange={setCreditLimit}
                      styles={fieldStyle({ width: 150 })}
                      disabled={creditLimitMode === 'view'}
                      clearButton={false}
                      placeholder="PLACEHOLDERS.AGREEMENTS.UNLIMITED"
                      regex={inputPatterns.decimal({ decimalLimit: 2 })}
                      dense
                    />
                  </div>
                  <div className="agreements-form__buttons-wrapper">
                    {creditLimitMode === 'view' ? (
                      <Button
                        text="CONTROLS.AGREEMENTS.CHANGE"
                        color="primary"
                        onClick={setEditCreditLimitMode}
                        disabled={!editAllowed}
                        title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
                        dense
                      />
                    ) : (
                      <>
                        <Button
                          icon="reset"
                          view="outlined"
                          onClick={resetCreditLimit}
                          dense
                        />
                        <Button
                          icon="tick"
                          color="primary"
                          onClick={submitNewCreditLimit}
                          loading={isPendingPutAgreementCreditLimit}
                          dense
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            )}
            {mode === 'add' && (
              <div className="agreements-form__agreement-block-wrapper">
                <div className="agreements-form__row mt24">
                  <Field
                    name="companyId"
                    label="INSTANCES.COMPANY"
                    component={DropdownField}
                    options={companiesOptions}
                    styles={fieldStyle({ width: 268 })}
                    disabled={isView}
                    placeholder="PLACEHOLDERS.SELECT_COMPANY"
                    isRequired={!isView}
                  />
                  <Field
                    name="direction"
                    label="INSTANCES.DIRECTION"
                    component={DropdownField}
                    options={AGREEMENT_DIRECTIONS}
                    styles={fieldStyle({ width: 268 })}
                    disabled={isView}
                    placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_DIRECTION"
                  />
                </div>
                <div className="agreements-form__row mt24">
                  <Field
                    name="legalEntityId"
                    label="INSTANCES.LEGAL_ENTITY"
                    component={DropdownField}
                    options={filteredLegalEntitiesOptionsRef.current}
                    styles={fieldStyle({ width: 268 })}
                    disabled={isView || !values.companyId}
                    placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_LEGAL_ENTITY"
                    isRequired={!isView}
                  />
                  <Field
                    name="agreementCode"
                    label="INSTANCES.AGREEMENTS.AGREEMENT_CODE"
                    component={TextField}
                    placeholder="PLACEHOLDERS.AGREEMENTS.ENTER_AGREEMENT_CODE"
                    styles={fieldStyle({ width: 268 })}
                    disabled={isView}
                    isRequired={!isView}
                  />
                </div>
                <div className="agreements-form__row mt24">
                  <Field
                    name="parentCompanyLegalEntityId"
                    label="INSTANCES.OWN_LEGAL_ENTITY"
                    component={DropdownField}
                    options={ownLegalEntitiesOptions}
                    styles={fieldStyle({ width: 268 })}
                    disabled
                    placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_OWN_LEGAL_ENTITY"
                    isRequired={!isView}
                  />
                  <Field
                    name="agreementDate"
                    label="INSTANCES.AGREEMENTS.AGREEMENT_DATE"
                    placeholder="PLACEHOLDERS.SELECT_DATE"
                    component={DatePickerField}
                    format="DD.MM.YYYY"
                    styles={fieldStyle({
                      width: 268,
                      marginLeft: 16,
                      marginRight: 'auto',
                    })}
                    disabled={isView}
                    isRequired={!isView}
                  />
                </div>
                <Field
                  name="currency"
                  label="INSTANCES.CURRENCY"
                  component={DropdownField}
                  options={currenciesOptions}
                  styles={fieldStyle({
                    width: 268,
                    marginBottom: 24,
                    marginTop: 24,
                  })}
                  disabled={isView}
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_CURRENCY"
                  isRequired={!isView}
                />
              </div>
            )}
            <div className="agreements-form__agreement-block-wrapper">
              {(mode === 'edit' || mode === 'view') && (
              <>
                <Typography>PLACEHOLDERS.AGREEMENTS.VERSION</Typography>
                <div className="agreements-form__agreement-vesrion-block">
                  <Field
                    name="actualVersionId"
                    component={DropdownField}
                    options={agreementVersionsOptions}
                    placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_VERSION_OF_AGREEMENT"
                    styles={dropdownStyle({
                      width: 375,
                    })}
                    disabled={isView}
                    translateOptions={false}
                  />
                  {isNewVersion ? (
                    <Button
                      view="outlined"
                      color="danger"
                      onClick={setIsNewVersion}
                      disabled={isView}
                      fill
                    >
                      <Typography small color="danger" colorStep={50}>CONTROLS.CANCEL</Typography>
                    </Button>
                  ) : (
                    <Button
                      view="outlined"
                      color="error"
                      onClick={setIsNewVersion}
                      disabled={isView}
                      fill
                    >
                      <Typography small color="primary" colorStep={50}>PLACEHOLDERS.AGREEMENTS.ADD_NEW_VERSION</Typography>
                    </Button>
                  )}
                </div>
                <Field
                  name="actualCode"
                  label="PLACEHOLDERS.AGREEMENTS.VERSION_CODE"
                  component={TextField}
                  styles={fieldStyle({ width: 268, marginBottom: 24 })}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  clearButton={false}
                  disabled={isView}
                />
              </>
              )}
              <div className="agreements-form__row mt24">
                <Field
                  name="actualEffectiveFrom"
                  label="INSTANCES.EFFECTIVE_FROM"
                  placeholder="PLACEHOLDERS.SELECT_DATE"
                  component={DatePickerField}
                  format="DD.MM.YYYY"
                  styles={fieldStyle({ width: 268 })}
                  disabled={isView}
                  isRequired={!isView}
                  tooltipMessage={formatAbsoluteDate(values.actualEffectiveFromAbs)}
                />
                <Field
                  name="actualEffectiveFromTz"
                  label=""
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                  component={DropdownField}
                  options={timezonesOptions}
                  styles={fieldStyle({
                    width: 268,
                    marginTop: 24,
                  })}
                  disabled={isView}
                  isRequired={!isView}
                  translateOptions={false}
                />
              </div>
              <div className="agreements-form__row mt24 mb24">
                <Field
                  name="actualEffectiveTill"
                  label="INSTANCES.EFFECTIVE_TILL"
                  component={DatePickerField}
                  format="DD.MM.YYYY"
                  styles={fieldStyle({ width: 268 })}
                  disabled={isView}
                  isRequired={!isView}
                  withIndefinitely
                  tooltipMessage={formatAbsoluteDate(values.actualEffectiveTillAbs)}
                />
                <Field
                  name="actualEffectiveTillTz"
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                  component={DropdownField}
                  options={timezonesOptions}
                  styles={fieldStyle({
                    width: 268,
                    marginTop: 24,
                  })}
                  disabled={isView}
                  isRequired={!isView}
                  translateOptions={false}
                />
              </div>
              {mode === 'add' && (
                <div className="mb24">
                  <Typography>INSTANCES.AGREEMENTS.CREDIT_LIMIT</Typography>
                  <Field
                    name="creditLimit"
                    component={TextField}
                    styles={fieldStyle({ width: 268, marginTop: 24 })}
                    disabled={isView}
                    clearButton={false}
                    placeholder="PLACEHOLDERS.AGREEMENTS.UNLIMITED"
                  />
                </div>
              )}
              <Typography>PLACEHOLDERS.AGREEMENTS.INVOICING</Typography>
              <div className="agreements-form__row mt24">
                <Field
                  name="actualBillingTz"
                  label="INSTANCES.BILLING_TIME_ZONE"
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_BILLING_TIME_ZONE"
                  component={DropdownField}
                  options={timezonesOptions}
                  styles={fieldStyle({
                    width: 268,
                  })}
                  disabled={isView}
                  isRequired={!isView}
                  translateOptions={false}
                />
                <Field
                  name="actualPaymentPeriod"
                  label="PLACEHOLDERS.AGREEMENTS.DAYS_FOR_PAYMENT"
                  component={TextField}
                  styles={fieldStyle({ width: 268 })}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  clearButton={false}
                  regex={inputPatterns.numbers()}
                  disabled={isView}
                  isRequired={!isView}
                />
              </div>
              <div className="agreements-form__row mt24 mb24">
                <Field
                  name="actualBillingPeriod"
                  label="INSTANCES.BILLING_PERIOD"
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_BILLING_PERIOD"
                  component={DropdownField}
                  options={AGREEMENTS_BILLING_PERIOD}
                  styles={fieldStyle({ width: 197 })}
                  disabled={isView}
                  isRequired={!isView}
                />
                <Field
                  name="actualBillingDay"
                  label="INSTANCES.AGREEMENTS.BILLING_DAY"
                  placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_BILLING_DAY"
                  component={DropdownField}
                  options={values.actualBillingPeriod === 7 ? BILLING_DAY : BILLING_PERIOD_DAYS}
                  styles={fieldStyle({ width: 148 })}
                  disabled={isView || values.actualBillingPeriod === 1}
                  isRequired={!isView && values.actualBillingPeriod !== 1}
                />
                <Field
                  name="actualSplitBMonth"
                  component={CheckboxField}
                  text="INSTANCES.AGREEMENTS.SPLIT_BY_MONTH"
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  styles={fieldStyle({ marginTop: 20 })}
                  disabled={isView || !(values.actualBillingPeriod % 30)}
                />
              </div>
              <Typography>PLACEHOLDERS.AGREEMENTS.EMAILS</Typography>
              <Field
                name="actualInvoiceEmails[0]"
                label="INSTANCES.AGREEMENTS.INVOICE_EMAILS"
                component={TextAreaField}
                disabled={isView}
                styles={fieldStyle({ marginTop: 24 })}
                placeholder="PLACEHOLDERS.AGREEMENTS.INVOICE_MAIL"
              />
              <Field
                name="actualAccountAlertEmails[0]"
                label="INSTANCES.AGREEMENTS.ACCOUNT_EMAILS"
                component={TextAreaField}
                styles={fieldStyle({ marginTop: 16 })}
                disabled={isView}
                placeholder="PLACEHOLDERS.AGREEMENTS.ALERT_MAIL"
              />
              <Field
                name="actualRateChangeEmails[0]"
                label="INSTANCES.AGREEMENTS.RATE_CHANGE_EMAILS"
                component={TextAreaField}
                styles={fieldStyle({ marginTop: 16 })}
                disabled={isView}
                placeholder="PLACEHOLDERS.AGREEMENTS.CHANGES_MAIL"
              />
              <Field
                name="actualServiceEmails[0]"
                label="INSTANCES.AGREEMENTS.SERVICE_EMAILS"
                component={TextAreaField}
                styles={fieldStyle({ marginTop: 16 })}
                disabled={isView}
                placeholder="PLACEHOLDERS.AGREEMENTS.SERVICE_MAIL"
              />
            </div>
          </div>
          <FormButtonsGroup
            onDelete={onDeleteAgreementVersion}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
            deleteButtonText="CONTROLS.AGREEMENTS.DELETE_THIS_VERSION"
            disabledDelete={
              !values.actualVersionId
              || agreementVersionsOptions?.length < 2
              || moment(values.actualEffectiveFrom).isBefore(moment())
            }
          />
          <OnChangeComponent field="actualBillingTz" onChange={onChangeBillingZone} />
          <OnChangeComponent field="companyId" onChange={onChangeCompanyId} />
          <OnChangeComponent field="actualEffectiveFrom" onChange={onChangeEffectiveFrom} />
          <OnChangeComponent field="actualVersionId" onChange={onChangeActualVersionId} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          {isOpenModal && (
          <Modal
            closeModal={closeModal}
            withTitle={false}
            withCloseButton={false}
            className="agreements-form__modal"
          >
            <div className="agreements-form__modal-header">
              <div className="agreements-form__modal-title">
                {t('INSTANCES.AGREEMENTS.SERVICES_ENABLED')}
              </div>
              <Icon
                icon="cross"
                onClick={closeModal}
                size={20}
              />
            </div>
            <ServicesTable
              agreementId={initialValues.id}
            />
          </Modal>
          )}
        </Form>
      )}
    </Formik>
  );
};
