import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import endpoints from 'consts/endpoints';
import Button from '@setproduct-ui/core/Button';
import { PdfIconNew } from 'assets/icons';
import toastRef from 'helpers/toast';
import fetchFile from 'helpers/fetchFile';

import styles from './FileField.module.scss';

// todo: merge with ImageField in Portals
const FileField = ({
  label,
  hint,
  field: {
    name,
    value,
  },
  form: {
    setFieldValue,
    values,
  },
  disabled,
}) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = ({ target }) => {
    const file = target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedFile(e.target.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
      setFieldValue(name, file);
      target.value = '';
    }
  };
  const removeUploadedImage = () => {
    setFieldValue(name, null);
    setUploadedFile(null);
    setFileName(null);
  };
  const getFileUrl = (entityId, fileId) =>
    `${endpoints.rootHost}/backoffice-service/self-register/files/${entityId}/${fileId}`;
  const downloadFile = () => {
    fetchFile({
      url: getFileUrl(values?.id, value),
      fileName: value?.split('/').at(-1),
      withAuth: false,
    });
  };

  useLayoutEffect(() => {
    if (typeof value === 'string') {
      fetch(getFileUrl(values?.id, value), {
        method: 'GET',
      })
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          }
          setIsLoading(false);
          throw `${response.status} (${response.statusText})`;
        })
        .then((response) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            setUploadedFile(e.target.result);
            setFileName(value?.name || value?.split('/')?.at(-1));
            setIsLoading(false);
          };
          reader.readAsDataURL(response);
        })
        .catch((err) => {
          toastRef.current.showMessage({ message: err?.key ? err?.key : err, intent: 'danger' });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [value]);

  if (isLoading) {
    return (
      <div className={styles.field}>
        {label && (
          <span className={styles.label}>
            {t(label)}
          </span>
        )}
        <div className={styles.contentLoading} />
      </div>
    );
  }

  return (
    <div className={styles.field}>
      {label && (
        <span className={styles.label}>
          {t(label)}
        </span>
      )}
      <div className={styles.content}>
        {uploadedFile ? (
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <PdfIconNew
                className={styles.uploadedImage}
              />
              <div>
                {fileName}
              </div>
            </div>
            {hint && (
              <div className={styles.hint}>
                {hint}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.container}>
            <label
              htmlFor={name}
              className={styles.input}
              data-testid={`${window.location.pathname}/file-field/${name}`}
            >
              {label}
            </label>
            {hint && (
              <div className={styles.hint}>
                {hint}
              </div>
            )}
          </div>
        )}
        {fileName && (
          <div className={styles.tools}>
            {typeof value === 'string' && (
            <Button
              onClick={downloadFile}
              type="default"
              view="outlined"
              color="default"
              icon="import"
            />
            )}
            {!disabled && !!value && (
            <Button
              onClick={removeUploadedImage}
              type="default"
              view="outlined"
              color="default"
              icon="trash"
            />
            )}
          </div>
        )}
        <input
          type="file"
          id={name}
          accept=".pdf"
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default FileField;
