import React, { useState, useMemo } from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import SearchInput from '@setproduct-ui/core/Inputs/SearchInput/SearchInput';
import Drawer from 'components/Drawer';
import Button from '@setproduct-ui/core/Button';
import { CheckboxField } from 'components/fields';
import { Typography } from 'components/Typography';

import './style.scss';

export default ({
  columnsNames = [],
  onSubmit = () => {},
  initialValues: externalInitialValues,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const initialValues = useMemo(
    () => {
      if (isEmpty(externalInitialValues)) {
        return columnsNames.reduce((acc, { field }) => ({ ...acc, [field]: true }), {});
      }

      return columnsNames.reduce((acc, { field }) =>
        ({
          ...acc,
          [field]: externalInitialValues[field] ?? true,
        }), {});
    },
    [externalInitialValues],
  );

  const filteredColumns = useMemo(() => columnsNames.reduce((acc, item) => {
    if (t(item.headerName).toLowerCase().includes(searchValue?.toLowerCase())) {
      acc.push({
        ...item,
        visible: true,
      });
    } else {
      acc.push(item);
    }

    return acc;
  }, []), [searchValue, columnsNames]);

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={320}
      position="right"
      title="CONTROLS.COLUMNS"
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {
          ({ handleSubmit }) => (
            <Form className="custom-view">
              <SearchInput
                type="def"
                view="outlined"
                color="#B4B4BB"
                value={searchValue}
                placeholder={t('PLACEHOLDERS.COLUMNS_FILTER.SEARCH_COLUMNS')}
                fill
                onChange={setSearchValue}
                className="custom-view__search"
              />
              <div className="custom-view__check-list">
                {filteredColumns.map((item, index) => (
                  <Field
                    key={item.field}
                    name={item.field}
                    component={CheckboxField}
                    text={t(item.headerName)}
                    fill
                    classNames={{
                      container: 'custom-view__check-list__item',
                    }}
                    styles={{
                      container: item.visible ? undefined : {
                        display: 'none',
                      },
                    }}
                    disabled={index === 0}
                  />
                ))}
              </div>
              <Button
                type="default"
                view="filled"
                color="primary"
                className="custom-view__apply"
                fill={false}
                onClick={handleSubmit}
                dense
              >
                <Typography tagName="span" className="custom-view__apply__text">
                  CONTROLS.APPLY
                </Typography>
              </Button>
            </Form>
          )
        }
      </Formik>
    </Drawer>
  );
};
