import { Icon } from '@blueprintjs/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { WhatsappChannelIcon } from 'assets/icons';
import { MediaPreview } from 'components';

import styles from '../preview.module.scss';

const highlightTag = ({ children }) => {
  const target = children.replace(/\*([^*]+?)\*/gm, '$1')
    .replace(/~([^~]+?)~/gm, '$1')
    .replace(/_([^_]+?)_/gm, '$1')
    .replace(/```([^```]+?)```/gm, '$1');
  if (children.match(/~([^~]+?)~/gm)?.length) {
    return <s>{target}</s>;
  }
  if (children.match(/\*([^*]+?)\*/gm)?.length) {
    return <b>{target}</b>;
  }
  if (children.match(/_([^_]+?)_/gm)?.length) {
    return <i>{target}</i>;
  }
  if (children.match(/```([^```]+?)```/gm)?.length) {
    return <kbd>{target}</kbd>;
  }
  return <span className={styles.highlight}>{target}</span>;
};

const WhatAppPreview = ({ message, showChannelIcon }) => {
  let result;
  switch (message.type) {
    case 'text':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <WhatsappChannelIcon />}
            <Icon icon="font" color="var(--grey30)" size={16} />
            Text
          </div>
          <div className={styles.bodyContainer}>
            <Highlighter
              highlightTag={highlightTag}
              searchWords={[
                /{{.([^]+?)}}/gm,
                /\*([^*]+?)\*/gm,
                /~([^~]+?)~/gm,
                /_([^_]+?)_/gm,
                /```([^```]+?)```/gm,
              ]}
              textToHighlight={message.body}
              autoEscape={false}
            />
          </div>
        </div>
      );
      break;
    case 'file':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <WhatsappChannelIcon />}
            <Icon icon="document" color="var(--grey30)" size={16} />
            File
          </div>
          <div className={styles.bodyContainer}>
            <MediaPreview file={message.body} />
          </div>
        </div>
      );
      break;
    case 'template':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <WhatsappChannelIcon />}
            <Icon icon="document-open" color="var(--grey30)" size={16} />
            Template
          </div>
          <div className={styles.bodyContainer}>
            template
          </div>
        </div>
      );
      break;
    case 'geolocation':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <WhatsappChannelIcon />}
            <Icon icon="locate" color="var(--grey30)" size={16} />
            Get geolocation
          </div>
          <div className={styles.bodyContainer}>
            <div className={styles.locationProp}>
              <div className={styles.locationPropName}>Latitude:</div>
              <div className={styles.locationPropValue}>
                {message.body.location.latitude}
              </div>
            </div>
            <div>
              <div className={styles.locationProp}>
                <div className={styles.locationPropName}>Longitude:</div>
                <div className={styles.locationPropValue}>
                  {message.body.location.longitude}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.locationProp}>
                <div className={styles.locationPropName}>Name:</div>
                <div className={styles.locationPropValue}>
                  {message.body.location.name}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.locationProp}>
                <div className={styles.locationPropName}>Address:</div>
                <div className={styles.locationPropValue}>
                  {message.body.location.address}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 'interactive': {
      if (message.body.type === 'button') {
        result = (
          <div className={styles.messageContainer}>
            <div className={styles.messageTile}>
              {showChannelIcon && <WhatsappChannelIcon />}
              <Icon icon="widget-footer" color="var(--grey30)" size={16} />
              Card
            </div>
            <div className={styles.bodyContainer}>
              <div className={styles.messageHeader}>
                {message.body.header.type === 'image' && (
                <MediaPreview file={message.body.header} />
                )}
                {message.body.header.type === 'text' && (
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.header.text}
                  autoEscape={false}
                />
                )}
              </div>
              <div className={styles.messageBody}>
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.body.text}
                  autoEscape={false}
                />
              </div>
              <div className={styles.messageFooter}>
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.footer?.text}
                  autoEscape={false}
                />
              </div>
              <div className={styles.messageButtons}>
                {message.body.action.buttons.map(I => (
                  <div className={styles.messageButtonsItem}>{I.reply.title}</div>
                ))}
              </div>
            </div>
          </div>
        );
      }
      if (message.body.type === 'location_request_message') {
        result = (
          <div className={styles.messageContainer}>
            <div className={styles.messageTile}>
              {showChannelIcon && <WhatsappChannelIcon />}
              <Icon icon="map-marker" color="var(--grey30)" size={16} />
              Send geolocation
            </div>
            <div className={styles.bodyContainer}>
              <Highlighter
                highlightTag={highlightTag}
                searchWords={[
                  /{{.([^]+?)}}/gm,
                  /\*([^*]+?)\*/gm,
                  /~([^~]+?)~/gm,
                  /_([^_]+?)_/gm,
                  /```([^```]+?)```/gm,
                ]}
                textToHighlight={message.body.body?.text}
                autoEscape={false}
              />
            </div>
          </div>
        );
      }
      if (message.body.type === 'list') {
        result = (
          <div className={styles.messageContainer}>
            <div className={styles.messageTile}>
              {showChannelIcon && <WhatsappChannelIcon />}
              <Icon icon="widget-header" color="var(--grey30)" size={16} />
              List message
            </div>
            <div className={styles.bodyContainer}>
              <div className={styles.messageHeader}>
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.header.text}
                  autoEscape={false}
                />
              </div>
              <div className={styles.messageBody}>
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.body.text}
                  autoEscape={false}
                />
              </div>
              <div className={styles.messageFooter}>
                <Highlighter
                  highlightTag={highlightTag}
                  searchWords={[
                    /{{.([^]+?)}}/gm,
                    /\*([^*]+?)\*/gm,
                    /~([^~]+?)~/gm,
                    /_([^_]+?)_/gm,
                    /```([^```]+?)```/gm,
                  ]}
                  textToHighlight={message.body.footer.text}
                  autoEscape={false}
                />
              </div>
              <div className={styles.messageList}>
                {message.body.action.sections.map(S => (
                  <div>
                    <div className={styles.messageListSection}>{S.title}</div>
                    {S.rows.map(I => (
                      <div className={styles.messageListItem}>
                        •
                        <div>
                          <div>{I.title}</div>
                          <div className={styles.messageListItemDescription}>{I.description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className={styles.messageButtons}>
                <div className={styles.messageButtonsItem}>{message.body.action.button}</div>
              </div>
            </div>
          </div>
        );
      }
      break;
    }
    default:
      break;
  }
  return result;
};

export default WhatAppPreview;
