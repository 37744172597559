import React from 'react';
import { Icon } from '@blueprintjs/core';

import { useUsersApi } from 'hooks/api';
import { useToken } from 'hooks';
import endpoints from 'consts/endpoints';

import Avatar from './Avatar';

import './style.scss';

const RightTools = () => {
  const { currentUser } = useUsersApi();
  const { resetAuth } = useToken();

  const logout = () => {
    fetch(endpoints.getLogoutUrl(), {
      method: 'POST',
      credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          resetAuth();
        }
      })
      .catch((error) => {
        console.error('ERROR:', error);
      });
  };

  return (
    <div className="header-right-tools">
      <Avatar
        name={currentUser.firstName}
      />
      <span className="header-right-tools__name">
        {`${currentUser.firstName} ${currentUser.lastName}\n(${currentUser.username})`}
      </span>
      <button
        className="header-right-tools__logout"
        type="button"
        onClick={logout}
        data-testid="log-out-button"
      >
        <Icon icon="log-out" size={12} color="#2264D1" />
      </button>
    </div>
  );
};

export default RightTools;
