import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getWaba as getWabaApi,
  deleteWaba as deleteWabaApi,
  putWaba as putWabaApi,
  postWaba as postWabaApi,
} from 'queries/waba';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetWaba,
      lastUpdated: lastUpdatedGetWaba,
    },
    getWaba,
  ] = useMutation(getWabaApi);
  const [
    {
      isPending: isPendingDeleteWaba,
    },
    deleteWaba,
  ] = useMutation(deleteWabaApi);
  const [{ isPending: isPendingPutWaba }, putWaba] = useMutation(putWabaApi);
  const [{ isPending: isPendingPostWaba }, postWaba] = useMutation(postWabaApi);

  const selector = useCallback(state => ({
    waba: getArray(state, 'waba'),
    wabaMeta: getObject(state, 'wabaMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getWaba,
    deleteWaba,
    putWaba,
    postWaba,
    isPendingGetWaba,
    isPendingDeleteWaba,
    isPendingPutWaba,
    isPendingPostWaba,
    lastUpdatedGetWaba,
    ...data,
  };
};
