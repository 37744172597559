import endpoints from 'consts/endpoints';

export default ({
  successCallback = () => {},
  errorCallback = () => {},
} = {}) => ({
  url: endpoints.getResourcesDictionaryUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ data }) => ({
    resourcesOptions: data.map(({ id, name, serviceDomainId }) => ({
      value: id,
      label: name,
      serviceDomainId,
    })),
    resourcesDictionary: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    resourcesOptions: (oldValue, newValue) => newValue,
    resourcesDictionary: (oldValue, newValue) => newValue,
  },
});
