import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import { useServicesEnabledApi, useDictionariesApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { SERVICES_ENABLED } from 'consts/columns';
import Modal from 'components/Modal';

import ServiceEnabledForm from './ServiceEnabledForm';

import './style.scss';

const ServicesEnabledScreen = ({ screenRef }) => {
  const {
    getServicesEnabled,
    deleteServiceEnabled,
    putServiceEnabled,
    postServiceEnabled,
    isPendingGetServicesEnabled,
    isPendingPutServiceEnabled,
    isPendingDeleteServiceEnabled,
    isPendingPostServiceEnabled,
    lastUpdatedGetServicesEnabled,
    servicesEnabled,
    servicesEnabledMeta,
  } = useServicesEnabledApi();
  const {
    timezones,
    servicesOptions,
    getServicesDictionary,
    agreementsDictionary,
    agreementsOptions,
    getAgreementsDictionary,
    isPendingGetAgreementsDictionary,
  } = useDictionariesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getServicesEnabled,
    deleteFunc: deleteServiceEnabled,
    onRowClickCallback: (data) => {
      const agreementData = agreementsDictionary[data.agreementId];

      return {
        ...data,
        effectiveFrom: new Date(moment(data.effectiveFrom).tz(agreementData?.actualEffectiveFromTz).format('YYYY-MM-DDTHH:mm:ss')),
        effectiveTill: data.effectiveTill
          ? new Date(moment(data.effectiveTill).tz(agreementData?.actualEffectiveTillTz).format('YYYY-MM-DDTHH:mm:ss'))
          : null,
      };
    },
  });

  const agreementsOptionsModify = useMemo(() => agreementsOptions.map(item => ({
    value: item.value,
    label: `${item.companyName} - ${item.label}`,
    currency: item.currency,
    fromTz: item.actualEffectiveFromTz,
    tillTz: item.actualEffectiveTillTz,
  })), [agreementsOptions]);
  const filteredServicesOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 1), [servicesOptions]);
  const timezonesOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(I => (I.areaName === v.areaName)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);

  const onSubmit = (body) => {
    (editableRecord ? putServiceEnabled : postServiceEnabled)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getServicesDictionary();
    getAgreementsDictionary();
  }, []);

  return (
    <div className="services-enabled-layout">
      <Table
        tableHeader="TABS.SERVICES_ENABLED"
        columnDefs={SERVICES_ENABLED}
        rowData={servicesEnabled}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.SERVICES_ENABLED.ADD"
        limit={limit}
        total={servicesEnabledMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetServicesEnabled || !lastUpdatedGetServicesEnabled || isPendingGetAgreementsDictionary}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={SERVICES_ENABLED}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.SERVICES_ENABLED.SERVICE_ENABLED"
            editableRecord={editableRecord}
          >
            <ServiceEnabledForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingDeleteServiceEnabled
                || isPendingPostServiceEnabled
                || isPendingPutServiceEnabled
              }
              formValuesRef={formValuesRef}
              agreementsOptions={agreementsOptionsModify}
              servicesOptions={filteredServicesOptions}
              timezonesOptions={timezonesOptions}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default ServicesEnabledScreen;
