/* eslint-disable camelcase */
import React, { useRef, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  SwitchField,
  DropdownField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';
import { HTTP_VENDOR_TYPES_OPTIONS, IS_DEV } from 'consts';
import { HTTP_CONNECTION_SCHEMA } from 'consts/validationSchemas';

import './style.scss';

const SmppConnectionsForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {
    // по факту тру, там перевернуто, чтоб пользователю было понятнее
    enabled: false,
  },
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = ({
    marginTop,
    marginBottom,
    overlay,
  } = {}) => ({
    container: {
      marginBottom,
    },
    label: {
      marginTop,
    },
    overlay,
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);

  const isView = useMemo(() => mode === 'view', [mode]);
  const vendorTypesOptionsModify = useMemo(() => {
    if (IS_DEV || initialValues.vendorType === 'mkit_http_vk_ok') {
      return HTTP_VENDOR_TYPES_OPTIONS;
    }

    return HTTP_VENDOR_TYPES_OPTIONS.filter(item => item.value !== 'mkit_http_vk_ok');
  }, []);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    enabled,
    routes,
    application,
    service_id,
    template_url,
    media_url,
    // sender_email,
    // sender_name,
    ...values
  }) => {
    let vendorConfig = {};

    switch (values.vendorType) {
      case 'mkit_http_viber':
        vendorConfig = {
          service_id,
        };
        break;
      case 'mkit_http_gupshup':
        vendorConfig = {
          template_url,
        };
        break;
      case 'mkit_http_dialog360cloud':
        vendorConfig = {
          media_url,
        };
        break;
        // case 'mkit_http_elasticemail':
        //   vendorConfig = {
        //     api_key,
        //     sender_email,
        //     sender_name,
        //   };
        // break;
      default: break;
    }

    onSubmit({
      ...values,
      enabled: !enabled,
      ...(values.vendorType === 'mkit_http_viber' ? {
        vendorConfig,
      } : {
        application,
        routes: routes ? routes.split(',') : routes,
        vendorConfig,
      }),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={HTTP_CONNECTION_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="http-connections-form">
          {showTopShadow && <div className="http-connections-form__shadow" />}
          <div className="http-connections-form__content" ref={contentRef}>
            <Field
              name="name"
              component={TextField}
              label="INSTANCES.NAME"
              disabled={isView}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="vendorType"
              component={DropdownField}
              label="INSTANCES.VENDOR_TYPE"
              options={vendorTypesOptionsModify}
              disabled={isView}
              isRequired={!isView}
              styles={fieldStyle({
                marginTop: 16,
              })}
              placeholder="PLACEHOLDERS.SELECT"
              full
            />
            <Field
              name="vendorUrl"
              component={TextField}
              label="INSTANCES.HTTP.VENDOR_URL"
              disabled={isView}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            {values.vendorType === 'mkit_http_vk_ok' && (
              <>
                <Field
                  name="signatureKey"
                  component={TextField}
                  label="INSTANCES.HTTP.SIGNATURE_KEY"
                  disabled={isView}
                  styles={fieldStyle({ marginTop: 16 })}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  full
                />
                <Field
                  name="application"
                  component={TextField}
                  label="INSTANCES.HTTP.APPLICATION"
                  disabled={isView}
                  styles={fieldStyle({ marginTop: 16 })}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  full
                />
                <Field
                  name="routes"
                  component={TextField}
                  label="INSTANCES.HTTP.ROUTES"
                  disabled={isView}
                  styles={fieldStyle({ marginTop: 16 })}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  full
                />
              </>
            )}
            {values.vendorType === 'mkit_http_viber' && (
              <Field
                name="service_id"
                component={TextField}
                label="INSTANCES.HTTP.SERVICE_ID"
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ marginTop: 16 })}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                full
              />
            )}
            {values.vendorType === 'mkit_http_gupshup' && (
              <Field
                name="template_url"
                component={TextField}
                label="INSTANCES.HTTP.TEMPLATE_URL"
                disabled={isView}
                styles={fieldStyle({ marginTop: 16 })}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                full
              />
            )}
            {values.vendorType === 'mkit_http_dialog360cloud' && (
              <Field
                name="media_url"
                label="INSTANCES.SENDER_IDS.MEDIA_URL"
                styles={fieldStyle({ marginTop: 16 })}
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled={isView}
                isRequired
                full
              />
            )}
            {/* {values.vendorType === 'mkit_http_elasticemail' && ( */}
            {/*  <> */}
            {/*    <Field */}
            {/*      name="api_key" */}
            {/*      component={TextField} */}
            {/*      label="INSTANCES.API_KEY" */}
            {/*      disabled={isView} */}
            {/*      isRequired={!isView} */}
            {/*      styles={fieldStyle({ marginTop: 16 })} */}
            {/*      placeholder="PLACEHOLDERS.ENTER_VALUE" */}
            {/*      full */}
            {/*    /> */}
            {/*    <Field */}
            {/*      name="sender_email" */}
            {/*      component={TextField} */}
            {/*      label="INSTANCES.HTTP.SENDER_EMAIL" */}
            {/*      disabled={isView} */}
            {/*      isRequired={!isView} */}
            {/*      styles={fieldStyle({ marginTop: 16 })} */}
            {/*      placeholder="PLACEHOLDERS.ENTER_VALUE" */}
            {/*      full */}
            {/*    /> */}
            {/*    <Field */}
            {/*      name="sender_name" */}
            {/*      component={TextField} */}
            {/*      label="INSTANCES.HTTP.SENDER_NAME" */}
            {/*      disabled={isView} */}
            {/*      isRequired={!isView} */}
            {/*      styles={fieldStyle({ marginTop: 16 })} */}
            {/*      placeholder="PLACEHOLDERS.ENTER_VALUE" */}
            {/*      full */}
            {/*    /> */}
            {/*  </> */}
            {/* )} */}
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
          >
            <Field
              name="enabled"
              label={`CONTROLS.HTTP.DISABLE${values?.enabled ? 'D' : ''}`}
              styles={fieldStyle({ marginBottom: 24 })}
              component={SwitchField}
              disabled={isView}
            />
          </FormButtonsGroup>
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default SmppConnectionsForm;
