import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import moment from 'moment';

import { useModalLogic } from 'hooks';
import { useLegalEntitiesApi, useDictionariesApi } from 'hooks/api';
import { DropdownField, DatePickerField } from 'components/fields';
import ModalTable from 'components/ModalTable';
import { AGREEMENTS_MODAL } from 'consts/columns';

const AgreementsTable = ({
  legalEntityId,
  companyId,
  isOwnLegalEntities,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [resellingAgreements, setResellingAgreements] = useState([]);
  const [rowAgreementsTableData, setRowAgreementsTableData] = useState([]);
  const [idToDelete, setIdToDelete] = useState(null);
  const [isModalOpen, { openModal, closeModal }] = useModalLogic('');
  const formRef = useRef(null);
  const {
    getResellingAgreementList,
    postResellingAgreement,
    putResellingAgreement,
    deleteResellingAgreement,
    isPendingDeleteResellingAgreement,
    isPendingPostResellingAgreement,
    isPendingPutResellingAgreement,
  } = useLegalEntitiesApi();
  const {
    getAgreementsDictionary,
    agreementsDictionary,
    agreementsOptions,
    ownAgreementsOptions,
  } = useDictionariesApi();

  const agreementsOptionsModify = useMemo(
    () => (isOwnLegalEntities ? ownAgreementsOptions : agreementsOptions)
      .filter(agreement => agreement.companyId === companyId && agreement.legalEntityId === legalEntityId),
    [agreementsOptions, ownAgreementsOptions, companyId],
  );
  const formFieldConfig = [
    {
      type: 'dropdown',
      name: 'agreementId',
      component: DropdownField,
      options: agreementsOptionsModify,
      placeholder: 'PLACEHOLDERS.SELECT',
    },
    {
      type: 'date',
      name: 'effectiveFrom',
      component: DatePickerField,
      maxDate: values => values.effectiveTill,
      timePrecision: 'second',
    },
    {
      type: 'date',
      name: 'effectiveTill',
      component: DatePickerField,
      minDate: values => values.effectiveFrom,
      timePrecision: 'second',
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'previous': return 'var(--grey50)';
      case 'current': return 'var(--green50)';
      case 'upcoming': return 'var(--blue50)';
      default: return '';
    }
  };
  const getAgreementVilue = (row) => {
    if (row === 'newRow') {
      return {
        legalEntityId,
        companyId,
        effectiveFrom: null,
        effectiveTill: null,
      };
    }
    const agreement = resellingAgreements.find(item => item.id === row);
    return {
      ...agreement,
      effectiveFrom: agreement.effectiveFrom
        ? moment(`${agreement.effectiveFrom}+0000`).toDate()
        : null,
      effectiveTill: agreement.effectiveTill
        ? moment(`${agreement.effectiveTill}+0000`).toDate()
        : null,
    };
  };
  const conversionRowData = item => (
    {
      id: item.id,
      config: {
        statusAgreement: {
          type: 'string',
          value: item.status,
          style: getStatusColor(item.status),
        },
        agreementNameAndCurrency: {
          type: 'string',
          value: agreementsDictionary[item.agreementId]?.agreementCode,
          secondValue: agreementsDictionary[item.agreementId]?.currency,
        },
        effectiveFrom: {
          type: 'date',
          value: item.effectiveFrom,
        },
        effectiveTill: {
          type: 'date',
          value: item.effectiveTill,
        },
      },
    });
  const onCloseModal = () => {
    if (idToDelete) {
      setIdToDelete(null);
    }
    closeModal();
  };
  const onDeleteClick = (agreementId) => {
    const agreement = resellingAgreements.find(item => item.id === agreementId);
    setIdToDelete(agreement.id);

    if (!agreementId.effectiveFrom || moment.utc(agreementId.effectiveFrom).isSameOrBefore(moment().utc())) {
      openModal('confirmChanges');
    } else {
      openModal('delete');
    }
  };
  const confirmDelete = () => {
    deleteResellingAgreement({
      id: idToDelete,
      successCallback: () => {
        setIdToDelete(null);
        closeModal();
        getResellingAgreementList({
          legalEntityId,
          successCallback: (data) => {
            setResellingAgreements(data);
            setRowAgreementsTableData(
              data.map(item => conversionRowData(item)),
            );
          },
        });
      },
    });
  };
  const sendQuery = ({
    id,
    effectiveFrom,
    effectiveTill,
    status,
    ...body
  }) => {
    (id ? putResellingAgreement : postResellingAgreement)({
      id,
      body: {
        ...body,
        effectiveFrom: effectiveFrom ? moment.utc(effectiveFrom).format('YYYY-MM-DD HH:mm:ss') : null,
        effectiveTill: effectiveTill ? moment.utc(effectiveTill).format('YYYY-MM-DD HH:mm:ss') : null,
      },
      successCallback: () => {
        setActiveRow(null);
        closeModal();
        getResellingAgreementList({
          legalEntityId,
          successCallback: (data) => {
            setResellingAgreements(data);
            setRowAgreementsTableData(
              data.map(item => conversionRowData(item)),
            );
          },
        });
      },
    });
  };
  const confirmChanges = () => {
    sendQuery(formRef.current.values);
  };
  const onSubmit = (values) => {
    const { initialValues } = formRef.current;
    const dateNow = moment().utc();

    if ((!values.effectiveFrom || moment.utc(values.effectiveFrom).isSameOrBefore(dateNow)) || (initialValues.id && (
      !initialValues.effectiveFrom || moment.utc(initialValues.effectiveFrom).isSameOrBefore(dateNow))
    )) {
      openModal('confirmChanges');
    } else {
      sendQuery(values);
    }
  };

  useEffect(() => {
    getAgreementsDictionary();
    getResellingAgreementList({
      legalEntityId,
      successCallback: (data) => {
        setResellingAgreements(data);
        setRowAgreementsTableData(
          data.map(item => conversionRowData(item)),
        );
      },
    });
  }, []);

  return (
    <ModalTable
      rowData={rowAgreementsTableData}
      columnsName={AGREEMENTS_MODAL}
      onSubmit={onSubmit}
      onDeleteClick={onDeleteClick}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      confirmDelete={confirmDelete}
      idToDelete={idToDelete}
      isPending={{
        post: isPendingPostResellingAgreement,
        put: isPendingPutResellingAgreement,
        delete: isPendingDeleteResellingAgreement,
      }}
      confirmChanges={confirmChanges}
      formFieldConfig={formFieldConfig}
      getInitialVilue={getAgreementVilue}
      formRef={formRef}
      addAndEditRow
    />
  );
};
export default AgreementsTable;
