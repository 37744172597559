import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CHANNELS_BY_ID } from 'consts';

import './style.scss';

const OperationCellRenderer = ({
  data: {
    rowCount,
    body,
  },
  value,
}) => {
  const { t } = useTranslation();

  const entriesCount = useMemo(() => {
    if (rowCount) {
      return Object.values(rowCount).reduce((acc, item) => {
        acc += item;
        return acc;
      }, 0);
    }
    return 0;
  }, [rowCount]);
  const channelName = useMemo(() => {
    const channelId = body?.channel;
    if (channelId) {
      return CHANNELS_BY_ID[channelId];
    }
    return null;
  }, [body]);

  return (
    <div className="operation-cell-renderer">
      {t(`SCREENS.AUDIT.${value}`, { channelName })}
      <span className="operation-cell-renderer__count">
        {`${entriesCount} `}
        {t('SCREENS.AUDIT.ENTRIES')}
      </span>
    </div>
  );
};

export default OperationCellRenderer;
