import React from 'react';

import { ReportBlock } from '../../index';

export default ({
  postCreateReport,
  report,
  isPendingPostCreateReport,
}) => (
  <div className="run-report-block__tab-body">
    <ReportBlock
      postCreateReport={postCreateReport}
      report={report}
      loading={isPendingPostCreateReport}
    />
  </div>
);
