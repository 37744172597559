import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getModerations as getModerationsApi,
  deleteModeration as deleteModerationApi,
  putModeration as putModerationApi,
  postResolution as postResolutionApi,
  postModeration as postModerationApi,
} from 'queries/moderation';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetModerations,
      lastUpdated: lastUpdatedGetModerations,
    },
    getModerations,
  ] = useMutation(getModerationsApi);

  const [
    {
      isPending: isPendingDeleteModeration,
      lastUpdated: lastUpdatedDeleteModeration,
    },
    deleteModeration,
  ] = useMutation(deleteModerationApi);

  const [
    {
      isPending: isPendingPutModeration,
      lastUpdated: lastUpdatedPutModeration,
    },
    putModeration,
  ] = useMutation(putModerationApi);

  const [
    {
      isPending: isPendingPostResolution,
      lastUpdated: lastUpdatedPostResolution,
    },
    postResolution,
  ] = useMutation(postResolutionApi);

  const [
    {
      isPending: isPendingPostModeration,
      lastUpdated: lastUpdatedPostModeration,
    },
    postModeration,
  ] = useMutation(postModerationApi);

  const selector = useCallback(state => ({
    moderations: getArray(state, 'moderations'),
    moderationsMeta: getObject(state, 'moderations'),
    moderationsPending: getArray(state, 'moderationsPending'),
    moderationsPendingMeta: getObject(state, 'moderationsPendingMeta'),
    moderationsHistory: getArray(state, 'moderationsHistory'),
    moderationsHistoryMeta: getObject(state, 'moderationsHistoryMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getModerations,
    deleteModeration,
    putModeration,
    postResolution,
    postModeration,
    isPendingGetModerations,
    isPendingPutModeration,
    isPendingPostResolution,
    isPendingPostModeration,
    isPendingDeleteModeration,
    lastUpdatedGetModerations,
    lastUpdatedDeleteModeration,
    lastUpdatedPostResolution,
    lastUpdatedPostModeration,
    lastUpdatedPutModeration,
    ...data,
  };
};
