import endpoints from 'consts/endpoints';

export default ({
  id,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getSenderUrl(id),
  queryKey: `delete${endpoints.getSenderUrl(id)}`,
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
