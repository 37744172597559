import React from 'react';

import { useHttpConnectionsApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { HTTP_CONNECTIONS } from 'consts/columns';
import Modal from 'components/Modal';

import HttpConnectionsForm from './HttpConnectionsForm';

import './style.scss';

const HttpConnectionsScreen = ({ screenRef }) => {
  const {
    getHttpConnections,
    postHttpConnection,
    putHttpConnection,
    deleteHttpConnection,
    httpConnections,
    httpConnectionsMeta,
    isPendingGetHttpConnections,
    isPendingPostHttpConnection,
    isPendingPutHttpConnection,
    isPendingDeleteHttpConnection,
    lastUpdatedGetHttpConnections,
  } = useHttpConnectionsApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    onSortChanged,
    onFilterChanged,
  } = useScreen({
    screenRef,
    getFunc: getHttpConnections,
    deleteFunc: deleteHttpConnection,
    useNewFilters: true,
    onRowClickCallback: ({ vendorConfig, routes, ...data }) => ({
      ...data,
      ...vendorConfig,
      routes: routes?.toString() || routes,
      enabled: !data.enabled,
    }),
  });

  const onSubmit = (body) => {
    (editableRecord ? putHttpConnection : postHttpConnection)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  return (
    <div className="http-connections-layout">
      <Table
        tableHeader="TABS.HTTP_CONNECTIONS"
        columnDefs={HTTP_CONNECTIONS}
        rowData={httpConnections}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.HTTP.ADD"
        limit={limit}
        total={httpConnectionsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        sendQuery={sendQuery}
        isPending={isPendingGetHttpConnections || !lastUpdatedGetHttpConnections}
        tableRef={tableRef}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={HTTP_CONNECTIONS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.HTTP.HTTP_CONNECTION"
            editableRecord={editableRecord}
          >
            <HttpConnectionsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingDeleteHttpConnection || isPendingPostHttpConnection || isPendingPutHttpConnection
              }
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default HttpConnectionsScreen;
