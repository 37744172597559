import endpoints from 'consts/endpoints';

export default ({
  taskId,
  limit,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  url: endpoints.getRateImportFilePreviewUrl(taskId),
  body: {
    limit,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: data => ({
    rateImportInfo: data,
  }),
  update: {
    rateImportInfo: (oldValue = {}, newValue) => ({
      ...oldValue,
      filePreview: newValue,
    }),
  },
});
