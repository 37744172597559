import endpoints from 'consts/endpoints';

export default ({
  id,
  postfix,
  body = {},
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  body,
  url: endpoints.getInvoiceUrl(id, postfix),
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({
    invoices: response.data,
  }),
  update: {
    invoices: (oldValue = [], newValue) => oldValue.map((invoice) => {
      if (invoice.id === newValue.id) {
        return { ...newValue };
      }
      return { ...invoice };
    }),
  },
});
