import isFunction from 'lodash/isFunction';
import { actionTypes } from 'redux-query';

import toastRef from 'helpers/toast';

const getMessage = (responseBody) => {
  if (responseBody?.key) {
    return { key: responseBody.key, values: responseBody?.args };
  }
  return responseBody?.message
      || responseBody?.data?.message
      || 'An error occurred while executing the request.';
};

export default () => next => (action) => {
  if (action.type === actionTypes.REQUEST_FAILURE
      || action.type === actionTypes.MUTATE_FAILURE) {
    if (action.status === 401 && window.location.pathname !== '/auth') {
      const message = 'Authorization error! You will be redirected to the login page.';

      if (!toastRef.current?.getToasts()?.find(item => item.message === message)) {
        toastRef.current?.show({
          message,
          intent: 'danger',
        });

        setTimeout(async () => {
          await localStorage.setItem('boToken', null);
          await localStorage.setItem('boTokenExpiresIn', null);
          await localStorage.setItem('boRefreshTokenExpiresIn', null);
          await localStorage.setItem('boIdToken', null);
          window.location.reload();
        }, 5000);
      }
    } else {
      const errorCallback = action?.meta?.errorCallback;

      if (errorCallback && isFunction(errorCallback)) {
        errorCallback(action.responseBody, action.status);
      }
    }
    if (!action?.meta?.hideToast) {
      toastRef?.current?.showMessage({
        message: getMessage(action?.responseBody),
        intent: 'danger',
      });
    }
  }

  return next(action);
};
