export const legendItemName = () => {
  const style = getComputedStyle(document.body);

  return {
    style: () => ({
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 20,
      fill: style.getPropertyValue('--grey50'),
    }),
  };
};

export const axisGridConfig = () => {
  const style = getComputedStyle(document.body);

  return ({
    line: {
      style: {
        stroke: style.getPropertyValue('--grey10'),
      },
    },
  });
};

export const tooltipDomStyles = () => {
  const style = getComputedStyle(document.body);

  return {
    'g2-tooltip': {
      backgroundColor: style.getPropertyValue('--white'),
      boxShadow: style.getPropertyValue('--greyShadow8dp'),
      opacity: 1,
      borderRadius: '8px',
    },
    'g2-tooltip-name': {
      color: style.getPropertyValue('--grey50'),
      textTransform: 'capitalize',
      fontSize: 13,
    },
    'g2-tooltip-value': {
      color: style.getPropertyValue('--black'),
      fontSize: 13,
    },
    'g2-tooltip-title': {
      color: style.getPropertyValue('--black'),
      fontSize: 13,
    },
  };
};

export const axisTextStyle = {
  style: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20,
  },
};
