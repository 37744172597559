import React from 'react';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import toastRef from 'helpers/toast';
import { copyInClipboard } from 'helpers';

import styles from './SuggestionItem.module.scss';

const SuggestionItem = ({
  text,
  category,
  isInside = true,
  suggestionType = 'reply',
  url,
  phoneNumber,
  latitude,
  longitude,
  startTime,
  endTime,
}) => {
  const { t } = useTranslation();

  const className = classNames(styles.outside, {
    [styles.inside]: isInside,
    [styles.action]: suggestionType === 'action',
  });

  const onCopy = (contentToCopy) => {
    copyInClipboard(contentToCopy);
    toastRef?.current?.show({ message: t('TOASTS.COPY_IN_CLIPBOARD') });
  };

  return (
    <div
      className={className}
    >
      <div>
        {(category === 'urlAction' && suggestionType === 'action')
            && <Icon className={styles.icon} icon="globe" color="var(--grey30)" size={20} />}
        {(category === 'dialerAction' && suggestionType === 'action')
            && <Icon className={styles.icon} icon="phone" color="var(--grey30)" size={20} />}
        {(category === 'mapAction' && suggestionType === 'action')
            && <Icon className={styles.icon} icon="map-marker" color="var(--grey30)" size={20} />}
        {(category === 'calendarAction' && suggestionType === 'action')
            && <Icon className={styles.icon} icon="calendar" color="var(--grey30)" size={20} />}
      </div>
      {suggestionType === 'action' && (
        <div className={styles.buttonAction}>
          {(category === 'urlAction' && suggestionType === 'action') && (
            <div className={styles.urlActionContainer}>
              <div className={styles.urlContainer}>
                <a href={url} title={url}>{url}</a>
              </div>
              <Icon
                className={styles.copyIcon}
                icon="duplicate"
                color="var(--grey30)"
                size={20}
                onClick={() => onCopy(url)}
              />
            </div>
          )}
          {(category === 'dialerAction' && suggestionType === 'action') && <span title={phoneNumber}>{phoneNumber}</span>}
          {(category === 'mapAction' && suggestionType === 'action') && (
            <span title={`${latitude}, ${longitude}`}>
              {`${latitude}, ${longitude}`}
            </span>
          )}
          {(category === 'calendarAction' && suggestionType === 'action') && (
            <span title={`${moment(startTime).format('DD.MM.YYYY HH:mm')} - ${moment(endTime).format('DD.MM.YYYY HH:mm')}`}>
              {`${moment(startTime).format('DD.MM.YYYY HH:mm')} - ${moment(endTime).format('DD.MM.YYYY HH:mm')}`}
            </span>
          )}
        </div>
      )}
      {text}
    </div>
  );
};

export default SuggestionItem;
