import React, {
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { useWindowSize } from 'react-use';

import {
  ListFilter,
  DateFilter,
  MaskFilter,
  NumberFilter,
} from 'components/forms';
import Spinner from '@setproduct-ui/core/Spinner';

import '@ag-grid-community/core/dist/styles/ag-grid.css';

import AgColumnHeader from './AgColumnHeader';
import ButtonsCellRenderer from './ButtonsCellRenderer';
import DateCellRenderer from './DateCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
import ConfigCellRenderer from './ConfigCellRenderer';
import DirectionCellRenderer from './DirectionCellRenderer';
import TextCellRenderer from './TextCellRenderer';
import ChipsCellRenderer from './ChipsCellRenderer';
import RateCellRenderer from './RateCellRenderer';
import RatesCellRenderer from './RatesCellRenderer';
import SendersCellRenderer from './SendersCellRenderer';
import NumberCellRenderer from './NumberCellRenderer';
import SourcesCellRenderer from './SourcesCellRenderer';
import InvoiceStatusCellRenderer from './InvoiceStatusCellRenderer';
import TreeCellRenderer from './TreeCellRenderer';
import DateTimeCellRenderer from './DateTimeCellRenderer';
import ReportCellRenderer from './ReportCellRenderer';
import ChannelCellRenderer from './ChannelCellRenderer';
import StyledTextRenderer from './StyledTextRenderer';
import ServicesEnabledRenderer from './ServicesEnabledRenderer';
import ChannelConfigCellRenderer from './ChannelConfigCellRenderer';
import NotificationTextCellRenderer from './NotificationTextCellRenderer';
import NotificationVariableCellRenderer from './NotificationVariableCellRenderer';
import NotificationReceiversCellRenderer from './NotificationReceiversCellRenderer';
import CompanyWithChipCellRenderer from './CompanyWithChipCellRenderer';
import MapDictionaryCellRenderer from './MapDictionaryCellRenderer';
import OperationCellRenderer from './OperationCellRenderer';
import IconsCellRenderer from './IconsCellRenderer';
import FileCellRenderer from './FileCellRenderer';
import ChannelsCellRenderer from './ChannelsCellRenderer';
import PortalImageCell from './PortalImageCell';
import ExpandRowCellRenderer from './ExpandRowCellRenderer';

import './style.scss';

const areEqual = (prevProps, nextProps) => {
  // ререндер нужен если:
  if (
    prevProps.rowData !== nextProps.rowData
    || prevProps.loading !== nextProps.loading
    || prevProps.columnDefs !== nextProps.columnDefs
    || prevProps.filteredColumns !== nextProps.filteredColumns
    || prevProps.onRowDoubleClicked !== nextProps.onRowDoubleClicked
    || prevProps.pinnedBottomRowData !== nextProps.pinnedBottomRowData
  ) {
    return false;
  }

  return true;
};

export default React.memo(
  ({
    className,
    columnDefs,
    rowData = [],
    rowKey = 'id',
    onFilterChanged,
    onSortChanged,
    rowSelection,
    onSelectionChanged,
    loading,
    resizableColDef = true,
    tableRef,
    onRowClicked,
    filteredColumns = {},
    rowHeight = 32,
    denseRowHeight = 28,
    headerHeight = rowHeight,
    denseHeaderHeight = denseRowHeight,
    sendQuery = Function.prototype,
    agColumnHeader = AgColumnHeader,
    pinnedTopRowData,
    additionalFrameworkComponent = {},
    ...props
  }) => {
    console.count('re-render');
    const agGridRef = useRef();

    const { width } = useWindowSize();

    const currentRowHeight = useRef(rowHeight);
    const currentHeaderHeight = useRef(headerHeight);

    const defaultColDef = {
      resizable: resizableColDef,
      minWidth: 105,
      lockPosition: true,
      sortable: true,
      comparator: Function.prototype,
    };
    const frameworkComponents = {
      maskColumnFilter: MaskFilter,
      listColumnFilter: ListFilter,
      dateColumnFilter: DateFilter,
      numberColumnFilter: NumberFilter,
      cellRendererActions: ButtonsCellRenderer,
      cellRendererDate: DateCellRenderer,
      cellRendererStatus: StatusCellRenderer,
      cellRendererDirection: DirectionCellRenderer,
      cellRendererConfig: ConfigCellRenderer,
      cellRendererText: TextCellRenderer,
      cellRendererChips: ChipsCellRenderer,
      cellRendererRate: RateCellRenderer,
      cellRendererRates: RatesCellRenderer,
      cellRendererSenders: SendersCellRenderer,
      cellRendererNumber: NumberCellRenderer,
      cellRendererSources: SourcesCellRenderer,
      cellRendererInvoiceStatus: InvoiceStatusCellRenderer,
      cellRendererTree: TreeCellRenderer,
      cellRendererDateTime: DateTimeCellRenderer,
      cellRendererReport: ReportCellRenderer,
      cellRendererChannel: ChannelCellRenderer,
      cellRendererStyled: StyledTextRenderer,
      cellRendererServices: ServicesEnabledRenderer,
      cellRendererChannelConfig: ChannelConfigCellRenderer,
      cellRendererNotificationText: NotificationTextCellRenderer,
      cellRendererNotificationVariable: NotificationVariableCellRenderer,
      cellRendererNotificationReceivers: NotificationReceiversCellRenderer,
      cellRendererCompanyWithChip: CompanyWithChipCellRenderer,
      cellRendererDictionaryMap: MapDictionaryCellRenderer,
      cellRendererOperation: OperationCellRenderer,
      cellRendererIcons: IconsCellRenderer,
      cellRendererChannels: ChannelsCellRenderer,
      cellRendererFile: FileCellRenderer,
      cellRendererPortalImage: PortalImageCell,
      cellRendererExpandRow: ExpandRowCellRenderer,
      agColumnHeader,
      agLoadingOverlay: Spinner,
      ...additionalFrameworkComponent,
    };

    const handleFilterChanged = ({ api }) => {
      onFilterChanged(api.getFilterModel());
    };
    const handleSortChanged = ({ api }) => {
      onSortChanged(api.getSortModel());
    };
    const handleRowClicked = ({ node, ...params }) => {
      if (onRowClicked) {
        if (!node.gridOptionsWrapper.gridOptions.isPropagationStopped) {
          onRowClicked({ node, ...params });
        }
      }
    };
    const getRowNodeId = row => row[rowKey];

    useImperativeHandle(tableRef, () => ({
      setFilters(filters) {
        agGridRef.current.api.setFilterModel(filters);
      },
      redrawRows() {
        agGridRef.current.api.redrawRows();
      },
      deselectAll() {
        agGridRef.current.api.deselectAll();
      },
      selectAll() {
        agGridRef.current.api.selectAll();
      },
      selectNode({ id }) {
        const node = agGridRef.current.api.getRowNode(id);
        if (node) {
          node.setSelected(true);
        }
      },
      setSort(columnKey, sortType) {
        agGridRef.current.api.setSortModel(
          columnKey && sortType
            ? [{ colId: columnKey, sort: sortType }]
            : [],
        );
      },
    }));
    useEffect(() => {
      if (loading) {
        agGridRef.current.api.showLoadingOverlay();
      } else if (!rowData.length) {
        agGridRef.current.api.showNoRowsOverlay();
      } else {
        agGridRef.current.api.hideOverlay();
      }
    }, [loading, rowData]);
    useEffect(() => {
      const hiddenColumns = [];
      const visibleColumns = [];

      const allColumns = agGridRef.current.columnApi.getAllColumns();
      if (allColumns) {
        allColumns.forEach(({ colId, visible, colDef }) => {
          if (
            filteredColumns[colId] === false
            && visible
            && !colDef.lockVisible
          ) {
            hiddenColumns.push(colId);
          } else if (filteredColumns[colId] === true && !visible) {
            visibleColumns.push(colId);
          }
        });
      }

      if (hiddenColumns.length) {
        agGridRef.current.columnApi.setColumnsVisible(hiddenColumns, false);
      }

      if (visibleColumns.length) {
        agGridRef.current.columnApi.setColumnsVisible(visibleColumns, true);
        // обновление строк, что бы не было случаев
        // когда ячейки с данными находятся не под своим заголовком
        agGridRef.current.api.redrawRows();
      }
    }, [filteredColumns]);

    // const getRowHeight = () => currentRowHeight.current;

    useEffect(() => {
      if (width <= 1366) {
        if (currentRowHeight.current !== denseRowHeight) {
          currentRowHeight.current = denseRowHeight;
        }
        if (currentHeaderHeight.current !== denseHeaderHeight) {
          currentHeaderHeight.current = denseHeaderHeight;
          agGridRef.current.api.setHeaderHeight(denseHeaderHeight);
        }
      } else {
        if (currentRowHeight.current !== rowHeight) {
          currentRowHeight.current = rowHeight;
        }
        if (currentHeaderHeight.current !== headerHeight) {
          currentHeaderHeight.current = headerHeight;
          agGridRef.current.api.setHeaderHeight(headerHeight);
        }
      }
    }, [width, currentRowHeight.current, currentHeaderHeight.current]);

    useEffect(() => {
      agGridRef.current.api.resetRowHeights();
    }, [currentRowHeight.current]);

    // Не помню для чего это делалось, мб рудимент. Из-за него были лишние блики таблицы
    // useEffect(() => {
    //   if (!loading && rowData.length) {
    //     setTimeout(() => {
    //       if (agGridRef.current) {
    //         agGridRef.current.api.setRowData(rowData);
    //         agGridRef.current.api.redrawRows();
    //       }
    //     }, 100);
    //   }
    // }, [rowData, loading]);

    useEffect(() => {
      agGridRef.current.api.sendQuery = sendQuery;
    }, []);

    return (
      <div
        className={`ag-table ${className}`}
      >
        <AgGridReact
          {...props}
          ref={agGridRef}
          headerHeight={currentHeaderHeight.current}
          rowHeight={currentRowHeight.current}
          onRowClicked={handleRowClicked}
          getRowNodeId={getRowNodeId}
          rowData={rowData}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          frameworkComponents={frameworkComponents}
          // onGridReady={onGridReady}
          onSortChanged={handleSortChanged}
          onFilterChanged={handleFilterChanged}
          pinnedTopRowData={pinnedTopRowData}
          suppressColumnVirtualisation
          enableBrowserTooltips
        />
      </div>
    );
  },
  areEqual,
);
