import { saveAs } from 'file-saver';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToggle, useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import { useFilteredColumns, useTable, useToken } from 'hooks';
import { CustomColumnsView } from 'components/blocks';
import { Pagination } from 'components/Pagination';
import Button from '@setproduct-ui/core/Button/Button';
import endpoints from 'consts/endpoints';
import { FileDocument } from 'assets/icons';

import './style.scss';

export default ({
  postCreateReport,
  report,
  loading,
}) => {
  const paginationStyles = {
    container: {
      marginLeft: 'auto',
    },
  };
  const { t } = useTranslation();
  const downloadOverlayRef = useRef();
  const tableRef = useRef(null);
  const [isDownloading, setIsDownloading] = useToggle(false);
  const [showDownloadDropdown, setDownloadDropdown] = useToggle(false);
  const [columns, setColumns] = useState(report.columns);
  const { token } = useToken();

  useClickAway(downloadOverlayRef, () => {
    setDownloadDropdown(false);
  });

  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns();

  const {
    storageKeyColumns,
    storageKeyFilters,
  } = useMemo(() => {
    const nameReport = report.name.replace(/\s+/g, '').toLowerCase();
    return {
      storageKeyColumns: `columns_${window.location.pathname}_${nameReport}`,
      storageKeyFilters: `filters_${window.location.pathname}_${nameReport}`,
    };
  }, [report.name]);

  const onPressDownloadFile = (type) => {
    setIsDownloading(true);
    setDownloadDropdown(false);
    const oReq = new XMLHttpRequest();
    oReq.open('POST', endpoints.getReportFileUrl(), true);
    oReq.responseType = 'blob';
    oReq.setRequestHeader('Authorization', `Bearer ${token}`);

    oReq.send(JSON.stringify({
      name: report.name,
      templateId: report.templateId,
      parameters: report.initialParameters,
      type,
    }));

    oReq.onload = () => {
      setIsDownloading(false);
      saveAs(
        oReq.response,
        `${report.name}.${type}`,
      );
    };
  };

  const {
    offset,
    limit,
    setAndSendLimit,
    setAndSendOffset,
    onSortChanged,
    onFilterChanged,
    sendQuery,
  } = useTable({
    tableRef,
    useNewFilters: true,
    initialFilters: JSON.parse(window.sessionStorage.getItem(storageKeyFilters)),
    sendQuery: (body) => {
      let valuesModify = {};

      Object.keys(report.parameters).forEach((key) => {
        valuesModify = {
          ...valuesModify,
          [key]: report.parameters[key] || '',
        };
      });

      postCreateReport({
        body: {
          name: report.name,
          params: valuesModify,
          templateId: report.templateId,
          ...body,
          offset: body?.offset ? ((body.offset - 1) * body.limit) : body.offset,
        },
      });
    },
  });

  const onFilterChangedModify = (newFilters) => {
    window.sessionStorage.setItem(storageKeyFilters, JSON.stringify(newFilters));
    onFilterChanged(newFilters);
  };

  useEffect(() => {
    if (report.columns.length > 0) {
      window.sessionStorage.setItem(storageKeyColumns, JSON.stringify(columns, (key, value) =>
        (key === 'columnParams' ? undefined : value)));
    } else {
      setColumns(JSON.parse(window.sessionStorage.getItem(storageKeyColumns)));
    }
  }, []);

  return (
    <div className="reports-table-layout">
      <Table
        tableRef={tableRef}
        isPending={loading}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChangedModify}
        columnDefs={columns}
        rowData={report.list}
        openCustomColumns={openCustomViewDrawer}
        filteredColumns={filteredColumns}
        withPagination={false}
        sendQuery={sendQuery}
      />
      <div className="reports-table-layout__footer">
        <div className="reports-table-layout__row" style={{ width: '100%' }}>
          <div
            ref={downloadOverlayRef}
            className="reports-table-layout__download-container"
          >
            {showDownloadDropdown && (
              <div className="reports-table-layout__file-dropdown">
                <div
                  role="presentation"
                  className="reports-table-layout__dropdown-item"
                  onClick={() => onPressDownloadFile('csv')}
                  title={t('TOOLTIPS.REPORTS.UNLIMITED_ROWS')}
                >
                  <FileDocument style={{ marginRight: 6 }} />
                  {t('INSTANCES.REPORT.EXPORT_FILE', { type: 'CSV' })}
                </div>
              </div>
            )}
            <Button
              onClick={setDownloadDropdown}
              type="default"
              color="primary"
              // className="rates-layout__expand-button"
              icon="export"
              view="outlined"
              text="Export report as ..."
              loading={isDownloading}
              dense
            />
          </div>
          <Pagination
            total={report?.size}
            limit={limit}
            page={offset}
            onChangePage={setAndSendOffset}
            onChangeLimit={setAndSendLimit}
            onRefreshClick={sendQuery}
            isPending={loading}
            styles={paginationStyles}
          />
        </div>
      </div>
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={report.columns}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};
