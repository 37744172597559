import React, { useState } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';

import './style.scss';

const PopoverEditBlock = ({
  onIconClick,
  title,
  onSave,
  children,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isPopoverShow, setPopoverShow] = useState(false);

  const handleInteraction = (nextOpenState) => {
    if (!nextOpenState) {
      onClose();
    }
    setPopoverShow(nextOpenState);
  };

  const closePopover = () => {
    setPopoverShow(false);
    onClose();
  };
  const onSaveModify = () => {
    onSave();
    closePopover();
  };

  return (
    <Popover2
      isOpen={isPopoverShow}
      onInteraction={handleInteraction}
      placement="bottom-end"
      minimal
      usePortal
      transitionDuration={0}
      interactionKind="click"
      renderTarget={({ ref, isOpen, ...targetProps }) => (
        <div
          ref={ref}
          style={{ marginLeft: 'auto' }}
          {...targetProps}
        >
          <Button
            text="CONTROLS.EDIT"
            icon="edit"
            view="smooth"
            onClick={onIconClick}
            dense
          />
        </div>
      )}
      content={(
        <div className="popover-edit-block__overlay">
          <span className="popover-edit-block__title">
            {t(title)}
          </span>
          {children}
          <div className="popover-edit-block__footer">
            <Button
              text="CONTROLS.CANCEL"
              color="primary"
              view="outlined"
              onClick={closePopover}
              dense
            />
            <Button
              text="CONTROLS.SAVE"
              color="primary"
              view="filled"
              onClick={onSaveModify}
              disabled={disabled}
              dense
            />
          </div>
        </div>
      )}
    />
  );
};

export default PopoverEditBlock;
