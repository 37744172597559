import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components';
import Dialog from '@setproduct-ui/core/Dialog/Dialog';

import RecordInfo from './RecordInfo';

import './style.scss';

export default ({
  editableRecord,
  className,
  mode,
  closeModal,
  children,
  title,
  stickyHeader,
  size,
  withTitle = true,
  titleComponent,
  styles,
  backdropOpacity = 40,
  contextMenuItems,
  withInfo = true,
  withCloseButton,
}) => {
  const { t } = useTranslation();

  const modalClass = classNames('modal', {
    modal_lg: size === 'lg',
    modal_lx: size === 'lx',
    [className]: className,
  });

  const titleText = useMemo(
    () =>
      `${mode ? `${t(`CONTROLS.${mode.toUpperCase()}`)} ` : ''} ${t(title)}`,
    [mode],
  );

  return (
    <Dialog
      type="default"
      view="raised"
      color="default"
      title={titleComponent || (withTitle && (
        <div className="modal__title" data-testid={`${window.location.pathname}/modal-title`}>
          <Typography className="modal__title__mode">{titleText}</Typography>
          {
            (!!editableRecord?.id && withInfo) && (
              <RecordInfo {...editableRecord} />
            )
          }
        </div>
      ))}
      backdropOpacity={backdropOpacity}
      isOpen
      onClose={closeModal}
      className={modalClass}
      stickyHeader={stickyHeader}
      canOutsideClickClose={false}
      styles={styles}
      contextMenuItems={contextMenuItems}
      withCloseButton={withCloseButton}
    >
      {children}
    </Dialog>
  );
};
