import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { decimal } from 'consts/inputPatterns';

import styles from './MappingRow.module.scss';

import Dropdown from '../../../ServiceTab/TreeTable/Row/Dropdown';

const MappingRow = ({
  initialKey,
  initialValue,
  setMappingRowsToUpdate,
  isPriceCol,
  options,
  style,
  selectedValue,
  setSelectedValue,
  flatValue,
  setFlatValue,
}) => {
  const { serviceType } = useSelector(state => state.states.rateImportConfig);

  const onChangeSelectedValue = (newVal) => {
    setSelectedValue(newVal);
    if (isPriceCol) {
      setFlatValue(null);
    }
    setMappingRowsToUpdate((prev) => {
      const filteredMapping = prev.filter(item => item.k !== initialKey);

      if (newVal !== initialValue) {
        filteredMapping.push({
          _delete: false,
          k: initialKey,
          v: isPriceCol
            ? { priceType: newVal, price: null }
            : newVal === 'null' ? '' : newVal,
          potentialValues: isPriceCol
            ? undefined
            : options.find(I => I.value === newVal)?.potentialValues,
        });
      }

      return filteredMapping;
    });
  };
  const onChangeFlatValue = ({ target }) => {
    const regExp = new RegExp(decimal({ decimalLimit: 5 }), 'i');

    if (regExp.test(target.value)) {
      setFlatValue(target.value);
      setMappingRowsToUpdate((prev) => {
        const filteredMapping = prev.filter(item => item.k !== initialKey);

        if (target.value !== initialValue) {
          filteredMapping.push({
            _delete: false,
            k: initialKey,
            v: {
              priceType: selectedValue,
              price: +target.value,
            },
          });
        }

        return filteredMapping;
      });
    }
  };
  return (
    <div className={styles.row} style={style}>
      <span
        className={cx(styles.key, {
          [styles.key_missmatchValue]: !initialValue && !selectedValue,
          [styles.key_resolved]: !initialValue && selectedValue,
        })}
      >
        {initialKey || '<Empty cell value>'}
      </span>
      {isPriceCol ? (
        <>
          <Dropdown
            value={selectedValue}
            onChange={onChangeSelectedValue}
            options={options}
            containerClassName={cx(styles.value, {
              [styles.value_changed]: (initialValue?.priceType ?? initialValue) !== selectedValue,
            })}
            wrapperStyle={isPriceCol ? { backgroundColor: 'transparent' } : undefined}
            withSearch
          />
          <input
            type="text"
            value={flatValue || (serviceType === 1 && selectedValue === 1 ? '' : '-')}
            onChange={onChangeFlatValue}
            className={styles.input}
            disabled={!selectedValue || !(serviceType === 1 && selectedValue === 1)}
          />
        </>
      ) : (
        <Dropdown
          value={selectedValue}
          onChange={onChangeSelectedValue}
          options={options}
          containerClassName={cx(styles.value, {
            [styles.value_changed]: initialValue !== selectedValue,
          })}
          withSearch
        />
      )}
    </div>
  );
};

export default MappingRow;
