import React, { useEffect, useRef } from 'react';

import styles from './EmailPreview.module.scss';

const EmailPreview = ({
  message,
}) => {
  const shadowRootRef = useRef();

  useEffect(() => {
    if (shadowRootRef.current) {
      const hostElement = shadowRootRef.current;

      if (!hostElement.shadowRoot) {
        const shadowRoot = hostElement.attachShadow({ mode: 'open' });

        shadowRoot.innerHTML = message;
      } else {
        hostElement.shadowRoot.innerHTML = message;
      }
    }
  }, [message]);

  return (
    <div className={styles.preview}>
      <div ref={shadowRootRef} />
    </div>
  );
};

export default EmailPreview;
