import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getNotificationsChannels as getNotificationsChannelsApi,
  getNotificationsScenarios as getNotificationsScenariosApi,
  putNotificationsScenario as putNotificationsScenarioApi,
  putNotificationsChannel as putNotificationsChannelApi,
} from 'queries/notifications';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetNotificationsChannels,
      lastUpdated: lastUpdatedGetNotificationsChannels,
    },
    getNotificationsChannels,
  ] = useMutation(getNotificationsChannelsApi);
  const [
    {
      isPending: isPendingGetNotificationsScenarios,
      lastUpdated: lastUpdatedGetNotificationsScenarios,
    },
    getNotificationsScenarios,
  ] = useMutation(getNotificationsScenariosApi);
  const [
    {
      isPending: isPendingPutNotificationsScenario,
    },
    putNotificationsScenario,
  ] = useMutation(putNotificationsScenarioApi);
  const [
    {
      isPending: isPendingPutNotificationsChannel,
    },
    putNotificationsChannel,
  ] = useMutation(putNotificationsChannelApi);

  const selector = useCallback(state => ({
    notificationsChannels: getArray(state, 'notificationsChannels'),
    notificationsScenarios: getArray(state, 'notificationsScenarios'),
    notificationsChannelsMeta: getObject(state, 'notificationsChannelsMeta'),
    notificationsScenariosMeta: getObject(state, 'notificationsScenariosMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    isPendingGetNotificationsChannels,
    lastUpdatedGetNotificationsChannels,
    isPendingGetNotificationsScenarios,
    lastUpdatedGetNotificationsScenarios,
    isPendingPutNotificationsScenario,
    isPendingPutNotificationsChannel,
    getNotificationsChannels,
    getNotificationsScenarios,
    putNotificationsScenario,
    putNotificationsChannel,
    ...data,
  };
};
