import React from 'react';
import { Icon } from '@blueprintjs/core';

import './style.scss';

const ChargePrice = ({
  price,
  service,
  date,
  errorMessage,
  style,
}) => {
  if (price) {
    return (
      <>
        <div className="charge-price" style={style}>
          <Icon
            icon="tick-circle"
            color="var(--green60)"
            style={{ marginRight: 6 }}
          />
          {price}
          <span className="charge-price__service-name">
            {service}
          </span>
        </div>
        <span className="charge-price__effective-date" style={{ marginLeft: style?.marginLeft }}>
          {date}
        </span>
      </>
    );
  }

  if (errorMessage) {
    return (
      <div className="charge-price charge-price_error" style={style}>
        <Icon
          icon="error"
          color="var(--red60)"
          style={{ marginRight: 6 }}
        />
        {errorMessage}
      </div>
    );
  }

  return null;
};

export default ChargePrice;
