import React from 'react';
import moment from 'moment';

import './style.scss';

export default ({
  value,
}) => (
  <div className="datetime-cell-renderer">
    <div className="datetime-cell-renderer__date">
      {moment(value).format('DD/MM/YY,')}
    </div>
    &#160;
    <div className="datetime-cell-renderer__time">
      {moment(value).format('hh:mm')}
    </div>
    <sup className="datetime-cell-renderer__timezone">
      {moment(value).utcOffset()
        ? `+${moment(value).utcOffset() / 60}`
        : moment(value).utcOffset() / 60}
    </sup>
  </div>
);
