import classNames from 'classnames';
import { useScroll } from 'react-use';
import React, { useRef, useMemo } from 'react';

import './style.scss';

export default ({
  children,
  className,
}) => {
  const wrapperRef = useRef(null);
  const { y } = useScroll(wrapperRef);

  const {
    showTopShadow,
    showBottomShadow,
  } = useMemo(() => {
    const { current } = wrapperRef;

    let showTopShadowModify = false;
    let showBottomShadowModify = false;

    if (current && current.scrollHeight > current.offsetHeight) {
      // -1 это костыль для формы аккаунтов
      const maxScrollValue = current.scrollHeight - current.offsetHeight - 1;

      if (y > 0) {
        showTopShadowModify = true;
      }
      if (y < maxScrollValue) {
        showBottomShadowModify = true;
      }
    }

    return {
      showTopShadow: showTopShadowModify,
      showBottomShadow: showBottomShadowModify,
    };
  }, [wrapperRef.current?.scrollHeight, wrapperRef.current?.offsetHeight, y]);

  const wrapperClass = classNames('form-content-wrapper', {
    'form-content-wrapper_top-shadow': showTopShadow,
    'form-content-wrapper_bottom-shadow': showBottomShadow,
  });

  return (
    <div className={wrapperClass}>
      <div className={`form-content-wrapper__content ${className}`} ref={wrapperRef}>
        {children}
      </div>
    </div>
  );
};
