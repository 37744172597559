import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';

import Button from '@setproduct-ui/core/Button';
import { round } from 'helpers';
import {
  INVOICE_DIRECTIONS,
  CHARGE_SOURCES,
  INVOICE_STATUSES,
  INVOICE_DISPUTE_STATUSES,
  CHARGE_DUE_SOURCES,
} from 'consts';
import { useChargesApi, useDictionariesApi } from 'hooks/api';
import { useTable, useUserPermissions } from 'hooks';
import DropdownField from 'components/fields/DropdownField';
import { InnerField } from 'components/fields/InnerField';
import { CHARGE_DETAILS } from 'consts/columns';

import DetailsBlock from '../DetailsBlock';
import DetailCell from '../DetailCell';
import PopoverEditBlock from '../PopoverEditBlock';

import './style.scss';

const fieldStyles = {
  label: {
    marginTop: 14,
  },
};
const rightFieldStyles = {
  container: {
    textAlign: 'center',
    width: 48,
    background: 'var(--grey0)',
    color: 'var(--grey50)',
    borderRadius: ' 0 4px 4px 0',
    margin: 0,
  },
};
const leftFieldStyles = {
  container: {
    boxShadow: 'none',
    borderRadius: ' 4px 0 0 4px',
    margin: 0,
  },
};
const innerStyles = {
  wrapper: {
    marginTop: 14,
  },
};

const ChargeDetails = ({
  id,
  invoiceReferenceCode,
  invoiceId,
  direction,
  source,
  billableCount,
  billableVolume,
  billableNetAmount,
  billableTaxAmount,
  billableTotalAmount,
  dueAmount,
  currency,
  invoiceStatus,
  periodStart,
  periodStartTz,
  periodEnd,
  periodEndTz,
  version,
  correctionFor,
  disputeStatus,
  dueSource,
  counterpartyAmount,
  disputedAmount,
  modifyTabData,
  updateChargesList,
  invoiceConfirmed,
  invoiceData,
  openNewTab,
  serviceId,
  resourceId,
}) => {
  const { t } = useTranslation();
  const { editAllowed } = useUserPermissions();
  const {
    getChargeDetails,
    patchCharge,
    isPendingPatchCharge,
  } = useChargesApi();
  const {
    getResourcesDictionary,
    getServicesDictionary,
    resourcesDictionary,
    servicesDictionary,
  } = useDictionariesApi();
  const [chargeDetails, setChargeDetails] = useState([]);
  const [chargeDetailsMeta, setChargeDetailsMeta] = useState({});
  const [newDisputeStatus, setNewDisputeStatus] = useState(disputeStatus);
  const [newDueSource, setNewDueSource] = useState(dueSource);
  const [newCounterpartyAmount, setNewCounterpartyAmount] = useState(counterpartyAmount);
  const {
    sendQuery,
    setAndSendLimit,
    setAndSendOffset,
    limit,
    offset,
    onSortChanged,
    onFilterChanged,
  } = useTable({
    sendQuery: props => getChargeDetails({
      id,
      successCallback: (res) => {
        setChargeDetails(res.data);
        setChargeDetailsMeta(res.meta);
      },
      ...props,
    }),
  });

  const cols = useMemo(() => {
    const colsModify = [];

    if (chargeDetailsMeta.cols) {
      colsModify.push(...CHARGE_DETAILS);

      chargeDetailsMeta.cols.forEach((col) => {
        colsModify.splice(2, 0, {
          headerName: col.displayName,
          field: 'cols',
          flex: 1,
          sortable: false,
          valueFormatter: ({ value }) => value[`${col.fieldName}`],
        });
      });
    }

    return colsModify;
  }, [chargeDetailsMeta]);

  const resourceLabel = useMemo(
    () =>
      resourcesDictionary?.[resourceId]?.name || `Deleted (ID: ${resourceId})`,
    [resourcesDictionary, resourceId],
  );
  const serviceLabel = useMemo(
    () =>
      servicesDictionary?.[serviceId]?.name || `Deleted (ID: ${serviceId})`,
    [servicesDictionary, serviceId],
  );
  const onInvoiceLinkClick = () => {
    openNewTab({ data: invoiceData });
  };

  const headerCells = [
    {
      title: 'INSTANCES.ID',
      value: id,
      parentId: id,
    },
    {
      title: 'INSTANCES.CHARGES.INVOICE',
      value: invoiceReferenceCode || invoiceId,
      id: invoiceId,
      onClick: onInvoiceLinkClick,
      parentId: id,
    },
    {
      title: 'INSTANCES.DIRECTION',
      value: t(INVOICE_DIRECTIONS[direction]?.label),
      icon: INVOICE_DIRECTIONS[direction]?.icon,
      iconColor: INVOICE_DIRECTIONS[direction]?.iconColor,
      parentId: id,
    },
    {
      title: 'INSTANCES.RESOURCE',
      value: resourceLabel,
      parentId: id,
    },
  ];
  const footerCells = [
    {
      title: 'INSTANCES.CHARGES.COUNT',
      value: billableCount,
      isNumber: true,
      parentId: id,
    },
    {
      title: 'INSTANCES.CHARGES.VOLUME',
      value: billableVolume,
      isNumber: true,
      parentId: id,
    },
    {
      title: 'INSTANCES.SYSTEM_NET',
      value: round({ num: billableNetAmount }),
      label: currency,
      isNumber: true,
      parentId: id,
    },
    {
      title: 'INSTANCES.SYSTEM_TAX',
      value: round({ num: billableTaxAmount }),
      label: currency,
      isNumber: true,
      parentId: id,
    },
    {
      title: 'INSTANCES.SYSTEM_TOTAL',
      value: round({ num: billableTotalAmount }),
      label: currency,
      isNumber: true,
      parentId: id,
    },
    {
      title: 'INSTANCES.TOTAL_DUE',
      value: round({ num: dueAmount }),
      label: currency,
      isNumber: true,
      isBold: true,
      parentId: id,
    },
  ];
  const disputeStatusesOptions = Object.entries(INVOICE_DISPUTE_STATUSES).map(([key, value]) => ({
    label: value.label,
    value: +key,
    icon: <Icon icon={value.icon} size={16} color={value.iconColor} />,
  }));
  const chargeDueSourcesOptions = Object.entries(CHARGE_DUE_SOURCES).map(([key, value]) => ({
    label: value,
    value: +key,
  }));

  const onSaveNewValues = () => {
    patchCharge({
      id,
      postfix: 'dispute',
      body: {
        counterpartyAmount: +newCounterpartyAmount,
        disputeStatus: newDisputeStatus,
        dueSource: newDueSource,
      },
      successCallback: ({ data }) => {
        setNewDisputeStatus(data.disputeStatus);
        setNewDueSource(data.dueSource);
        setNewCounterpartyAmount(data.counterpartyAmount);
        updateChargesList();
        modifyTabData(data);
      },
    });
  };
  const onRecalculate = () => {
    patchCharge({
      id,
      postfix: 'refresh',
      successCallback: ({ data }) => {
        updateChargesList();
        modifyTabData(data);
      },
    });
  };
  const onClosePopover = () => {
    setNewDisputeStatus(disputeStatus);
    setNewDueSource(dueSource);
    setNewCounterpartyAmount(counterpartyAmount);
  };

  useEffect(() => {
    getResourcesDictionary();
    getServicesDictionary();
  }, []);

  return (
    <DetailsBlock
      headerCells={headerCells}
      footerCells={footerCells}
      detailsBody={(
        <>
          <div className="charge-details__column">
            <DetailCell
              parentId={id}
              title="INSTANCES.CHARGES.SOURCE"
              value={t(CHARGE_SOURCES[source]?.label)}
              icon={CHARGE_SOURCES[source]?.icon}
              iconColor={CHARGE_SOURCES[source]?.iconColor}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.START_PERIOD"
              value={moment(periodStart).format('DD.MM.YYYY')}
              label={periodStartTz}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.END_PERIOD"
              value={moment(periodEnd).format('DD.MM.YYYY')}
              label={periodEndTz}
            />
          </div>
          <div className="charge-details__column">
            <DetailCell
              parentId={id}
              title="INSTANCES.STATUS"
              value={t(INVOICE_STATUSES[invoiceStatus]?.label)}
              icon={INVOICE_STATUSES[invoiceStatus]?.icon}
              iconColor={INVOICE_STATUSES[invoiceStatus]?.iconColor}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.SERVICE"
              value={serviceLabel}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.CHARGES.VERSION"
              value={version}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.CHARGES.CORRECTION_FOR"
              value={correctionFor || '-'}
            />
          </div>
          <div className="charge-details__column">
            <DetailCell
              parentId={id}
              title="INSTANCES.DISPUTE_STATUS"
              value={t(INVOICE_DISPUTE_STATUSES[disputeStatus]?.label)}
              icon={INVOICE_DISPUTE_STATUSES[disputeStatus]?.icon}
              iconColor={INVOICE_DISPUTE_STATUSES[disputeStatus]?.iconColor}
              editControl={editAllowed && (
                <PopoverEditBlock
                  title="SCREENS.INVOICES.EDIT_DISPUTE_DATA"
                  onClose={onClosePopover}
                  onSave={onSaveNewValues}
                  disabled={isPendingPatchCharge}
                >
                  <DropdownField
                    value={newDisputeStatus}
                    onChange={setNewDisputeStatus}
                    options={disputeStatusesOptions}
                    label="INSTANCES.DISPUTE_STATUS"
                    dense
                  />
                  <DropdownField
                    value={newDueSource}
                    onChange={setNewDueSource}
                    options={chargeDueSourcesOptions}
                    label="INSTANCES.CHARGES.DUE_SOURCE"
                    styles={fieldStyles}
                    dense
                  />
                  <InnerField
                    chipValue={currency}
                    label="STATES.CHARGES.COUNTERPARTY_ESTIMATE"
                    leftFieldStyles={leftFieldStyles}
                    rightFieldStyles={rightFieldStyles}
                    leftProps={{
                      value: newCounterpartyAmount,
                      onChange: setNewCounterpartyAmount,
                      full: true,
                    }}
                    styles={innerStyles}
                    noFields
                    dense
                  />
                </PopoverEditBlock>
              )}
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.CHARGES.DUE_SOURCE"
              value={t(CHARGE_DUE_SOURCES[dueSource])}
            />
          </div>
          <div className="charge-details__column">
            <DetailCell
              parentId={id}
              title="INSTANCES.CHARGES.COUNTERPARTY_EST"
              value={round({ num: counterpartyAmount })}
              label={currency}
              isNumber
            />
            <DetailCell
              parentId={id}
              title="INSTANCES.DISPUTED"
              value={round({ num: disputedAmount })}
              label={currency}
              isNumber
            />
          </div>
        </>
      )}
      footerElement={(
        <>
          {/* todo: открыть по готовности api */}
          {5 < 2 && (
            <Button
              text={t('CONTROLS.INVOICES.DOWNLOAD')}
              view="smooth"
              icon="import"
              disabled={!editAllowed}
              title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
            />
          )}
          {!invoiceConfirmed && (
            <Button
              onClick={onRecalculate}
              text="CONTROLS.INVOICES.RECALCULATE"
              color="primary"
              view="smooth"
              icon="calculator"
              style={{ marginLeft: 'auto' }}
              disabled={isPendingPatchCharge || !editAllowed}
              title={(!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS') || 'CONTROLS.INVOICES.RECALCULATE'}
              data-testid={`${window.location.pathname}/recalculate-charge-button/${id}`}
            />
          )}
        </>
      )}
      isPendingTable={!cols.length}
      tableProps={{
        columnDefs: cols,
        rowData: chargeDetails,
        onSortChanged,
        onFilterChanged,
        limit,
        page: offset,
        total: chargeDetailsMeta.size,
        onChangePage: setAndSendOffset,
        onChangeLimit: setAndSendLimit,
        sendQuery,
      }}
      rowStyle={{ justifyContent: 'space-between' }}
      tableTitle="SCREENS.INVOICES.CHARGE_DETAILS"
    />
  );
};

export default ChargeDetails;
