import {
  useEffect, useRef, useState,
} from 'react';
import { isEmpty } from 'lodash';

import { getFiltersForQuery, getFiltersForQueryNew } from 'helpers';

export default ({
  tableRef,
  sendQuery,
  initialLimit = 100,
  useNewFilters,
  withInitialQuery = true,
  initialFilters,
}) => {
  // пагинация
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(initialLimit);
  // костыль, для того, чтоб при вызове handleQuery из фильтров
  // значения пагинации были актуальны
  const limitRef = useRef(initialLimit);
  const offsetRef = useRef(1);

  const setAndSendOffset = (value) => {
    setOffset(value);
    offsetRef.current = value;
    // eslint-disable-next-line no-use-before-define
    handleQuery({ stateOffset: value });
  };
  const setAndSendLimit = (value) => {
    setLimit(value);
    setOffset(1);
    limitRef.current = value;
    offsetRef.current = 1;
    // eslint-disable-next-line no-use-before-define
    handleQuery({ stateLimit: value, stateOffset: 1 });
  };
  const setLimitModify = (value) => {
    setLimit(value);
    setOffset(1);
    limitRef.current = value;
    offsetRef.current = 1;
  };

  const resetPagination = () => setAndSendOffset(1);

  const sort = useRef(null);
  const filters = useRef(initialFilters || null);

  const handleQuery = ({ stateLimit, stateOffset } = {}) => {
    let queries = {
      limit: stateLimit || limitRef.current,
      offset: stateOffset || offsetRef.current,
    };

    if (filters.current) {
      if (useNewFilters) {
        queries = {
          ...queries,
          filter: JSON.stringify(getFiltersForQueryNew(filters.current)),
        };
      } else {
        queries = {
          ...queries,
          ...getFiltersForQuery(filters.current),
        };
      }
    }

    if (sort.current) {
      queries.sort = `${sort.current.type === 'desc' ? '-' : ''}${sort.current.name}`;
    }

    sendQuery(queries);
  };

  const onFilterChanged = (newFilters) => {
    if (isEmpty(newFilters)) {
      filters.current = null;
    } else {
      filters.current = newFilters;
    }

    if (offset !== 1) {
      resetPagination();
    }
    // if (offset !== 1) {
    //   resetPagination();
    // } else {
    //   handleQuery();
    // }
  };
  const onSortChanged = (newSort) => {
    if (isEmpty(newSort)) {
      sort.current = null;
    } else {
      sort.current = {
        name: newSort[0].colId,
        type: newSort[0].sort,
      };
    }

    // handleQuery();
  };
  const setFilters = (newFilters) => {
    filters.current = newFilters;
  };

  useEffect(() => {
    if (withInitialQuery) {
      handleQuery();
    }
  }, []);

  useEffect(() => {
    if (initialFilters) {
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current?.setFilters(initialFilters);
        }
      }, 500);
    }
  }, []);

  return {
    onFilterChanged,
    onSortChanged,
    setFilters,
    sort: sort.current,
    sortRef: sort,
    filters: filters.current,
    filtersRef: filters,
    offset,
    limit,
    setAndSendOffset,
    setAndSendLimit,
    setLimit: setLimitModify,
    setOffset,
    resetPagination,
    sendQuery: handleQuery,
  };
};
