import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  TextAreaField,
  DropdownField,
  DatePickerField,
} from 'components/fields';
import { PRINTABLE_FORM_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';

import FileField from './FileField';

import './style.scss';

const PrintableFormForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
  legalEntitiesOptions,
  typesOptions,
  resourcesOptions,
}) => {
  const fieldStyle = ({ width, marginTop, overlay } = {}) => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
    overlay,
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const selectedTypeRef = useRef({});
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onChangeType = ({ form, value }) => {
    selectedTypeRef.current = typesOptions.find(type => type.value === value);

    if (form.dirty) {
      form.setFieldValue('file', undefined);
      form.setFieldValue('content', undefined);
      form.setFieldValue('resourceId', undefined);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PRINTABLE_FORM_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="printable-form-form">
          {showTopShadow && <div className="printable-form-form__shadow" />}
          <div className="printable-form-form__content" ref={contentRef}>
            <Field
              name="type"
              label="INSTANCES.TYPE"
              component={DropdownField}
              options={typesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
            />
            <Field
              name="legalEntityId"
              label="INSTANCES.LEGAL_ENTITY"
              component={DropdownField}
              options={legalEntitiesOptions}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              clearButton
            />
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              full
            />
            <Field
              name="dateFrom"
              label="INSTANCES.PRINTABLE_FORMS.DATE_FROM"
              component={DatePickerField}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.SELECT_DATE"
              format="DD.MM.YYYY"
              disabled={isView}
              placement="top-start"
              usePortal={false}
            />
            {values.type === 2 && (
              <Field
                name="resourceId"
                label="INSTANCES.RESOURCE"
                component={DropdownField}
                options={resourcesOptions}
                styles={fieldStyle({ marginTop: 16 })}
                placeholder="PLACEHOLDERS.SELECT"
                disabled={isView}
                clearButton
              />
            )}
            {values.type && (selectedTypeRef.current?.isInner ? null : (
              <Field
                name="file"
                component={FileField}
                accept={`.${selectedTypeRef?.current?.allowedExt}`}
                styles={fieldStyle({ marginTop: 16, width: '100%' })}
                disabled={isView}
                isRequired={!isView}
                idToDownload={initialValues.id}
              />
            ))}
            <Field
              name="comment"
              label="INSTANCES.COMMENT"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 16 })}
              minRows={2}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
            showDelete={!!initialValues.isEditable}
            showSave={!(mode === 'view' && !initialValues.isEditable)}
          />
          <OnChangeComponent field="type" onChange={onChangeType} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default PrintableFormForm;
