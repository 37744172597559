import transformMessage from './transformMessage';

export default ({ contentPattern }) => {
  if (contentPattern) {
    switch (contentPattern.type) {
      case 'text':
        return ({
          selectedType: 'whatsAppText',
          message: transformMessage(contentPattern.text.body),
        });
      case 'location':
        return ({
          selectedType: 'whatsAppGetGeolocation',
          location: {
            longitude: contentPattern.location.longitude,
            latitude: contentPattern.location.latitude,
            name: contentPattern.location.name,
            address: contentPattern.location.address,
          },
        });
      case 'image':
      case 'video':
      case 'document': {
        const { link, file } = contentPattern[contentPattern.type];
        const {
          type,
          fileMimeType,
          fileName,
          fileSize,
          thumbnailUri,
          thumbnailUrl,
        } = contentPattern;

        return ({
          selectedType: 'whatsAppFile',
          [type]: {
            link: link || file,
            fileUrl: link || file,
            mediaType: contentPattern.type[0].toUpperCase() + contentPattern.type.slice(1),
            fileMimeType,
            fileName: fileName || contentPattern.type,
            fileSize,
            thumbnailUri: thumbnailUri || thumbnailUrl,
          },
        });
      }
      case 'template': {
        const content = contentPattern.template.components.reduce((acc, item) => {
          if (item.type === 'header' && item.parameters[0].type !== 'text') {
            switch (item.parameters[0].type) {
              case 'image':
              case 'video':
              case 'document':
                acc.image = {
                  fileUrl: item.parameters[0][item.parameters[0].type].link,
                  fileMimeType: item.parameters[0].fileMimeType,
                  fileName: item.parameters[0].fileName,
                  fileSize: item.parameters[0].fileSize,
                  thumbnailUri: item.parameters[0].thumbnailUrl,
                };
                break;
              case 'location':
                acc = {
                  ...acc,
                  location: {
                    ...item.parameters[0].location,
                  },
                };
                break;
              default: break;
            }
          } else {
            const variablesObj = {};

            item.parameters.forEach((P, index) => {
              variablesObj[index + 1] = P.text;
            });

            acc.componentsParams = {
              ...acc.componentsParams,
              [item.type]: variablesObj,
            };
          }
          return acc;
        }, {});
        return ({
          name: contentPattern.template.name,
          ...content,
        });
      }
      default: return {};
    }
  }

  return null;
};
