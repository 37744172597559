import React from 'react';
import { Field } from 'formik';
import cx from 'classnames';

import { round } from 'helpers';
import { numbers, decimal } from 'consts/inputPatterns';

import styles from './ScaleConfig.module.scss';

import { ReactComponent as AddIcon } from '../../../icons/circle-plus.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash-xmark.svg';

const ScaleConfig = ({
  form: {
    values,
  },
  insert,
  remove,
  disabled,
  errors,
  touched,
}) => {
  const getInputClassName = (index, name) => cx(styles.input, {
    [styles.input_error]: touched?.price?.[index]?.[name] && errors?.price?.[index]?.[name],
  });

  const onChange = (e, updateFunc, regex) => {
    const regExp = new RegExp(regex, 'i');

    if (regExp.test(e.target.value)) {
      updateFunc(e);
    }
  };

  return (
    <div className={styles.scaleConfig}>
      {values.price?.map?.((item, index) => (
        <div
          className={styles.row}
          // eslint-disable-next-line react/no-array-index-key
          key={`price.${index}.value`}
        >
          {!disabled && (
            <div
              className={styles.addingControl}
              role="presentation"
              onClick={() => insert(index, { tier: '', price: '' })}
            >
              <AddIcon />
              <div className={styles.line} />
            </div>
          )}
          <Field name={`price.${index}.price`}>
            {({ field }) => (
              <input
                type="text"
                className={getInputClassName(index, 'price')}
                placeholder="Price"
                disabled={disabled}
                {...field}
                value={disabled
                  ? round({ num: field.value, decimalsCount: 5 })
                  : field.value}
                onChange={e => onChange(e, field.onChange, decimal({ decimalLimit: 5 }))}
                onBlur={Function.prototype}
              />
            )}
          </Field>
          {index + 1 === values.price.length ? (
            <span className={cx(styles.infinity, { [styles.infinity_disabled]: disabled })}>
              ∞
            </span>
          ) : (
            <Field name={`price.${index}.tier`}>
              {({ field }) => (
                <input
                  type="text"
                  className={getInputClassName(index, 'tier')}
                  placeholder={`Tier #${index + 1}`}
                  disabled={disabled}
                  style={disabled ? { opacity: 0.5, textAlign: 'left' } : undefined}
                  {...field}
                  value={disabled
                    ? round({ num: field.value, cutDecimalZeroes: true })
                    : field.value}
                  onChange={e => onChange(e, field.onChange, numbers())}
                  onBlur={Function.prototype}
                />
              )}
            </Field>
          )}
          {values.price?.length > 2 && index + 1 !== values.price.length && !disabled && (
            <TrashIcon
              icon="delete"
              size={16}
              className={styles.removeButton}
              color="var(--grey50)"
              onClick={() => remove(index)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ScaleConfig;
