import React, { useMemo, useRef } from 'react';
import {
  Axis, Chart, Coordinate, Interaction, Interval, Legend, Tooltip,
} from 'bizcharts';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { kFormatter } from 'helpers';

import styles from './CountryChart.module.scss';

import { axisGridConfig, axisTextStyle, tooltipDomStyles } from '../chartConfig';

const CountryChart = ({
  data,
  countriesDictionary,
}) => {
  const listRef = useRef();
  const { t } = useTranslation();
  const style = getComputedStyle(document.body);

  const tooltipDomStylesModify = {
    ...tooltipDomStyles(),
    'g2-tooltip-marker': {
      display: 'none',
    },
  };

  const countryNames = useMemo(() => data.map(I => ({
    ...I,
    country: I.country,
    countryName: countriesDictionary?.[I.country]
    || I.country,
  })), [data]);
  const maxBarValue = useMemo(() => {
    const allValues = countryNames.map(I => (I.cnt_total));
    return Math.max(...allValues);
  }, [countryNames]);
  const totalTraffic = useMemo(() => countryNames.reduce((acc, item) => {
    acc += Number(item.cnt_total);
    return acc;
  }, 0), [countryNames]);
  const reversedData = useMemo(() => {
    const dataArr = [...countryNames];
    return dataArr.reverse();
  }, [countryNames]);
  const transformedData = useMemo(() => reversedData.flatMap((item) => {
    let networkValue = item.network;
    let countryValue = item.countryName;

    if (!networkValue) {
      networkValue = 'Unknown';
    }

    if (!countryValue) {
      countryValue = 'Unknown';
    }

    return [{
      type: t('SCREENS.TWO_FACTOR.CHARTS.SENT'),
      country: countryValue,
      network: networkValue,
      count: Number(item.cnt_sent),
    },
    {
      type: t('SCREENS.TWO_FACTOR.CHARTS.DELIVERED'),
      country: countryValue,
      network: networkValue,
      count: Number(item.cnt_delivered),
    }];
  }), [reversedData]);
  const intervalLabel = useMemo(() => [
    'traffic',
    () => ({
      position: 'middle',
      offsetX: -15,
      style: {
        fill: style.getPropertyValue('--white'),
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 24,
      },
    }),
  ], [maxBarValue]);
  const chartHeight = useMemo(() => countryNames.length * 55 + 14, [countryNames]);
  const itemsGap = useMemo(
    () =>
      (chartHeight - data.length * 24 - 50) / data.length,
    [data],
  );
  const countryAxis = useMemo(() => ({
    style: {
      ...axisTextStyle.style,
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      fill: style.getPropertyValue('--black'),
    },
    formatter: (val) => {
      const traffic = countryNames.find(I => I.countryName === val)?.cnt_total;

      return `${Number(traffic / totalTraffic * 100).toFixed(1)}%`;
    },
  }), [totalTraffic, countryNames, style]);

  const countAxis = {
    ...axisTextStyle,
    formatter: val => kFormatter(+val),
  };

  return (
    <div className={styles.countryChart}>
      <div ref={listRef} style={{ width: 265 }} className={styles.list}>
        {
          countryNames.map(({ country, countryName }) => (
            <div
              className={styles.item}
              key={country}
              style={{ marginBottom: itemsGap }}
            >
              <ReactCountryFlag
                countryCode={country}
                title={countryName}
                style={{
                  marginRight: 4,
                  fontSize: 17,
                }}
              />
              <span title={countryName}>
                {countryName}
              </span>
            </div>
          ))
        }
      </div>
      <div style={{ display: 'flex', flex: 1 }}>
        <Chart
          height={chartHeight}
          data={transformedData}
          autoFit
          appendPadding={[0, 0, 0, 0]}
        >
          <Legend
            position="top"
            marker={{
              symbol: 'circle',
              style: {
                r: 6,
              },
            }}
          />
          <Axis
            name="country"
            verticalLimitLength={150}
            grid={axisGridConfig()}
            label={countryAxis}
          />
          <Axis
            name="count"
            grid={axisGridConfig()}
            label={countAxis}
          />
          <Coordinate transpose />
          <Tooltip
            position="right"
            domStyles={tooltipDomStylesModify}
          />
          <Interval
            adjust="stack"
            color={['type', ['#65B168', '#4F91FF']]}
            position="country*count"
            size={20}
            label={intervalLabel}
          />
          <Interaction type="active-region" />
        </Chart>
      </div>
    </div>
  );
};

export default CountryChart;
