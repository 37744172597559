import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  ...queries
} = {}) => ({
  url: endpoints.getServicesUrl(),
  body: {
    limit,
    offset: 0,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      resourceList,
    } = response;

    return {
      services: resourceList,
    };
  },
  update: {
    services: (oldValue, newValue) => newValue,
  },
});
