import React from 'react';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';

import Dialog from '@setproduct-ui/core/Dialog/Dialog';
import Button from '@setproduct-ui/core/Button';

export default ({
  closeModal,
  onConfirm,
  isPending,
}) => {
  const dialogStyles = {
    title: {
      marginBottom: 16,
    },
    container: {
      overflowY: 'unset',
    },
  };
  const { t } = useTranslation();

  useKey('Enter', () => {
    onConfirm();
  });
  useKey('Escape', () => {
    closeModal();
  });

  return (
    <Dialog
      type="default"
      view="raised"
      color="danger"
      icon="info-sign"
      iconColor="var(--red30)"
      title={t('SCREENS.SENDER_IDS.WARNING')}
      text={t('SCREENS.SENDER_IDS.TEMPLATE_WARNING')}
      backdropOpacity={40}
      isOpen
      onClose={closeModal}
      className="close-confirm-modal"
      withCloseButton={false}
      styles={dialogStyles}
      leftButton={(
        <Button
          text={t('CONTROLS.CANCEL')}
          view="flat"
          color="danger"
          onClick={closeModal}
          disabled={isPending}
        />
      )}
      rightButton={(
        <Button
          text={t('CONTROLS.CONFIRM_MODALS.DELETE_CONFIRM')}
          view="smooth"
          color="danger"
          onClick={onConfirm}
          loading={isPending}
        />
      )}
    />
  );
};
