import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

export default ({
  valueFormatted,
}) => {
  const { t } = useTranslation();

  return <span className="text-cell-renderer">{t(valueFormatted)}</span>;
};
