import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@setproduct-ui/core/Dialog/Dialog';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

export default ({
  closeModal,
  onConfirm,
  isRewrite,
}) => {
  const dialogStyles = {
    title: {
      marginBottom: 16,
    },
    titleFont: {
      fontWeight: 500,
      fontSize: 20,
    },
    container: {
      overflowY: 'unset',
    },
  };
  const { t } = useTranslation();

  return (
    <Dialog
      type="default"
      view="raised"
      color="warning"
      icon="info-sign"
      title={t('SCREENS.SENDER_IDS.WARNING')}
      text={t(`SCREENS.SENDER_IDS.${isRewrite ? 'REWRITE' : 'WRITE_OFF'}_WARNING_DESC`)}
      backdropOpacity={40}
      isOpen
      onClose={closeModal}
      className="confirm-write-off-modal"
      withCloseButton={false}
      styles={dialogStyles}
      leftButton={(
        <Button
          text={t('CONTROLS.CANCEL')}
          view="flat"
          color="warning"
          onClick={closeModal}
        />
      )}
      rightButton={(
        <Button
          text={t(`CONTROLS.SENDER_IDS.${isRewrite ? 'REWRITE_OFF' : 'PROCEED'}`)}
          view="smooth"
          color="warning"
          onClick={onConfirm}
        />
      )}
    />
  );
};
