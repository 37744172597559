import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/Typography';
import { useRatePlansApi } from 'hooks/api';

import './style.scss';

export default ({
  value,
  data,
}) => {
  const { t } = useTranslation();
  const { ratePlansAll } = useRatePlansApi();

  const ratePlan = useMemo(
    () =>
      ratePlansAll.find(I => I.id === data.ratePlanId),
    [ratePlansAll, data],
  );

  const getValue = (val) => {
    if (val === 0) {
      return '0.00000';
    }
    if (val) {
      return parseFloat(val).toFixed(5);
    }
    return null;
  };

  return (
    <div className="rate-cell-renderer">
      <div className="rate-cell-renderer__column">
        {
          ratePlan?.ratingSchemeId === 1
            ? (
              <>
                <Typography className="rate-cell-renderer__label">
                  A2P
                </Typography>
                <Typography className="rate-cell-renderer__label">
                  P2A
                </Typography>
              </>
            ) : (
              <>
                <Typography className="rate-cell-renderer__label">
                  {t('INSTANCES.RATES.STANDALONE_KEY', { key: 'A2P' })}
                </Typography>
                <Typography className="rate-cell-renderer__label">
                  {t('INSTANCES.RATES.STANDALONE_KEY', { key: 'P2A' })}
                </Typography>
                <Typography className="rate-cell-renderer__label">
                  INSTANCES.RATES.SESSION
                </Typography>
              </>
            )
        }
      </div>
      <div className="rate-cell-renderer__column rate-cell-renderer__column_values">
        {
          ratePlan?.ratingSchemeId === 1
            ? (
              <>
                <span className="rate-cell-renderer__value">
                  {getValue(value[1])}
                </span>
                <span className="rate-cell-renderer__value">
                  {getValue(value[2])}
                </span>
              </>
            ) : (
              <>
                <span className="rate-cell-renderer__value">
                  {getValue(value[3])}
                </span>
                <span className="rate-cell-renderer__value">
                  {getValue(value[4])}
                </span>
                <span className="rate-cell-renderer__value">
                  {getValue(value[5])}
                </span>
              </>
            )
        }
      </div>
    </div>
  );
};
