import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import moment from 'moment';

import Modal from 'components/Modal';
import Chips from '@setproduct-ui/core/Chips';
import Callout from '@setproduct-ui/core/Callout';
import Button from '@setproduct-ui/core/Button';
import {
  EDR_RERATE_STATUSES_DICTIONARY,
  CHANNELS_DICTIONARY,
  EDR_RERATE_STATUSES,
} from 'consts';
import { useUserPermissions } from 'hooks';
import { useDictionariesApi, useRerateApi } from 'hooks/api';
import round from 'helpers/round';

import styles from './EdrRerateViewModal.module.scss';

const EdrRerateViewModal = ({
  onClose,
  id,
  statDetails,
  status,
  timeFrom,
  timeTill,
  scheduledFor,
  updatedAt,
  userId,
  filter,
  errorMessage,
  refreshTableData,
}) => {
  const { t } = useTranslation();
  const {
    boUsersDictionary,
    companiesDictionary,
    agreementsDictionary,
  } = useDictionariesApi();
  const { putEdrRerateTask, isPendingPutEdrRerateTask } = useRerateApi();
  const { editAllowed } = useUserPermissions();

  const statsCols = useMemo(() => {
    const {
      edrsEstimate,
      edrsProcessed,
      edrsUpdated,
      edrsRerateFailed,
      extraCdrCreated,
      extraCdrProcessed,
      extraCdrUpdated,
      extraCdrFailed,
      sessionsProcessed,
      sessionsRerateFailed,
      sessionsUpdated,
    } = statDetails;

    return [
      {
        title: 'EDR details',
        icon: 'th-list',
        items: [
          {
            label: 'Estimated',
            value: edrsEstimate,
          },
          {
            label: 'Processed',
            value: edrsProcessed,
          },
          {
            label: 'Updated',
            value: edrsUpdated,
          },
          {
            label: 'Failed',
            value: edrsRerateFailed,
          },
        ],
      },
      {
        title: 'Session details',
        icon: 'time',
        items: [
          {
            label: 'Processed',
            value: sessionsProcessed,
          },
          {
            label: 'Updated',
            value: sessionsUpdated,
          },
          {
            label: 'Failed',
            value: sessionsRerateFailed,
          },
        ],
      },
      {
        title: 'Reseller CDR details',
        icon: 'layers',
        items: [
          {
            label: 'Processed',
            value: extraCdrProcessed,
          },
          {
            label: 'Created',
            value: extraCdrCreated,
          },
          {
            label: 'Updated',
            value: extraCdrUpdated,
          },
          {
            label: 'Failed',
            value: extraCdrFailed,
          },
        ],
      },
    ];
  }, [statDetails]);
  const filterChips = useMemo(() => {
    const parsedFilter = JSON.parse(atob(filter));

    return parsedFilter.expressions.reduce((acc, { field, value }) => {
      switch (field) {
        case 'companyId':
          acc.push(`${t('INSTANCES.COMPANY')}: ${String(value.map(I => companiesDictionary[I]?.name))?.replaceAll(',', ', ')}`);
          break;
        case 'agreementId':
          acc.push(`${t('INSTANCES.AGREEMENT')}: ${String(value.map(I => agreementsDictionary[I]?.agreementCode))?.replaceAll(',', ', ')}`);
          break;
        case 'channelType':
          acc.push(`${t('INSTANCES.CHANNEL')}: ${String(value.map(I => CHANNELS_DICTIONARY[I]))?.replaceAll(',', ', ')}`);
          break;
        default: break;
      }

      return acc;
    }, []);
  }, [filter, companiesDictionary, agreementsDictionary]);
  const cancelTask = () => {
    putEdrRerateTask({
      id,
      body: {
        status: 4,
      },
      successCallback: () => {
        refreshTableData();
        onClose();
      },
    });
  };

  return (
    <Modal
      closeModal={onClose}
      title={t('SCREENS.EDR_RERATE.TASK_ID', { id })}
      size="lg"
    >
      <div className={styles.container}>
        <div className={styles.statsRow}>
          {statsCols.map(item => (
            <div className={styles.statsBlock} key={item.title}>
              <span className={styles.statBlockTitle}>
                <Icon icon={item.icon} size={14} color="var(--grey40)" />
                {item.title}
              </span>
              <div className={styles.statsGrid}>
                {item.items.map(I => (
                  <div className={styles.statCol} key={I.label}>
                    <span>{round({ num: I.value, decimalsCount: 0 })}</span>
                    <span>{t(I.label)}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {status === EDR_RERATE_STATUSES.SCHEDULED.key && (
          <Callout
            view="smooth"
            color="default"
            icon={<Icon icon="info-sign" color="var(--grey70)" size={20} />}
            className={styles.callout}
            dense
          >
            <div className={styles.scheduledDateWrapper}>
              {t('SCREENS.EDR_RERATE.TASK_SCHEDULED', { date: moment(scheduledFor).format('DD.MM.YYYY HH:mm:ss') })}
              <Button
                view="outlined"
                text="CONTROLS.CANCEL"
                onClick={cancelTask}
                icon="cross"
                loading={isPendingPutEdrRerateTask}
                disabled={!editAllowed}
                title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
                dense
              />
            </div>
          </Callout>
        )}
        {errorMessage && (
          <Callout
            view="smooth"
            color="danger"
            icon={<Icon icon="warning-sign" color="var(--grey70)" size={20} />}
            className={styles.callout}
            dense
          >
            {errorMessage}
          </Callout>
        )}
        <div className={styles.configContainer}>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <Icon icon="layout-circle" size={16} />
              <span>{t('INSTANCES.STATUS')}</span>
            </div>
            <Chips {...EDR_RERATE_STATUSES_DICTIONARY[status]} />
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <Icon icon="calendar" size={16} />
              <span>{t('SCREENS.EDR_RERATE.EFFECTIVE_PERIOD')}</span>
            </div>
            <span>{`${moment(timeFrom).format('DD.MM.YYYY HH:mm:ss')} - ${moment(timeTill).format('DD.MM.YYYY HH:mm:ss')}`}</span>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <Icon icon="time" size={16} />
              <span>{t('INSTANCES.EDR_RERATE.SCHEDULED_FOR')}</span>
            </div>
            <span>{moment(scheduledFor).format('DD.MM.YYYY HH:mm:ss')}</span>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <Icon icon="history" size={16} />
              <span>{t('INSTANCES.UPDATED')}</span>
            </div>
            <span>{moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}</span>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <Icon icon="user" size={16} />
              <span>{t('INSTANCES.UPDATED_BY')}</span>
            </div>
            <span>{boUsersDictionary[userId]?.username}</span>
          </div>
          <div className={styles.configRow} style={{ alignItems: 'flex-start' }}>
            <div className={styles.configLabel}>
              <Icon icon="filter" size={16} />
              <span>{t('INSTANCES.EDR_RERATE.FILTER')}</span>
            </div>
            <div className={styles.chipsWrapper}>
              {filterChips.map(I => (
                <Chips
                  tag={I}
                  key={I}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EdrRerateViewModal;
