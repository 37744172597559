import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { Field, ErrorMessage } from 'formik';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import Color from '@setproduct-ui/styles/color.module.css';

import styles from './ResourceCell.module.scss';

import { isPrefixMatch } from '../../utils';
import { ReactComponent as CirclePlusIcon } from '../../icons/circle-plus.svg';
import { ReactComponent as CrossIcon } from '../../icons/circle-xmark.svg';
import { ReactComponent as SearchIcon } from '../../icons/magnifying-glass.svg';
import { ReactComponent as FolderPlusIcon } from '../../icons/folder-plus.svg';
import Dropdown from '../Dropdown';

const ResourceCell = ({
  level,
  id,
  treeKey,
  options = [],
  freeInput,
  withControls,
  controlsDisabled,
  withSearch,
  onAdd,
  onMultiAdd,
  disabled,
  isEdit,
  addChildrenDisabled,
  cellRef,
  touched,
  errors,
  title,
  searchValue: initialSearchValue,
  onSearchChange,
  siblingValues,
  isLoading,
  displayLinksFor,
  selectedRow,
  searchIsActive,
  levelOfSearch,
}) => {
  const debouncedOnSearchChange = useRef(
    debounce((nodeId, value) => {
      onSearchChange(nodeId, value);
    }, 1000, { leading: false }),
  ).current;
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const ownLinkColor = useMemo(() => {
    if (isPrefixMatch(selectedRow.treeKey, treeKey)) {
      return 'var(--blue50)';
    }
    if (searchIsActive) {
      return 'var(--orange50)';
    }

    return 'var(--grey20)';
  }, [selectedRow, searchIsActive]);
  const {
    fieldStyle,
    freeInputStyle,
    wrapperStyle,
    cellStyle,
    linkStyle,
  } = useMemo(() => ({
    fieldStyle: {
      color: level > 2 ? undefined : 'var(--indigo70)',
      width: 225,
    },
    freeInputStyle: {
      width: 225,
      cursor: isEdit ? undefined : 'default',
    },
    wrapperStyle: {
      cursor: isEdit ? undefined : 'default',
    },
    cellStyle: {
      paddingLeft: level * 24,
      paddingBottom: withControls ? 6 : 2,
    },
    linkStyle: {
      left: level * 24 - 11,
      borderColor: ownLinkColor,
    },
  }), [level, isEdit, withControls, ownLinkColor]);

  const searchInputRef = useRef();
  const { t } = useTranslation();

  const optionsModify = useMemo(() => {
    let optionsToReturn = options;

    if (level > 2) {
      optionsToReturn = [
        ...options,
        {
          label: 'Others',
          value: 'null',
          style: {
            color: 'var(--grey50)',
          },
        },
      ];
    }
    if (siblingValues) {
      return optionsToReturn
        .filter(I => !siblingValues.find(siblingValue => String(siblingValue) === String(I.value)));
    }

    return optionsToReturn;
  }, [options]);

  const handleSearchChange = ({ target }) => {
    setSearchValue(target.value);
    debouncedOnSearchChange(id, target.value);
  };
  const onSearchWrapperClick = (e) => {
    e.stopPropagation();

    if (!controlsDisabled) {
      searchInputRef.current?.focus();
    }
  };
  const onClearClick = (e) => {
    e.stopPropagation();
    searchInputRef.current?.blur();
    setSearchValue('');
    onSearchChange(id, '');
  };
  const onAddClick = (e) => {
    e.stopPropagation();
    if (!addChildrenDisabled && !controlsDisabled) {
      onAdd();
    }
  };
  const onMultiAddClick = (e) => {
    e.stopPropagation();
    if (!controlsDisabled) {
      onMultiAdd();
    }
  };

  useEffect(() => () => {
    debouncedOnSearchChange.cancel();
  }, [debouncedOnSearchChange]);

  return (
    <div
      className={styles.cell}
      style={cellStyle}
      ref={cellRef}
      title={title}
    >
      {level > 1 && (
        <div
          style={linkStyle}
          className={styles.link}
        />
      )}
      {displayLinksFor?.map(item => (
        <div
          key={item}
          className={styles.linkToSibling}
          style={{
            left: item * 24 - 11,
            backgroundColor: item > selectedRow?.level
              ? ownLinkColor
              : (searchIsActive && item >= levelOfSearch)
                ? 'var(--orange50)'
                : undefined,
          }}
        />
      ))}
      {freeInput ? (
        <>
          <Field
            name="matchAttributeValues"
          >
            {({ field }) => (
              <input
                type="text"
                disabled={disabled}
                placeholder={t('PLACEHOLDERS.SERVICES.ENTER_OR_LEFT')}
                className={cx(styles.input, {
                  [styles.input_error]: touched.matchAttributeValues && errors.matchAttributeValues,
                })}
                style={{
                  ...freeInputStyle,
                  color: disabled && field.value === 'null' ? 'var(--grey50)' : undefined,
                }}
                {...field}
                value={field.value === 'null' ? disabled ? 'Others' : '' : field.value}
                onBlur={Function.prototype}
              />
            )}
          </Field>
          <ErrorMessage name="matchAttributeValues">
            {msg => <span className={styles.error}>{t(msg)}</span>}
          </ErrorMessage>
        </>
      ) : (
        <Field
          name="matchAttributeValues"
          component={Dropdown}
          options={optionsModify}
          placeholder={`PLACEHOLDERS.SERVICES.SELECT_${level === 1 ? 'SERVICE_DOMAIN' : 'ATTRIBUTE'}`}
          textInputStyles={fieldStyle}
          wrapperStyle={wrapperStyle}
          disabled={disabled || isLoading}
          isLoading={isLoading}
          withSearch
        />
      )}
      {withControls && (
        <div className={styles.row}>
          <CirclePlusIcon
            icon="add"
            onClick={onAddClick}
            className={cx(styles.button, Color.primary, {
              [styles.button_disabled]: addChildrenDisabled || controlsDisabled,
            })}
          />
          <FolderPlusIcon
            onClick={onMultiAddClick}
            className={cx(styles.button, Color.primary, {
              [styles.button_disabled]: addChildrenDisabled || controlsDisabled,
            })}
          />
          {withSearch && (
            <div
              className={styles.searchWrapper}
              role="presentation"
              onClick={onSearchWrapperClick}
            >
              <SearchIcon
                className={cx(styles.button, Color.warning_alt, {
                  [styles.button_disabled]: controlsDisabled,
                })}
              />
              <input
                type="text"
                ref={searchInputRef}
                value={searchValue}
                onChange={handleSearchChange}
              />
              <CrossIcon className={styles.cleanButton} onClick={onClearClick} />
              <div className={styles.searchBorder} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResourceCell;
