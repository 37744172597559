import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  DropdownField,
} from 'components/fields';
import { WABA_SCHEMA } from 'consts/validationSchemas';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';

import './style.scss';

const WabaForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
  tzOptions,
  companiesOptions,
}) => {
  const fieldStyle = ({ width, marginTop, overlay } = {}) => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
    overlay,
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={WABA_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
      }) => (
        <Form className="waba-form">
          {showTopShadow && <div className="waba-form__shadow" />}
          <div className="waba-form__content" ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              styles={fieldStyle()}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              full
            />
            <Field
              name="companyId"
              label="INSTANCES.COMPANY"
              component={DropdownField}
              options={companiesOptions}
              styles={fieldStyle({
                marginTop: 16,
              })}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
            />
            <Field
              name="wabaId"
              label="INSTANCES.WABA_ID"
              component={TextField}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              full
            />
            <Field
              name="tz"
              label="INSTANCES.TIMEZONE"
              component={DropdownField}
              options={tzOptions}
              styles={fieldStyle({
                marginTop: 16,
              })}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
            />
            <Field
              name="namespace"
              label="INSTANCES.WABA.NAMESPACE"
              component={TextField}
              styles={fieldStyle({ marginTop: 16 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired
              full
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default WabaForm;
