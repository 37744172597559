import { Icon } from '@blueprintjs/core';
import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';

import './styles.scss';
import { useTranslation } from 'react-i18next';

export default ({ valueFormatted }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const iconSize = useMemo(() => (width <= 1366 ? 12 : 20), [width]);

  return (
    <div className="icons-cell-renderer">
      {valueFormatted
        && valueFormatted.map(icon => (
          <div className="icons-cell-renderer__container">
            <Icon
              key={icon.id}
              size={iconSize}
              {...icon}
              className="icons-cell-renderer__icon"
            />
            {t(icon?.title)}
          </div>
        ))}
    </div>
  );
};
