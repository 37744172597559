import endpoints from 'consts/endpoints';

export default ({
  errorCallback,
  successCallback,
  templateId,
} = {}) => ({
  url: endpoints.getReportParametersUrl(),
  queryKey: endpoints.getReportParametersUrl(),
  body: {
    templateId,
  },
  meta: {
    errorCallback,
    successCallback,
    authToken: true,
  },
  options: {
    method: 'GET',
  },
  transform: response => ({
    reportParameters: response,
  }),
  update: {
    reportParameters: (oldValue, newValue) => newValue,
  },
});
