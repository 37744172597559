import { Icon } from '@blueprintjs/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { ViberChannelIcon } from 'assets/icons';
import { SuggestionItem, MediaPreview } from 'components';

import styles from '../preview.module.scss';

const ViberPreview = ({
  message,
  showChannelIcon,
}) => {
  switch (message.type) {
    case 'text':
      return (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <ViberChannelIcon />}
            <Icon icon="align-left" color="var(--grey30)" size={16} />
            Text
          </div>
          <Highlighter
            className={styles.bodyContainer}
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={message.body}
            autoEscape={false}
          />
        </div>
      );
    case 'textCard':
      return (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <ViberChannelIcon />}
            <Icon icon="application" color="var(--grey30)" size={16} />
            Text card
          </div>
          <div className={styles.bodyContainer}>
            <Highlighter
              className={styles.messageBody}
              highlightClassName={styles.highlight}
              highlightTag="span"
              searchWords={[/{{.([^]+?)}}/gm]}
              textToHighlight={message.body.text}
              autoEscape={false}
            />
            <div className={styles.messageButtons}>
              <SuggestionItem
                isInside={false}
                text={message.body.buttonCaption}
                url={message.body.buttonUrl}
                suggestionType="action"
                category="urlAction"
              />
            </div>
          </div>
        </div>
      );
    case 'picCard':
      return (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon > 2 && <ViberChannelIcon />}
            <Icon icon="panel-stats" color="var(--grey30)" size={16} />
            Pick card
          </div>
          <div className={styles.bodyContainer}>
            <div className={styles.messageHeader}>
              <MediaPreview file={message.body} />
            </div>
            <Highlighter
              className={styles.messageBody}
              highlightClassName={styles.highlight}
              highlightTag="span"
              searchWords={[/{{.([^]+?)}}/gm]}
              textToHighlight={message.body.message}
              autoEscape={false}
            />
            <div className={styles.messageButtons}>
              <SuggestionItem
                isInside={false}
                text={message.body.buttonCaption}
                url={message.body.buttonUrl}
                suggestionType="action"
                category="urlAction"
              />
            </div>
          </div>
        </div>
      );
    case 'file':
      return (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon > 2 && <ViberChannelIcon />}
            <Icon icon="media" color="var(--grey30)" size={16} />
            File
          </div>
          <div className={styles.bodyContainer}>
            <MediaPreview file={message.body} />
          </div>
        </div>
      );
    default: return null;
  }
};

export default ViberPreview;
