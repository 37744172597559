import React, { useMemo } from 'react';

import { CustomColumnsView } from 'components/blocks';
import { AUDIT_COLUMNS } from 'consts/columns';
import { useAccountsApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';

import RowDetails from './RowDetails';
import styles from './AuditScreen.module.scss';

const AuditScreen = ({ screenRef }) => {
  const {
    getApiCalls,
    apiCalls,
    apiCallsMeta,
    isPendingGetApiCalls,
    lastUpdatedGetApiCalls,
  } = useAccountsApi();
  const {
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getApiCalls,
  });

  const columns = useMemo(() => {
    const nextColumns = [...AUDIT_COLUMNS];

    nextColumns.push({
      headerName: '',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererExpandRow',
      pinned: 'right',
      width: 40,
      minWidth: 40,
      resizable: false,
      sortable: false,
      lockVisible: true,
      cellRendererParams: {
        rowKey: 'time',
      },
    });

    return nextColumns;
  }, []);

  const getRowHeight = ({ node }) => {
    if (node.detail && node.data?.execId) {
      return 236;
    }
    return 50;
  };

  return (
    <div className={styles.container}>
      <Table
        tableHeader="TABS.AUDIT"
        openCustomColumns={openCustomViewDrawer}
        columnDefs={columns}
        rowData={apiCalls}
        showFilters={false}
        limit={limit}
        total={apiCallsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetApiCalls || !lastUpdatedGetApiCalls}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        getRowHeight={getRowHeight}
        additionalFrameworkComponent={{ cellRendererAuditDetails: RowDetails }}
        detailCellRenderer="cellRendererAuditDetails"
        rowKey="time"
        masterDetail
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={AUDIT_COLUMNS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};

export default AuditScreen;
