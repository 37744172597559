import React from 'react';
import { Dialog as BlueprintDialog, Icon } from '@blueprintjs/core';
import cx from 'classnames';

import styles from './CustomDialog.module.scss';

const CustomDialog = ({
  isOpen,
  title,
  children,
  onClose,
  classNames,
}) => (
  <BlueprintDialog
    isOpen={isOpen}
    className={cx(styles.modal, classNames?.modal)}
    backdropClassName={styles.backdrop}
    canOutsideClickClose={false}
  >
    <div className={styles.header}>
      <span className={cx(styles.title, classNames?.title)}>
        {title}
      </span>
      <Icon
        icon="cross"
        onClick={onClose}
        className={styles.closeModalButton}
        size={17}
      />
    </div>
    <div className={cx(styles.content, classNames?.content)}>
      {children}
    </div>
  </BlueprintDialog>
);

export default CustomDialog;
