import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { updateEntities } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getGroups as getGroupsApi,
  getParameters as getParametersApi,
  postCreateReport as postCreateReportApi,
  postNewReport as postNewReportApi,
  putReport as putReportApi,
  deleteReport as deleteReportApi,
  getReport as getReportApi,
} from 'queries/reports';

import {
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetGroups,
      lastUpdated: lastUpdatedGetGroups,
    },
    getGroups,
  ] = useMutation(getGroupsApi);
  const [
    {
      isPending: isPendingGetParameters,
      lastUpdated: lastUpdatedGetParameters,
      status: statusGetParameters,
    },
    getParameters,
  ] = useMutation(getParametersApi);
  const [
    {
      isPending: isPendingPostCreateReport,
    },
    postCreateReport,
  ] = useMutation(postCreateReportApi);
  const [
    {
      isPending: isPendingPostNewReport,
    },
    postNewReport,
  ] = useMutation(postNewReportApi);

  const [
    {
      isPending: isPendingPutReport,
    },
    putReport,
  ] = useMutation(putReportApi);
  const [
    {
      isPending: isPendingGetReport,
    },
    getReport,
  ] = useMutation(getReportApi);
  const [
    {
      isPending: isPendingDeleteReport,
    },
    deleteReport,
  ] = useMutation(deleteReportApi);

  const selector = useCallback(state => ({
    groups: getArray(state, 'groups'),
    reportParameters: getArray(state, 'reportParameters'),
    reports: getArray(state, 'reports'),
    report: getArray(state, 'report'),
  }), []);
  const data = useISESelector(selector);

  const dispatch = useDispatch();

  const removeReport = (reportName) => {
    dispatch(
      updateEntities({
        reports: () => data.reports.filter(R => R.name !== reportName),
      }),
    );
  };

  return {
    getGroups,
    getParameters,
    getReport,
    postCreateReport,
    postNewReport,
    putReport,
    isPendingGetGroups,
    lastUpdatedGetGroups,
    isPendingGetParameters,
    lastUpdatedGetParameters,
    isPendingPostCreateReport,
    isPendingPostNewReport,
    isPendingPutReport,
    isPendingGetReport,
    deleteReport,
    isPendingDeleteReport,
    isSuccessGetParameters: statusGetParameters === 200,
    removeReport,
    ...data,
  };
};
