import React from 'react';
import cx from 'classnames';
import {Popover} from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Type from './_type.module.css';
import View from './_view.module.css';

import Color from '../../styles/color.module.css';

export const Dropdown = React.forwardRef(
  (
    {
      content,
      position = 'bottom-left',
      view,
      dense = false,
      text,
      color = 'default',
      className,
      ...props
    },
    ref,
  ) => (
    <Popover
      content={content}
      position={position}
      minimal
      popoverClassName={cx(View.popover)}>
      <button
        {...props}
        ref={ref}
        type="button"
        className={cx(
          dense ? Type['dropdown--dense'] : Type.dropdown,
          View[view],
          Color[color],
          {[className]: className},
        )}>
        {text}
      </button>
    </Popover>
  ),
);
Dropdown.propTypes = {
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(['filled',  'smooth', 'outlined', 'raised']),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  position:PropTypes.oneOf([
    "bottom-left",
    "auto",
    "auto-end",
    "auto-start",
    "bottom",
    "bottom-right",
    "left",
    "left-bottom",
    "left-top",
    "right",
    "right-bottom",
    "right-top",
    "top",
    "top-left",
    "top-right",
  ]),
};

