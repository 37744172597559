export default (contentPattern) => {
  if (contentPattern) {
    // text + media + button
    if (contentPattern.buttonCaption && (contentPattern.imageUrl || contentPattern.videoUrl)) {
      return {
        selectedType: 'viberImageCard',
        buttonCaption: contentPattern.buttonCaption,
        buttonUrl: contentPattern.buttonUrl,
        message: contentPattern.text,
        image: {
          fileUrl: contentPattern.imageUrl || contentPattern.videoUrl,
        },
      };
    }
    // text + button
    if (contentPattern.buttonCaption) {
      return {
        selectedType: 'viberTextCard',
        buttonCaption: contentPattern.buttonCaption,
        buttonUrl: contentPattern.buttonUrl,
        message: contentPattern.text,
      };
    }
    // file
    if (contentPattern.imageUrl || contentPattern.videoUrl || contentPattern.fileUrl) {
      return {
        selectedType: 'viberImage',
        image: {
          fileUrl: contentPattern.imageUrl || contentPattern.videoUrl || contentPattern.fileUrl,
        },
      };
    }
    // text
    if (contentPattern.text) {
      return {
        selectedType: 'viberText',
        message: contentPattern.text,
      };
    }
  }

  return null;
};
