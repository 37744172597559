import React from 'react';
import { useTranslation } from 'react-i18next';

import TypographySource from '@setproduct-ui/core/Typography';

export const Typography = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <TypographySource {...props}>
      {typeof children === 'object' ? t(children.key, { ...children.values }) : t(children)}
    </TypographySource>
  );
};
