import { Icon } from '@blueprintjs/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { WeChatChannelIcon } from 'assets/icons';
import { MediaPreview } from 'components';

import styles from '../preview.module.scss';

const WeChatPreview = ({
  message,
  showChannelIcon,
}) => {
  let result;
  switch (message.type) {
    case 'text':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon && <WeChatChannelIcon />}
            <Icon icon="align-left" color="var(--grey30)" size={16} />
            Text
          </div>
          <Highlighter
            className={styles.bodyContainer}
            highlightClassName={styles.highlight}
            highlightTag="span"
            searchWords={[/{{.([^]+?)}}/gm]}
            textToHighlight={message.body.message}
            autoEscape={false}
          />
        </div>
      );
      break;
    case 'file':
      result = (
        <div className={styles.messageContainer}>
          <div className={styles.messageTile}>
            {showChannelIcon > 2 && <WeChatChannelIcon />}
            <Icon icon="media" color="var(--grey30)" size={16} />
            File
          </div>
          <div className={styles.bodyContainer}>
            <MediaPreview file={message.body} />
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return result;
};

export default WeChatPreview;
