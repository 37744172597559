import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PRE_MODERATION_HISTORY, PRE_MODERATION_MODERATION } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import ScrollableTabs from 'components/ScrollableTabs';
import {
  useFilteredColumns,
  useModalLogic,
  useTable,
  useUserPermissions,
} from 'hooks';
import Table from 'components/Table';
import { useDictionariesApi, useModerationApi, useUsersApi } from 'hooks/api';
import Button from '@setproduct-ui/core/Button';
import { Modal } from 'components';
import { SENDERS_CHANNELS_IDS } from 'consts/channels';
import toastRef from 'helpers/toast';
import { ConfirmModal } from 'components/modals';

import styles from './PreModerationScreen.module.scss';
import RequestModal from './RequestModal';

const PreModerationScreen = () => {
  const { t } = useTranslation();

  const moderationPendingTableRef = useRef(null);
  const moderationHistoryTableRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [previewRequest, setPreviewRequest] = useState({});

  const [isOpenModal, { closeModal, openModal }] = useModalLogic('');
  const { editAllowed } = useUserPermissions();

  const [
    isOpenConfirm,
    {
      openModal: openConfirm,
      closeModal: closeConfirm,
    },
  ] = useModalLogic('');

  const openTabs = [
    {
      id: 1,
      title: t('SCREENS.PRE_MODERATION.ON_MODERATION'),
      withoutCross: true,
    },
    {
      id: 2,
      title: t('SCREENS.PRE_MODERATION.HISTORY'),
      withoutCross: true,
    },
  ];

  const { currentUser } = useUsersApi();

  const {
    moderationsPending,
    moderationsPendingMeta,
    moderationsHistory,
    moderationsHistoryMeta,
    getModerations,
    postResolution,
    isPendingGetModerations,
    isPendingPostResolution,
  } = useModerationApi();

  const {
    getCampaignsDictionary,
    getCompaniesDictionary,
    getUsersDictionary,
  } = useDictionariesApi();

  const selectedMessagesCount = useMemo(() =>
    selectedRequests?.filter(I => I.type === 0)?.length, [selectedRequests]);

  const selectedDialogsCount = useMemo(() =>
    selectedRequests?.filter(I => I.type === 1)?.length, [selectedRequests]);

  const confirmTitle = useMemo(() => {
    if (isOpenConfirm === 'block') {
      return 'Are you sure you want to block?';
    }
    return 'Are you sure you want to approve?';
  }, [isOpenConfirm]);

  const getAllChannels = (rawContent) => {
    const channelIds = rawContent?.map(I => SENDERS_CHANNELS_IDS
      .find(channel => channel.type === I.type).value);

    return [...new Set(channelIds)];
  };

  const moderationsPendingPrepared = useMemo(() => moderationsPending.map(I => ({
    ...I,
    channel: getAllChannels(I.rawContent),
  })), [moderationsPending]);

  const moderationsHistoryPrepared = useMemo(() => moderationsHistory.map(I => ({
    ...I,
    channel: getAllChannels(I.rawContent),
  })), [moderationsHistory]);

  const moderationSendQueryFunc = (props) => {
    getModerations({
      storeKey: 'moderationsPending',
      ...props,
    });
  };

  const historySendQueryFunc = (props) => {
    getModerations({
      storeKey: 'moderationsHistory',
      history: 1,
      ...props,
    });
  };

  const {
    sendQuery: moderationSendQuery,
    limit: moderationLimit,
    offset: moderationOffset,
    onSortChanged: moderationOnSortChanged,
    onFilterChanged: moderationOnFilterChanged,
    setAndSendOffset: setPendingPage,
    setAndSendLimit: setPendingLimit,
  } = useTable({
    sendQuery: moderationSendQueryFunc,
    moderationPendingTableRef,
    withInitialQuery: false,
    useNewFilters: true,
  });

  const {
    sendQuery: historySendQuery,
    limit: historyLimit,
    offset: historyOffset,
    onSortChanged: historyOnSortChanged,
    onFilterChanged: historyOnFilterChanged,
    setAndSendOffset: setHistoryPage,
    setAndSendLimit: setHistoryLimit,
  } = useTable({
    sendQuery: historySendQueryFunc,
    moderationHistoryTableRef,
    withInitialQuery: false,
    useNewFilters: true,
  });

  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns();

  const onSelectionChanged = ({ api }) => {
    const selectedRequestsEntities = api.getSelectedRows().map(({ id, type }) => ({ id, type }));
    setSelectedRequests(selectedRequestsEntities);
  };

  const onSelectAll = () => {
    moderationPendingTableRef.current?.selectAll();
  };

  const onRowClickedModeration = ({ data }) => {
    setPreviewRequest(data);
    openModal('edit');
  };
  const onRowClickedHistory = ({ data }) => {
    setPreviewRequest(data);
    openModal('view');
  };

  const onCancelSelect = () => {
    moderationPendingTableRef.current.deselectAll();
    setSelectedRequests([]);
  };

  const onAllowRequestsConfirm = (ids = []) => {
    const selectedIds = ids?.length ? ids : selectedRequests.map(I => I.id);
    postResolution({
      body: {
        ids: selectedIds,
        resolution: 1,
        resolutionUserId: currentUser?.id,
      },
      successCallback: () => {
        closeModal();
        closeConfirm();
        onCancelSelect();
        moderationSendQueryFunc({
          limit: moderationLimit,
          offset: moderationOffset,
        });
      },
      errorCallback: ({ data, key } = {}) => {
        toastRef?.current?.show({ message: key || data?.message, intent: 'danger' });
      },
    });
  };

  const onBlockRequestsConfirm = (ids = []) => {
    const selectedIds = ids?.length ? ids : selectedRequests.map(I => I.id);
    postResolution({
      body: {
        ids: selectedIds,
        resolution: 2,
        resolutionUserId: currentUser?.id,
      },
      successCallback: () => {
        closeModal();
        closeConfirm();
        onCancelSelect();
        moderationSendQueryFunc({
          limit: moderationLimit,
          offset: moderationOffset,
        });
      },
      errorCallback: ({ data, key } = {}) => {
        toastRef?.current?.show({ message: key || data?.message, intent: 'danger' });
      },
    });
  };

  const onBlockRequests = () => {
    openConfirm('block');
  };

  const onAllowRequests = () => {
    openConfirm('allow');
  };

  const getRowHeight = () => 50;

  const renderPanel = (tab) => {
    if (tab.id === 1) {
      return (
        <>
          <div className={styles.tableWrapper}>
            <Table
              tableRef={moderationPendingTableRef}
              columnDefs={PRE_MODERATION_MODERATION(editAllowed)}
              filteredColumns={filteredColumns}
              isPending={isPendingGetModerations}
              limit={moderationLimit}
              page={moderationOffset}
              rowData={moderationsPendingPrepared}
              getRowHeight={getRowHeight}
              rowSelection={editAllowed ? 'multiple' : 'single'}
              sendQuery={moderationSendQuery}
              showColumns={false}
              showFilters={false}
              suppressRowClickSelection
              total={moderationsPendingMeta.size}
              withPagination={!selectedRequests.length}
              onFilterChanged={moderationOnFilterChanged}
              onRowClicked={onRowClickedModeration}
              onSelectionChanged={onSelectionChanged}
              onSortChanged={moderationOnSortChanged}
              onChangeLimit={setPendingLimit}
              onChangePage={setPendingPage}
            />
            {!!selectedRequests.length && (
              <div className={styles.massControls}>
                <div className={styles.massControlsLeft}>
                  <div className={styles.regularText}>
                    Selected:
                    dialogs -&nbsp;
                    <div className={styles.primaryText}>
                      {selectedDialogsCount}
                    &nbsp;
                    </div>
                    and
                    messages -&nbsp;
                    <div className={styles.primaryText}>{selectedMessagesCount}</div>
                  </div>
                  <Button
                    color="primary"
                    text="Select all"
                    type="default"
                    view="outlined"
                    onClick={onSelectAll}
                  />
                </div>
                <div className={styles.massControlsRight}>
                  <Button
                    color="default"
                    style={{
                      alignSelf: 'initial',
                    }}
                    text="Cancel"
                    type="default"
                    view="outlined"
                    onClick={onCancelSelect}
                  />
                  <Button
                    color="danger"
                    icon="disable"
                    text="Block"
                    type="default"
                    view="filled"
                    onClick={onBlockRequests}
                  />
                  <Button
                    color="primary"
                    icon="tick"
                    text="Allow"
                    type="default"
                    view="filled"
                    onClick={onAllowRequests}
                  />
                </div>
              </div>
            )}
          </div>
          <Button
            dense
            className="invoices-layout__columns-button"
            color="primary"
            icon="add-column-right"
            text="CONTROLS.COLUMNS"
            type="card"
            view="outlined"
            onClick={openCustomViewDrawer}
          />
        </>
      );
    }
    return (
      <>
        <div className={styles.tableWrapper}>
          <Table
            columnDefs={PRE_MODERATION_HISTORY}
            filteredColumns={filteredColumns}
            isPending={isPendingGetModerations}
            limit={historyLimit}
            openCustomColumns={openCustomViewDrawer}
            page={historyOffset}
            rowData={moderationsHistoryPrepared}
            getRowHeight={getRowHeight}
            sendQuery={historySendQuery}
            showColumns={false}
            showFilters={false}
            tableRef={moderationHistoryTableRef}
            total={moderationsHistoryMeta.size}
            onFilterChanged={historyOnFilterChanged}
            onRowClicked={onRowClickedHistory}
            onSortChanged={historyOnSortChanged}
            onChangeLimit={setHistoryLimit}
            onChangePage={setHistoryPage}
          />
        </div>
        <Button
          dense
          className={styles.columnsButton}
          color="primary"
          icon="add-column-right"
          text="CONTROLS.COLUMNS"
          type="card"
          view="outlined"
          onClick={openCustomViewDrawer}
        />
      </>
    );
  };

  useEffect(() => {
    if (activeTab === 0) {
      moderationSendQueryFunc({
        limit: moderationLimit,
        offset: moderationOffset,
      });
    }
    if (activeTab === 1) {
      historySendQueryFunc({
        limit: historyLimit,
        offset: historyOffset,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    getCampaignsDictionary({});
    getCompaniesDictionary({});
    getUsersDictionary({});
  }, []);

  return (
    <div className={styles.container}>
      <ScrollableTabs
        activeTab={activeTab}
        renderPanel={renderPanel}
        setActiveTab={setActiveTab}
        styles={{ wrapper: { flex: 1 } }}
        tabsList={openTabs}
      />
      {isOpenCustomViewDrawer && (
        <CustomColumnsView
          columnsNames={activeTab.id === 1
            ? PRE_MODERATION_MODERATION()
            : PRE_MODERATION_HISTORY}
          initialValues={filteredColumns}
          isOpen={isOpenCustomViewDrawer}
          onClose={closeCustomViewDrawer}
          onSubmit={onSaveCustomView}
        />
      )}
      {isOpenModal && (
        <Modal
          withCloseButton={false}
          closeModal={closeModal}
          mode={isOpenModal}
          withTitle={false}
          className={styles.modal}
        >
          <RequestModal
            data={previewRequest}
            mode={isOpenModal}
            onClose={closeModal}
            onBlockRequests={onBlockRequestsConfirm}
            onAllowRequests={onAllowRequestsConfirm}
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete
          title={confirmTitle}
          iconColor={isOpenConfirm === 'block' ? 'var(--red30)' : 'var(--grey30)'}
          color="default"
          closeModal={closeConfirm}
          leftButton={(
            <Button
              text={t('CONTROLS.CANCEL')}
              view="flat"
              color={isOpenConfirm === 'block' ? 'danger' : 'default'}
              onClick={closeConfirm}
            />
          )}
          rightButton={(
            <Button
              text={isOpenConfirm === 'block' ? 'Yes, block' : 'Yes, approve'}
              view="filled"
              color={isOpenConfirm === 'block' ? 'danger' : 'primary'}
              onClick={isOpenConfirm === 'block'
                ? onBlockRequestsConfirm
                : onAllowRequestsConfirm}
              loading={isPendingPostResolution}
            />
          )}
        />
      )}
    </div>
  );
};

export default PreModerationScreen;
