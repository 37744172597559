import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getQuantityRulesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => ({
    quantityRules: data,
    quantityRulesDictionary: data.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    quantityRules: (oldValue, newValue) => newValue,
    quantityRulesDictionary: (oldValue, newValue) => newValue,
  },
});
