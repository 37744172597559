import { useMutation } from 'redux-query-react';

import {
  postAddAsset as postAddAssetApi,
} from 'queries/assets';

export default () => {
  const [
    {
      isPending: isPendingAddAsset,
    },
    postAddAsset,
  ] = useMutation(postAddAssetApi);

  return {
    postAddAsset,
    isPendingAddAsset,
  };
};
