import moment from 'moment';
import React, { useMemo } from 'react';

import TextField from 'components/fields/TextField';

export const YearPickerField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  placeholder,
  label,
  styles = {},
  fieldError,
  fieldTouched,
  children,
  ...props
}) => {
  const textValue = useMemo(() => {
    if (value) {
      return moment(value).format('YYYY');
    }
    return moment().format('YYYY');
  }, [value]);

  const moveNumbers = (number, size = 4) => {
    if (number.length > size) {
      return number.substring(1);
    }
    if (number.length === size) {
      return number;
    }
    /* TODO: реализовать backspace удаление элементов со смещенем влево (последний не удалять) */
    return moment().format('YYYY');
  };

  const handleChange = (val) => {
    const formattedValues = moveNumbers(val);
    if (setFieldValue) {
      setFieldValue(name, moment(formattedValues));
    } else {
      onChange(moment(formattedValues));
    }
  };

  return (
    <>
      <TextField
        name={name}
        label={label}
        fieldType="number"
        styles={styles}
        placeholder={placeholder}
        onChange={handleChange}
        value={textValue}
        fieldError={errors[name]}
        fieldTouched={touched[name]}
        clearButton={false}
        {...props}
      />
      {children}
    </>
  );
};
