import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useMemo } from 'react';

import DropdownField from 'components/fields/DropdownField';
import { InnerField } from 'components/fields/InnerField';
import { YearPickerField } from 'components/fields/YearPickerField';
// import { MONTHS } from 'consts';

import './style.scss';

// todo: Костыль для сторибука, почему-то ругается и не видит ссылку на константу
const MONTHS = [
  { value: 1, label: 'STATES.MONTHS.JANUARY' },
  { value: 2, label: 'STATES.MONTHS.FEBRUARY' },
  { value: 3, label: 'STATES.MONTHS.MARCH' },
  { value: 4, label: 'STATES.MONTHS.APRIL' },
  { value: 5, label: 'STATES.MONTHS.MAY' },
  { value: 6, label: 'STATES.MONTHS.JUNE' },
  { value: 7, label: 'STATES.MONTHS.JULY' },
  { value: 8, label: 'STATES.MONTHS.AUGUST' },
  { value: 9, label: 'STATES.MONTHS.SEPTEMBER' },
  { value: 10, label: 'STATES.MONTHS.OCTOBER' },
  { value: 11, label: 'STATES.MONTHS.NOVEMBER' },
  { value: 12, label: 'STATES.MONTHS.DECEMBER' },
];

export const MonthYearPickerField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  label,
  fieldError,
  fieldTouched,
  styles = {},
  leftStyles = {},
  rightStyles = {},
  children,
  ...props
}) => {
  const { t } = useTranslation();

  const [year, setYear] = useState(1);
  const [month, setMonth] = useState(1);

  const errorHelperText = useMemo(() => (fieldError && fieldTouched
    ? t(fieldError)
    : errors[name] && touched[name]
      ? t(errors[name])
      : ''), [fieldError, fieldTouched, errors, touched, name]);

  const isHaveError = useMemo(
    () => (errors[name] && touched[name]) || (fieldError && fieldTouched),
    [errors, touched, fieldError, fieldTouched, name],
  );

  useEffect(() => {
    if (value) {
      const currentMonth = moment(value).format('M');
      const currentYear = moment(value).format('YYYY');
      const currentMonthNumber = Number(currentMonth);
      const currentYearNumber = Number(currentYear);
      setYear(currentYearNumber);
      setMonth(currentMonthNumber);
    } else {
      const currentMonth = moment().format('M');
      const currentYear = moment().format('YYYY');
      const currentMonthNumber = Number(currentMonth);
      const currentYearNumber = Number(currentYear);
      setYear(currentYearNumber);
      setMonth(currentMonthNumber);
    }
  }, [value]);

  const moveNumbers = (number, size = 4) => {
    if (number?.length > size) {
      return number.substring(1);
    }
    if (number?.length === size) {
      return number;
    }
    return moment().format('YYYY');
  };

  const getFieldValue = (nYear, nMonth) =>
    moment(`${nYear}${nMonth}`, nMonth <= 9 ? 'YYYYM' : 'YYYYMM');

  const handleChangeYear = (e) => {
    const formattedValue = moveNumbers(e);
    const resultValue = getFieldValue(e, month);
    if (resultValue.isValid()) {
      if (setFieldValue) {
        setFieldValue(name, resultValue);
      } else {
        onChange(resultValue);
      }
    }
    setYear(formattedValue);
  };

  const handleChangeMonth = (e) => {
    const resultValue = getFieldValue(year, e);
    if (resultValue.isValid()) {
      if (setFieldValue) {
        setFieldValue(name, resultValue);
      } else {
        onChange(resultValue);
      }
    }
    setMonth(e);
  };

  return (
    <>
      <InnerField
        styles={styles}
        noFields
        label={label}
        leftFieldComponent={DropdownField}
        rightFieldComponent={YearPickerField}
        isHaveError={isHaveError}
        leftProps={{
          onChange: handleChangeMonth,
          options: MONTHS,
          styles: leftStyles,
          value: month,
        }}
        rightProps={{
          onChange: handleChangeYear,
          styles: rightStyles,
          disabled: false,
          value: year,
          clearButton: false,
          fieldType: 'number',
          error: isHaveError,
          errorHelperText,
          classNames: {
            container: 'month-year-field__year-field',
          },
        }}
        {...props}
      />
      {children}
    </>
  );
};
