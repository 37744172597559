import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getSettings as getSettingsApi,
  putSettings as putSettingsApi,
} from 'queries/settings';

import {
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSettings,
    },
    getSettings,
  ] = useMutation(getSettingsApi);
  const [
    {
      isPending: isPendingPutSettings,
    },
    putSettings,
  ] = useMutation(putSettingsApi);

  const selector = useCallback(state => ({
    settings: getObject(state, 'settings'),
    threadStatistics: getObject(state, 'threadStatistics'),
  }), []);
  const data = useISESelector(selector);

  return {
    getSettings,
    putSettings,
    isPendingGetSettings,
    isPendingPutSettings,
    ...data,
  };
};
