import React from 'react';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';

import './style.scss';

export default ({
  id,
  title,
  subItems,
  isSelected,
  onSelectSection,
  onSelectItem,
  selectedSubsection,
}) => {
  const dropdownClass = classNames({
    'tree-dropdown-field': true,
    'tree-dropdown-field_selected': isSelected,
  });
  const subItemClass = itemId => classNames({
    'tree-dropdown-field__sub-item': true,
    'tree-dropdown-field__sub-item_selected': selectedSubsection === itemId,
  });

  const onSectionClick = () => {
    if (isSelected) {
      onSelectSection({});
    } else {
      onSelectSection({ id, title });
    }
  };

  return (
    <div className={dropdownClass}>
      <div
        className="tree-dropdown-field__button"
        onClick={onSectionClick}
        role="presentation"
      >
        <Icon
          icon="caret-right"
          iconSize={20}
          color={isSelected ? 'var(--blue100)' : 'var(--grey40)'}
          className="tree-dropdown-field__icon"
        />
        {title}
      </div>
      {
        subItems.map(item => (
          <button
            type="button"
            onClick={() => onSelectItem(item)}
            className={subItemClass(item.id)}
            key={item.id}
            data-testid={item.id
              ? `${window.location.pathname}/tree-dropdown-field/${item.id}`
              : undefined}
          >
            {item.title}
          </button>
        ))
      }
    </div>
  );
};
