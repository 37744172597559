import React, { useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Icon } from '@blueprintjs/core';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import DropdownField from 'components/fields/DropdownField';
import TextField from 'components/fields/TextField';
import { FormButtonsGroup } from 'components/blocks';
import {
  SIMPLE_SENDERS_SCHEMA,
  SMS_SENDER_SCHEMA,
  VIBER_SENDER_SCHEMA,
  WA_SENDER_SCHEMA,
  EMAIL_SENDER_SCHEMA,
  WECHAT_SENDER_SCHEMA,
  TELEGRAM_GATEWAY_SENDER_SCHEMA,
} from 'consts/validationSchemas';
import {
  SENDER_STATUSES,
  SMS_VENDOR_TYPES,
  WHATSAPP_VENDOR_TYPES,
  VIBER_VENDOR_TYPES_OPTIONS,
  VK_OK_VENDOR_TYPES_OPTIONS,
  EMAIL_VENDOR_TYPES,
  WECHAT_VENDOR_TYPES,
  TELEGRAM_GATEWAY_VENDOR_TYPES,
} from 'consts';
import { useDictionariesApi } from 'hooks/api';
import { generateUuid } from 'helpers';
import { CheckboxField } from 'components/fields';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import IsFormChanged from 'components/forms/IsFormChanged';
import { numbers } from 'consts/inputPatterns';

import './style.scss';

const SenderForm = ({
  onSubmit,
  smppConnectionsOptions,
  smppApiConnectionsOptions,
  httpConnectionsOptions,
  wabaOptions,
  initialValues = {
    senderStatus: 2,
  },
  isPending,
  mode,
  onDelete,
  onCancel,
  channel,
  formValuesRef,
  isFormPristine,
}) => {
  const fieldStyle = marginTop => ({
    container: {
      width: '100%',
    },
    label: {
      marginTop,
    },
  });
  const connectionFieldStyles = marginTop => ({
    container: {
      marginTop,
    },
  });
  const generateButtonStyles = {
    position: 'absolute',
    right: 8,
    top: 12,
    cursor: 'pointer',
  };

  const {
    companiesOptionsWithOwn,
    companiesDictionary,
    agreementsOptions,
  } = useDictionariesApi();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const [isTenantCompanySelected, setIsTenantCompanySelected] = useState(false);
  const [filteredAgreementsOptions, setFilteredAgreementsOptions] = useState([]);

  const getWabaOptions = companyId => wabaOptions.reduce((acc, item) => {
    if (item.companyId === companyId) {
      acc.push(item);
    }
    return acc;
  }, []);
  const getConnectionsOptions = (vendorType) => {
    if (vendorType === 'smpp') {
      return smppConnectionsOptions;
    }
    return httpConnectionsOptions.filter(I => I.vendorType === vendorType);
  };
  const validationSchema = useMemo(() => {
    const resultSchema = SIMPLE_SENDERS_SCHEMA(isTenantCompanySelected);

    switch (channel) {
      case 1: return resultSchema.concat(SMS_SENDER_SCHEMA);
      case 3: return resultSchema.concat(VIBER_SENDER_SCHEMA);
      case 4: return resultSchema.concat(WA_SENDER_SCHEMA);
      case 6: return resultSchema.concat(WECHAT_SENDER_SCHEMA);
      case 7: return resultSchema.concat(EMAIL_SENDER_SCHEMA);
      case 8: return resultSchema.concat(TELEGRAM_GATEWAY_SENDER_SCHEMA);
      default: return resultSchema;
    }
  }, [channel, isTenantCompanySelected]);
  const vendorTypesOptions = useMemo(() => {
    switch (channel) {
      case 1: return SMS_VENDOR_TYPES;
      case 3: return VIBER_VENDOR_TYPES_OPTIONS;
      case 4: return WHATSAPP_VENDOR_TYPES;
      case 5: return VK_OK_VENDOR_TYPES_OPTIONS;
      case 6: return WECHAT_VENDOR_TYPES;
      case 7: return EMAIL_VENDOR_TYPES;
      case 8: return TELEGRAM_GATEWAY_VENDOR_TYPES;
      default: return [];
    }
  }, [channel]);
  const initialValuesByChannel = useMemo(() => {
    if (channel === 4) {
      return {
        isSync: true,
        mediaUrl: 'https://waba.360dialog.io/v1/media',
        ...initialValues,
      };
    }
    return initialValues;
  }, [initialValues, channel]);

  const onChangeCompany = ({ form, value }) => {
    if (form.dirty) {
      form.setFieldValue('agreementId', undefined);
      form.setFieldValue('waba_id', undefined);
    }
    setFilteredAgreementsOptions(agreementsOptions?.filter(I => I.companyId === value));
    setIsTenantCompanySelected(value && !companiesDictionary[value]?.parentCompanyId);
  };
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    mcc,
    keyword,
    // eslint-disable-next-line camelcase
    waba_id,
    sessionModeOn,
    ...values
  }) => {
    onSubmit({
      ...values,
      properties: {
        senderId: values.senderId,
        displayName: values.displayName,
        senderEmail: values.senderEmail,
        // eslint-disable-next-line camelcase
        waba_id,
        mcc,
        keyword,
        sessionModeOn,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValuesByChannel}
      onSubmit={onSubmitModify}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        values,
        errors,
      }) => (
        <Form className="sender-form">
          <div className="sender-form__shadow" />
          <div className="sender-form__content">
            <div className="sender-form__column">
              <div>
                <Field
                  name="senderId"
                  label="INSTANCES.SENDER_ID"
                  component={TextField}
                  placeholder="PLACEHOLDERS.SENDER_ID.ENTER_SENDER_ID"
                  isRequired
                  full
                />
              </div>
              <Field
                name="companyId"
                component={DropdownField}
                options={companiesOptionsWithOwn}
                styles={fieldStyle(24)}
                label="INSTANCES.COMPANY"
                placeholder="PLACEHOLDERS.SELECT_PARTNER"
                isRequired
              />
              <Field
                name="senderStatus"
                component={DropdownField}
                label="INSTANCES.STATUS"
                placeholder="PLACEHOLDERS.SELECT"
                options={SENDER_STATUSES.filter(I => I.value !== 1)}
                styles={fieldStyle(24)}
                isRequired
              />
              {
                channel === 1 && (
                  <>
                    <Field
                      name="mcc"
                      label="INSTANCES.SENDER_IDS.MCC"
                      styles={fieldStyle(24)}
                      component={TextField}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      full
                    />
                    <Field
                      name="smppApiConnectionId"
                      label="INSTANCES.SENDER_IDS.WEBHOOK_CONNECTION"
                      styles={fieldStyle(24)}
                      component={DropdownField}
                      options={smppApiConnectionsOptions}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      disabled={!!values.webHookUrl}
                      title={!!values.webHookUrl && 'TOOLTIPS.SENDER_IDS.ONLY_ONE_ENABLED'}
                      clearButton
                      full
                    />
                  </>
                )
              }
              {
                channel === 4 && (
                  <Field
                    name="waba_id"
                    label="INSTANCES.WABA_ID"
                    styles={fieldStyle(24)}
                    component={DropdownField}
                    options={getWabaOptions(values.companyId)}
                    placeholder="PLACEHOLDERS.SELECT"
                    disabled={!values.companyId}
                    isRequired
                    full
                  />
                )
              }
              <Field
                name="vendorType"
                label="INSTANCES.VENDOR_TYPE"
                styles={fieldStyle(24)}
                component={DropdownField}
                options={vendorTypesOptions}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                isRequired={values.senderStatus === 2}
                full
              />
              {(values.vendorType === 'mkit_http_telin' || values.vendorType === 'mkit_http_wechat') && (
                <Field
                  name="appId"
                  label="INSTANCES.APP_ID"
                  styles={fieldStyle(24)}
                  component={TextField}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  isRequired
                  full
                />
              )}
              {
                (values.vendorType === 'alaris_http_sms' || values.vendorType === 'alaris_http_viber') && (
                  <Field
                    name="login"
                    label="INSTANCES.LOGIN"
                    styles={fieldStyle(24)}
                    component={TextField}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    isRequired
                    full
                  />
                )
              }
              {
                ((channel === 1 && !!values.vendorType) || values.vendorType === 'alaris_http_viber') && (
                  <Field
                    name="serviceType"
                    label="INSTANCES.SENDER_IDS.SERVICE_TYPE"
                    styles={fieldStyle(24)}
                    component={TextField}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    full
                  />
                )
              }
              {['dialog360', 'mkit_http_elasticemail', 'mkit_http_dialog360cloud', 'mkit_http_telegramgw'].includes(values.vendorType) && (
                <Field
                  name="apiKey"
                  label="INSTANCES.API_KEY"
                  styles={fieldStyle(24)}
                  component={TextField}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  isRequired
                  full
                />
              )}
            </div>
            <div className="sender-form__column">
              <Field
                name="displayName"
                label="INSTANCES.NAME"
                component={TextField}
                placeholder="PLACEHOLDERS.SENDER_ID.SENDER_ID_NAME"
                isRequired
                full
              />
              <Field
                name="agreementId"
                label="INSTANCES.AGREEMENT"
                component={DropdownField}
                options={filteredAgreementsOptions}
                styles={fieldStyle(24)}
                placeholder="PLACEHOLDERS.SELECT"
                disabled={!values.companyId}
                isRequired={!isTenantCompanySelected}
              />
              <Field
                name="webHookGuid"
                component={TextField}
                styles={fieldStyle(24)}
                rightElement={!values.webHookGuid && (
                  <Icon
                    icon="new-link"
                    size={20}
                    color="var(--blue70)"
                    style={generateButtonStyles}
                    onClick={() => setFieldValue('webHookGuid', generateUuid())}
                  />
                )}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                label="INSTANCES.SENDER_IDS.WEBHOOK_GUID"
                full
              />
              {
                channel === 1 && (
                  <>
                    <Field
                      name="keyword"
                      label="INSTANCES.SENDER_IDS.KEYWORD"
                      styles={fieldStyle(24)}
                      component={TextField}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      full
                    />
                    <Field
                      name="webHookUrl"
                      label="INSTANCES.SENDER_IDS.WEBHOOK_URL"
                      styles={fieldStyle(24)}
                      component={TextField}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      disabled={!!values.smppApiConnectionId}
                      title={!!values.smppApiConnectionId && 'TOOLTIPS.SENDER_IDS.ONLY_ONE_ENABLED'}
                      full
                    />
                  </>
                )
              }
              {
                channel === 3 && (
                  <Field
                    name="sessionModeOn"
                    component={CheckboxField}
                    styles={fieldStyle(46)}
                    text="INSTANCES.SENDER_IDS.SESSION_MODE"
                  />
                )
              }
              {
                (values.vendorType === 'alaris_http_sms' || values.vendorType === 'alaris_http_viber') && (
                  <Field
                    name="password"
                    label="INSTANCES.PASSWORD"
                    styles={fieldStyle(values.vendorType === 'alaris_http_viber' ? 24 : 112)}
                    component={TextField}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    isRequired
                    full
                  />
                )
              }
              {[
                'smpp',
                'mkit_http_vk_ok',
                'mkit_http_viber',
                'mkit_http_telin',
                'mkit_http_gupshup',
                'mkit_http_elasticemail',
                'mkit_http_dialog360cloud',
                'mkit_http_wechat',
                'mkit_http_telegramgw',
              ].includes(values.vendorType) && (
                <Field
                  name="connectionId"
                  label="INSTANCES.SENDER_IDS.CONNECTION"
                  styles={connectionFieldStyles(
                    [
                      'mkit_http_telin',
                      'mkit_http_gupshup',
                      'mkit_http_dialog360cloud',
                    ].includes(values.vendorType) ? 112 : 24,
                  )}
                  component={DropdownField}
                  options={getConnectionsOptions(values.vendorType)}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  isRequired
                  full
                />
              )}
              {values.vendorType === 'mkit_http_gupshup' && (
                <>
                  <Field
                    name="appId"
                    component={TextField}
                    label="INSTANCES.APP_ID"
                    styles={fieldStyle(24)}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    isRequired
                    full
                  />
                  <Field
                    name="apiKey"
                    component={TextField}
                    label="INSTANCES.API_KEY"
                    styles={fieldStyle(24)}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    isRequired
                    full
                  />
                  <Field
                    name="accessMedia"
                    component={TextField}
                    label="INSTANCES.SENDER_IDS.ACCESS_MEDIA"
                    styles={fieldStyle(24)}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    isRequired
                    full
                  />
                </>
              )}
              {values.vendorType === 'dialog360' && (
                <Field
                  name="mediaUrl"
                  label="INSTANCES.SENDER_IDS.MEDIA_URL"
                  styles={fieldStyle(112)}
                  component={TextField}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  isRequired
                  full
                />
              )}
              {(values.vendorType === 'dialog360' || values.vendorType === 'mkit_http_dialog360cloud') && (
                <Field
                  name="isSync"
                  component={CheckboxField}
                  styles={fieldStyle(46)}
                  text="INSTANCES.SENDER_IDS.IS_SYNC"
                />
              )}
              {values.vendorType === 'mkit_http_telegramgw' && (
                <Field
                  name="isVerified"
                  component={CheckboxField}
                  styles={fieldStyle(46)}
                  text="INSTANCES.SENDER_IDS.IS_VERIFIED"
                />
              )}
              {
                values.vendorType === 'mkit_http_telin' && (
                  <>
                    <Field
                      name="phoneNumberId"
                      component={TextField}
                      label="INSTANCES.HTTP.PHONE_NUMBER_ID"
                      isRequired
                      styles={fieldStyle(24)}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      regex={numbers()}
                      full
                    />
                    <Field
                      name="accessToken"
                      component={TextField}
                      label="INSTANCES.HTTP.ACCESS_TOKEN"
                      isRequired
                      styles={fieldStyle(24)}
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      full
                    />
                  </>
                )
              }
              {
                values.vendorType === 'mkit_http_wechat' && (
                <>
                  <Field
                    name="appSecret"
                    component={TextField}
                    label="INSTANCES.SENDER_IDS.APP_SECRET"
                    isRequired
                    styles={fieldStyle(24)}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    full
                  />
                  <Field
                    name="token"
                    component={TextField}
                    label="INSTANCES.SENDER_IDS.TOKEN"
                    isRequired
                    styles={fieldStyle(24)}
                    placeholder="PLACEHOLDERS.ENTER_VALUE"
                    full
                  />
                </>
                )
              }
              {values.vendorType === 'mkit_http_elasticemail' && (
                <Field
                  name="senderEmail"
                  label="INSTANCES.SENDER_EMAIL"
                  component={TextField}
                  placeholder="PLACEHOLDERS.SENDER_ID.ENTER_SENDER_EMAIL"
                  styles={fieldStyle(24)}
                  isRequired
                  full
                />
              )}
            </div>
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow
          />
          <OnChangeComponent field="companyId" onChange={onChangeCompany} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default SenderForm;
