import React, { useRef, useEffect } from 'react';

import Switch from '@setproduct-ui/core/Switch/Switch';
import { Typography } from 'components/Typography';

import './style.scss';

const SwitchField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
  } = {},
  placeholder,
  label,
  text,
  styles = {},
  classNames = {},
  isRequired,
  color = 'danger',
  inputRef,
  ...props
}) => {
  const innerInputRef = useRef();

  const handleChange = () => {
    if (setFieldValue) {
      setFieldValue(name, !value);
    } else {
      onChange(!value);
    }
  };

  useEffect(() => {
    if ((inputRef || innerInputRef).current && name) {
      (inputRef || innerInputRef).current.parentNode.setAttribute('data-testid', `${window.location.pathname === '/'
        ? '/companies'
        : window.location.pathname}/switch-field/${name}`);
    }
  }, []);

  return (
    <div className={classNames.container} style={styles.container}>
      <Switch
        type="dense"
        color={color}
        checked={value}
        label={<Typography className={classNames.label} style={styles.label}>{label}</Typography>}
        name={name}
        onChange={handleChange}
        className={classNames.input}
        style={styles.input}
        data-testid={name
          ? `${window.location.pathname === '/'
            ? '/companies'
            : window.location.pathname}/switch-field/${name}/input`
          : undefined}
        inputRef={inputRef || innerInputRef}
        {...props}
      />
    </div>
  );
};

export default SwitchField;
