import endpoints from 'consts/endpoints';

export default ({
  limit,
  offset,
  ...queries
} = {}) => ({
  url: endpoints.getInvoicesUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    sort: '-periodStart',
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      data,
      meta,
    } = response;

    return {
      invoices: data,
      invoicesMeta: meta,
    };
  },
  update: {
    invoices: (oldValue, newValue) => newValue,
    invoicesMeta: (oldValue, newValue) => newValue,
  },
});
