import React, { useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PERMISSIONS_MAP_BY_PATH, IS_DEV } from 'consts';
import { useUsersApi } from 'hooks/api';
import Badge from '@setproduct-ui/core/Badge/Badge';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

const ScreenHeader = ({
  title,
  openColumns,
  openForm,
  buttonText,
  buttonView = 'filled',
  classNames = {},
  showFilters = true,
  showColumns = true,
  iconColor = 'white',
  dense,
}) => {
  const { location: { pathname } } = useHistory();
  const headerClassName = cx('screen-header', {
    [classNames.screenHeader]: !!classNames.screenHeader,
  });
  const titleClassName = cx('screen-header__title', {
    'screen-header__title_dense': dense,
  });
  const columnsButtonClass = cx({
    'screen-header__columns': buttonText || openForm,
  });

  const { currentUser: { roles = [] } } = useUsersApi();
  const { t } = useTranslation();

  const createAllowed = useMemo(() => {
    const requiredCreatePermission = PERMISSIONS_MAP_BY_PATH[pathname]?.createPermission;

    return !requiredCreatePermission || roles.includes(requiredCreatePermission);
  }, [roles]);
  const dataTestId = useMemo(
    () =>
      `${title ? `${window.location.pathname}/${title.toLowerCase()?.replace('.', '-')}` : ''}`,
    [title],
  );

  return (
    <div className={headerClassName}>
      <span
        data-testid={`${dataTestId}/title`}
        className={titleClassName}
      >
        {t(title)}
      </span>
      <div>
        {
          showFilters && IS_DEV && (
            <Badge
              view="outlined"
              color="primary"
              value="5"
              className="screen-header__badge"
            >
              <Button
                type={dense ? 'default' : 'card'}
                view="outlined"
                color="primary"
                icon="settings"
                className="screen-header__prime-button"
                text="CONTROLS.FILTERS"
                dense
                data-testid={`${dataTestId}/filters`}
              />
            </Badge>
          )
        }
        {
          showColumns && (
            <Button
              type={dense ? 'default' : 'card'}
              view="outlined"
              color="primary"
              icon="add-column-right"
              className={columnsButtonClass}
              onClick={openColumns}
              text="CONTROLS.COLUMNS"
              data-testid={`${dataTestId}/columns`}
              dense
            />
          )
        }
        {
          (openForm || buttonText) && (
            <Button
              icon="plus"
              view={buttonView}
              iconColor={iconColor}
              color="primary"
              className="screen-header__button"
              onClick={openForm}
              text={buttonText}
              dense={dense}
              data-testid={`${dataTestId}/create-instance`}
              disabled={!createAllowed}
              title={!createAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
            />
          )
        }
      </div>
    </div>
  );
};

export default ScreenHeader;
