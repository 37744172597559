import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';

import Switch from '@setproduct-ui/core/Switch';

import styles from './SwitchRow.module.scss';

const SwitchRow = ({
  checked = false,
  label,
  onChange,
  isCheckedByDependency,
  dependencies,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useToggle(false);

  const componentClass = cx(styles.container, {
    [styles.container_locked]: isCheckedByDependency,
    [styles.container_open]: isOpen,
  });
  const rowClass = cx(styles.row, {
    [styles.row_disabled]: disabled,
  });

  const handleChange = ({ target }) => {
    if (!isCheckedByDependency && !disabled) {
      onChange(target.checked);
    }
  };

  return (
    <div className={componentClass}>
      <div className={rowClass}>
        <Switch
          checked={isCheckedByDependency || checked}
          onChange={handleChange}
          color={isCheckedByDependency ? 'warning_alt' : 'primary'}
          label={t(label)}
        />
        {isCheckedByDependency && (
          <Icon
            size={20}
            color="var(--grey30)"
            icon="lock"
            htmlTitle={t('NEW_TRANSLATES.TOOLTIPS.USERS.FULL_ACCESS')}
          />
        )}
        {!!dependencies.length && (
          <Icon
            size={20}
            color="var(--grey30)"
            icon="chevron-down"
            className={styles.chevron}
            onClick={toggleOpen}
          />
        )}
      </div>
      <div className={styles.dependenciesList}>
        {dependencies.map(dependency => (
          <div className={styles.dependency} key={dependency.code}>
            <Icon
              size={20}
              icon={dependency.code.includes('view') ? 'eye-open' : 'tick-circle'}
              color={`var(--${dependency.code.includes('view') ? 'blue' : 'green'}70)`}
              style={{ marginRight: 10 }}
            />
            {dependency.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SwitchRow;
