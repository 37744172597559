import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';

import './style.scss';

export default ({
  valueFormatted,
}) => {
  const variables = [];
  const parser = new DOMParser();
  const html = parser.parseFromString(valueFormatted, 'text/html');
  html?.documentElement?.textContent.replace(/{{.([^]+?)}}/gm, (match, p1) => {
    variables.push(p1);
  });
  return (
    <Popover2
      minimal
      interactionKind="hover"
      transitionDuration={0}
      popoverClassName="text-cell-renderer__overlay"
      className="text-cell-renderer"
      content={(
        <div className="text-cell-renderer__content">
          {variables.map(I => (
            <div className="text-cell-renderer__content__item">{I}</div>
          ))}
        </div>
      )}
    >
      {variables.join(', ')}
    </Popover2>
  );
};
