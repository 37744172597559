import React from 'react';

import {
  EmailChannelIcon,
  RCSIcon,
  SmsChannelIcon,
  TelegramChannelIcon,
  ViberChannelIcon,
  WhatsappChannelIcon,
} from 'assets/icons';

export const ALL_CHANNELS_IDS = [
  { value: 1, label: 'SMS', icon: <SmsChannelIcon /> },
  { value: 2, label: 'RCS', icon: <RCSIcon /> },
  { value: 3, label: 'Viber', icon: <ViberChannelIcon /> },
  { value: 4, label: 'WhatsApp', icon: <WhatsappChannelIcon /> },
  { value: 5, label: 'Telegram', icon: <TelegramChannelIcon /> },
  { value: 6, label: 'Email', icon: <EmailChannelIcon /> },
];

export const SENDERS_CHANNELS_IDS = [
  { value: 1, label: 'SMS', type: 'SMS' },
  { value: 2, label: 'RCS', type: 'RCS' },
  { value: 3, label: 'Viber', type: 'VIBER' },
  { value: 4, label: 'WhatsApp', type: 'WHATSAPP' },
  { value: 5, label: 'VK/OK', type: 'VKOK' },
  { value: 6, label: 'WeChat', type: 'WE_CHAT' },
  { value: 7, label: 'Email', type: 'EMAIL' },
  { value: 8, label: 'Telegram Gateway', type: 'TELEGRAM_GW' },
];

export const NOTIFICATION_CHANNELS_IDS = [
  { value: 1, label: 'SMS', icon: <SmsChannelIcon /> },
  { value: 5, label: 'Telegram', icon: <TelegramChannelIcon /> },
  { value: 6, label: 'Email', icon: <EmailChannelIcon /> },
];
