import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Chips from '@setproduct-ui/core/Chips/Chips';

import './styles.scss';

export default ({
  value,
}) => {
  const { t } = useTranslation();

  const classes = classNames('status-cell-renderer', {
    'status-cell-renderer_confirmed': value,
    'status-cell-renderer_not-confirmed': !value,
  });

  return (
    <Chips
      type="dense"
      view="smooth"
      round={false}
      className={classes}
      tag={t(value ? 'STATES.CONFIRMED' : 'STATES.PAYMENTS.NOT_CONFIRMED')}
    />
  );
};
