import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PERMISSIONS_MAP_BY_PATH } from 'consts';
import { useUsersApi } from 'hooks/api';

export default () => {
  const { location: { pathname } } = useHistory();
  const { currentUser: { roles = [] } } = useUsersApi();

  const {
    editAllowed,
    deleteAllowed,
    createAllowed,
    enableAllowed,
  } = useMemo(() => {
    const requiredPermissions = PERMISSIONS_MAP_BY_PATH[pathname] || {};
    const {
      editPermission,
      deletePermission,
      createPermission,
      enablePermission,
    } = requiredPermissions;

    return {
      editAllowed: !editPermission || roles.includes(editPermission),
      deleteAllowed: !deletePermission || roles.includes(deletePermission),
      createAllowed: !createPermission || roles.includes(createPermission),
      enableAllowed: !enablePermission || roles.includes(enablePermission),
    };
  }, [roles, pathname]);

  return {
    editAllowed,
    deleteAllowed,
    createAllowed,
    enableAllowed,
  };
};
