import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBuilder from 'classnames';

import { Typography } from 'components/Typography';
import CheckBox from '@setproduct-ui/core/CheckBox';

import './style.scss';

export const CheckboxField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
  } = {},
  placeholder,
  label,
  text,
  styles = {},
  classNames = {},
  isRequired,
  fieldType = 'def',
  children,
  inputRef,
  ...props
}) => {
  const { t } = useTranslation();
  const innerInputRef = useRef();

  const labelClass = classNamesBuilder('checkbox-field__label', {
    'checkbox-field__label_required': isRequired,
  });

  const handleChange = () => {
    if (setFieldValue) {
      setFieldValue(name, !value);
    } else {
      onChange(!value);
    }
  };

  useEffect(() => {
    if ((inputRef || innerInputRef).current && name) {
      (inputRef || innerInputRef).current.parentNode.setAttribute('data-testid', `${window.location.pathname === '/'
        ? '/companies'
        : window.location.pathname}/checkbox-field/${name}`);
    }
  }, []);

  return (
    <div
      className={`checkbox-field ${classNames.container}`}
      style={styles.container}
    >
      <Typography
        className={`${labelClass} ${classNames.label}`}
        style={styles.label}
      >
        {label}
      </Typography>
      <CheckBox
        type={fieldType}
        name={name}
        onChange={handleChange}
        label={t(text)}
        checked={value}
        style={styles.input}
        color="primary"
        className={`checkbox-field__input ${classNames.input}`}
        data-testid={name
          ? `${window.location.pathname === '/'
            ? '/companies'
            : window.location.pathname}/checkbox-field/${name}/input`
          : undefined}
        inputRef={inputRef || innerInputRef}
        {...props}
      />
      {children}
    </div>
  );
};
