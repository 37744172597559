import React, { useState, useMemo } from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  DropdownField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import FormContentWrapper from 'components/FormContentWrapper';
import ChargePrice from 'components/blocks/ChargePrice';
import { useDictionariesApi, useAdditionalFeesApi } from 'hooks/api';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import OnFormChange from 'components/forms/OnFormChange';
import { inputPatterns } from 'consts';
import { ADDITIONAL_FEE_SCHEMA } from 'consts/validationSchemas';
import round from 'helpers/round';

import styles from './AdditionalFeeForm.module.scss';

const ADDITIONAL_FEES_TYPES = [
  {
    value: 1,
    label: 'SCREENS.ADDITIONAL_FEES.BY_TARIFF',
  },
  {
    value: 2,
    label: 'SCREENS.ADDITIONAL_FEES.FIXED_PRICE',
  },
];

export default ({
  onSubmit,
  onCancel,
  isPending,
}) => {
  const { t } = useTranslation();
  const {
    agreementsOptions,
    companiesOptions,
    agreementsDictionary,
    definitionsList,
  } = useDictionariesApi();
  const {
    getAdditionalFeePrice,
  } = useAdditionalFeesApi();
  const [priceInfo, setPriceInfo] = useState(null);

  const suppliesOptions = useMemo(() => definitionsList?.['61']?.map(I => ({
    value: +I.value,
    label: I.label,
  })), [definitionsList]);
  const getAgreementsOptions = companyId => agreementsOptions.filter(I => I.companyId === companyId);
  const onChangeCompanyId = ({ form }) => {
    if (form.dirty) {
      form.setFieldValue('agreementId', undefined);
    }
  };
  const onChangeFeeType = ({ form, value }) => {
    if (form.dirty) {
      if (value === 1) {
        form.setFieldValue('preRatedCost', undefined);
      } else {
        form.setFieldValue('supplyId', undefined);
        form.setFieldValue('volume', undefined);
      }
    }
  };
  const onChangeFormValues = (values) => {
    if (values.companyId && values.agreementId && values.supplyType === 1) {
      getAdditionalFeePrice({
        props: {
          companyId: values.companyId,
          agreementId: values.agreementId,
          volume: values.volume,
          supplyId: values.supplyId,
        },
        successCallback: res => setPriceInfo({
          date: `Effective: ${res.effectiveFrom} - ${res.effectiveTill || '∞'}`,
          price: `${round({ num: res.value, decimalsCount: 5, cutDecimalZeroes: true })} ${agreementsDictionary[values.agreementId]?.currency}`,
        }),
        errorCallback: res => setPriceInfo({ errorMessage: t(res?.key, res?.args) }),
      });
    } else if (priceInfo) {
      setPriceInfo(null);
    }
  };

  return (
    <Formik
      initialValues={{ supplyType: 1, volume: 1 }}
      onSubmit={onSubmit}
      validationSchema={ADDITIONAL_FEE_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        errors,
        setFieldTouched,
      }) => (
        <Form className={styles.form}>
          <FormContentWrapper>
            <div className={styles.row}>
              <Field
                name="companyId"
                label="INSTANCES.COMPANY"
                component={DropdownField}
                options={companiesOptions}
                placeholder="PLACEHOLDERS.SELECT_COMPANY"
                fieldClassNames={{ container: styles.field }}
                isRequired
                full
              />
              <Field
                name="agreementId"
                label="INSTANCES.AGREEMENT"
                component={DropdownField}
                options={getAgreementsOptions(values.companyId)}
                placeholder="PLACEHOLDERS.SELECT"
                fieldClassNames={{ container: styles.field }}
                disabled={!values.companyId}
                isRequired
                full
              />
            </div>
            <Field
              name="title"
              label="INSTANCES.DESCRIPTION"
              component={TextField}
              options={getAgreementsOptions(values.companyId)}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              full
            />
            <Field
              name="supplyType"
              label="INSTANCES.ADDITIONAL_FEES.FEE_TYPE"
              component={DropdownField}
              options={ADDITIONAL_FEES_TYPES}
              placeholder="PLACEHOLDERS.SELECT"
              fieldClassNames={{ container: styles.field }}
              isRequired
              full
            />
            {values.supplyType === 1 && (
              <div className={styles.row}>
                <Field
                  name="supplyId"
                  label="INSTANCES.ADDITIONAL_FEES.ADDITIONAL_SERVICE"
                  component={DropdownField}
                  options={suppliesOptions}
                  placeholder="PLACEHOLDERS.SELECT"
                  fieldClassNames={{ container: styles.field }}
                  clearButton
                  full
                />
                <Field
                  name="volume"
                  label="INSTANCES.ADDITIONAL_FEES.VOLUME"
                  component={TextField}
                  options={getAgreementsOptions(values.companyId)}
                  placeholder="PLACEHOLDERS.ENTER_VALUE"
                  fieldClassNames={{ container: styles.field }}
                  regex={inputPatterns.decimal({ decimalLimit: 3 })}
                  isRequired
                  full
                />
              </div>
            )}
            {values.supplyType === 2 && (
              <Field
                name="preRatedCost"
                label="INSTANCES.ADDITIONAL_FEES.PRE_RATED_COST"
                component={TextField}
                options={getAgreementsOptions(values.companyId)}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                fieldClassNames={{ container: styles.field }}
                regex={inputPatterns.decimal({ decimalLimit: 5 })}
                isRequired
                full
              />
            )}
            {priceInfo && values.agreementId && values.supplyType === 1 && (
              <div>
                <ChargePrice {...priceInfo} />
              </div>
            )}
          </FormContentWrapper>
          <FormButtonsGroup
            showDelete={false}
            onCancel={onCancel}
            onApply={handleSubmit}
            isPending={isPending}
            setFieldTouched={setFieldTouched}
            errors={errors}
            mode="add"
          />
          <OnChangeComponent field="companyId" onChange={onChangeCompanyId} />
          <OnChangeComponent field="supplyType" onChange={onChangeFeeType} />
          <OnFormChange callback={onChangeFormValues} />
        </Form>
      )}
    </Formik>
  );
};
