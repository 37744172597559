import React, { useRef } from 'react';
import { Icon } from '@blueprintjs/core';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Spinner from '@setproduct-ui/core/Spinner';
import Chips from '@setproduct-ui/core/Chips';
import { Typography } from 'components/index';
import { useAssetsApi } from 'hooks/api';
import { ImageIcon } from 'assets/icons';
import toastRef from 'helpers/toast';

import './style.scss';

const FileInput = ({
  form: { setFieldValue },
  field: {
    value,
    name,
  } = {},
  text,
  styles = {},
  label,
  isDiff,
  currentValue,
  disabled,
  withDownloadButton,
  highlightChanged,
  fileWidth,
  fileHeight,
  maxWeight,
}) => {
  const fileInputRef = useRef(null);
  const chipsStyles = color => ({
    backgroundColor: `var(--${color}30)`,
    position: 'absolute',
    top: 4,
    left: 4,
  });
  const wrapperStyles = {
    display: 'flex',
    flexDirection: name === 'bannerImageUrl' ? 'column' : 'row',
  };
  const currentImageStyles = name === 'bannerImageUrl' ? {
    marginBottom: 19,
  } : { marginRight: 19 };

  const { t } = useTranslation();
  const {
    postAddAsset,
    isPendingAddAsset,
  } = useAssetsApi();

  const onRemove = () => {
    setFieldValue(name, null);
  };
  const onDownloadCurrent = () => {
    saveAs(currentValue, name);
  };
  const handleChange = (e) => {
    if (!e.target.files[0].type.includes('image')) {
      toastRef.current.showMessage({ message: 'Unsupported file format!' });
      e.target.value = '';
      return;
    }
    if (e.target.files[0].size > maxWeight) {
      toastRef.current.showMessage({ message: 'File size is larger than allowed' });
      e.target.value = '';
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      // eslint-disable-next-line func-names
      image.onload = function () {
        const { width, height } = this;

        if (width !== fileWidth || height !== fileHeight) {
          toastRef.current.showMessage({ message: 'Wrong file resolution' });
          e.target.value = '';
          return;
        }

        const form = new FormData();

        form.append('multipartFiles', e.target.files[0]);

        postAddAsset({
          body: form,
          successCallback: (res) => {
            setFieldValue(
              name,
              res[0]?.fileUrl,
            );
          },
          errorCallback: () => {
            e.target.value = '';
          },
        });
      };
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onSaveClick = () => {
    saveAs(value);
  };

  const onUploadClick = () => {
    fileInputRef.current?.click();
  };

  if (value) {
    return (
      <div className="image-field" style={styles.container}>
        <Typography className="image-field__label">
          {label}
        </Typography>
        <div className="image-field__container">
          <div style={wrapperStyles}>
            {
            isDiff && !highlightChanged && (
              <div
                className="image-field__wrapper image-field__wrapper_border"
                style={{ ...styles.image, ...currentImageStyles, borderColor: 'var(--grey30)' }}
              >
                <Chips
                  tag={t('SCREENS.APPLICATIONS.CURRENT')}
                  type="dense"
                  className="image-field__chips"
                  style={chipsStyles('grey')}
                />
                <img
                  src={currentValue}
                  className="image-field__image"
                  alt=""
                />
              </div>
            )
          }
            <div
              className={cx('image-field__wrapper', {
                'image-field__wrapper_uploaded': !isDiff,
                'image-field__wrapper_border': isDiff,
              })}
              style={{ ...styles.image, borderColor: isDiff ? 'var(--blue30)' : 'transparent' }}
            >
              {
              isDiff && !highlightChanged && (
                <Chips
                  tag={t('SCREENS.APPLICATIONS.NEW')}
                  type="dense"
                  className="image-field__chips"
                  style={chipsStyles('blue')}
                />
              )
            }
              <img
                src={value}
                className="image-field__image"
                alt=""
              />
            </div>
          </div>
          <div className="image-field__controls">
            <Icon
              htmlTitle={t('HINTS.SENDER_IDS.REPLACE')}
              color="var(--grey40)"
              icon="log-out"
              size={18}
              style={{ rotate: '-90deg' }}
              onClick={onUploadClick}
            />
            {withDownloadButton && (
              <Icon
                htmlTitle={t('HINTS.SENDER_IDS.DOWNLOAD')}
                color="var(--grey40)"
                icon="log-in"
                size={18}
                style={{ rotate: '90deg' }}
                onClick={currentValue ? onDownloadCurrent : onSaveClick}
              />
            )}
            <Icon
              htmlTitle={t('HINTS.SENDER_IDS.REMOVE')}
              color="var(--grey40)"
              icon="trash"
              size={18}
              onClick={onRemove}
            />
          </div>
        </div>
        <input
          ref={fileInputRef}
          id={name}
          type="file"
          onChange={handleChange}
          accept=".png, .jpg, .jpeg"
          className="image-field__input"
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <label htmlFor={name} className="image-field" style={styles.container}>
      <Typography className="image-field__label">
        {label}
      </Typography>
      <div style={wrapperStyles}>
        {
          isDiff && !highlightChanged && (
            <div
              className="image-field__wrapper image-field__wrapper_border"
              style={{ ...styles.image, ...currentImageStyles, borderColor: 'var(--grey30)' }}
            >
              <Chips
                tag={t('SCREENS.APPLICATIONS.CURRENT')}
                type="dense"
                className="image-field__chips"
                style={chipsStyles('grey')}
              />
              <img
                src={currentValue}
                className="image-field__image"
                alt=""
              />
              <Icon
                icon="cloud-download"
                color="var(--grey100)"
                size={20}
                style={{ opacity: 0.7 }}
                className="image-field__remove"
                onClick={onDownloadCurrent}
              />
            </div>
          )
        }
        <div className="image-field__wrapper" style={styles.image}>
          {
            isPendingAddAsset ? (
              <Spinner size={20} color="primary" />
            ) : (
              <>
                <ImageIcon />
                <div className="image-field__row">
                  <Icon
                    icon="add"
                    color="var(--blue30)"
                    size={20}
                    style={{ marginRight: 8 }}
                  />
                  <Typography className="image-field__text">
                    {text}
                  </Typography>
                </div>
              </>
            )
          }
        </div>
      </div>
      <input
        ref={fileInputRef}
        id={name}
        type="file"
        onChange={handleChange}
        accept=".png, .jpg, .jpeg"
        className="image-field__input"
        disabled={disabled}
      />
    </label>
  );
};

export default FileInput;
